import axios from "axios";
import React, { useEffect, useState } from "react";
import base_url from "../api/bootapi";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./../../assets/images/no-data-found.png";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const AccountFees = () => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [invoicedetails, setinvoicedetails] = useState([]);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [paymentpageno, setpaymentpageno] = useState(0);
  const [paymentgetpageno, setpaymentgetpageno] = useState();
  const [paymentdetails, setpaymentdetails] = useState([]);
  const [paymenttotalUser, setpaymenttotalUser] = useState();
  const [paymentcountnumber, setpaymentcountnumber] = useState();
  const [paymentnumberofElements, setpaymentnumberofElements] = useState();
  const classes = useStyles();
  const [backdropopen, setbackdropOpen] = useState(false);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getinvoice(pageno);
    getpaymentschedule(paymentpageno);
  }, []);
  const handleinvoicepage = (event, value) => {
    setpageno(value - 1);
    getinvoice(value - 1);
  };
  const handlepaymentpage = (event, value) => {
    setpaymentpageno(value - 1);
    getpaymentschedule(value - 1);
  };
  const getinvoice = (value) => {
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getInvoiceByClientId`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          clientId: clientdetails?.clientId,
          columnName: "invoice_created_date",
          direction: "DESC",
          page: Number(value),
          size: 10,
        },
      })
      .then((res) => {
        setinvoicedetails(res.data.data.content || []);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
        setbackdropOpen(false);
      })
      .catch((err) => {
        setbackdropOpen(false);
        setinvoicedetails([]);
      });
  };

  const getpaymentschedule = (value) => {
    axios
      .get(`${base_url.api5}/getEnquirySemesterByClientId`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          clientId: clientdetails?.clientId,
          columnName: "id",
          direction: "ASC",
          page: Number(value),
          size: 10,
        },
      })
      .then((res) => {
        setpaymentdetails(res.data.data.content || []);
        setpaymentgetpageno(res.data.data.totalPages);
        setpaymenttotalUser(res.data.data.totalElements);
        setpaymentcountnumber(res.data.data.number);
        setpaymentnumberofElements(res.data.data.numberOfElements);
      })
      .catch((err) => setinvoicedetails([]));
  };
  return (
    <>
      <div className="show-desktop-only">
        <div class="accountbody">
          <h2 className="accountbody-title">Fee & Payment</h2>
          <ul className="nav nav-tabs accountbody_tabnav">
            <li className="nav-item row mx-0 ">
              <a className="nav-link active" data-toggle="tab" href="#tution">
                Tution Fee
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#payment">
                Payment Schedule
              </a>
            </li>
          </ul>
          <div className="accountbody accountenquiry">
            <Backdrop className={classes.backdrop} open={backdropopen}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <div className="tab-content">
              <div id="tution" className=" tab-pane active">
                <div style={{ marginBottom: "15px", overflowX: "auto" }}>
                  {invoicedetails.length > 0 ? (
                    <table className="table tbb">
                      <thead className="thb">
                        <tr>
                          <th>SN</th>
                          <th style={{ minWidth: "200px" }}>WorkFlow</th>
                          <th style={{ minWidth: "200px" }}>Provider</th>
                          <th style={{ minWidth: "200px" }}>Course</th>
                          <th style={{ minWidth: "150px" }}>Paid Amount</th>
                          <th style={{ minWidth: "150px" }}>Total Amount</th>
                          <th style={{ minWidth: "130px" }}>Due Date</th>
                          <th style={{ minWidth: "125px" }}>Paid Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoicedetails.map((p, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{p.enquiryName}</td>
                            <td>
                              {p.serviceProvider?.split("-")
                                ? p.serviceProvider?.split("-")[0]
                                : p.serviceProvider}
                            </td>
                            <td>{p.courseName}</td>
                            <td className="text-center">{p.totalPaid}</td>
                            <td className="text-center">
                              {p.totalAmountIncludingTax}
                            </td>
                            <td className="text-center">
                              {p.invoiceDueDate
                                ? moment(p.invoiceDueDate).format("DD MMM YYYY")
                                : "-"}
                              <br />
                              {p.invoiceDueDate &&
                                moment(p.invoiceDueDate).format("hh.mm a")}
                            </td>
                            <td className="text-center">
                              {p.invoicePaidDate
                                ? moment(p.invoicePaidDate).format(
                                    "DD MMM YYYY"
                                  )
                                : "-"}
                              <br />
                              {p.invoicePaidDate &&
                                moment(p.invoicePaidDate).format("hh.mm a")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <>
                      {backdropopen ? null : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ marginTop: "50px" }}
                        >
                          <img alt="No data Found" src={NoDataFound} />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: 20,
                  }}
                >
                  <div>
                    {invoicedetails?.length ? (
                      <p>
                        {countnumber * 10 + 1} to{" "}
                        {countnumber * 10 + numberofElements} of {totalUser}{" "}
                        records
                      </p>
                    ) : (
                      <p>No Record Found </p>
                    )}
                  </div>
                  <Pagination count={getpageno} onChange={handleinvoicepage} />
                </div>
              </div>
              <div id="payment" className=" tab-pane">
                <div style={{ marginBottom: "15px", overflowX: "auto" }}>
                  {paymentdetails.length > 0 ? (
                    <table className="table tbb">
                      <thead className="thb">
                        <tr>
                          <th>SN</th>
                          <th style={{ minWidth: "200px" }}>WorkFlow</th>
                          <th style={{ minWidth: "200px" }}>Provider</th>
                          <th style={{ minWidth: "200px" }}>Course</th>
                          <th>Semester</th>
                          <th style={{ minWidth: "130px" }}>Total Units</th>
                          <th>Amount</th>
                          <th style={{ minWidth: "100px" }}>Status</th>
                          <th style={{ minWidth: "150px" }}>Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentdetails.map((p, i) => (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{p.workflowName}</td>
                            <td>
                              {p.institutionName?.split("-")
                                ? p.institutionName?.split("-")[0]
                                : p.institutionName}
                            </td>
                            <td>{p.courseName}</td>
                            <td className="text-center">{p.semester}</td>
                            <td className="text-center">{p.numberOfUnits}</td>
                            <td className="text-center">{p.amount}</td>
                            <td>{p.paidStatus ? "Paid" : "Not Paid"}</td>
                            <td>
                              {moment(p.dueDate).format("DD MMM YYYY")}
                              <br />
                              {moment(p.dueDate).format("hh.mm a")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ marginTop: "50px" }}
                    >
                      <img alt="No data Found" src={NoDataFound} />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: 20,
                  }}
                >
                  <div>
                    {paymentdetails?.length ? (
                      <p>
                        {paymentcountnumber * 10 + 1} to{" "}
                        {paymentcountnumber * 10 + paymentnumberofElements} of{" "}
                        {paymenttotalUser} records
                      </p>
                    ) : (
                      <p>No Record Found </p>
                    )}
                  </div>
                  <Pagination
                    count={paymentgetpageno}
                    onChange={handlepaymentpage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="show-mobile-only">
        <div class="accountbody">
          <h2 className="accountbody-title">Fee & Payment</h2>
          <ul className="nav nav-tabs accountbody_tabnav">
            <li className="nav-item row mx-0 ">
              <a className="nav-link active" data-toggle="tab" href="#tution">
                Tution Fee
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#payment">
                Payment Schedule
              </a>
            </li>
          </ul>
          <div className="accountbody accountenquiry">
            <Backdrop className={classes.backdrop} open={backdropopen}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <div className="tab-content">
              <div id="tution" className=" tab-pane active">
                <div style={{ marginBottom: "15px", overflowX: "auto" }}>
                  {invoicedetails.length > 0 ? (
                    <table className="table tbb">
                      <tbody>
                        {invoicedetails.map((p, i) => (
                          <tr key={i}>
                            <td>
                              <div>
                                <p>
                                  <span className="visa-label-mobile">
                                    Workflow Name
                                  </span>{" "}
                                  {p.enquiryName}
                                </p>
                                <p>
                                  <span className="visa-label-mobile">
                                    Provider
                                  </span>{" "}
                                  {p.serviceProvider?.split("-")
                                    ? p.serviceProvider?.split("-")[0]
                                    : p.serviceProvider}{" "}
                                </p>

                                <p>
                                  <span className="visa-label-mobile">
                                    Course
                                  </span>{" "}
                                  {p.courseName}{" "}
                                </p>
                                <p>
                                  <span className="visa-label-mobile">
                                    Paid Amount
                                  </span>{" "}
                                  {p.totalPaid}
                                </p>

                                <p>
                                  <span className="visa-label-mobile">
                                    Total Amount
                                  </span>{" "}
                                  {p.totalAmountIncludingTax}
                                </p>
                                <p>
                                  <span className="visa-label-mobile">
                                    Due Date
                                  </span>{" "}
                                  {p.invoiceDueDate
                                    ? moment(p.invoiceDueDate).format(
                                        "DD MMM YYYY"
                                      )
                                    : "-"}
                                  <br />
                                  {p.invoiceDueDate &&
                                    moment(p.invoiceDueDate).format("hh.mm a")}
                                </p>
                                <p>
                                  <span className="visa-label-mobile">
                                    Paid Date
                                  </span>{" "}
                                  {p.invoicePaidDate
                                    ? moment(p.invoicePaidDate).format(
                                        "DD MMM YYYY"
                                      )
                                    : "-"}
                                  <br />
                                  {p.invoicePaidDate &&
                                    moment(p.invoicePaidDate).format("hh.mm a")}
                                </p>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <>
                      {backdropopen ? null : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ marginTop: "50px" }}
                        >
                          <img alt="No data Found" src={NoDataFound} />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: 20,
                  }}
                >
                  <div>
                    {invoicedetails?.length ? (
                      <p>
                        {countnumber * 10 + 1} to{" "}
                        {countnumber * 10 + numberofElements} of {totalUser}{" "}
                        records
                      </p>
                    ) : (
                      <p>No Record Found </p>
                    )}
                  </div>
                  <Pagination count={getpageno} onChange={handleinvoicepage} />
                </div>
              </div>
              <div id="payment" className=" tab-pane">
                <div style={{ marginBottom: "15px", overflowX: "auto" }}>
                  {paymentdetails.length > 0 ? (
                    <table className="table tbb">
                      <tbody>
                        {paymentdetails.map((p, i) => (
                          <tr key={i}>
                            <td>
                              <p>
                                <span className="visa-label-mobile">
                                  WorkFlow
                                </span>{" "}
                                {p.workflowName}
                              </p>
                              <p>
                                <span className="visa-label-mobile">
                                  Provider
                                </span>{" "}
                                {p.institutionName?.split("-")
                                  ? p.institutionName?.split("-")[0]
                                  : p.institutionName}
                              </p>
                              <p>
                                <span className="visa-label-mobile">
                                  Course
                                </span>{" "}
                                {p.courseName}
                              </p>
                              <p>
                                <span className="visa-label-mobile">
                                  Semester
                                </span>{" "}
                                {p.semester}
                              </p>
                              <p>
                                <span className="visa-label-mobile">
                                  Total Units
                                </span>{" "}
                                {p.numberOfUnits}
                              </p>
                              <p>
                                <span className="visa-label-mobile">
                                  Amount
                                </span>{" "}
                                {p.amount}{" "}
                              </p>
                              <p>
                                <span className="visa-label-mobile">
                                  Status
                                </span>{" "}
                                {p.paidStatus ? "Paid" : "Not Paid"}
                              </p>
                              <p>
                                <span className="visa-label-mobile">
                                  Due Date
                                </span>{" "}
                                {moment(p.dueDate).format("DD MMM YYYY")}
                                <br />
                                {moment(p.dueDate).format("hh.mm a")}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ marginTop: "50px" }}
                    >
                      <img alt="No data Found" src={NoDataFound} />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: 20,
                  }}
                >
                  <div>
                    {paymentdetails?.length ? (
                      <p>
                        {paymentcountnumber * 10 + 1} to{" "}
                        {paymentcountnumber * 10 + paymentnumberofElements} of{" "}
                        {paymenttotalUser} records
                      </p>
                    ) : (
                      <p>No Record Found </p>
                    )}
                  </div>
                  <Pagination
                    count={paymentgetpageno}
                    onChange={handlepaymentpage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountFees;
