import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../api/bootapi";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";

import SelectCurrency from "react-select-currency";
import Modal from "react-modal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const LeadProfile = () => {
  const [clientDetail, setclientDetail] = useState();
  const [region, setregion] = useState();
  const [country1, setcountry] = useState();
  const [countryrequired, setcountryrequired] = useState(false);
  const [staterequired, setstaterequired] = useState(false);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  // const [stateCust, setstateCust] = useState();
  const classes = useStyles();
  const [backdropopen, setbackdropOpen] = useState(false);

  const loggedInUser = localStorage.getItem("LeadLogin");

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */

    getLeadDetails();
  }, []);

  const getLeadDetails = () => {
    axios
      .get(`${base_url.api5}//getClientTempById`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          clientTempId: clientdetails?.clientTempId,
        },
      })
      .then((res) => {
        //console.log("62----->", res.data.data);
        setclientDetail(res.data.data);
        setcountry(res.data.data.country);
        setregion(res.data.data.state);
      })
      .catch((e) => {
        //console.log("Error", e);
      });
  };

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   reset,
  // } = useForm({});

  const selectCountry = (val) => {
    setcountry(val);
  };

  const selectRegion = (val) => {
    setregion(val);
    setstaterequired(false);
  };

  const inputEvent = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setclientDetail((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    // stateCust.clientDetail = clientDetail;
  };

  const editprofile = (event) => {
    event.preventDefault();
    if (!country1) {
      setcountryrequired(true);
      setstaterequired(false);
      return;
    } else if (!region) {
      setcountryrequired(false);
      setstaterequired(true);
      return;
    }

    let firstName = event.target[0].value;
    let middleName = event.target[1].value;
    let lastName = event.target[2].value;
    let gender = event.target[3].value;
    let email = event.target[4].value;
    let phone = event.target[5].value;

    setcountryrequired(false);
    setstaterequired(false);
    setbackdropOpen(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];

    let data = {
      id: clientdetails?.clientTempId,
      firstName,
      lastName,
      middleName,
      country: country1,
      state: region,
      email,
      phone: Number(phone),
      updatedOn: datenow,
    };

    //console.log("234---->", data);

    axios
      .post(`${base_url.api5}/updateClientTempById`, data, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
        },
      })
      .then((res) => {
        toast.success("Profile Updated Successfully");
        getLeadDetails();
        setbackdropOpen(false);
      })
      .catch((err) => {
        setbackdropOpen(false);
        toast.error("Something went wrong, Please try again!!");
      });
  };

  return (
    <div class="accountbody">
      <h2 className="accountbody-title">Edit Profile</h2>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <form
          onSubmit={editprofile}
          className="accountenquiry account-profilebox"
        >
          <div className="my-4 row">
            <div className="col-md-4">
              <label>
                First Name<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  name="firstName"
                  className="form-control"
                  placeholder="First Name"
                  value={clientDetail?.firstName}
                  onChange={inputEvent}
                  required
                />
                {clientDetail?.firstName === "" && (
                  <p className="errormessage mb-0">First Name is required</p>
                )}
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Middle Name</label>
              <div style={{ position: "relative" }}>
                <input
                  name="middleName"
                  className="form-control"
                  placeholder="Middle Name"
                  value={clientDetail?.middleName}
                  onChange={inputEvent}
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                Last Name<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  name="lastName"
                  className="form-control"
                  placeholder="Last Name"
                  value={clientDetail?.lastName}
                  onChange={inputEvent}
                  required
                />
                {clientDetail?.lastName === "" && (
                  <p className="errormessage mb-0">Last Name is required</p>
                )}
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                Gender<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <select
                  name="gender"
                  className="form-control selecttag2"
                  onChange={inputEvent}
                  value={clientDetail?.gender}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                {clientDetail?.gender === "" && (
                  <p className="errormessage mb-0">Gender is required</p>
                )}
                <i className="fa fa-user-o designi" />
              </div>
            </div>
          </div>

          <div className="account-profilebox-subtitle">
            <h4>Contact Details</h4>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>Email</label>
              <div style={{ position: "relative" }}>
                <input
                  name="email"
                  className="readonlyformcontrol"
                  value={clientDetail?.email}
                  readOnly
                />
                <i className="fa fa-envelope-o designi" />
              </div>
            </div>

            <div className="col-md-4">
              <label>Phone Number</label>
              <div style={{ position: "relative" }}>
                <input
                  name="phone"
                  className="form-control"
                  type="number"
                  value={clientDetail?.phone}
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Phone Number"
                  onChange={inputEvent}
                  required
                />
                <i className="fa fa-volume-control-phone designi" />
              </div>
            </div>
          </div>
          <div className="account-profilebox-subtitle">
            <h4>Address</h4>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>
                Country<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <CountryDropdown
                  className="form-control selecttag2"
                  value={country1}
                  onChange={(val) => selectCountry(val)}
                />
                <i className="fa fa-globe designi" />
              </div>
              {countryrequired && (
                <p className="errormessage mb-0">Country is required</p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                State<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                {country1 === "Nepal" ? (
                  <select
                    className="form-control selecttag2"
                    onChange={(e) => selectRegion(e.target.value)}
                  >
                    <option value="">Select Province</option>
                    <option value="Province No. 1">Province No. 1</option>
                    <option value="Madhesh Province">Madhesh Province</option>
                    <option value="Bagmati Province">Bagmati Province</option>
                    <option value="Gandaki Province">Gandaki Province</option>
                    <option value="Lumbini Province">Lumbini Province</option>
                    <option value="Karnali Province">Karnali Province</option>
                    <option value="Sudurpashchim Province">
                      Sudurpashchim Province
                    </option>
                  </select>
                ) : (
                  <RegionDropdown
                    className="form-control selecttag2"
                    defaultOptionLabel="Select State"
                    country={country1}
                    value={region}
                    onChange={(val) => selectRegion(val)}
                  />
                )}
                <i className="fa fa-globe designi" />
              </div>
              {staterequired && (
                <p className="errormessage mb-0">State is required</p>
              )}
            </div>
          </div>
          <button type="submit" className="btn">
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default LeadProfile;
