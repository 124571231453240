import axios from "axios";
import React, { useState, useEffect } from "react";
import base_url from "../api/bootapi";
import Modal from "react-bootstrap/Modal";
import Typography from "@mui/material/Typography";

import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";

const CompareModal = (props) => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [alreadycomparedmodal, setalreadycomparedmodal] = useState(false);
  const location = useLocation();

  const handlealreadycomparedmodal = () =>
    setalreadycomparedmodal(!alreadycomparedmodal);

  useEffect(() => {
    //console.log("print7--->");
    // props.Sangamgetcomparelist();
  }, []);

  const agentLoginCheck = JSON.parse(localStorage.getItem("agentLogin"));

  const clientLoginCheck = JSON.parse(localStorage.getItem("clientLogin"));

  const handlecomparechange = () => {
    props.setbackdropOpen(true);
    let value =
      props.comparecheck?.[`${props.p.courseId}${props.p.branchInstitutionId}`];
    if (clientdetails?.token) {
      if (value) {
        props.setbackdropOpen(false);
        handlealreadycomparedmodal();
      } else {
        if (agentLoginCheck === 1) {
          axios
            .get(`${base_url.api5}/fetchCompareListByAgentId`, {
              headers: { agentId: clientdetails?.agentId },
            })
            .then((res) => {
              if (res.data.data.length <= 3) {
                addtocompare("agent");
                props.getavailablecourses();
                //console.log("print8--->");
                props.Sangamgetcomparelist();
              } else {
                props.getavailablecourses();
                props.setbackdropOpen(false);
                props.toast.error("Cant Add More than 4 course to compare!");
                //console.log("print9--->");
                props.Sangamgetcomparelist();
              }
            })
            .catch((err) => addtocompare("agent"));
        } else if (clientLoginCheck === 1) {
          //console.log("coming in else");
          axios
            .get(`${base_url.api5}/fetchClientCompareListByClientId`, {
              headers: { clientId: clientdetails?.clientId },
            })
            .then((res) => {
              if (res.data.data.length <= 3) {
                addtocompare("client");
                props.getavailablecourses();
                //console.log("print10--->");
                props.Sangamgetcomparelist();
              } else {
                // props.getavailablecourses();
                props.setbackdropOpen(false);
                props.toast.error("Cant Add More than 4 course to compare!");
                //console.log("print11--->");
                props.Sangamgetcomparelist();
              }
            })
            .catch((err) => addtocompare("client"));
        } else {
          axios
            .get(`${base_url.api5}/fetchClientCompareListByClientTempId`, {
              headers: {
                clientTempId: clientdetails?.clientId,
              },
            })
            .then((res) => {
              if (res.data.data.length <= 3) {
                //console.log("print12--->");
                props.Sangamgetcomparelist();
                addtocompare("clienttemp");
                props.getavailablecourses();
              } else {
                //console.log("print13--->");
                props.Sangamgetcomparelist();
                props.setbackdropOpen(false);
                props.toast.error("Cant Add More than 4 course to compare!");
              }
            })
            .catch((err) => addtocompare("clienttemp"));
        }
      }
    } else {
      //console.log("print14--->");
      props.Sangamgetcomparelist();
      props.getavailablecourses();
      props.setbackdropOpen(false);
      sessionStorage.setItem("prev_path_before_login", location.pathname);
      // props.navigate("/login");
      props.navigate("/force-login");
    }
  };

  const addtocompare = (forwhom) => {
    let comparecourse = `${props.p.courseId}${props.p.branchInstitutionId}`;
    props.setcomparecheck({
      ...props.comparecheck,
      [comparecourse]: true,
    });
    let data;
    if (forwhom === "agent") {
      data = [
        {
          agentId: clientdetails?.agentId,
          courseId: props.p.courseId,
          branchId: props.p.branchInstitutionId,
          id: 0,
        },
      ];
    } else if (forwhom === "client") {
      data = [
        {
          clientId: clientdetails?.clientId,
          courseId: props.p.courseId,
          branchId: props.p.branchInstitutionId,
          id: 0,
        },
      ];
    } else {
      data = [
        {
          clientTempId: clientdetails?.clientId,
          courseId: props.p.courseId,
          branchId: props.p.branchInstitutionId,
          id: 0,
        },
      ];
    }
    axios.post(`${base_url.api5}/addToCompare`, data).then((res) => {
      props.toast.success("List Added to Compare");
      props.getheader(props.pageno, props.columnName);
      //console.log("print15--->");
      props.Sangamgetcomparelist();
    });
  };

  return (
    <div className="p-2">
      <button
        onClick={handlecomparechange}
        className="btn coursesearchbody_comparebutton w-100"
      >
        {props.comparecheck?.[
          `${props.p.courseId}${props.p.branchInstitutionId}`
        ] === true
          ? "Comparing!!"
          : "+ Add to Compare"}
      </button>

      <Modal show={alreadycomparedmodal} onHide={handlealreadycomparedmodal}>
        <Modal.Header closeButton>
          <Modal.Title>Compared!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography variant="h5" color="primary">
            Already Added to Compared List
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlealreadycomparedmodal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => props.navigate("/course-compare")}
          >
            ComparedList
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompareModal;
