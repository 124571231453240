import React, { useState } from "react";
import base_url from "../Components/api/bootapi";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));
const EnquiryFileDownloadService = (props) => {
  const classes = useStyles();
  const [backdrop, setbackdrop] = useState(false);

  const downloadAll = (path, clientId) => {
    setbackdrop(true);
    const clientdetails = JSON.parse(
      localStorage.getItem("isw_client_details_frontend")
    );
    let file_name = path.split("amazonaws.com/")[1];
    const header = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${clientdetails?.token}`,
        clientId: clientId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api5}/downloadEnquiryAttachmentByClientIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        const downloadFileType = localStorage.getItem(
          "enquiry_attachment_file_content_type"
        );
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdrop(false);
      })
      .catch((err) => setbackdrop(false));
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "enquiry_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };
  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <span
        className="filedownloadtd"
        onClick={() => downloadAll(props.path, props.client_id)}
      >
        {props.name}
      </span>
    </div>
  );
};

export default EnquiryFileDownloadService;
