import React, { useState, useEffect } from "react";
import Footer from "./../Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import base_url from "./../api/bootapi";
// import ReactNavbar from "../Navbar/Navbar";
import NavbarNew from "../Navbar/NavbarNew";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const VerifyAgent = () => {
  const [backdropopen, setbackdropOpen] = useState(false);
  const classes = useStyles();
  let navigate = useNavigate();
  let params = useParams();
  const [userVerify, setuserVerify] = useState();
  const [error, seterror] = useState();
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    setbackdropOpen(true);
    verifyClient();
  }, []);

  const verifyClient = () => {
    axios
      .get(
        `${
          base_url.api5
        }/agentEmailVerification?verificationToken=${params.token.trim()}&clientEmailId=${params.email.trim()}`
      )
      .then((res) => {
        setbackdropOpen(false);
        setuserVerify(true);
      })
      .catch((err) => {
        setbackdropOpen(false);
        if (err.response) {
          seterror(err.response.data.message);
        } else {
          seterror("Something went wrong");
        }
      });
  };

  const gotologin = () => navigate("/agent-login");
  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavbarNew fromwhere={"Verify"} />
      <div className="login resetcontainer">
        <h2 className="text-center mb-0">Verify Agent</h2>
        <h2 className="text-center">International Student World</h2>
        <div className="login_container loginwidthsize">
          {userVerify && (
            <div className="alert alert-success text-center my-4">
              <strong>Success!! </strong>
              Your Account Have been verified
            </div>
          )}
          {error && (
            <div className="alert alert-danger text-center mt-4">
              <strong>Error!! </strong>
              {error}
            </div>
          )}
          <button onClick={gotologin} className="btn mt-4">
            Go to Login
          </button>
        </div>
      </div>
      <div className="loginpage_footer">
        <Footer />
      </div>
    </div>
  );
};

export default VerifyAgent;
