import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../../assets/images/downlogo.png";
import AccountBody from "./AccountBody";
import AccountSidebar from "./AccountSidebar";
import AgentClients from "./AgentClients";
import { useStateValue } from "../../ContextAPI/StateProvider";
import { toast } from "react-toastify";
import MenuIcon from "@mui/icons-material/Menu";
import LeadDash from "./LeadDash";
import LeadSidebar from "./LeadSidebar";

const Account = (props) => {
  //console.log(props);
  const [toggle, setToggle] = useState(true);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  //console.log(clientdetails);
  const [sidebartab, setsidebartab] = useState("dashboard");
  const loggedInUser = localStorage.getItem("agentLogin");

  const agentLoginCheck = JSON.parse(localStorage.getItem("agentLogin"));

  const clientLoginCheck = JSON.parse(localStorage.getItem("clientLogin"));

  const handlesidebartab = (val) => setsidebartab(val);
  let navigate = useNavigate();
  let location = useLocation();
  const [{ user }, dispatch] = useStateValue();
  const handleimage = () => navigate("/");
  const handledashboard = () => navigate("/myaccount");
  const handlesearch = () => navigate("/popular_course");
  const handleinstitution = () => navigate("/institution");

  const handlecompare = () => {
    if (!clientdetails?.clientId) {
      sessionStorage.setItem("prev_path_before_login", "/course-compare");
      // navigate("/login");
      navigate("/force-login");
    } else {
      navigate("/course-compare");
    }
  };

  const handlelogin = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/login");
  };
  const handleAgentlogin = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/agent-login");
  };
  const handleregister = () => navigate("/register");
  const handleAgentregister = () => navigate("/agent-register");

  const handlelogout = () => {
    dispatch({
      type: "SET_USER",
      user: null,
    });

    localStorage.clear();
    navigate("/login");
    toast.success("Logged Out Successfully");
    localStorage.setItem("countryvalue", "Australia");
  };

  const handlecontact = () => navigate("/contactus");

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 71);
    });
  }, []);

  const handleAusEducation = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/education");
  };
  const handleChangeProvider = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/provider");
  };

  return (
    <>
      <div className="main-header account-header">
        <div className={scroll ? "bg-white" : null}>
          <div className="container-fluid pt-3 pb-3">
            <div className="row">
              <div className="col-6 col-lg-2 col-md-2">
                <img
                  className="cursor-pointer"
                  onClick={handleimage}
                  src={Logo}
                  alt="isw_logo"
                />
              </div>
              <div className="col-6 col-lg-10 col-md-10">
                <nav className="navbar navbar-expand-lg new-navbar-change">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon">
                      <i
                        onClick={() => setToggle(!toggle)}
                        className={toggle ? "fa fa-bars" : "fa fa-close"}
                        aria-hidden="true"
                      ></i>
                    </span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item active">
                        <span
                          class="nav-link text-dark nav-link active"
                          onClick={handledashboard}
                        >
                          Dashboard
                        </span>
                      </li>
                      <li className="nav-item">
                        <span
                          class="nav-link text-dark"
                          onClick={handleinstitution}
                        >
                          Institution
                        </span>
                      </li>
                      <li className="nav-item">
                        <span class="nav-link text-dark" onClick={handlesearch}>
                          Popular Course
                        </span>
                      </li>

                      <li className="nav-item">
                        <span
                          class="nav-link text-dark"
                          onClick={handlecompare}
                        >
                          Choose &amp; Compare
                        </span>
                      </li>
                      <li className="nav-item">
                        <span
                          class="nav-link text-dark"
                          onClick={handlecontact}
                        >
                          Contact Us
                        </span>
                      </li>

                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Resources
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <a
                            className="dropdown-item"
                            onClick={handleAusEducation}
                          >
                            Australian's education System
                          </a>
                          <a
                            className="dropdown-item"
                            onClick={handleChangeProvider}
                          >
                            Changing your course or provider
                          </a>
                        </div>
                      </li>

                      <li className="nav-item">
                        <span
                          className="nav-link nav-item-enquiry"
                          onClick={handlecontact}
                        >
                          <i className="fa fa-star"></i> Enquiry
                        </span>
                      </li>
                    </ul>

                    {clientdetails?.clientId ? (
                      <div className="my-account-dash-name-container">
                        <div>
                          <span>Welcome, {clientdetails?.firstName}</span>
                          <br />
                          <span>{clientdetails?.username}</span>
                        </div>
                        <ul
                          className="navbar-nav"
                          style={{ cursor: "pointer" }}
                        >
                          <li className="nav-item">
                            <span
                              class="font-weight-bold text-dark"
                              onClick={handlelogout}
                            >
                              <i className="fa fa-sign-out"></i> Logout
                            </span>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <>
                        <ul className="navbar-nav">
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Log In
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <a
                                className="dropdown-item"
                                onClick={handlelogin}
                              >
                                User Login
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={handleAgentlogin}
                              >
                                Agent Login
                              </a>
                            </div>
                          </li>

                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle nav-item-signup"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Sign Up
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <a
                                className="dropdown-item"
                                onClick={handleregister}
                              >
                                Student SignUp
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={handleAgentregister}
                              >
                                Agent SignUp
                              </a>
                            </div>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mainaccount">
        {/* Account Left  */}
        <div className="mainaccount_left">
          {agentLoginCheck === 0 && clientLoginCheck === 0 ? (
            <>
              <LeadSidebar
                sidebartab={sidebartab}
                handlesidebartab={handlesidebartab}
              />
            </>
          ) : (
            <AccountSidebar
              sidebartab={sidebartab}
              handlesidebartab={handlesidebartab}
            />
          )}
        </div>
        {/* Account Right  */}
        <div className="mainaccount_right">
          {/* {Number(loggedInUser) === 0 ? (
            <AccountBody sidebartab={sidebartab} />
          ) : (
            <AgentClients sidebartab={sidebartab} />
          )} */}

          {clientLoginCheck === 1 ? (
            <AccountBody sidebartab={sidebartab} />
          ) : (
            <>
              {agentLoginCheck === 1 ? (
                <AgentClients sidebartab={sidebartab} />
              ) : (
                <>
                  <LeadDash sidebartab={sidebartab} />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Account;
