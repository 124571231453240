import React from "react";
import AccountMainBody from "../AccountMainBody";
// import AccountEnquiry from "../AccountEnquiry";
import AccountInbox from "../AccountInbox";
import AccountSendMessage from "../AccountSendMessage";
import ReactNavbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import EditAccount from "../EditAccount";
import ChangePassword from "../ChangePassword";
import AccountFees from "../AccountFees";
import AgentFees from "../AgentFees";
import LeadCompare from "../LeadCompare";
import LeadProfile from "../LeadProfile";
import ApplicationForm from "../ApplicationForm";
import LeadMainBody from "../LeadMainBody";
import DocumentUpload from "../DocumentUpload";
import ReceiptUpload from "../ReceiptUpload";
import OfferLetter from "../OfferLetter";
import OtherDocuments from "../OtherDocuments";
import Coe from "../Coe";
const AccountBody = (props) => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  return (
    <div>
      <ReactNavbar
        // closeModal={closeModal}
        // openModal={openModal}
        fromwhere={"Account"}
      />
      {props.sidebartab === "dashboard" ? (
        <AccountMainBody />
      ) : (
        <>
          {props.sidebartab === "fees" ? (
            <div>
              {clientdetails?.agentId ? <AgentFees /> : <AccountFees />}
            </div>
          ) : (
            <>
              {props.sidebartab === "inbox" ? (
                <AccountInbox />
              ) : (
                <>
                  {props.sidebartab === "message" ? (
                    <AccountSendMessage />
                  ) : (
                    <>
                      {props.sidebartab === "edit" ? (
                        <EditAccount />
                      ) : (
                        <>
                          {props.sidebartab === "comapre" ? (
                            <LeadCompare />
                          ) : (
                            <>
                              {props.sidebartab === "applied" ? (
                                <LeadMainBody />
                              ) : (
                                <>
                                  {props.sidebartab === "applicationform" ? (
                                    <ApplicationForm />
                                  ) : (
                                    <>
                                      {props.sidebartab === "upload" ? (
                                        <DocumentUpload />
                                      ) : (
                                        <>
                                          {props.sidebartab === "receipt" ? (
                                            <ReceiptUpload />
                                          ) : (
                                            <>
                                              {props.sidebartab ===
                                              "offerletter" ? (
                                                <OfferLetter />
                                              ) : (
                                                <>
                                                  {props.sidebartab ===
                                                  "coe" ? (
                                                    <Coe />
                                                  ) : (
                                                    <>
                                                      {props.sidebartab ===
                                                      "otherDocument" ? (
                                                        <OtherDocuments />
                                                      ) : (
                                                        <ChangePassword />
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {props.sidebartab === "edit" ? (
        <Footer />
      ) : (
        <div className="account_footer">{/* <Footer /> */}</div>
      )}
    </div>
  );
};

export default AccountBody;
