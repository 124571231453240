import React, { useEffect } from "react";
// import ReactNavbar from "../Navbar/Navbar";
import { useNavigate } from "react-router-dom";
// import CourseCompareMainBody from "./CourseCompareMainBody";
import CourseCompareNew from "./CourseCompareNew";
import NavbarNew from "../Navbar/NavbarNew";

const CourseCompare = () => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  let navigate = useNavigate();

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    if (!clientdetails?.clientId) {
      navigate("/");
    }
  }, []);
  return (
    <div>
      <NavbarNew />
      {/* <CourseCompareMainBody /> */}
      <CourseCompareNew />
    </div>
  );
};

export default CourseCompare;
