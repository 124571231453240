import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const CourseCompareRequirementModal = ({
  requirement,
  fromwhere,
  whattoshow,
  overview,
  objective,
}) => {
  const [requirementmodal, setrequirementmodal] = useState(false);
  const [overviewmodal, setoverviewmodal] = useState(false);
  const [objectivemodal, setobjectivemodal] = useState(false);

  const handlemodal = () => {
    requirement && setrequirementmodal(!requirementmodal);
  };
  const handleoverviewmodal = () =>
    overview && setoverviewmodal(!overviewmodal);

  const handleobjectivemodal = () =>
    objective && setobjectivemodal(!objectivemodal);
  return (
    <div className="d-flex justify-content-center">
      {whattoshow === "overview" ? (
        <p
          className="learnmoretext"
          style={{ width: "auto" }}
          onClick={handleoverviewmodal}
        >
          {overview ? "View CourseOverview" : "-"}
        </p>
      ) :

        whattoshow === "objective" ? (
          <p
            className="learnmoretext"
            style={{ width: "auto" }}
            onClick={handleobjectivemodal}
          >
            {objective ? "View CourseObjective" : "-"}
          </p>

        ) :

          fromwhere === "courselist" ? (
            <p className="learnmoretext" onClick={handlemodal}>
              Learn More
            </p>
          ) : (
            <p
              className="learnmoretext"
              style={{ width: "auto" }}
              onClick={handlemodal}
            >
              {requirement ? "View Requirement" : "-"}
            </p>
          )}

      <Modal
        show={requirementmodal}
        onHide={handlemodal}
        centered
        size="lg"
        className="changecolormodal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Requirement</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div
            dangerouslySetInnerHTML={{
              __html: requirement || "No Data Available!",
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handlemodal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={overviewmodal}
        onHide={handleoverviewmodal}
        centered
        size="lg"
        className="changecolormodal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Overview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div
            dangerouslySetInnerHTML={{
              __html: overview || "-",
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleoverviewmodal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={objectivemodal}
        onHide={handleobjectivemodal}
        centered
        size="lg"
        className="changecolormodal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Objective</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div
            dangerouslySetInnerHTML={{
              __html: objective || "-",
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleobjectivemodal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CourseCompareRequirementModal;
