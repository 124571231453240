import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useStateValue } from "../../ContextAPI/StateProvider";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
// import ReactNavbar from "../../Navbar/Navbar";
import ReCAPTCHA from "react-google-recaptcha";
import NavbarNew from "../../Navbar/NavbarNew";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const AgentLogin = () => {
  const [backdropopen, setbackdropOpen] = useState(false);
  const classes = useStyles();
  const [showui, setshowui] = useState("agentlogin");
  const [inputtype, setinputtype] = useState("password");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [captchaerror, setcaptchaerror] = useState(false);
  const [captchavalue, setcaptchavalue] = useState("");

  // eslint-disable-next-line
  const [{}, dispatch] = useStateValue();
  let navigate = useNavigate();
  const pageStartRef = useRef(null);

  //Forgot UI UseState
  const [forgotemail, setforgotemail] = useState("");
  const [forgotemailerror, setforgotemailerror] = useState(false);
  const [forgotsuccessmessage, setforgotsuccessmessage] = useState(false);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const location_path =
    sessionStorage.getItem("prev_path_before_login") || null;

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    if (clientdetails?.clientId) {
      navigate("/");
    }
    pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const onChangeCaptcha = (value) => setcaptchavalue(value);

  const handlelogin = (data2) => {
    // if (!captchavalue) {
    //   setcaptchaerror(true);
    //   return;
    // }
    setcaptchaerror(false);
    setbackdropOpen(true);
    const data = {
      username: data2.username.trim(),
      password: data2.password.trim(),
    };
    axios
      .post(`${base_url.api5}/agentAuthenticate`, data)
      .then((res) => {
        localStorage.setItem("agentLogin", 1);
        dispatch({
          type: "SET_USER",
          user: res.data.jwtToken,
        });
        let arr = {
          token: res.data.jwtToken,
          clientId: res.data.data.agentId,
          agentId: res.data.data.agentId,
          name: res.data.data.name,
          firstName: res.data.data.name,
          lastName: null,
          username: res.data.data.email,
          phone: res.data.data.mobile,
          officeId: null,
          officeName: null,
          address: res.data.data.address,
        };
        localStorage.setItem(
          "isw_client_details_frontend",
          JSON.stringify(arr)
        );
        setbackdropOpen(false);
        toast.success("User Logged in Successfully");
        if (location_path) {
          navigate(location_path);
        } else {
          navigate("/myaccount");
        }
      })
      .catch((err) => handleerror(err));
  };

  const gotoregister = () => navigate("/agent-register");

  const submitforgotpassword = () => {
    if (!forgotemail) {
      setforgotemailerror(true);
      return;
    }
    setforgotemailerror(false);
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/forgotAgentPassword`, {
        headers: { email: forgotemail.trim() },
      })
      .then((res) => {
        setbackdropOpen(false);
        setforgotsuccessmessage(true);
      })
      .catch((err) => handleerror(err));
  };

  const handleerror = (err) => {
    setbackdropOpen(false);
    if (err.response) {
      toast.error(err.response.data.message);
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <div ref={pageStartRef}>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavbarNew />
      <div className="login">
        <h2 className="text-center mb-0">
          {showui === "agentlogin" ? "Agent Sign In To" : "Forgot Password"}
        </h2>
        <h2 className="text-center mb-4">International Student World</h2>
        {showui === "agentlogin" ? (
          <form
            onSubmit={handleSubmit(handlelogin)}
            className="login_container loginwidthsize mt-4"
          >
            <div className="my-4">
              <label>Email</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("username", {
                    required: true,
                    pattern:
                      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/,
                  })}
                  className="form-control"
                  placeholder="e-mail address"
                />
                <i className="fa fa-envelope-o designi" />
              </div>
              {errors.username?.type === "required" && (
                <p className="errormessage">E-Mail is required</p>
              )}
              {errors.username?.type === "pattern" && (
                <p className="errormessage">Enter valid E-Mail</p>
              )}
            </div>
            <div className="mt-4">
              <label>Password</label>
              <div style={{ position: "relative" }}>
                <input
                  type={inputtype}
                  {...register("password", { required: true })}
                  className="form-control"
                  placeholder="Password"
                />
                <i className="fa fa-lock designi" />
                {inputtype === "password" ? (
                  <i
                    className="fa fa-eye-slash fonticonpositioning"
                    onClick={() => setinputtype("text")}
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye fonticonpositioning"
                    onClick={() => setinputtype("password")}
                    aria-hidden="true"
                  ></i>
                )}
              </div>
              {errors.password && (
                <p className="errormessage">Password is required</p>
              )}
            </div>
            <p
              className="text-right"
              onClick={() => setshowui("forgotpassword")}
            >
              <i className="fa fa-unlock-alt pr-1" />
              Forgot your password?
            </p>
            {/* <div>
              <ReCAPTCHA
                sitekey="6LeowfQcAAAAAFC97vZ7g695Sv5aUbk5ciTDU49m"
                onChange={onChangeCaptcha}
              />
              {captchaerror && (
                <p className="errormessage mb-0">Captcha is required</p>
              )}
            </div> */}
            <button type="submit" className="btn mt-2">
              Sign In
            </button>
            <p className="text-center mt-4">
              Are you new? <span onClick={gotoregister}>Sign Up</span>
            </p>
          </form>
        ) : (
          <div className="login_container loginwidthsize">
            <div className="my -4">
              <label>Email</label>
              <div style={{ position: "relative" }}>
                <input
                  value={forgotemail}
                  onChange={(e) => setforgotemail(e.target.value)}
                  className="form-control"
                  placeholder="e-mail address"
                />
                <i className="fa fa-envelope-o designi" />
              </div>
              {forgotemailerror && (
                <p className="errormessage mb-0">E-Mail is required</p>
              )}
            </div>
            {!forgotsuccessmessage && (
              <p className="text-right" onClick={() => setshowui("agentlogin")}>
                Cancel Forgot Password..
              </p>
            )}
            {forgotsuccessmessage ? (
              <div className="alert alert-success text-center mt-4">
                <strong>Success!! </strong>
                Reset Link has been sent to your Registered Email Id
              </div>
            ) : (
              <button onClick={submitforgotpassword} className="btn">
                Submit
              </button>
            )}
          </div>
        )}
      </div>
      {/* {showui === "login" ? (
        <Footer />
      ) : ( */}
      <div className="loginpage_footer">
        <Footer />
      </div>
      {/* )} */}
    </div>
  );
};

export default AgentLogin;
