import React from "react";
import "./Education.css";
import logo from "../../assets/images/downlogo.png";
import headerImg from "../../assets/images/education-header-img.png";
import circleImg from "../../assets/images/unnamed.png";

import SearchIcon from "@mui/icons-material/Search";

import LanguageIcon from "@mui/icons-material/Language";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import NavbarForSearchTwo from "../Navbar/NavbarForSearchTwo";
import Footer from "../Footer/Footer";
import Navbarfor from "../Navbar/Navbarfor";

const Education = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      {/* header section start */}

      {/* <div className="education_header">
				<div className="center_container">
					<div className="header_left">
						<img src={logo} alt="logo" style={{ width: "40%" }} />
						<div>
							<h3>STUDY</h3>
							<h3>AUSTRALIA</h3>
						</div>
					</div>

					<div className="header_right">
						<div className="input_wrapper">
							<SearchIcon style={{ color: "#00694B" }} />
							<input
								placeholder="Search..."
								style={{
									outline: "none",
									border: "none",
									flex: 1,
									padding: 0,
								}}
							/>
						</div>

						<div
							className="nav-item dropdown"
							style={{ display: "flex", alignItems: "center" }}
						>
							<LanguageIcon style={{ color: "#00694B" }} />
							<a
								className="nav-link dropdown-toggle language"
								href="#"
								id="navbarDropdown"
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								style={{ padding: "0 5px", fontSize: "14px" }}
							>
								ENGLISH
							</a>
							<div className="dropdown-menu" aria-labelledby="navbarDropdown">
								<a className="dropdown-item">User SignUp</a>
								<a className="dropdown-item">Agent SignUp</a>
							</div>
						</div>
					</div>
				</div>
			</div> */}
      <div className="s_bgimage">
        <Navbarfor />

        {/* header section end */}

        {/* menu section start */}

        {/* <div className="menu_section">
				<div className="center_container">
					<div
						className="nav-item dropdown"
						style={{ display: "flex", alignItems: "center", width: "100%" }}
					>
						<a
							className="nav-link dropdown-toggle language menu_item"
							href="#"
							id="navbarDropdown"
							role="button"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						// style={{ padding: "0 5px" }}
						>
							Why Australia
						</a>
						<div
							className="dropdown-menu"
							aria-labelledby="navbarDropdown"
							style={{ width: "100%" }}
						>
							<a className="dropdown-item">User SignUp</a>
							<a className="dropdown-item">Agent SignUp</a>
						</div>
					</div>
				</div>
			</div> */}

        {/* menu section end */}
        {/* home section start */}
        {/* <div className="home_wrapper">
				<div style={{ height: "100%" }}>
					<img
						src={headerImg}
						alt="img"
						style={{ height: "100%", width: "100%" }}
					/>
					<div className="bg_education">
						<>
							<h1 style={{ color: "#fff", marginTop: "20px" }}>Australia’s</h1>
						</>
						<h1 style={{ color: "#fff" }}>education system</h1>

						<p style={{ marginTop: "2rem", fontSize: "1.3rem", color: "#fff" }}>
							The Australian education system offers a <br /> wide range of study
							options, in Australia and <br />
							around the world.
						</p>
					</div>
				</div>

			</div> */}

        <div
          style={{
            display: "grid",
            // gridTemplateColumns: "1fr 1fr",
            height: "35vh",
            overflow: "hidden",
          }}
        >
          {/* <div style={{ height: "100%" }}>
                        <img
                            src={headerImg}
                            alt="img"
                            style={{ height: "100%", width: "100%" }}
                        />
                    </div> */}
          <div className="bg_education">
            <>
              <h2
                style={{
                  color: "black",
                  marginLeft: "10%",
                  fontSize: "3rem",
                  fontFamily: "monospace",
                  marginTop: "90px",
                }}
              >
                Australia’s education system
              </h2>
            </>

            <p
              style={{
                paddingBottom: "10px",
                marginTop: "1rem",
                fontSize: "1.8rem",
                color: "#000",
                marginLeft: "10%",
                fontFamily: "sans-serif",
              }}
            >
              The Australian education system offers a wide range of study
              options, <br />
              in Australia and around the world.
            </p>
          </div>
        </div>
      </div>
      {/* home section end */}
      {/* summary section start */}
      <div>
        <div className="center_container">
          <div className="summary_section">
            <div>
              <h4>Menu</h4>
              <div className="sub_menu">
                <a onClick={() => scrollToSection("school")}>
                  School (Primary and Secondary)
                </a>
                <a onClick={() => scrollToSection("english_section")}>
                  English language courses
                </a>
                <a onClick={() => scrollToSection("pathway_section")}>
                  Pathway and Foundation courses
                </a>
                <a onClick={() => scrollToSection("vocational_section")}>
                  Vocational Education and Training{" "}
                </a>
                <a onClick={() => scrollToSection("higher_section")}>
                  Higher education
                </a>
                <a>
                  Australian Qualifications <br />
                  Framework for tertiary studies
                </a>
                <a>Discover more</a>
              </div>
            </div>

            <div className="summary">
              <p>
                The Australian education system provides primary, secondary and
                higher education to international students.{" "}
              </p>

              <div className="school_section" id="school">
                <h1>School (Primary and Secondary)</h1>
                <p>
                  Australia hosts international students aged five years of age
                  and over, including children of international students who are
                  studying at a tertiary level.
                  <br />
                  Australian schools are among the finest in the world,
                  including government and private schools. Fees and entry
                  requirements vary across Australian states and territories,
                  and across schools.
                </p>
                <div>
                  <h5>Schools academic year dates</h5>
                  <ul>
                    <li>
                      Length - 13 years in total (Kindergarten/Preparatory to
                      Year 12)
                    </li>
                    <li>
                      Terms per year - Four, with holidays (breaks) between each
                      term
                    </li>
                    <li>Start date - Late January/early February</li>
                  </ul>
                </div>
              </div>

              <div className="english_section" id="english_section">
                <h1>English language courses</h1>
                <p>
                  ELICOS stands for English language Intensive Courses for
                  Overseas Students. These courses must meet strict national
                  standards and follow an industry-led quality assurance
                  framework. Whatever your reason for wanting to learn English
                  in Australia, there is a course to help you reach your goal.
                </p>

                <div>
                  <h5>ELICOS academic year dates</h5>
                  <ul>
                    <li>
                      Length - From five weeks to 60 weeks, depending on your
                      level
                    </li>
                    <li>
                      Semesters per year - The year is split into blocks of
                      ELICOS weeks
                    </li>
                    <li>Start date - Throughout the year </li>
                  </ul>
                </div>
              </div>

              <div className="pathway_section" id="pathway_section">
                <h1>Pathway and Foundation courses</h1>
                <p>
                  If you don’t quite meet the academic requirements for direct
                  entry to an Australian university, pathway studies can give
                  you with the skills you need to start an undergraduate higher
                  education program.
                </p>
                <p>
                  Education pathways can include English language preparation,
                  Vocational Education and Training (VET) qualifications and
                  Foundation studies.
                  <br />
                  Some higher education providers have formal arrangements with
                  pathway providers to give study credits for prior learning.{" "}
                </p>
                <div>
                  <h5>Foundation academic year dates </h5>
                  <ul>
                    <li>Length - 1 year (some may be longer)</li>
                    <li>Semesters - Vary by course</li>
                    <li>
                      Starts - Usually in February, but can vary by course.{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="vocational_section" id="vocational_section">
                <h1>Vocational Education and Training </h1>
                <p>
                  Vocational Education and Training (VET) is taught by
                  Australian Government-owned Technical and Further Education
                  (TAFE) institutes and private registered training
                  organisations (RTOs).
                  <br />
                  These education and training providers are usually smaller
                  than universities and offer more practical courses, with
                  job-ready learning leading to over 500 different careers. Many
                  VET courses include work placements or workplace-based
                  learning.
                </p>
                <div>
                  <h5>
                    Vocational Education and Training (VET) academic year dates{" "}
                  </h5>
                  <ul>
                    <li>Length - 1 to 4 years</li>
                    <li>Semesters - 2 (or 4 ‘terms’)</li>
                    <li>
                      Starts - Late January/early February (can vary by course).{" "}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="higher_section" id="higher_section">
                <h1>Higher education</h1>
                <p>
                  Universities and non-university higher education providers
                  (including private institutes and Government-owned TAFEs)
                  offer undergraduate and postgraduate studies, including
                  Bachelor degrees, Master degrees and Doctoral degrees.
                  <br />
                  Many higher education providers also offer Graduate Diploma
                  and Graduate Certificate programs, and even Associate Degrees
                  leading to full Bachelor qualifications.
                </p>
                <div>
                  <h5>Undergraduate (Bachelor level)</h5>
                  <ul>
                    <li>
                      Length - Typically 3 years (4 years for a Bachelor Honours
                      degree){" "}
                    </li>
                    <li>
                      Semesters - 2, but some providers now offer three
                      trimesters{" "}
                    </li>
                    <li>
                      Starts - Usually March, but can vary by course and
                      education provider. Mid-year or second semester starts can
                      also be available.
                    </li>
                  </ul>
                </div>

                <div>
                  <h5>Postgraduate </h5>
                  <ul>
                    <li>Length - 1 to 2 years</li>
                    <li>
                      Semesters - 2, but some providers now offer three
                      trimesters
                    </li>
                    <li>
                      Starts - Usually March, but can vary by course and
                      education provider
                    </li>
                  </ul>
                </div>

                <div>
                  <h5>Doctoral </h5>
                  <ul>
                    <li>Length - 3 years (plus 1 year for review)</li>
                    <li>
                      Semesters - Most Doctoral candidates do not attend
                      classes, but instead have academic supervision throughout
                      the year
                    </li>
                    <li>
                      Starts - Start dates are negotiated with a Doctoral degree
                      supervisor
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="australia_section" id="australia_section">
								<h1>
									Australian Qualifications Framework for tertiary studies
								</h1>
								<p>
									The Australian Qualifications Framework (AQF) is the national
									tertiary (post-secondary) education system that has 10
									different levels. You can move from one level of study to the
									next, as long as you meet your student visa requirements.
									These qualifications are nationally accredited and recognised
									internationally in many industries.
								</p>

								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<img src={circleImg} />
								</div>
							</div> */}
            </div>
          </div>
        </div>
      </div>
      {/* summary section end */}

      {/* <div>
				<div className="center_container">
					<div>
						<h1>Discover more</h1>
						<div style={{ display: "flex", gap: "40px", marginTop: "20px" }}>
							<div className="card_wrapper">
								<div style={{ height: "200px" }}>
									<img
										src={headerImg}
										style={{
											width: "100%",
											height: "100%",
										}}
									/>
								</div>
								<div
									style={{
										padding: "30px 40px",
										display: "flex",
										flexDirection: "column",
										gap: "40px",
									}}
								>
									<div>
										<h5>Area of study</h5>

										<p>
											Find out how to change your course or level of
											qualification, or transfer between education providers.
										</p>
									</div>

									<ArrowForwardIcon />
								</div>
							</div>

							<div className="card_wrapper">
								<div style={{ height: "200px" }}>
									<img
										src={headerImg}
										style={{
											width: "100%",
											height: "100%",
										}}
									/>
								</div>
								<div
									style={{
										padding: "30px 40px",
										display: "flex",
										flexDirection: "column",
										gap: "40px",
									}}
								>
									<div>
										<h5>Area of study</h5>

										<p>
											Find out how to change your course or level of
											qualification, or transfer between education providers.
										</p>
									</div>

									<ArrowForwardIcon />
								</div>
							</div>

							<div className="card_wrapper">
								<div style={{ height: "200px" }}>
									<img
										src={headerImg}
										style={{
											width: "100%",
											height: "100%",
										}}
									/>
								</div>
								<div
									style={{
										padding: "30px 40px",
										display: "flex",
										flexDirection: "column",
										gap: "40px",
									}}
								>
									<div>
										<h5>Area of study</h5>

										<p>
											Find out how to change your course or level of
											qualification, or transfer between education providers.
										</p>
									</div>

									<ArrowForwardIcon />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}

      {/* <div
				style={{
					// height: "60vh",
					backgroundColor: "#173F35",
					marginTop: "30px",
					padding: "30px 0px",
				}}
			>
				<div className="center_container">
					<div>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div>
								<h5 style={{ color: "#0FAE6C" }}>Quick links</h5>
								<div>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
										}}
									>
										<ArrowForwardIcon style={{ color: "#fff" }} />
										<p style={{ marginBottom: 0, color: "#fff" }}>
											Why Australia
										</p>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
										}}
									>
										<ArrowForwardIcon style={{ color: "#fff" }} />
										<p style={{ marginBottom: 0, color: "#fff" }}>
											Visa and travel advice
										</p>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
										}}
									>
										<ArrowForwardIcon style={{ color: "#fff" }} />
										<p style={{ marginBottom: 0, color: "#fff" }}>
											Living costs
										</p>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
										}}
									>
										<ArrowForwardIcon style={{ color: "#fff" }} />
										<p style={{ marginBottom: 0, color: "#fff" }}>
											Australian study locations
										</p>
									</div>
								</div>
							</div>

							<div>
								<h5 style={{ color: "#0FAE6C" }}>Useful tools</h5>
								<div>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
										}}
									>
										<ArrowForwardIcon style={{ color: "#fff" }} />
										<p style={{ marginBottom: 0, color: "#fff" }}>
											Career Matcher
										</p>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
										}}
									>
										<ArrowForwardIcon style={{ color: "#fff" }} />
										<p style={{ marginBottom: 0, color: "#fff" }}>
											Speaking English practice test
										</p>
									</div>

									<div
										style={{
											display: "flex",
											alignItems: "center",
											gap: "10px",
										}}
									>
										<ArrowForwardIcon style={{ color: "#fff" }} />
										<p style={{ marginBottom: 0, color: "#fff" }}>
											Cost of living calculator
										</p>
									</div>
								</div>
							</div>

							<div>
								<h5 style={{ color: "#0FAE6C" }}>Follow us</h5>
								<h5 style={{ color: "#0FAE6C" }}>
									Subscribe to our newsletter
								</h5>
								<p style={{ marginBottom: 0, color: "#fff" }}>
									Sign up to get all the latest student stories, blog <br />
									posts and updates on student visas and travel to
									<br /> Australia straight to your inbox.
								</p>
							</div>
						</div>

						<div
							style={{
								height: "2px",
								backgroundColor: "#00BA6C",
								margin: "30px 0px",
							}}
						/>

						<p style={{ color: "#fff" }}>
							In the spirit of reconciliation we acknowledge the Traditional
							Custodians of country throughout Australia and their connections
							to land, sea and community. We pay our respect to their elders
							past and present and extend that respect to all Aboriginal and
							Torres Strait Islander peoples today.
						</p>

						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "50px",
							}}
						>
							<div style={{ display: "flex", gap: "30px" }}>
								<p style={{ color: "#fff" }}>About us</p>
								<p style={{ color: "#fff" }}>Site Disclaimer</p>
								<p style={{ color: "#fff" }}>Privacy policy</p>
							</div>

							<p style={{ color: "#fff" }}>
								© 2023, Australian Trade and Investment Commission
							</p>
						</div>
					</div>
				</div>
			</div> */}
      <Footer />
    </div>
  );
};

export default Education;
