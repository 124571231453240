import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";
import LockIcon from "@mui/icons-material/Lock";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SearchIcon from "@mui/icons-material/Search";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";

import Logo from "../../assets/images/downlogo.png";
import { toast } from "react-toastify";
import { useStateValue } from "../ContextAPI/StateProvider";

const ReactNavbar = (props) => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  let navigate = useNavigate();
  let location = useLocation();
  // eslint-disable-next-line
  const [{ user }, dispatch] = useStateValue();

  const handlecompare = () => {
    if (!clientdetails?.clientId) {
      sessionStorage.setItem("prev_path_before_login", "/course-compare");
      navigate("/login");
    } else {
      navigate("/course-compare");
    }
  };

  const handleimage = () => navigate("/");
  const handlesearch = () => navigate("/course-searchresult");
  const handlelogin = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/login");
  };
  const handleAgentlogin = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/agent-login");
  };
  const handleregister = () => navigate("/register");
  const handleAgentregister = () => navigate("/agent-register");
  const handledashboard = () => navigate("/myaccount");
  // const handleenquiry = () => navigate("/enquiry");
  const handlecontact = () => navigate("/contactus");
  const handlelogout = () => {
    dispatch({
      type: "SET_USER",
      user: null,
    });
    localStorage.clear();
    navigate("/login");
    toast.success("Logged Out Successfully");
    localStorage.setItem("countryvalue", "Australia");
  };

  return (
    <Navbar collapseOnSelect expand="xl">
      {props.fromwhere === "Account" ? (
        <>
          {/* <Nav.Link className="account-username">
            Welcome <br/>
            <span>{clientdetails?.firstName} {clientdetails?.lastName}</span>
          </Nav.Link> */}
        </>
      ) : (
        <Navbar.Brand className="header_navbarimagecontainer d-none">
          <img
            className="cursor-pointer"
            onClick={handleimage}
            src={Logo}
            alt="isw_logo"
          />
        </Navbar.Brand>
      )}
      {props.fromwhere === "Verify" ? (
        ""
      ) : (
        <>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <div className="d-none">
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="w-100 justify-content-end">
                {props.fromwhere === "Account" ? (
                  ""
                ) : (
                  <>
                    {clientdetails?.token || clientdetails?.clientId ? (
                      <Nav.Link className="account-username">
                        Welcome <br />
                        <span>
                          {clientdetails?.firstName} {clientdetails?.lastName}
                        </span>
                      </Nav.Link>
                    ) : null}
                  </>
                )}
                {clientdetails?.token && (
                  <Nav.Link onClick={handledashboard} className="headertextcss">
                    <DashboardIcon />
                    Dashboard
                  </Nav.Link>
                )}
                <Nav.Link onClick={handlesearch} className="headertextcss">
                  <SearchIcon />
                  Courses
                </Nav.Link>
                <Nav.Link onClick={handlecompare} className="headertextcss">
                  <CompareArrowsOutlinedIcon />
                  Compare
                </Nav.Link>
                {/* <Nav.Link onClick={handleenquiry} className="headertextcss">
                <MailOutlineOutlinedIcon />
                Enquiry
              </Nav.Link> */}
                <Nav.Link onClick={handlecontact} className="headertextcss">
                  <PermContactCalendarOutlinedIcon />
                  Contact Us
                </Nav.Link>
                {clientdetails?.clientId ? (
                  <Nav.Link
                    style={{ cursor: "pointer" }}
                    onClick={handlelogout}
                    className="headertextcss"
                  >
                    <LockIcon />
                    Logout
                  </Nav.Link>
                ) : (
                  <>
                    <NavDropdown
                      className="headertextcss"
                      style={{ position: "relative" }}
                      title={"Sign In"}
                    >
                      <NavDropdown.Item onClick={handlelogin}>
                        User Login
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleAgentlogin}>
                        Agent Login
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      className="headersignupcss"
                      style={{ position: "relative" }}
                      title={"Register"}
                    >
                      <NavDropdown.Item onClick={handleregister}>
                        Student SignUp
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleAgentregister}>
                        Agent SignUp
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
                {/* <Nav.Link className="headertextcss">
                <i className="fa fa-heart-o" />
              </Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </div>
        </>
      )}
      {/* Login detail modal */}
      {/* <Modal show={props.modalShow} onHide={props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            International School World
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography variant="h5" color="primary">
            Please Login or create an account to proceed furthur!!
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn headersignupcss" onClick={handlelogin}>
            Go to Login
          </button>
        </Modal.Footer>
      </Modal> */}
    </Navbar>
  );
};

export default ReactNavbar;
