import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../api/bootapi";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";

import SelectCurrency from "react-select-currency";
import Modal from "react-modal";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import CloseIcon from "@mui/icons-material/Close";

import EnquiryWorkflowFiles from "./EnquiryWorkflowFiles";
import { Card, Col, Row, Button } from "reactstrap";
import close from "../../assets/images/closeicon.png";
import GetAppIcon from "@mui/icons-material/GetApp";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

export const customStylesAttachment = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "1000px",
    maxWidth: "1000px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};

const stylesAttachment = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "800px",
    maxWidth: "800px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const ApplicationForm = () => {
  const [clientDocuments, setclientDocuments] = useState([]);
  const [iswDocuments, setIswDocuments] = useState([]);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [allIntake, setallIntake] = useState([]);
  const [allVisa, setallVisa] = useState([]);
  const [allHealth, setallHealth] = useState([]);
  const [clientDetail, setclientDetail] = useState();
  const [region, setregion] = useState();
  const [country1, setcountry] = useState();
  const [country1Passport, setcountryPassport] = useState();
  const [countryrequired, setcountryrequired] = useState(false);
  const [staterequired, setstaterequired] = useState(false);

  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [stateCust, setstateCust] = useState();
  const classes = useStyles();
  const [backdropopen, setbackdropOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: clientDetail });

  const closeModal = () => {
    // setIsOpen(false);
    setIsOpen2(false);
    // setIsOpen3(false);
    // setIsOpen4(false);
  };

  useEffect(() => {
    getClientDoc();
    getIswDoc();
  }, []);

  const loggedInUser = localStorage.getItem("agentLogin");
  const [currency1, setCurrency] = useState("");
  const [agentData, setAgentData] = useState();
  const [agentType, setAgentType] = useState();
  const [openTransactionalForm, setOpenTransactionalForm] = useState(false);
  const [transactionalArrangementList, setTransactionalArrangementList] =
    useState();

  const [filesizemore, setfilesizemore] = useState(false);
  const [attachmentdisplay, setattachmentdisplay] = useState(false);
  const chkfilesize = (e) => {
    if (e.target.files.length) {
      let fileSize = 0;
      for (let i = 0; i < e.target.files.length; i++) {
        fileSize += e.target.files[i].size;
      }
      //console.log(fileSize);
      if (fileSize / 1048576 > 200) {
        setfilesizemore(true);
      } else {
        setfilesizemore(false);
      }
    }
  };

  const [documentpage, setDocPage] = useState(0);
  const [iswdocumentpage, setIswDocPage] = useState(0);

  const [getDocPage, setgetDocPage] = useState();
  const [getISwPage, setgetIswPage] = useState();
  const [totalDocUser, settotalDocUser] = useState();
  const [totalIswUser, settotalIswUser] = useState();
  const [countDocnumber, setcountDocnumber] = useState();
  const [countIswnumber, setcountIswnumber] = useState();
  const [numberofDocElements, setnumberofDocElements] = useState();
  const [numberofIswElements, setnumberofIswElements] = useState();

  const getClientDoc = () => {
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getAllAttachmentsByDocumentTypeAndClientId`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          clientId: clientdetails?.clientId,
          page: documentpage,
          size: 10,
          documentType: "miscellaneous_doc",
        },
      })
      .then((res) => {
        let data = [];

        // res.data.data.forEach((element) => data.push(element));
        //uploaded by user pagination
        const filteredData = res.data.client_attachment.filter((item) =>
          /^\d+$/.test(item.createdBy)
        );
        filteredData.forEach((element) => data.push(element));
        setclientDocuments(filteredData);
        const data2 = data.slice(0, 10) || [];
        setgetDocPage(res.data.data.totalPages);
        settotalDocUser(data.length);
        setcountDocnumber(Number(0));
        setnumberofDocElements(data2.length);
        setbackdropOpen(false);
      })
      .catch((err) => {
        setclientDocuments(null);
        setgetDocPage(null);
        settotalDocUser(null);
        setcountDocnumber(null);
        setnumberofDocElements(null);
        setbackdropOpen(false);
        //console.log(err);
      });
  };

  const getIswDoc = () => {
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getAllAttachmentsByDocumentTypeAndClientId`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          clientId: clientdetails?.clientId,
          page: iswdocumentpage,
          size: 10,
          documentType: "miscellaneous_doc",
        },
      })
      .then((res) => {
        let data = [];

        // res.data.data.forEach((element) => data.push(element));
        //uploaded by user pagination
        const filteredData = res.data.client_attachment.filter((item) =>
          /^[a-zA-Z]/.test(item.createdBy)
        );

        filteredData.forEach((element) => data.push(element));
        setIswDocuments(filteredData);
        const data2 = data.slice(0, 10) || [];
        setgetIswPage(res.data.data.totalPages);
        settotalIswUser(data.length);
        setcountIswnumber(Number(0));
        setnumberofIswElements(data2.length);
        setbackdropOpen(false);
      })
      .catch((err) => {
        setIswDocuments(null);
        setgetIswPage(null);
        settotalIswUser(null);
        setcountIswnumber(null);
        setnumberofIswElements(null);
        setbackdropOpen(false);
        //console.log(err);
      });
  };

  //   const handleTypeChange = (e) => {
  //     setSelectedType(e.target.value);
  //   };

  const onSubmitAttachment = (data) => {
    //console.log("Current Page No--->", data);

    let fileToUpload = data.picture[0];
    let formData = new FormData();

    formData.append("stepfile", fileToUpload);
    postAttachment(formData, data.documentType);
    reset();
  };
  const postAttachment = (formData, fileType) => {
    //console.log("Current here sp--->");
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];

    let logs3 = "Added attachment - By ";
    //  + isw_user_name;
    setbackdropOpen(true);

    axios
      .post(`${base_url.api5}/uploadAttachment`, formData, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          clientId: clientdetails?.clientId,
          createdBy: clientdetails?.clientId,
          createdOn: datenow,
          log: logs3,
          documentType: fileType,
          stepfile: [null],
          // isw_user_name + " (" + isw_user_email + ")",
        },
      })
      .then(
        (res) => {
          //console.log(res.data.data);
          //console.log("Current Page No--->", documentpage);
          getClientDoc(documentpage);
          setIsOpen2(false);
          setbackdropOpen(false);
          toast.success(res.data.message);
        },
        (error) => {
          setbackdropOpen(false);
          //console.log(error);
          // toast.error(JSON.stringify(error.response.data.message));
        }
      );
  };

  const downloadAll = (path, clientId) => {
    setbackdropOpen(true);
    let file_name;
    if (path.split("amazonaws.com//")[1]) {
      file_name = path.split("amazonaws.com//")[1];
    } else {
      file_name = path.split("amazonaws.com/")[1];
    }

    const header = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${clientdetails?.token}`,
        clientId: clientdetails?.clientId,
        fileName: clientdetails?.clientId + `/` + file_name,
      },
    };

    var url = `${base_url.api3}/downloadEnquiryAttachmentByClientIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        const downloadFileType = localStorage.getItem(
          "client_attachment_file_content_type"
        );
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdropOpen(false);
      })
      .catch((error) => {
        setbackdropOpen(false);
        //console.log(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "client_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };

  const [finalFile, setFinalFile] = useState();
  const [finalFileType, setFinalFileType] = useState();

  const handleDocument = (event, value) => {
    setbackdropOpen(true);
    setDocPage(value - 1);
    getClientDoc(value - 1);
  };

  const handleiswDocument = (event, value) => {
    setbackdropOpen(true);
    setIswDocPage(value - 1);
    getIswDoc(value - 1);
  };

  return (
    <div class="accountbody">
      <div class="header-container">
        {/* <h2 className="accountbody-title">Application Form Document</h2> */}
        <button className="document-button" onClick={() => setIsOpen2(true)}>
          UPLOAD DOCUMENTS
        </button>
      </div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ marginBottom: "15px", overflowX: "auto" }}>
        <div className="documentTable">
          <div className="userTable">
            <div className="upload-heading">
              <h3 style={{ fontWeight: "bolder" }}>Documents</h3>
              <div class="upload-container">
                <span className="upload-text">Uploaded by You</span>
                <div className="upload-line"></div>
              </div>
            </div>

            <table className="table tbb">
              <thead className="thb isw-th">
                <tr>
                  <th>SN</th>
                  <th>Title</th>
                  <th>Document Type</th>
                  {/*  <th>Course</th>
                            <th>Provider</th> */}
                  <th>Added On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {clientDocuments?.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.attachmentName}</td>
                    <td>{item.documentType}</td>
                    {/* <td>{item.course_name}</td>
                                <td>{item.institution_name}</td> */}
                    <td style={{ width: "140px" }}>
                      {moment(item.createdOn).format("DD MMM YYYY")}
                      <br />
                      {moment(item.createdOn).format("hh.mm a")}
                    </td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          textAlign: "left",
                        }}
                      >
                        <a
                          className="download-button"
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            downloadAll(item.path, item.clientId);
                          }}
                        >
                          <GetAppIcon />
                        </a>

                        <EnquiryWorkflowFiles
                          fileName={
                            item.path.includes("amazonaws.com//")
                              ? item.path.split("amazonaws.com//")[1]
                              : item.path.split("amazonaws.com/")[1]
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Row>
              <Col md={6}>
                {clientDocuments?.length ? (
                  <p style={{ marginTop: "20px" }}>
                    {countDocnumber * 3 + 1} to{" "}
                    {countDocnumber * 3 + numberofDocElements} of {totalDocUser}{" "}
                    records
                  </p>
                ) : (
                  <p className="formfooterrecordstyle">No Record Found</p>
                )}
              </Col>
              <Col md={4}></Col>
              <Col md={2}>
                <div className={classes.root}>
                  <Pagination
                    count={getDocPage}
                    onChange={handleDocument}
                    shape="rounded"
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="iswTable">
            <div className="upload-heading">
              <h3 style={{ fontWeight: "bolder" }}>Documents</h3>
              <div class="upload-container">
                <span className="upload-text">Uploaded by ISW</span>
                <div className="upload-line"></div>
              </div>
            </div>
            <table className="table tbb">
              <thead className="thb">
                <tr>
                  <th>SN</th>
                  <th>Title</th>
                  <th>Document Type</th>
                  {/*  <th>Course</th>
                            <th>Provider</th> */}
                  <th>Added On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {iswDocuments?.map((item, index) => (
                  <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{item.attachmentName}</td>
                    <td>{item.documentType}</td>
                    {/* <td>{item.course_name}</td>
                                <td>{item.institution_name}</td> */}
                    <td style={{ width: "140px" }}>
                      {moment(item.createdOn).format("DD MMM YYYY")}
                      <br />
                      {moment(item.createdOn).format("hh.mm a")}
                    </td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          textAlign: "left",
                        }}
                      >
                        <a
                          className="download-button"
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            downloadAll(item.path, item.clientId);
                          }}
                        >
                          <GetAppIcon />
                        </a>

                        <EnquiryWorkflowFiles
                          fileName={
                            item.path.includes("amazonaws.com//")
                              ? item.path.split("amazonaws.com//")[1]
                              : item.path.split("amazonaws.com/")[1]
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Row>
              <Col md={6}>
                {iswDocuments?.length ? (
                  <p style={{ marginTop: "20px" }}>
                    {countIswnumber * 3 + 1} to{" "}
                    {countIswnumber * 3 + numberofIswElements} of {totalIswUser}{" "}
                    records
                  </p>
                ) : (
                  <p className="formfooterrecordstyle">No Record Found</p>
                )}
              </Col>
              <Col md={4}></Col>
              <Col md={2}>
                <div className={classes.root}>
                  <Pagination
                    count={getISwPage}
                    onChange={handleiswDocument}
                    shape="rounded"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={closeModal}
          style={stylesAttachment}
          contentLabel="Example Modal"
        >
          <div
            className="upload"
            style={{ marginLeft: "30px", marginRight: "35px" }}
          >
            <img className="closei" src={close} onClick={closeModal} />
            <br />
            <h4 className="s_upload">Upload Attachment </h4>

            <div>
              <form class="mt-3" onSubmit={handleSubmit(onSubmitAttachment)}>
                <div
                  className="uploadDocument"
                  style={{ marginBottom: "10px" }}
                >
                  <label className="upload_label form-label">File</label>
                  <input
                    className="form-control"
                    type="file"
                    id="fileUpload"
                    style={{ padding: "10px" }}
                    {...register("picture", { required: true })} // Register the input field with react-hook-form
                    accept=".jpg, .jpeg, .bmp, .gif, .png, .pdf,.PDF"
                    multiple={false}
                  />
                  {errors.picture && (
                    <p className="error">Please Choose a file to upload</p>
                  )}
                </div>
                <div className="selectDocType" style={{ marginBottom: "10px" }}>
                  <label className="upload_label form-label">
                    Type of Document <span style={{ color: "red" }}>*</span>:
                  </label>
                  <br />
                  <select
                    id="documentType"
                    className="select-field form-control"
                    {...register("documentType", { required: true })}
                  >
                    <option value="">-Select Type of Document-</option>
                    <option value="Application Form">Application Form</option>
                    <option value="Passport">Passport</option>
                    <option value="PTE">PTE</option>
                    <option value="Transcript">Transcript</option>
                    <option value="Payment Receipt">Payment Receipt</option>
                    <option value="Offer Letter">Offer Letter</option>
                    <option value="Coe">Coe</option>
                    <option value="Others">Others</option>
                  </select>
                  {errors.documentType && (
                    <p className="error">Please select a document type</p>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "5px",
                  }}
                >
                  <button
                    className="btn btn-secondary"
                    style={{
                      color: "white",
                      backgroundColor: "red",
                      borderColor: "red",
                    }}
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button className="btn listing_addbutton ml-1" type="submit">
                    Submit
                  </button>
                </div>
              </form>
              <br />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ApplicationForm;
