import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CountryDropdown } from "react-country-region-selector";
import axios from "axios";
import base_url from "../api/bootapi";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "./Home.css";
import HomeSlide1 from "../../assets/images/home-slide1.jpg";
import HomeSlide2 from "../../assets/images/home-slide2.jpg";
import HomeSlide3 from "../../assets/images/home-slide3.jpg";
import HomeSlide4 from "../../assets/images/home-slide4.jpg";
import HomeSlide5 from "../../assets/images/home-slide5.jpg";

import HpClient1 from "../../assets/images/hp-client-1.jpg";
import HpClient2 from "../../assets/images/hp-client-2.jpg";
import HpClient3 from "../../assets/images/hp-client-3.jpg";
import HpClient4 from "../../assets/images/hp-client-4.jpg";
import HpClient5 from "../../assets/images/hp-client-5.jpg";
import HpClient6 from "../../assets/images/hp-client-6.jpg";
import HpClient7 from "../../assets/images/hp-client-7.jpg";
import HpClient8 from "../../assets/images/hp-client-8.jpg";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

const HomePageBodyForConsultant = () => {
  const navigate = useNavigate();
  const [level, setlevel] = useState([]);
  const [area, setarea] = useState([]);
  const [city, setcity] = useState([]);
  const [country1, setcountry] = useState("Australia");
  const [selectedCountry, setSelectedCountry] = useState("Australia");
  const allowedCountries = [
    "Australia",
    "United States",
    "United Kingdom",
    "Canada",
  ];

  const [levelcheck, setlevelcheck] = useState(true);
  const [locationcheck, setlocationcheck] = useState(true);
  const [countrycheck, setcountrycheck] = useState(true);
  const [areacheck, setareacheck] = useState(true);

  const settings = {
    arrows: false,
    dots: false,
    pauseOnHover: false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    fade: true,
    variableWidth: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    setlevel(data);
    getLevel();
    getArea();
    getcity("Australia");
    localStorage.setItem("countryvalue", "Australia");
  }, []);

  const getcity = (val) => {
    axios
      .get(`${base_url.api5}/findCityByCountry`, {
        headers: { country: val },
      })
      .then((res) => setcity(res.data.data))
      .catch((err) => setcity([]));
  };

  const getLevel = () => {
    axios
      .get(`${base_url.api5}/getCourseCountGroupByLevel`)
      .then((res) =>
        // setlevel(res.data.data)
        console.log(res)
      )
      .catch((err) => console.log(err));
  };

  const data = [
    // {
    //   "courseLevelCount": 21,
    //   "levelId": 20,
    //   "levelName": "Certificate I"
    // },
    {
      courseLevelCount: 166,
      levelId: 14,
      levelName: "Certificate II",
    },
    {
      courseLevelCount: 1758,
      levelId: 13,
      levelName: "Certificate III",
    },
    {
      courseLevelCount: 2106,
      levelId: 12,
      levelName: "Certificate IV",
    },
    {
      courseLevelCount: 3678,
      levelId: 11,
      levelName: "Diploma",
    },
    {
      courseLevelCount: 1617,
      levelId: 10,
      levelName: "Advanced Diploma",
    },
    {
      courseLevelCount: 205,
      levelId: 4,
      levelName: "Associate Degree",
    },
    {
      courseLevelCount: 4172,
      levelId: 1,
      levelName: "Bachelor Degree",
    },
    {
      courseLevelCount: 1518,
      levelId: 8,
      levelName: "Bachelor Honours Degree",
    },
    {
      courseLevelCount: 1005,
      levelId: 5,
      levelName: "Graduate Certificate",
    },
    {
      courseLevelCount: 1350,
      levelId: 9,
      levelName: "Graduate Diploma",
    },
    {
      courseLevelCount: 3383,
      levelId: 7,
      levelName: "Masters Degree (Coursework)",
    },
    {
      courseLevelCount: 867,
      levelId: 2,
      levelName: "Masters Degree (Research)",
    },
    {
      courseLevelCount: 49,
      levelId: 16,
      levelName: "Masters Degree (Extended)",
    },
    {
      courseLevelCount: 1090,
      levelId: 3,
      levelName: "Doctoral Degree",
    },
    {
      courseLevelCount: 389,
      levelId: 15,
      levelName: "Senior Secondary Certificate of Education",
    },
    {
      courseLevelCount: 1745,
      levelId: 6,
      levelName: "Non AQF Award",
    },
  ];

  const getArea = () => {
    axios
      .get(`${base_url.api5}/getCourseCountGroupByArea`)
      .then((res) => setarea(res.data.data))
      .catch((err) => console.log(err));
  };
  const selectCountry = (val) => {
    localStorage.setItem("countryvalue", `${val}`);
    getcity(val);
    setcountry(val);
    localStorage.removeItem("cityvalue");
  };

  const handleCountryChange = (e) => {
    let val = e.target.value;
    localStorage.setItem("countryvalue", `${val}`);
    getcity(val);
    setcountry(val);
    setSelectedCountry(val);
    localStorage.removeItem("cityvalue");
  };

  const handlechangecity = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementValue = optionElement.getAttribute("value");
    handlecitychange(optionElementValue.trim());
  };

  // Handle changes to the select input
  const handleSelectChange = (event) => {
    handlecitychange(event.target.value);
  };

  const handlechangelevel = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementValue = optionElement.getAttribute("value");
    let arr = [optionElementValue];
    localStorage.setItem("levelname", JSON.stringify(arr));
    localStorage.setItem("level", optionElementId);
  };

  const handlechangearea = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementValue = optionElement.getAttribute("value");
    let arr = [optionElementValue];
    localStorage.setItem("areaname", JSON.stringify(arr));
    localStorage.setItem("area", optionElementId);
  };

  const handlecitychange = (val) => localStorage.setItem("cityvalue", `${val}`);

  const submitcourse = (e) => {
    // localStorage.setItem("PopularState", 0);
    let countryvalue = e.target[0].value;
    // let cityvalue = e.target[1].value;
    let levelvalue = e.target[2].value;
    let coursevalue = e.target[3].value;
    if (!countryvalue || !levelvalue || !coursevalue) {
      if (!countryvalue) {
        setcountrycheck(false);
        setlocationcheck(true);
        setlevelcheck(true);
        setareacheck(true);
      } else if (!levelvalue) {
        setcountrycheck(true);
        setlevelcheck(false);
        setlocationcheck(true);
        setareacheck(true);
      } else if (!coursevalue) {
        setcountrycheck(true);
        setareacheck(false);
        setlocationcheck(true);
        setlevelcheck(true);
      }
    } else {
      navigate("/course-searchresult");
    }
  };
  return (
    <div>
      <div className="home-slider">
        <Slider {...settings}>
          <div className="home-slider1">&nbsp;</div>
          <div className="home-slider2">&nbsp;</div>
          <div className="home-slider3">&nbsp;</div>
          <div className="home-slider4">&nbsp;</div>
          <div className="home-slider5">&nbsp;</div>
        </Slider>
      </div>

      <div className="container homepage_selectioncontainer">
        <h1 className="text-center">Search, compare &amp; apply</h1>
        <div className="home-form">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitcourse(e);
            }}
            className="homepage_formcontainer"
          >
            <div className="row mx-0">
              <div className="col">
                <div style={{ position: "relative" }}>
                  {/* <CountryDropdown
                    defaultOptionLabel="Select Country"
                    className="form-control selecttag countrydropdown2"
                    value={country1}
                    onChange={(val) => selectCountry(val)}
                    valueType="short"
                    priorityOptions={filteredCountries}
                  /> */}
                  <select
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    className="form-control selecttag countrydropdown2"
                  >
                    {allowedCountries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {/* <ArrowDropDownIcon className="homepage_icon" /> */}
                </div>
                {!countrycheck && (
                  <p className="homepageerror">Country is Required</p>
                )}
              </div>
              <div className="col">
                {city.length > 0 ? (
                  <select
                    onChange={handlechangecity}
                    className="form-control selecttag"
                  >
                    <option value="">Select City</option>
                    {city.map((item, index) => (
                      <option key={index} value={item.city}>
                        {item.city}
                      </option>
                    ))}
                  </select>
                ) : (
                  // <select
                  //     onChange={handleSelectChange}
                  //     className="form-control selecttag"
                  // >
                  //     <option value="">Select Region</option>
                  //     <option value="ACT">ACT (inc. Canberra)</option>
                  //     <option value="NSW">NSW (inc. Sydney)</option>
                  //     <option value="NT">NT (inc. Darwin)</option>
                  //     <option value="QLD">QLD (inc. Brisbane)</option>
                  //     <option value="SA">SA (inc. Adelaide)</option>
                  //     <option value="TAS">TAS (inc. Hobart)</option>
                  //     <option value="VIC">VIC (inc. Melbourne)</option>
                  //     <option value="WA">WA (inc. Perth)</option>
                  // </select>
                  <input
                    className="form-control"
                    placeholder="Enter Region"
                    onChange={(e) => handlecitychange(e.target.value)}
                  />
                )}
                {!locationcheck && (
                  <p className="homepageerror">Region is Required</p>
                )}
              </div>

              <div className="col">
                <div style={{ position: "relative" }}>
                  <select
                    onChange={handlechangelevel}
                    className="form-control selecttag"
                  >
                    <option value="">Select Study Level</option>
                    {level.map((item, index) => (
                      <option
                        key={index}
                        value={item.levelName}
                        id={item.levelId}
                      >
                        {item.levelName}
                      </option>
                    ))}
                  </select>
                </div>
                {!levelcheck && (
                  <p className="homepageerror">Study Level is Required</p>
                )}
              </div>
              <div className="col">
                <div style={{ position: "relative" }}>
                  <select
                    onChange={handlechangearea}
                    className="form-control selecttag"
                  >
                    <option value="">Select Study Field </option>
                    {area.map((item, index) => (
                      <option
                        key={index}
                        value={item.area_name}
                        id={item.area_id}
                      >
                        {item.area_name}
                      </option>
                    ))}
                  </select>
                </div>
                {!areacheck && (
                  <p className="homepageerror">Study Field is Required</p>
                )}
              </div>

              <div className="col">
                <button type="submit" className="btn home-form-btn">
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="home-clients-logo">
        <img src={HpClient1} alt="Client 1" />
        <img src={HpClient2} alt="Client 2" />
        <img src={HpClient3} alt="Client 3" />
        <img src={HpClient4} alt="Client 4" />
        <img src={HpClient5} alt="Client 5" />
        <img src={HpClient6} alt="Client 6" />
        <img src={HpClient7} alt="Client 7" />
        <img src={HpClient8} alt="Client 8" />
      </div>
    </div>
  );
};

export default HomePageBodyForConsultant;
