import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import base_url from "../api/bootapi";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import ReactNavbar from "../Navbar/Navbar";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Footer from "../Footer/Footer";
import { CountryDropdown } from "react-country-region-selector";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import NavbarNew from "../Navbar/NavbarNew";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const ApplyNow = () => {
  const getCourse = JSON.parse(localStorage.getItem("courseId"));
  const loggedInUser = localStorage.getItem("agentLogin");
  const classes = useStyles();
  let navigate = useNavigate();
  const [backdropopen, setbackdropOpen] = useState(false);
  const pageStartRef = useRef(null);
  const [passportdate, setpassportdate] = useState();
  const [visadate, setvisadate] = useState();
  const courseid = JSON.parse(localStorage.getItem("courseId"));
  const [country1, setcountry] = useState();
  const [clientSource, setclientsource] = useState();

  const [selectedBranchId, setSelectedBranchId] = useState(""); // State to store the selected branchId
  const [selectedBranchValue, setSelectedBranchValue] = useState(""); // State to store the selected branchId

  const handleSelectChange = (event) => {
    const selectedOption = event.target.selectedOptions[0]; // Get the selected option
    setSelectedBranchId(selectedOption.id); // Update selectedBranchId with the id of the selected option
    setSelectedBranchValue(selectedOption.value); // Update selectedBranchValue with the value of the selected option
    //console.log(selectedOption.id);
    //console.log(selectedOption.value);
  };

  const [allVisa, setallVisa] = useState();
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const applydetails = JSON.parse(
    localStorage.getItem("isw_apply_details_frontend")
  );
  const [clienttempdata, setclienttempdata] = useState();
  const applydetail =
    JSON.parse(localStorage.getItem("isw_frontend_applycourse_detail")) || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    if (!clientdetails?.clientId) {
      navigate("/");
    } else if (clientdetails?.agentId) {
      navigate("/apply-agent");
    } else {
      pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
      axios.get(`${base_url.api5}/getAllClientSourceUsingQuery`).then((res) => {
        setclientsource(res.data.data);
        axios.get(`${base_url.api5}/getAllVisaType`).then((res) => {
          setallVisa(res.data.data);
          if (clientLoginCheck === 1) {
            getclientdetail();
          } else {
            getclienttempdetail();
          }
        });
      });
    }
    getAllBranchAddresses();
  }, []);

  const [totalAddress, setTotalAddress] = useState([]);

  const getAllBranchAddresses = () => {
    axios
      .get(`${base_url.api5}/getAllBranchAddressesByCourseId`, {
        headers: {
          courseId: getCourse.courseId,
        },
      })
      .then((response) => {
        //console.log("res----------->", response);
        setTotalAddress(response.data.data);
        setbackdropOpen(false);
      })
      .catch((error) => {
        setbackdropOpen(false);
      });
  };

  const branchIdToNameMap = {};

  for (const item of totalAddress) {
    branchIdToNameMap[item.branchId] = item.branchName;
  }

  //console.log('Apply Details------------>', applydetails);

  const transformedArray = Object.entries(branchIdToNameMap).map(
    ([branchId, branchName]) => ({
      branchName,
      branchId: Number(branchId), // Assuming you want branchId as a number
    })
  );

  //console.log('transformedArray------------->', transformedArray);

  const clientLoginCheck = JSON.parse(localStorage.getItem("clientLogin"));

  const getclientdetail = () => {
    axios
      .get(`${base_url.api5}/getClientByUsername`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          username: clientdetails?.username,
        },
      })
      .then((res) => {
        let client = res.data.data.clientDetail;
        setclienttempdata(res.data.data);
        client.dob = moment(client.dob).format("YYYY-MM-DD");
        client.passportIssueDate = client.passportIssueDate
          ? moment(client.passportIssueDate).format("YYYY-MM-DD")
          : null;
        client.passportExpiryDate = client.passportExpiryDate
          ? moment(client.passportExpiryDate).format("YYYY-MM-DD")
          : null;
        client.visaIssueDate = client.visaIssueDate
          ? moment(client.visaIssueDate).format("YYYY-MM-DD")
          : null;
        client.visaExpiry = client.visaExpiry
          ? moment(client.visaExpiry).format("YYYY-MM-DD")
          : null;
        reset(client);
        setcountry(client.passport || "");
      });
  };

  const getclienttempdetail = () => {
    axios
      .get(`${base_url.api5}/getClientTempById`, {
        headers: { clientTempId: clientdetails?.clientId },
      })
      .then((res) => {
        let client = res.data.data;
        setclienttempdata(res.data.data);
        client.dateOfBirth = moment(client.dateOfBirth).format("YYYY-MM-DD");
        client.passportIssueDate = client.passportIssueDate
          ? moment(client.passportIssueDate).format("YYYY-MM-DD")
          : null;
        client.passportExpiryDate = client.passportExpiryDate
          ? moment(client.passportExpiryDate).format("YYYY-MM-DD")
          : null;
        client.visaIssueDate = client.visaIssueDate
          ? moment(client.visaIssueDate).format("YYYY-MM-DD")
          : null;
        client.visaExpiryDate = client.visaExpiryDate
          ? moment(client.visaExpiryDate).format("YYYY-MM-DD")
          : null;
        reset(client);
        setcountry(client.passportCountry);
      });
  };

  const selectCountry = (val) => setcountry(val);

  const applyclient = (data2) => {
    if (clientLoginCheck === 1) {
      applythroughclient(data2);
    } else {
      setbackdropOpen(true);
      const isoDateString = new Date().toISOString();
      const datenow = isoDateString.split(".")[0];
      let comments = `${clienttempdata.comments} \n Course Id- ${
        courseid.courseId
      } \nCourse Name- ${
        applydetails.coursename
      } \n Branch Id- ${selectedBranchId} \nBranch Name- ${selectedBranchValue} \nProvider Id- ${
        courseid.branchId
      } \nProvider Name-${applydetails.providername} \nCricos- ${
        applydetails.cricos
      } \n-->Course Applied by ${clientdetails?.name} on ${moment(
        datenow
      ).format("DD MMM YYYY hh.mm.a")}`;
      let mailComments = `Course Name- ${applydetails.coursename}
        \n Branch Name-${selectedBranchValue}
        \n Course Code-${applydetails.courseCode}
        \n-->Course Applied by ${clientdetails?.name}
        on ${moment(datenow).format("DD MMM YYYY hh.mm.a")}`;

      let passportExpiryDate = moment(data2.passportExpiryDate).format(
        "YYYY-MM-DDThh:mm:ss"
      );
      let passportIssueDate = moment(data2.passportIssueDate).format(
        "YYYY-MM-DDThh:mm:ss"
      );
      let visaExpiryDate = moment(data2.visaExpiryDate).format(
        "YYYY-MM-DDThh:mm:ss"
      );
      let visaIssueDate = moment(data2.visaIssueDate).format(
        "YYYY-MM-DDThh:mm:ss"
      );
      if (passportExpiryDate === "Invalid date") passportExpiryDate = null;
      if (passportIssueDate === "Invalid date") passportIssueDate = null;
      if (visaIssueDate === "Invalid date") visaIssueDate = null;
      if (visaExpiryDate === "Invalid date") visaExpiryDate = null;
      let data = {
        id: clientdetails?.clientTempId,
        address: data2.address,
        clientSource: "Website",
        branchId: selectedBranchId,
        secondaryEmail: data2.secondaryEmail || null,
        alias: data2.alias || null,
        city: data2.city,
        dateOfBirth: moment(data2.dateOfBirth).format("YYYY-MM-DDThh:mm:ss"),
        comments,
        enquiryLeadType: "HotLead",
        enquiryStatus: "Enquiry",
        // passportExpiryDate,
        // passportIssueDate,
        // visaIssueDate,
        // visaExpiryDate,
        // passportCountry: country1,
        // passportNumber: data2.passportNumber,
        pincode: data2.pincode,
        updatedOn: datenow,
        // visaType: data2.visaType,
      };
      //console.log('Apply Data:', applydetails);
      axios
        .post(`${base_url.api5}/updateClientTempById`, data, {
          headers: {
            courseName: applydetails.coursename,
            providerName: applydetails.providername,
            applyCourse: "Yes",
          },
        })
        .then((res) => {
          //console.log("coming here");
          handlesuccess(mailComments);
          // ==================================================================//
          let arraytwo =
            JSON.parse(localStorage.getItem("AppliedCourseList")) || [];
          let bal_array = {
            coursename: applydetails.coursename,
            branchName: selectedBranchValue,
            providername: applydetails.providername,
            cricos: applydetails.cricos,
            offShoreFees: applydetails.offShoreFees,
            OnShoreFees: applydetails.OnShoreFees,
            duration: applydetails.duration,
            courseCode: applydetails.courseCode,
          };
          arraytwo.push(bal_array);
          localStorage.setItem("AppliedCourseList", JSON.stringify(arraytwo));
          // ==================================================================//
          navigate("/course-searchresult");
        })
        .catch((err) => {
          //console.log(err);
          handleerror(err);
        });
    }
  };

  const applythroughclient = (data2) => {
    // const actualdata = clienttempdata;
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let comment = `${clienttempdata.clientDetail.comment || ""} \n Course Id- ${
      courseid.courseId
    } \nCourse Name- ${
      applydetails.coursename
    } \n Branch Id- ${selectedBranchId} \nBranch Name- ${selectedBranchValue} \nProvider Id- ${
      courseid.branchId
    } \nProvider Name-${applydetails.providername} \nCricos- ${
      applydetails.cricos
    } \n-->Course Applied by ${clientdetails?.name} on ${moment(datenow).format(
      "DD MMM YYYY hh.mm.a"
    )}`;
    // actualdata.clientDetail.comment=
    //console.log(actualdata);
    setbackdropOpen(true);
    let mailComments = `Course Name- ${applydetails.coursename}
        \n Branch Name-${selectedBranchValue}
        \n Cricos Course Code-${applydetails.cricos}
        \n-->Course Applied by ${clientdetails?.name}
        on ${moment(datenow).format("DD MMM YYYY hh.mm.a")}`;

    let passportExpiryDate = moment(data2.passportExpiryDate).format(
      "YYYY-MM-DDThh:mm:ss"
    );
    let passportIssueDate = moment(data2.passportIssueDate).format(
      "YYYY-MM-DDThh:mm:ss"
    );
    let visaExpiry = moment(data2.visaExpiry).format("YYYY-MM-DDThh:mm:ss");
    let visaIssueDate = moment(data2.visaIssueDate).format(
      "YYYY-MM-DDThh:mm:ss"
    );
    if (passportExpiryDate === "Invalid date") passportExpiryDate = null;
    if (passportIssueDate === "Invalid date") passportIssueDate = null;
    if (visaIssueDate === "Invalid date") visaIssueDate = null;
    if (visaExpiry === "Invalid date") visaExpiry = null;
    let clientDetail = {
      address: data2.address,
      alias: data2.alias || null,
      city: data2.city,
      clientSource: "Website",
      comment,
      dob: moment(data2.dob).format("YYYY-MM-DDThh:mm:ss"),
      email: clientdetails?.username,
      id: clienttempdata.clientDetail.id,
      // passport: country1,
      // passportExpiryDate,
      // passportIssueDate,
      // passportNumber: data2.passportNumber,
      pincode: data2.pincode,
      secondaryEmail: data2.secondaryEmail || null,
      username: clientdetails?.username,
      // visaExpiry,
      // visaIssueDate,
      // visaType: data2.visaType,
    };
    let data = {
      address: data2.address,
      alias: data2.alias || null,
      city: data2.city,
      phone: Number(data2.phone),
      clientDetail,
      clientSource: "Website",
      email: clientdetails?.username,
      id: clientdetails?.clientId,
      enquiryLeadType: "HotLead",
      pincode: data2.pincode,
      secondaryEmail: data2.secondaryEmail || null,
      updatedOn: datenow,
      username: clientdetails?.username,
      // visaExpiry,
      // visaIssueDate,
      // visaType: data2.visaType,
    };
    axios
      .post(`${base_url.api5}/updateClientByUsername`, data, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          enquiryLeadType: "HotLead",
        },
      })
      .then((res) => {
        //console.log("coming here");
        handlesuccess(mailComments);
        // ==================================================================//
        let arraytwo =
          JSON.parse(localStorage.getItem("AppliedCourseList")) || [];
        let bal_array = {
          coursename: applydetails.coursename,
          branchName: selectedBranchValue,
          providername: applydetails.providername,
          cricos: applydetails.cricos,
          offShoreFees: applydetails.offShoreFees,
          OnShoreFees: applydetails.OnShoreFees,
          duration: applydetails.duration,
          courseCode: applydetails.courseCode,
        };
        arraytwo.push(bal_array);
        localStorage.setItem("AppliedCourseList", JSON.stringify(arraytwo));
        // ==================================================================//
        navigate("/course-searchresult");
      })
      .catch((err) => {
        //console.log(err);
        handleerror(err);
      });
  };
  const mailForwardone = "panda.sangam7@gmail.com";
  const mailForwardtwo = "panda.sangam234@gmail.com";

  const handlesuccess = (mailComments) => {
    //console.log("coming here in handlesucess");
    toast.success(
      `Your application has been applied for ${applydetails.coursename} at ${selectedBranchValue}`
    );
    const key = `${courseid.courseId}${courseid.branchId}`;
    applydetail[key] = true;
    localStorage.setItem(
      "isw_frontend_applycourse_detail",
      JSON.stringify(applydetail)
    );
    let isAgent;
    if (Number(loggedInUser) === 1) {
      isAgent = 1;
    } else {
      isAgent = 0;
    }
    let mailData = {
      description: mailComments,
      fromEmailId: clientdetails?.username,
      id: 0,
      mailType: "received",
      status: 0,
      isAgent: isAgent,
      subject:
        "Application Received From " +
        clientdetails?.name +
        " (" +
        clientdetails?.username +
        ")",
      toEmailId: clientdetails?.username,
    };
    axios.post(`${base_url.api5}/createEmail`, mailData);
    setbackdropOpen(false);
    navigate.goBack();
  };

  const handleerror = (err) => {
    setbackdropOpen(false);
    if (err.response) {
      toast.error(err.response.data.message);
    } else {
      toast.error("Something went wrong");
    }
  };
  return (
    <div ref={pageStartRef}>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavbarNew />
      {/* <ReactNavbar /> */}
      <div className="coursecompare_bodymaincontainer login">
        <h2 className="text-center mb-0">Apply Now at</h2>
        <h2 className="text-center mb-4">International Student World</h2>
        <h4 className="text-center my-4 login_h4">
          You are applying for {applydetails?.coursename} at{" "}
          {selectedBranchValue}
        </h4>
        <form
          onSubmit={handleSubmit(applyclient)}
          className="login_container mt-4"
        >
          <h4>PERSONAL DETAILS</h4>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>
                Date of Birth<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                {clientLoginCheck === 1 ? (
                  <input
                    type="date"
                    max="2010-12-31"
                    {...register("dob", { required: true })}
                    className="form-control"
                  />
                ) : (
                  <input
                    type="date"
                    max="2010-12-31"
                    {...register("dateOfBirth", { required: true })}
                    className="form-control"
                  />
                )}
                <i className="fa fa-calendar designi" />
              </div>
              {clientLoginCheck === 1 ? (
                <>
                  {errors.dob && (
                    <p className="errormessage mb-0">DOB is required</p>
                  )}
                </>
              ) : (
                <>
                  {errors.dateOfBirth && (
                    <p className="errormessage mb-0">DOB is required</p>
                  )}
                </>
              )}
            </div>
            <div className="col-md-4">
              <label>Alias</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("alias")}
                  className="form-control"
                  placeholder="Alias"
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Secondary Email</label>
              <div style={{ position: "relative" }}>
                <input
                  type="email"
                  className="form-control"
                  {...register("secondaryEmail")}
                  placeholder="Enter Secondary Email"
                />
                <i className="fa fa-envelope-o designi" />
              </div>
            </div>
          </div>

          <h4>Address</h4>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>
                Street<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("address", { required: true })}
                  className="form-control"
                  placeholder="Street"
                />
                <i className="fa fa-street-view designi" />
              </div>
              {errors.address && (
                <p className="errormessage mb-0">Street is required</p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                City<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("city", { required: true })}
                  className="form-control"
                  placeholder="City"
                />
                <i className="fa fa-globe designi" />
              </div>
              {errors.city && (
                <p className="errormessage mb-0">City is required</p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                Post Code<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("pincode", { required: true })}
                  className="form-control"
                  placeholder="PostCode"
                />
                <i className="fa fa-code designi" />
              </div>
              {errors.pincode && (
                <p className="errormessage mb-0">PostCode is required</p>
              )}
            </div>
          </div>

          <h4>Campus Location</h4>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>
                Select a campus location for the course you want to apply
                <span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <select
                  className="form-control selecttag2"
                  {...register("branchId", { required: true })}
                  onChange={handleSelectChange}
                  value={selectedBranchValue}
                >
                  <option value="">Select Branch</option>
                  {transformedArray?.map((cuntitem, index) => (
                    <option
                      key={index}
                      value={cuntitem.branchName}
                      id={cuntitem.branchId}
                    >
                      {cuntitem.branchName}
                    </option>
                  ))}
                  <option value="AnyLocation">Any location</option>
                </select>
                <i className="fa fa-share-square designi" />
              </div>
              {selectedBranchValue == "" && (
                <p
                  className="errormessage mb-0"
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    cursor: "none",
                  }}
                >
                  Note:&nbsp;
                  <span style={{ fontWeight: "normal" }}>
                    If you are unsure of the location, please choose 'Any
                    location'. We will confirm this later
                  </span>
                </p>
              )}
              {errors.branchId && (
                <p className="errormessage mb-0">Branch name is required</p>
              )}
            </div>
            {/* <div className="col-md-4">
              <label>
                Client Source<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <select
                  className="form-control selecttag2"
                  {...register("clientSource", { required: true })}
                >
                  <option value="">Select Client Source</option>
                  {clientSource?.map((cuntitem, index) => (
                    <option key={index} value={cuntitem.name} id={cuntitem.id}>
                      {cuntitem.name}
                    </option>
                  ))}
                </select>
                <i className="fa fa-share-square designi" />
              </div>
              {errors.clientSource && (
                <p className="errormessage mb-0">Client Source is required</p>
              )}
            </div> */}
          </div>
          {/* <h4>Passport/Visa Information</h4>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>Passport Country</label>
              <div style={{ position: "relative" }}>
                <CountryDropdown
                  className="form-control selecttag2"
                  value={country1}
                  onChange={(val) => selectCountry(val)}
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Passport Number</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("passportNumber")}
                  className="form-control"
                  placeholder="Passport Number"
                />
                <i className="fa fa-volume-control-phone designi" />
              </div>
            </div>

            <div className="col-md-4">
              <label>Passport Issue Date</label>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  {...register("passportIssueDate")}
                  className="form-control "
                  onChange={(e) => setpassportdate(e.target.value)}
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>Passport Expiry Date</label>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  {...register("passportExpiryDate")}
                  className="form-control"
                  min={passportdate}
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Visa Type</label>
              <div style={{ position: "relative" }}>
                <select
                  className="form-control selecttag2"
                  {...register("visaType")}
                >
                  <option value="">Select Visa Type</option>
                  {allVisa?.map((cuntitem, index) => (
                    <option key={index} value={cuntitem.name} id={cuntitem.id}>
                      {cuntitem.name}
                    </option>
                  ))}
                </select>
                <i className="fa fa-share-square designi" />
              </div>
            </div>

            <div className="col-md-4">
              <label>Visa Issue Date</label>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  {...register("visaIssueDate")}
                  className="form-control"
                  onChange={(e) => setvisadate(e.target.value)}
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>Expiry Date</label>
              <div style={{ position: "relative" }}>
                {clientdetails?.token ? (
                  <input
                    type="date"
                    {...register("visaExpiry")}
                    className="form-control"
                    min={visadate}
                  />
                ) : (
                  <input
                    type="date"
                    {...register("visaExpiryDate")}
                    className="form-control"
                    min={visadate}
                  />
                )}
                <i className="fa fa-calendar designi" />
              </div>
            </div>
          </div> */}
          <div className="my-4 row" style={{ height: "50px" }}>
            <div className="col-md-4"></div>
          </div>
          <div className="row">
            <div className="col-md-4">{null}</div>
            <div className="col-md-4">
              <button type="submit" className="btn">
                Apply
              </button>
            </div>
            <div className="col-md-4">{null}</div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ApplyNow;
