import React from "react";
import "./Education.css";
import logo from "../../assets/images/downlogo.png";
import headerImg from "../../assets/images/education-header-img.png";
import circleImg from "../../assets/images/unnamed.png";

import SearchIcon from "@mui/icons-material/Search";

import LanguageIcon from "@mui/icons-material/Language";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import NavbarForSearchTwo from "../Navbar/NavbarForSearchTwo";
import Footer from "../Footer/Footer";
import Navbarfor from "../Navbar/Navbarfor";

const ChangingProvider = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  return (
    <div>
      <div className="education_header center">
        {/* <div className="education_container contain">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <img src={logo} alt="logo" style={{ width: "70%" }} />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    border: "1px solid #000",
                                    borderRadius: "10px",
                                    padding: "5px",
                                    //   height: "30px",
                                }}
                            >
                                <SearchIcon style={{ color: "#00694B" }} />
                                <input
                                    placeholder="Search..."
                                    style={{
                                        outline: "none",
                                        border: "none",
                                        flex: 1,
                                        padding: 0,

                                        // fontSize: "20px",
                                        // height: "100% ",
                                    }}
                                />
                            </div>

                            <div
                                className="nav-item dropdown"
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <LanguageIcon style={{ color: "#00694B" }} />
                                <a
                                    className="nav-link dropdown-toggle language"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    style={{ padding: 0 }}
                                >
                                    ENGLISH
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a className="dropdown-item">English</a>
                                    <a className="dropdown-item">India</a>
                                    <a className="dropdown-item">Hindi</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

        {/* <div style={{ backgroundColor: "#D1D9D7" }}>
          <div className="contain">
            <h1>hello</h1>
          </div>
        </div> */}
        {/* <nav itemscope itemtype="http://schema.org/SiteNavigationElement" id="site-mega-menu" class="d-none d-lg-block" style={{ backgroundColor: "#d1d9d7" }}>
                    <div class="austrade-container" style={{ fontSize: "1.3rem", fontFamily: "ui-sans-serif" }}>
                        <div class="d-flex justify-content-between">
                            <div class="nav-item dropdown has-megamenu">
                                <a class="nav-link" data-bs-hover="dropdown" aria-expanded="false" href="/en/why-australia">Why Australia<span class="material-symbols icon-md"></span></a>
                                <div class="dropdown-menu megamenu animate slideIn austrade-container" role="menu">
                                    <div class="row">

                                        <div class="col-3">
                                            <p class="megamenu-heading"><a href="/en/why-australia"><span class="material-symbols icon-md">chevron_right</span> Why Australia</a></p>
                                            <hr class="mb-3 w-100" />
                                            <p class="smaller">Australia is ideal for international students, with high-quality education, a welcoming culture, vibrant student cities and beautiful natural surroundings. </p>
                                        </div>

                                        <div class="col-9 megamenu-columns">
                                            <ul class="navbar-nav">
                                                <li class="nav-item"><a class="nav-link" href="/en/why-australia/world-class-education">World class education</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/why-australia/australian-culture-and-lifestyle">Australian culture and lifestyle</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/why-australia/real-world-learning">Real world learning </a></li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="nav-item dropdown has-megamenu">
                                <a class="nav-link" data-bs-hover="dropdown" aria-expanded="false" href="/en/plan-your-studies">Plan your studies<span class="material-symbols icon-md"></span></a>
                                <div class="dropdown-menu megamenu animate slideIn austrade-container" role="menu">
                                    <div class="row">

                                        <div class="col-3">
                                            <p class="megamenu-heading"><a href="/en/plan-your-studies"><span class="material-symbols icon-md">chevron_right</span> Plan your studies</a></p>
                                            <hr class="mb-3 w-100" />
                                            <p class="smaller">Australia has a range of learning institutions, including universities, vocational education and training institutes, schools and English language colleges.</p>
                                        </div>

                                        <div class="col-9 megamenu-columns">
                                            <ul class="navbar-nav">
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/find-a-course">Find a course</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/how-to-apply-to-study">How to apply to study</a></li>
                                                <li class="nav-item"><a aria-current="page" class="nav-link" href="/en/plan-your-studies/australias-education-system">Australia’s education system </a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/areas-of-study">Areas of study</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/english-courses">English courses</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/schools">Schools</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/vocational-education-and-training">Vocational education and training</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/universities-and-higher-education">Universities and higher education</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/scholarships">Scholarships</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/study-abroad-and-exchange">Study abroad and exchange</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/other-ways-to-study">Other ways to study</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/list-of-australian-universities">List of Australian universities</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-studies/changing-your-course-or-provider">Changing your course or provider</a></li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="nav-item dropdown has-megamenu">
                                <a class="nav-link" data-bs-hover="dropdown" aria-expanded="false" href="/en/life-in-australia">Life in Australia<span class="material-symbols icon-md"></span></a>
                                <div class="dropdown-menu megamenu animate slideIn austrade-container" role="menu">
                                    <div class="row">

                                        <div class="col-3">
                                            <p class="megamenu-heading"><a href="/en/life-in-australia"><span class="material-symbols icon-md">chevron_right</span> Life in Australia</a></p>
                                            <hr class="mb-3 w-100" />
                                            <p class="smaller">Life in Australia offers international students a welcoming and diverse culture, a high standard of living, access to student support services and more.</p>
                                        </div>

                                        <div class="col-9 megamenu-columns">
                                            <ul class="navbar-nav">
                                                <li class="nav-item"><a class="nav-link" href="/en/life-in-australia/locations-in-australia">Locations in Australia</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/life-in-australia/accommodation">Accommodation</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/life-in-australia/living-and-education-costs">Living and education costs</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/life-in-australia/safety-in-australia">Safety in Australia</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/life-in-australia/student-support-services">Student support services</a></li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="nav-item dropdown has-megamenu">
                                <a class="nav-link" data-bs-hover="dropdown" aria-expanded="false" href="/en/plan-your-move">Plan your move<span class="material-symbols icon-md"></span></a>
                                <div class="dropdown-menu megamenu animate slideIn austrade-container" role="menu">
                                    <div class="row">

                                        <div class="col-3">
                                            <p class="megamenu-heading"><a href="/en/plan-your-move"><span class="material-symbols icon-md">chevron_right</span> Plan your move</a></p>
                                            <hr class="mb-3 w-100" />
                                            <p class="smaller">Find the information you’ll need as an international student to move to Australia and set yourself up for success.</p>
                                        </div>

                                        <div class="col-9 megamenu-columns">
                                            <ul class="navbar-nav">
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-move/what-can-i-bring-to-australia">What can you bring to Australia? </a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-move/your-first-week-in-australia">Your first week in Australia</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-move/preparing-to-travel">Preparing to travel</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-move/your-guide-to-visas">Your guide to visas</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-move/bringing-your-family">Bringing your family</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-move/overseas-student-health-cover-oshc">Overseas Student Health Cover (OSHC) </a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-move/visa-application-process">How to apply for your visa</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/plan-your-move/language-testing-organisations">Language testing organisations</a></li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="nav-item dropdown has-megamenu">
                                <a class="nav-link" data-bs-hover="dropdown" aria-expanded="false" href="/en/work-in-australia">Work in Australia<span class="material-symbols icon-md"></span></a>
                                <div class="dropdown-menu megamenu animate slideIn austrade-container" role="menu">
                                    <div class="row">

                                        <div class="col-3">
                                            <p class="megamenu-heading"><a href="/en/work-in-australia"><span class="material-symbols icon-md">chevron_right</span> Work in Australia</a></p>
                                            <hr class="mb-3 w-100" />
                                            <p class="smaller">International students in Australia can work while they study, with access to a wide range of options for building networks and gaining valuable industry experience. </p>
                                        </div>

                                        <div class="col-9 megamenu-columns">
                                            <ul class="navbar-nav">
                                                <li class="nav-item"><a class="nav-link" href="/en/work-in-australia/preparing-for-work">Preparing for work</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/work-in-australia/finding-work-while-you-study">Finding work while you study</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/work-in-australia/work-rights-and-responsibilities">Work rights and responsibilities </a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/work-in-australia/getting-work-and-industry-experience">Volunteer and industry experience</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/work-in-australia/work-after-graduating">Work after graduating</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/work-in-australia/student-employability-stories">Student Employability Stories</a></li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="nav-item dropdown has-megamenu">
                                <a class="nav-link" data-bs-hover="dropdown" aria-expanded="false" href="/en/tools-and-resources">Tools and resources<span class="material-symbols icon-md"></span></a>
                                <div class="dropdown-menu megamenu animate slideIn austrade-container" role="menu">
                                    <div class="row">

                                        <div class="col-3">
                                            <p class="megamenu-heading"><a href="/en/tools-and-resources"><span class="material-symbols icon-md">chevron_right</span> Tools and resources</a></p>
                                            <hr class="mb-3 w-100" />
                                            <p class="smaller">Masterclasses, inspiring student stories and helpful tips and advice (blogs).</p>
                                        </div>

                                        <div class="col-9 megamenu-columns">
                                            <ul class="navbar-nav">
                                                <li class="nav-item"><a class="nav-link" href="/en/tools-and-resources/masterclass-library">Masterclass library</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/tools-and-resources/news">News</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/tools-and-resources/student-stories">Student stories</a></li>
                                                <li class="nav-item"><a class="nav-link" href="/en/tools-and-resources/tips-and-advice-for-students">Tips and advice for students</a></li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </nav> */}

        <div className="s_bgimage">
          <Navbarfor />

          <div
            style={{
              display: "grid",
              // gridTemplateColumns: "1fr 1fr",
              height: "35vh",
              overflow: "hidden",
            }}
          >
            {/* <div style={{ height: "100%" }}>
                        <img
                            src={headerImg}
                            alt="img"
                            style={{ height: "100%", width: "100%" }}
                        />
                    </div> */}
            <div className="bg_education">
              <>
                <h2
                  style={{
                    color: "#000",
                    marginLeft: "10%",
                    fontSize: "3rem",
                    fontFamily: "monospace",
                    marginTop: "90px",
                  }}
                >
                  Changing your course or provider
                </h2>
              </>

              <p
                style={{
                  paddingBottom: "10px",
                  marginTop: "1rem",
                  fontSize: "1.8rem",
                  color: "#000",
                  marginLeft: "10%",
                  fontFamily: "sans-serif",
                }}
              >
                Find out how to change your course or level of
                <br />
                qualification, or transfer between education providers.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="center_container">
            <div className="summary_section">
              <div>
                <h4>Menu</h4>
                <div className="sub_menu">
                  <a onClick={() => scrollToSection("changecourse")}>
                    Changing your course at the same education provider
                  </a>
                  <a onClick={() => scrollToSection("changeeducation")}>
                    Changing your education provider
                  </a>
                  <a onClick={() => scrollToSection("moreinfo")}>
                    More information and support{" "}
                  </a>
                </div>
              </div>
              <div style={{ flex: 1 }} className="sub_menu">
                <p>
                  After you start studying in Australia, you might find a
                  different course or study area better suited to your interests
                  or career plans.
                  <br />
                  A condition of your student visa is that you must stay
                  enrolled in a course at the same or higher level as the course
                  your visa was granted.
                  <br />
                  You must check your student visa requirements and follow the
                  correct procedures to change your course or education
                  provider.
                  <br />
                  Your visa may have conditions that require you to get
                  permission before changing your study plan. To check your visa
                  status and conditions, use VEVO.
                </p>

                <div
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                  className="changecourse"
                  id="changecourse"
                >
                  <h1>Changing your course at the same education provider</h1>

                  <div>
                    <h4>
                      If you are changing your course but staying with the same
                      education or training provider:
                    </h4>
                    <ul>
                      <li>
                        If your new course is at the same or higher level of
                        qualification, or you are changing from a doctoral to a
                        master's degree, you can make arrangements with your
                        provider and do not need to apply for a new student
                        visa. However, if your current visa is expiring and you
                        need more time to complete your new course, you will
                        need to apply for a new student visa.
                      </li>
                      <li>
                        You must apply for another student visa if your new
                        course is a lower-level qualification (for example, from
                        a bachelor's degree to a Diploma) or you are
                        transferring to an ELICOS course.
                      </li>
                      <li>Start date - Late January/early February</li>
                    </ul>
                  </div>
                  <p>
                    It is essential to check the Department of Home Affairs
                    website as conditions can vary depending on your visa
                    subclass and circumstances.
                  </p>
                </div>

                <div
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "17px",
                  }}
                  className="changeeducation"
                  id="changeeducation"
                >
                  <h1>Changing your education provider </h1>

                  <div>
                    <h3>
                      If you have completed less than six months of your main
                      course{" "}
                    </h3>
                    <p>
                      You can only change education providers in some
                      circumstances. For example, the provider will consider
                      your transfer in situations such as:
                    </p>
                    <ul>
                      <li>serious illness</li>
                      <li>
                        a loss of a family member (where possible, a death
                        certificate should be provided)
                      </li>
                      <li>
                        a major disaster in your home country requiring
                        emergency travel{" "}
                      </li>
                      <li>a traumatic experience.</li>
                    </ul>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                  className="moreinfo"
                  id="moreinfo"
                >
                  <h1>More information and support </h1>

                  <div>
                    <h4>Foundation academic year dates </h4>
                    <ul>
                      <li>
                        Visit the Change in Your Situation page on the
                        Department of Home Affairs website for more information.
                      </li>
                      <li>
                        Contact the on-campus student support services or other
                        advisors for help and advice.
                      </li>
                    </ul>
                  </div>
                  {/* <div
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "1fr 1fr",
                                            height: "60vh",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <div style={{ height: "100%" }}>
                                            <img
                                                src={headerImg}
                                                alt="img"
                                                style={{ height: "100%", width: "100%" }}
                                            />
                                        </div>
                                        <div className="bg_education" >
                                            <>
                                                <h1 style={{ color: "#fff", fontFamily: "monospace" }}>Australia’s</h1>
                                            </>
                                            <h1 style={{ color: "#fff", fontFamily: "monospace" }}>education system</h1>

                                            <p style={{ marginTop: "3rem", fontSize: "1.3rem", color: "#fff", fontFamily: "sans-serif" }}>

                                                Find out more about Australia’s education<br />
                                                system and the qualification levels.
                                            </p>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
                    style={{
                        // height: "60vh",
                        backgroundColor: "#173F35",
                        marginTop: "30px",
                        padding: "30px 0px",
                    }}
                    className="s_footer_heading"
                >
                    <div className="contain">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <h3 style={{ color: "#0FAE6C" }}>Quick links</h3>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <ArrowForwardIcon style={{ color: "#fff" }} />
                                        <p style={{ marginBottom: 0, color: "#fff" }}>
                                            Why Australia
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <ArrowForwardIcon style={{ color: "#fff" }} />
                                        <p style={{ marginBottom: 0, color: "#fff" }}>
                                            Visa and travel advice
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <ArrowForwardIcon style={{ color: "#fff" }} />
                                        <p style={{ marginBottom: 0, color: "#fff" }}>
                                            Living costs
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <ArrowForwardIcon style={{ color: "#fff" }} />
                                        <p style={{ marginBottom: 0, color: "#fff" }}>
                                            Australian study locations
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h3 style={{ color: "#0FAE6C" }}>Useful tools</h3>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <ArrowForwardIcon style={{ color: "#fff" }} />
                                        <p style={{ marginBottom: 0, color: "#fff" }}>
                                            Career Matcher
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <ArrowForwardIcon style={{ color: "#fff" }} />
                                        <p style={{ marginBottom: 0, color: "#fff" }}>
                                            Speaking English practice test
                                        </p>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <ArrowForwardIcon style={{ color: "#fff" }} />
                                        <p style={{ marginBottom: 0, color: "#fff" }}>
                                            Cost of living calculator
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h3 style={{ color: "#0FAE6C" }}>Follow us</h3>
                                <h3 style={{ color: "#0FAE6C" }}>
                                    Subscribe to our newsletter
                                </h3>
                                <p style={{ marginBottom: 0, color: "#fff" }}>
                                    Sign up to get all the latest student stories, blog <br />
                                    posts and updates on student visas and travel to
                                    <br /> Australia straight to your inbox.
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: "2px",
                                backgroundColor: "#00BA6C",
                                margin: "30px 0px",
                            }}
                        />

                        <p style={{ color: "#fff" }}>
                            In the spirit of reconciliation we acknowledge the Traditional
                            Custodians of country throughout Australia and their connections
                            to land, sea and community. We pay our respect to their elders
                            past and present and extend that respect to all Aboriginal and
                            Torres Strait Islander peoples today.
                        </p>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "50px",
                            }}
                        >
                            <div style={{ display: "flex", gap: "30px" }}>
                                <p style={{ color: "#fff" }}>About us</p>
                                <p style={{ color: "#fff" }}>Site Disclaimer</p>
                                <p style={{ color: "#fff" }}>Privacy policy</p>
                            </div>

                            <p style={{ color: "#fff" }}>
                                © 2023, Australian Trade and Investment Commission
                            </p>
                        </div>
                    </div>
                </div> */}
        <Footer />
      </div>
    </div>
  );
};

export default ChangingProvider;
