import axios from "axios";
import React, { useEffect, useState } from "react";
import base_url from "../api/bootapi";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./../../assets/images/no-data-found.png";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const LeadCompare = (props) => {
  useEffect(() => {
    getcomparelistbylead();
  }, []);

  const classes = useStyles();

  const [backdropopen, setbackdropOpen] = useState(false);

  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  const applydetail =
    JSON.parse(localStorage.getItem("isw_frontend_applycourse_detail")) || {};
  const [classname, setclassname] = useState();
  const [comparedata, setcomparedata] = useState([]);

  const getcomparelistbylead = () => {
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getClientCompareListByClientTempId`, {
        headers: {
          clientTempId: clientdetails?.clientId,
          columnName: "id",
          direction: "DESC",
          page: 0,
          size: 20,
        },
      })
      .then((res) => {
        let data = res.data.data;
        setcomparedata(data);
        setbackdropOpen(false);
      })
      .catch((err) => {
        setbackdropOpen(false);
        setcomparedata([]);
      });
  };
  //console.log("comparedata.length ------->", comparedata.length);

  return (
    <div className="accountbody">
      <div className="account-username"></div>
      <div class="show-desktop-only">
        <div class="accountbody">
          <h2 className="accountbody-title">Compare List</h2>
          <div className="accountenquiry">
            <Backdrop className={classes.backdrop} open={backdropopen}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              {comparedata.length > 0 ? (
                <table className="table tbb">
                  <thead className="thb">
                    <tr>
                      <th>CourseName</th>
                      <th>ProviderName</th>
                      <th>Duration</th>
                      <th>OnShoreFee</th>
                      <th>OffShoreFee</th>
                      <th>Cricos</th>
                      <th>Course Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {comparedata?.map((item, i) => (
                      <tr key={i}>
                        <td>{item.name || "-"}</td>
                        <td>{item.branch_name?.split("-")[0] || "-"}</td>
                        <td>{item.duration || "-"}</td>
                        <td>
                          {Number(item.fees) === 0 ? (
                            <>-</>
                          ) : (
                            <>
                              {item.fees && "$"} {item.fees || "-"}
                            </>
                          )}
                        </td>
                        <td>
                          {Number(item.off_shore_fees) === 0 ? (
                            <>-</>
                          ) : (
                            <>
                              {item.off_shore_fees && "$"}{" "}
                              {item.off_shore_fees || "-"}
                            </>
                          )}
                        </td>
                        <td>{item.cricos || "-"}</td>
                        <td>{item.course_code || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <>
                  {backdropopen ? null : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ marginTop: "50px" }}
                    >
                      No Record Found
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div></div>
              <Pagination />
            </div>
          </div>
        </div>
      </div>

      <div class="show-mobile-only">
        <div class="accountbody">
          <h2 className="accountbody-title">Compare List</h2>
          <div className="accountenquiry">
            <Backdrop className={classes.backdrop} open={backdropopen}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              {comparedata.length > 0 ? (
                <table className="table tbb">
                  <tbody>
                    {comparedata?.map((item, i) => (
                      <tr key={i}>
                        <td>{item.name || "-"}</td>
                        <td>{item.branch_name?.split("-")[0] || "-"}</td>
                        <td>{item.duration || "-"}</td>
                        <td>
                          {Number(item.fees) === 0 ? (
                            <>-</>
                          ) : (
                            <>
                              {item.fees && "$"} {item.fees || "-"}
                            </>
                          )}
                        </td>
                        <td>
                          {Number(item.off_shore_fees) === 0 ? (
                            <>-</>
                          ) : (
                            <>
                              {item.off_shore_fees && "$"}{" "}
                              {item.off_shore_fees || "-"}
                            </>
                          )}
                        </td>
                        <td>{item.cricos || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <>
                  {backdropopen ? null : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ marginTop: "50px" }}
                    >
                      No Record Found
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div></div>
              <Pagination />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadCompare;
