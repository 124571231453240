import React, { useEffect, useRef } from "react";
import Footer from "../Footer/Footer";
import NavbarNew from "../Navbar/NavbarNew";
import PopularSearchbody from "./PopularSearchBody";

const PopularCourse = () => {
    const pageStartRef = useRef(null);
    useEffect(() => {
        /* eslint-disable react-hooks/exhaustive-deps */
        pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
    }, []);
    return (
        <div ref={pageStartRef}>
            <NavbarNew />
            <PopularSearchbody />
            <Footer />
        </div>
    )
}

export default PopularCourse