import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../api/bootapi";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import SelectCurrency from "react-select-currency";
import Modal from "react-modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const EditAccount = () => {
  const [allIntake, setallIntake] = useState([]);
  const [allVisa, setallVisa] = useState([]);
  const [allHealth, setallHealth] = useState([]);
  const [clientDetail, setclientDetail] = useState();
  const [region, setregion] = useState();
  const [country1, setcountry] = useState();
  const [country1Passport, setcountryPassport] = useState();
  const [countryrequired, setcountryrequired] = useState(false);
  const [staterequired, setstaterequired] = useState(false);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [stateCust, setstateCust] = useState();
  const classes = useStyles();
  const [backdropopen, setbackdropOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: clientDetail,
  });

  const loggedInUser = localStorage.getItem("agentLogin");
  const [currency1, setCurrency] = useState("");
  const [agentData, setAgentData] = useState();
  const [agentType, setAgentType] = useState();
  const [openTransactionalForm, setOpenTransactionalForm] = useState(false);
  const [transactionalArrangementList, setTransactionalArrangementList] =
    useState();

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    axios
      .get(`${base_url.api5}/getAllIntake`)
      .then((res) => setallIntake(res.data.data));
    axios
      .get(`${base_url.api5}/getAllVisaType`)
      .then((res) => setallVisa(res.data.data));
    axios
      .get(`${base_url.api5}/findAllInsurance`)
      .then((res) => setallHealth(res.data.data));
    getClientDetails();
  }, []);

  const getClientDetails = () => {
    if (Number(loggedInUser) === 0) {
      axios
        .get(`${base_url.api5}/getClientByUsername`, {
          headers: {
            Authorization: `Bearer ${clientdetails?.token}`,
            username: clientdetails?.username,
          },
        })
        .then((res) => {
          let client = res.data.data.clientDetail;
          let client2 = res.data.data;
          setclientDetail(client);
          client.dob = moment(client.dob).format("YYYY-MM-DD");
          client.passportIssueDate = client.passportIssueDate
            ? moment(client.passportIssueDate).format("YYYY-MM-DD")
            : null;
          client.passportExpiryDate = client.passportExpiryDate
            ? moment(client.passportExpiryDate).format("YYYY-MM-DD")
            : null;
          client.visaIssueDate = client.visaIssueDate
            ? moment(client.visaIssueDate).format("YYYY-MM-DD")
            : null;
          client.visaExpiry = client.visaExpiry
            ? moment(client.visaExpiry).format("YYYY-MM-DD")
            : null;
          client.insuranceIssueDate = client.insuranceIssueDate
            ? moment(client.insuranceIssueDate).format("YYYY-MM-DD")
            : null;
          client.insuranceExpiryDate = client.insuranceExpiryDate
            ? moment(client.insuranceExpiryDate).format("YYYY-MM-DD")
            : null;
          client.membershipExpiry = client.membershipExpiry
            ? moment(client.membershipExpiry).format("YYYY-MM-DD")
            : null;
          reset(client);
          setstateCust(client2);
          setcountry(client.country);
          setcountryPassport(client.passport || "");
          setregion(client.state);
          // setValue(client.contactPreference);
        });
    } else {
      axios
        .get(`${base_url.api5}/getAgentByUsername`, {
          headers: {
            Authorization: `Bearer ${clientdetails?.token}`,
            username: clientdetails?.username,
          },
        })
        .then((res) => {
          let agent = res.data.data;

          setAgentData(agent);
          setCurrency(agent.currency);
          setcountry(agent.country);
          setregion(agent.state);

          let agentType;
          if (Number(agent.subAgent) === 1) {
            agentType = "Sub Agent";
          } else if (Number(agent.superAgent) === 1) {
            agentType = "Super Agent";
          } else if (
            Number(agent.superAgent) === 1 &&
            Number(agent.subAgent) === 1
          ) {
            agentType = "Sub Agent, Super Agent";
          }
          setAgentType(agentType);

          setTransactionalArrangementList(agent.transactionalAgreement);
          // client.dob = moment(client.dob).format("YYYY-MM-DD");
          // client.passportIssueDate = client.passportIssueDate
          //   ? moment(client.passportIssueDate).format("YYYY-MM-DD")
          //   : null;
          // client.passportExpiryDate = client.passportExpiryDate
          //   ? moment(client.passportExpiryDate).format("YYYY-MM-DD")
          //   : null;
          // client.visaIssueDate = client.visaIssueDate
          //   ? moment(client.visaIssueDate).format("YYYY-MM-DD")
          //   : null;
          // client.visaExpiry = client.visaExpiry
          //   ? moment(client.visaExpiry).format("YYYY-MM-DD")
          //   : null;
          // client.insuranceIssueDate = client.insuranceIssueDate
          //   ? moment(client.insuranceIssueDate).format("YYYY-MM-DD")
          //   : null;
          // client.insuranceExpiryDate = client.insuranceExpiryDate
          //   ? moment(client.insuranceExpiryDate).format("YYYY-MM-DD")
          //   : null;
          // client.membershipExpiry = client.membershipExpiry
          //   ? moment(client.membershipExpiry).format("YYYY-MM-DD")
          //   : null;
          // reset(client);
          // setstateCust(client2);
          // setcountry(client.country);
          // setcountryPassport(client.passport || "");
          // setregion(client.state);
          // setValue(client.contactPreference);
        });
    }
  };

  const downloadAll = (agentId, agentAgreementPath) => {
    setbackdropOpen(true);
    let file_name =
      agentAgreementPath.split("amazonaws.com/")[
        agentAgreementPath.split("amazonaws.com/").length - 1
      ];
    const header = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${clientdetails?.token}`,
        agentId: agentData?.agentId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api5}/downloadAgentTransactionalAgreementByInstitutionIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        //console.log(response);
        const downloadFileType = localStorage.getItem(
          "agent_attachment_file_content_type"
        );
        //console.log(downloadFileType);
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdropOpen(false);
      })
      .catch((error) => {
        setbackdropOpen(false);
        //console.log(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "agent_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };

  const uploadLogo = async (data) => {
    //console.log(data.target.files);
    const file = data.target.files[0];
    const base64 = await convertBase64(file);
    agentData.photo = base64;
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const selectcurrency = (e) => {
    setCurrency(e.target.value);
  };

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectCountryPassport = (val) => {
    setcountryPassport(val);
  };
  const selectRegion = (val) => {
    setregion(val);
    setstaterequired(false);
  };

  const inputEvent = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setclientDetail((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    stateCust.clientDetail = clientDetail;
  };

  const editprofile = (data2) => {
    if (!country1) {
      setcountryrequired(true);
      setstaterequired(false);
      return;
    } else if (!region) {
      setcountryrequired(false);
      setstaterequired(true);
      return;
    }
    setcountryrequired(false);
    setstaterequired(false);
    setbackdropOpen(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let clientdetail = {
      address: data2.address,
      alias: data2.alias,
      ban: 0,
      city: data2.city,
      comment: data2.comment,
      company: "string",
      contactPreference: "phone",
      country: country1,
      currentlyStudyingInstitutionName: data2.currentlyStudyingInstitutionName,
      dob: moment(data2.dob).format("YYYY-MM-DDThh:mm:ss"),
      email: data2.email,
      firstName: data2.firstName,
      gender: data2.gender,
      id: clientDetail.id,
      insuranceExpiryDate: data2.insuranceExpiryDate
        ? moment(data2.insuranceExpiryDate).format("YYYY-MM-DDThh:mm:ss")
        : null,
      insuranceIssueDate: data2.insuranceIssueDate
        ? moment(data2.insuranceIssueDate).format("YYYY-MM-DDThh:mm:ss")
        : null,
      insuranceProvider: data2.insuranceProvider,
      insuranceType: data2.insuranceType,
      intake: data2.intake,
      lastName: data2.lastName,
      membershipExpiry: data2.membershipExpiry
        ? moment(data2.membershipExpiry).format("YYYY-MM-DDThh:mm:ss")
        : null,
      membershipNumber: Number(data2.membershipNumber),
      middleName: data2.middleName,
      nationality: country1,
      passport: country1Passport,
      passportExpiryDate: data2.passportExpiryDate
        ? moment(data2.passportExpiryDate).format("YYYY-MM-DDThh:mm:ss")
        : null,
      passportIssueDate: data2.passportIssueDate
        ? moment(data2.passportIssueDate).format("YYYY-MM-DDThh:mm:ss")
        : null,
      passportNumber: data2.passportNumber,
      phone: Number(data2.phone),
      photo: "string",
      pincode: data2.pincode,
      policyNumber: data2.policyNumber,
      secondaryEmail: data2.secondaryEmail,
      state: region,
      status: 1,
      street: data2.street,
      username: data2.email,
      usi: data2.usi,
      visaExpiry: data2.visaExpiry
        ? moment(data2.visaExpiry).format("YYYY-MM-DDThh:mm:ss")
        : null,
      visaIssueDate: data2.visaIssueDate
        ? moment(data2.visaIssueDate).format("YYYY-MM-DDThh:mm:ss")
        : null,
      visaType: data2.visaType,
    };
    let data = {
      address: data2.address,
      alias: data2.alias,
      phone: Number(data2.phone),
      ban: 0,
      city: data2.city,
      clientDetail: clientdetail,
      contactPreference: "phone",
      country: country1,
      email: data2.email,
      firstName: data2.firstName,
      id: stateCust.id,
      insuranceExpiryDate: data2.insuranceExpiryDate
        ? moment(data2.insuranceExpiryDate).format("YYYY-MM-DDThh:mm:ss")
        : null,
      insuranceIssueDate: data2.insuranceIssueDate
        ? moment(data2.insuranceIssueDate).format("YYYY-MM-DDThh:mm:ss")
        : null,
      insuranceProvider: data2.insuranceProvider,
      insuranceType: data2.insuranceType,
      lastName: data2.lastName,
      middleName: data2.middleName,
      pincode: data2.pincode,
      policyNumber: data2.policyNumber,
      roleId: 8,
      secondaryEmail: data2.secondaryEmail,
      state: region,
      status: 1,
      username: data2.email,
      updatedOn: datenow,
      visaExpiry: data2.visaExpiry
        ? moment(data2.visaExpiry).format("YYYY-MM-DDThh:mm:ss")
        : null,
      visaIssueDate: data2.visaIssueDate
        ? moment(data2.visaIssueDate).format("YYYY-MM-DDThh:mm:ss")
        : null,
      visaType: data2.visaType,
    };
    axios
      .post(`${base_url.api5}/updateClientByUsername`, data, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          enquiryLeadType: "HotLead",
        },
      })
      .then((res) => {
        toast.success("Profile Updated Successfully");
        getClientDetails();
        setbackdropOpen(false);
      })
      .catch((err) => {
        setbackdropOpen(false);
        toast.error("Something went wrong, Please try again!!");
      });
  };

  const inputEventAgent = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAgentData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const editAgent = (data2) => {
    if (!country1) {
      setcountryrequired(true);
      setstaterequired(false);
      return;
    } else if (!region) {
      setcountryrequired(false);
      setstaterequired(true);
      return;
    }
    setcountryrequired(false);
    setstaterequired(false);
    setbackdropOpen(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    agentData.updatedOn = datenow;
    agentData.country = country1;
    agentData.currency = currency1;
    agentData.state = region;

    delete agentData["agentApplicationForm"];
    delete agentData["transactionalAgreement"];
    let formData = new FormData();
    let fileToUpload = data2.transactionalArrangement;
    if (fileToUpload.length) {
      for (let i = 0; i < fileToUpload.length; i++) {
        formData.append(
          "agentTransactionalArrangement",
          fileToUpload[i],
          fileToUpload[i].name
        );
      }
    }

    //console.log(agentData);
    axios
      .put(`${base_url.api5}/updateAgentByUsername`, agentData, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
        },
      })
      .then(
        (response) => {
          //console.log(response);
          toast.success("Success");
          if (fileToUpload.length) {
            axios
              .post(
                `${base_url.api5}/uploadAgentTransactionalAgreements`,
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${clientdetails?.token}`,
                    agentId: response.data.data.agentId,
                  },
                }
              )
              .then((res) => {
                setbackdropOpen(false);
                toast.success("Sucess");
              })
              .catch((err) => {
                toast.error("Error uploading Transactional Arrangement");
                setbackdropOpen(false);
                //console.log(err);
              });
          }
          if (!fileToUpload.length) {
            setbackdropOpen(false);
          }
        },
        (error) => {
          setbackdropOpen(false);
        }
      );
  };

  return (
    <div class="accountbody">
      <h2 className="accountbody-title">Edit Profile</h2>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {Number(loggedInUser) === 0 ? (
        <form
          onSubmit={handleSubmit(editprofile)}
          class="accountenquiry account-profilebox"
        >
          <div className="account-profilebox-subtitle">
            <h4>Personal Details</h4>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>
                First Name<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("firstName", { required: true })}
                  className="form-control"
                  placeholder="First Name"
                  onChange={inputEvent}
                />
                <i className="fa fa-user-o designi" />
              </div>
              {errors.firstName && (
                <p className="errormessage mb-0">First Name is required</p>
              )}
            </div>
            <div className="col-md-4">
              <label>Middle Name</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("middleName")}
                  className="form-control"
                  placeholder="Middle Name"
                  onChange={inputEvent}
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                Last Name<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("lastName", { required: true })}
                  className="form-control"
                  placeholder="Last Name"
                  onChange={inputEvent}
                />
                <i className="fa fa-user-o designi" />
              </div>
              {errors.lastName && (
                <p className="errormessage mb-0">Last Name is required</p>
              )}
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>
                Date of Birth<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  max="2010-12-31"
                  {...register("dob", { required: true })}
                  onChange={inputEvent}
                  className="form-control"
                />
                <i className="fa fa-calendar designi" />
              </div>
              {errors.dob && (
                <p className="errormessage mb-0">DOB is required</p>
              )}
            </div>
            <div className="col-md-4">
              <label>Alias</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("alias")}
                  className="form-control"
                  placeholder="Alias"
                  onChange={inputEvent}
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>
                Gender<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <select
                  className="form-control selecttag2"
                  {...register("gender", { required: true })}
                  onChange={inputEvent}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                <i className="fa fa-user-o designi" />
              </div>
              {errors.gender && (
                <p className="errormessage mb-0">Gender is required</p>
              )}
            </div>
          </div>
          <div className="account-profilebox-subtitle">
            <h4>Contact Details</h4>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>Email</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("email")}
                  className="readonlyformcontrol"
                  readOnly
                />
                <i className="fa fa-envelope-o designi" />
              </div>
              {errors.email && (
                <p className="errormessage mb-0">Email is required</p>
              )}
            </div>
            <div className="col-md-4">
              <label>Secondary Email</label>
              <div style={{ position: "relative" }}>
                <input
                  type="email"
                  {...register("secondaryEmail")}
                  onChange={inputEvent}
                  className="form-control"
                  placeholder="secondary e-mail address"
                />
                <i className="fa fa-envelope-o designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Phone Number</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("phone")}
                  className="form-control"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Phone Number"
                  onChange={inputEvent}
                />
                <i className="fa fa-volume-control-phone designi" />
              </div>
            </div>
          </div>
          <div className="account-profilebox-subtitle">
            <h4>Address</h4>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>
                Street<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("street", { required: true })}
                  className="form-control"
                  placeholder="Street"
                  onChange={inputEvent}
                />
                <i className="fa fa-globe designi" />
              </div>
              {errors.street && (
                <p className="errormessage mb-0">Street is required</p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                Country<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <CountryDropdown
                  className="form-control selecttag2"
                  value={country1}
                  onChange={(val) => selectCountry(val)}
                />
                <i className="fa fa-globe designi" />
              </div>
              {countryrequired && (
                <p className="errormessage mb-0">Country is required</p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                State<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                {country1 === "Nepal" ? (
                  <select
                    className="form-control selecttag2"
                    onChange={(e) => selectRegion(e.target.value)}
                  >
                    <option value="">Select Province</option>
                    <option value="Province No. 1">Province No. 1</option>
                    <option value="Madhesh Province">Madhesh Province</option>
                    <option value="Bagmati Province">Bagmati Province</option>
                    <option value="Gandaki Province">Gandaki Province</option>
                    <option value="Lumbini Province">Lumbini Province</option>
                    <option value="Karnali Province">Karnali Province</option>
                    <option value="Sudurpashchim Province">
                      Sudurpashchim Province
                    </option>
                  </select>
                ) : (
                  <RegionDropdown
                    className="form-control selecttag2"
                    defaultOptionLabel="Select State"
                    country={country1}
                    value={region}
                    onChange={(val) => selectRegion(val)}
                  />
                )}
                <i className="fa fa-globe designi" />
              </div>
              {staterequired && (
                <p className="errormessage mb-0">State is required</p>
              )}
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>
                City<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("city", { required: true })}
                  className="form-control"
                  placeholder="City"
                  onChange={inputEvent}
                />
                <i className="fa fa-globe designi" />
              </div>
              {errors.city && (
                <p className="errormessage mb-0">City is required</p>
              )}
            </div>
            <div className="col-md-4">
              <label>
                PostCode<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("pincode", { required: true })}
                  className="form-control"
                  placeholder="Post Code"
                  onChange={inputEvent}
                />
                <i className="fa fa-street-view designi" />
              </div>
              {errors.pincode && (
                <p className="errormessage mb-0">Post Code is required</p>
              )}
            </div>
          </div>
          <div className="account-profilebox-subtitle">
            <h4>Passport/Visa Information</h4>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>Passport Country</label>
              <div style={{ position: "relative" }}>
                <CountryDropdown
                  className="form-control selecttag2"
                  value={country1Passport}
                  onChange={(val) => selectCountryPassport(val)}
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Passport Number</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("passportNumber")}
                  className="form-control"
                  placeholder="Passport Number"
                  onChange={inputEvent}
                />
                <i className="fa fa-volume-control-phone designi" />
              </div>
            </div>

            <div className="col-md-4">
              <label>Passport Issue Date</label>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  {...register("passportIssueDate")}
                  max={new Date()}
                  className="form-control"
                  onChange={inputEvent}
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>Passport Expiry Date</label>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  {...register("passportExpiryDate")}
                  className="form-control"
                  onChange={inputEvent}
                  min={clientDetail?.passportIssueDate}
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Visa Type</label>
              <div style={{ position: "relative" }}>
                <select
                  className="form-control selecttag2"
                  {...register("visaType")}
                  onChange={inputEvent}
                >
                  <option value="">Select Visa Type</option>
                  {allVisa?.map((cuntitem, index) => (
                    <option key={index} value={cuntitem.name} id={cuntitem.id}>
                      {cuntitem.name}
                    </option>
                  ))}
                </select>
                <i className="fa fa-share-square designi" />
              </div>
            </div>

            <div className="col-md-4">
              <label>Visa Issue Date</label>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  {...register("visaIssueDate")}
                  max={new Date()}
                  className="form-control"
                  onChange={inputEvent}
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>Visa Expiry</label>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  {...register("visaExpiry")}
                  className="form-control"
                  onChange={inputEvent}
                  min={clientDetail?.visaIssueDate}
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
          </div>
          <div class="d-none">
            <div className="account-profilebox-subtitle">
              <h4>Health Insurance</h4>
            </div>
            <div className="my-4 row">
              <div className="col-md-4">
                <label>Insurance Provider</label>
                <div style={{ position: "relative" }}>
                  <input
                    {...register("insuranceProvider")}
                    className="form-control"
                    placeholder="Insurance Provider"
                    onChange={inputEvent}
                  />
                  <i className="fa fa-calendar designi" />
                </div>
              </div>
              <div className="col-md-4">
                <label>Policy Number</label>
                <div style={{ position: "relative" }}>
                  <input
                    {...register("policyNumber")}
                    className="form-control"
                    placeholder="Policy Number"
                    onChange={inputEvent}
                  />
                  <i className="fa fa-sort-numeric-desc designi" />
                </div>
              </div>

              <div className="col-md-4">
                <label>Insurance Type</label>
                <div style={{ position: "relative" }}>
                  <select
                    className="form-control selecttag2"
                    {...register("insuranceType")}
                    onChange={inputEvent}
                  >
                    <option value="">Select Insurance Type</option>
                    {allHealth?.map((cuntitem, index) => (
                      <option
                        key={index}
                        value={cuntitem.name}
                        id={cuntitem.id}
                      >
                        {cuntitem.name}
                      </option>
                    ))}
                  </select>
                  <i className="fa fa-share-square designi" />
                </div>
              </div>
            </div>
            <div className="my-4 row">
              <div className="col-md-4">
                <label>Insurance Issue Date</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="date"
                    {...register("insuranceIssueDate")}
                    className="form-control"
                    onChange={inputEvent}
                  />
                  <i className="fa fa-calendar designi" />
                </div>
              </div>
              <div className="col-md-4">
                <label>Insurance Expiry Date</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="date"
                    {...register("insuranceExpiryDate")}
                    className="form-control"
                    max={new Date("12-31-2099")}
                    onChange={inputEvent}
                    min={clientDetail?.insuranceIssueDate}
                  />
                  <i className="fa fa-calendar designi" />
                </div>
              </div>
            </div>
          </div>

          <div className="account-profilebox-subtitle">
            <h4>Others</h4>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>USI</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("usi")}
                  className="form-control"
                  placeholder="USI"
                  onChange={inputEvent}
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Membership Number</label>
              <div style={{ position: "relative" }}>
                <input
                  type="number"
                  {...register("membershipNumber")}
                  onWheel={(event) => event.currentTarget.blur()}
                  className="form-control"
                  placeholder="MemberShip Number"
                  onChange={inputEvent}
                />
                <i className="fa fa-sort-numeric-desc designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Membership Expiry Date</label>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  {...register("membershipExpiry")}
                  min={new Date()}
                  className="form-control"
                  onChange={inputEvent}
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-4">
              <label>Institution Name</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("currentlyStudyingInstitutionName")}
                  className="form-control"
                  placeholder="Institution Name"
                  onChange={inputEvent}
                />
                <i className="fa fa-university designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Intake</label>
              <div style={{ position: "relative" }}>
                <select
                  className="form-control selecttag2"
                  {...register("intake")}
                  onChange={inputEvent}
                >
                  <option value="">Select Intake</option>
                  {allIntake?.map((cuntitem, index) => (
                    <option key={index} value={cuntitem.name} id={cuntitem.id}>
                      {cuntitem.name}
                    </option>
                  ))}
                </select>
                <i className="fa fa-share-square designi" />
              </div>
            </div>
            <div className="col-md-4">
              <label>Comment</label>
              <div style={{ position: "relative" }}>
                <textarea
                  {...register("comment")}
                  className="form-control"
                  placeholder="Comment"
                  onChange={inputEvent}
                />
                <i className="fa fa-commenting designi" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button type="submit" className="btn">
                Update
              </button>
            </div>
          </div>
        </form>
      ) : (
        <form
          onSubmit={handleSubmit(editAgent)}
          class="accountenquiry account-profilebox"
        >
          {/* <h2 className="accountheading">Edit Profile</h2> */}
          <h4>Personal Details</h4>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("name", { required: true })}
                  className="form-control"
                  placeholder="Name"
                  onChange={inputEventAgent}
                  value={agentData?.name}
                  name="name"
                />
                <i className="fa fa-user-o designi" />
              </div>
              {errors.name && (
                <p className="errormessage mb-0">Name is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>ABN</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("abn")}
                  className="form-control"
                  placeholder="ABN"
                  onChange={inputEventAgent}
                  value={agentData?.abn}
                  name="abn"
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>ACN</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("acn")}
                  className="form-control"
                  placeholder="ACN"
                  onChange={inputEventAgent}
                  value={agentData?.acn}
                  name="acn"
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>CAN</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("can")}
                  className="form-control"
                  placeholder="CAN"
                  onChange={inputEventAgent}
                  value={agentData?.can}
                  name="can"
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>PAN</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("pan")}
                  className="form-control"
                  placeholder="PAN"
                  onChange={inputEventAgent}
                  value={agentData?.pan}
                  name="pan"
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>VAT</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("vatNumber")}
                  className="form-control"
                  placeholder="VAT"
                  onChange={inputEventAgent}
                  value={agentData?.vatNumber}
                  name="vatNumber"
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Currency</label>
              <div style={{ position: "relative" }}>
                {!currency1 ? (
                  <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  <SelectCurrency
                    value={currency1}
                    onChange={selectcurrency}
                    style={{ width: "100%" }}
                  />
                )}
                {/* <i className="fa fa-user-o designi" /> */}
              </div>
            </div>
            <div className="col-md-3">
              <label>
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("email", { required: true })}
                  className="form-control"
                  placeholder="email"
                  onChange={inputEventAgent}
                  value={agentData?.email}
                  name="email"
                  readOnly
                />
                <i className="fa fa-user-o designi" />
              </div>
              {errors.email && (
                <p className="errormessage mb-0">Email is required</p>
              )}
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>Commission Percentage</label>
              <div style={{ position: "relative" }}>
                <input
                  type="number"
                  {...register("commissionPercentage")}
                  onChange={inputEventAgent}
                  className="form-control"
                  value={agentData?.commissionPercentage}
                  name="commissionPercentage"
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Referal Percentage</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("referalPercentage")}
                  className="form-control"
                  placeholder="Referal Percentage"
                  onChange={inputEventAgent}
                  value={agentData?.referalPercentage}
                  name="referalPercentage"
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>
                Remarks<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <textarea
                  {...register("remark")}
                  className="form-control"
                  placeholder="Remark"
                  onChange={inputEventAgent}
                  value={agentData?.remark}
                  name="remark"
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Agent Type</label>
              <div style={{ position: "relative" }}>
                <input
                  className="form-control"
                  placeholder="Agent Type"
                  value={agentType}
                  readOnly
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
          </div>
          <h4>Address</h4>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>
                Address<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("address", { required: true })}
                  className="form-control"
                  placeholder="Address"
                  onChange={inputEventAgent}
                  value={agentData?.address}
                  name="address"
                />
                <i className="fa fa-globe designi" />
              </div>
              {errors.address && (
                <p className="errormessage mb-0">Address is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>
                Country<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <CountryDropdown
                  className="form-control selecttag2"
                  value={country1}
                  onChange={(val) => selectCountry(val)}
                />
                <i className="fa fa-globe designi" />
              </div>
              {countryrequired && (
                <p className="errormessage mb-0">Country is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>
                State<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                {country1 === "Nepal" ? (
                  <select
                    className="form-control selecttag2"
                    onChange={(e) => selectRegion(e.target.value)}
                  >
                    <option value="">Select Province</option>
                    <option value="Province No. 1">Province No. 1</option>
                    <option value="Madhesh Province">Madhesh Province</option>
                    <option value="Bagmati Province">Bagmati Province</option>
                    <option value="Gandaki Province">Gandaki Province</option>
                    <option value="Lumbini Province">Lumbini Province</option>
                    <option value="Karnali Province">Karnali Province</option>
                    <option value="Sudurpashchim Province">
                      Sudurpashchim Province
                    </option>
                  </select>
                ) : (
                  <RegionDropdown
                    className="form-control selecttag2"
                    defaultOptionLabel="Select State"
                    country={country1}
                    value={region}
                    onChange={(val) => selectRegion(val)}
                  />
                )}
                <i className="fa fa-globe designi" />
              </div>
              {staterequired && (
                <p className="errormessage mb-0">State is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>
                City<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("city", { required: true })}
                  className="form-control"
                  placeholder="City"
                  onChange={inputEventAgent}
                  value={agentData?.city}
                  name="city"
                />
                <i className="fa fa-globe designi" />
              </div>
              {errors.city && (
                <p className="errormessage mb-0">City is required</p>
              )}
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>
                PostCode<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("pincode", { required: true })}
                  className="form-control"
                  placeholder="Post Code"
                  onChange={inputEventAgent}
                  value={agentData?.pincode}
                  name="pincode"
                />
                <i className="fa fa-street-view designi" />
              </div>
              {errors.pincode && (
                <p className="errormessage mb-0">Post Code is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>
                Website<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("website", { required: true })}
                  className="form-control"
                  placeholder="Website"
                  onChange={inputEventAgent}
                  value={agentData?.website}
                  name="website"
                />
                <i className="fa fa-street-view designi" />
              </div>
              {errors.website && (
                <p className="errormessage mb-0">Website is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>Latitude</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("lattitude")}
                  type="number"
                  step="any"
                  className="form-control"
                  placeholder="lattitude"
                  onChange={inputEventAgent}
                  value={agentData?.lattitude}
                  name="lattitude"
                />
                <i className="fa fa-street-view designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Longitude</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("longitude")}
                  type="number"
                  step="any"
                  className="form-control"
                  placeholder="longitude"
                  onChange={inputEventAgent}
                  value={agentData?.longitude}
                  name="longitude"
                />
                <i className="fa fa-street-view designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>Logo</label>
              <div style={{ position: "relative" }}>
                {/* <img src={agentData?.photo} alt="" height="50px" /> */}
                <input
                  type="file"
                  className="form-control underline-input"
                  onChange={(data) => {
                    uploadLogo(data);
                  }}
                  accept=".jpg, .jpeg, .bmp, .gif, .png"
                />
                <i className="fa fa-street-view designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Agreements</label>
              <div style={{ position: "relative" }}>
                <Modal
                  isOpen={openTransactionalForm}
                  onRequestClose={() => setOpenTransactionalForm(false)}
                  // style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div>
                    <h5>Transactional Arrangement</h5>
                    <hr />
                    <TableContainer
                      style={{ marginTop: "10px" }}
                      component={Paper}
                    >
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead
                          style={{ backgroundColor: "cornflowerblue" }}
                        >
                          <TableRow>
                            <TableCell>SN</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">View</TableCell>
                          </TableRow>
                        </TableHead>
                        {transactionalArrangementList?.length != null ? (
                          <TableBody>
                            {transactionalArrangementList?.map((p, index) => (
                              <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                  {index + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {p.agentAgreementname}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="right"
                                >
                                  {/* eslint-disable-next-line */}
                                  <a
                                    onClick={() => {
                                      downloadAll(
                                        stateCust?.agentId,
                                        p.agentAgreementPath
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    View
                                  </a>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          "No Files Available"
                        )}
                      </Table>
                    </TableContainer>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        marginRight: "20px",
                        marginTop: "20px",
                        float: "right",
                      }}
                      onClick={() => setOpenTransactionalForm(false)}
                    >
                      Close
                    </button>
                  </div>
                </Modal>
                <input
                  type="file"
                  multiple
                  className="form-control underline-input"
                  {...register("transactionalArrangement")}
                  name="transactionalArrangement"
                />
                <i className="fa fa-street-view designi" />
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => setOpenTransactionalForm(true)}
                >
                  View Files
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <label>University</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("agentUniversityName")}
                  className="form-control"
                  placeholder="University Name"
                  onChange={inputEventAgent}
                  value={agentData?.agentUniversityName}
                  name="agentUniversityName"
                />
                <i className="fa fa-street-view designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Overview</label>
              <div style={{ position: "relative" }}>
                <textarea
                  {...register("overview")}
                  className="form-control"
                  placeholder="Overview"
                  onChange={inputEventAgent}
                  value={agentData?.overview}
                  name="overview"
                />
                <i className="fa fa-street-view designi" />
              </div>
            </div>
          </div>
          <h4>Contact Details</h4>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>Contact Person</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("contactPerson")}
                  className="form-control"
                  placeholder="Contact Person"
                  onChange={inputEventAgent}
                  value={agentData?.contactPerson}
                  name="contactPerson"
                />
                <i className="fa fa-volume-control-phone designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Designation</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("designation")}
                  className="form-control"
                  placeholder="Designation"
                  onChange={inputEventAgent}
                  value={agentData?.designation}
                  name="designation"
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Phone</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("phone")}
                  className="form-control"
                  placeholder="Phone"
                  onChange={inputEventAgent}
                  value={agentData?.phone}
                  name="phone"
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Mobile</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("mobile")}
                  className="form-control"
                  placeholder="mobile"
                  onChange={inputEventAgent}
                  value={agentData?.mobile}
                  name="mobile"
                />
                <i className="fa fa-calendar designi" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <button type="submit" className="btn">
                Update
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditAccount;
