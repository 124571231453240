import React, { useEffect, useState } from "react";
import CourseSearchBodyLeft from "./CourseSearchBodyLeft";
import CourseSearchBodyRight from "./CourseSearchBodyRight";
import { CountryDropdown } from "react-country-region-selector";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import base_url from "../api/bootapi";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import attachmenturl from "../api/attachmenturl";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // marginTop: "24px",
    // boxShadow: "0 0 40px #ccc",
    // borderRadius: "10px",
  },
  heading: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#224289",
  },
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const CourseSearchBody = (props) => {
  const location = useLocation();

  const previousPage = location || "Unknown";
  //console.log(previousPage);

  const classes = useStyles();
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  const [backdropopen, setbackdropOpen] = useState(false);
  const [courseDetail, setcourseDetail] = useState(null);
  const [areacheck, setareacheck] = useState(true);
  const navigate = useNavigate();
  const [checklevel, setlevel] = useState([]);
  const [checkarea, setarea] = useState([]);
  const [comparecheck, setcomparecheck] = useState();
  // const [bookmarkcheck, setbookmarkcheck] = useState();
  const [comparedetailmodal, setcomparedetailmodal] = useState([]);
  const [levelcheckbox, setlevelcheckbox] = useState({});
  const [areacheckbox, setareacheckbox] = useState({});
  const [levelname, setlevelname] = useState(
    localStorage.getItem("levelname")
      ? JSON.parse(localStorage.getItem("levelname"))
      : []
  );
  const [countryvalue, setcountryvalue] = useState(
    localStorage.getItem("countryvalue")
  );
  const [statevalue, setstatevalue] = useState(
    localStorage.getItem("statevalue")
  );
  const [cityvalue, setcityvalue] = useState(localStorage.getItem("cityvalue"));
  const [areaname, setareaname] = useState(() => {
    if (localStorage.getItem("areaname")) {
      return JSON.parse(localStorage.getItem("areaname"));
    } else {
      return [];
    }
  });
  const [coursevalue, setcoursevalue] = useState(
    localStorage.getItem("frontendcoursename")
  );
  const [coursevalueid, setcoursevalueid] = useState(
    localStorage.getItem("frontendcourseid")
  );
  const courseName = areaname[0];
  const clientLoginCheck = JSON.parse(localStorage.getItem("clientLogin"));

  const [levelvalue, setlevelvalue] = useState(localStorage.getItem("level"));
  const [areavalue, setareavalue] = useState(localStorage.getItem("area"));
  const [levelid, setlevelid] = useState([localStorage.getItem("level")]);
  const [areaid, setareaid] = useState([localStorage.getItem("area")]);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [length, setlength] = useState();
  const [totallength, settotallength] = useState();
  const [countnumber, setcountnumber] = useState();
  const [configAdmin, setConfigAdmin] = useState(false);
  const [city, setcity] = useState([]);
  const [columnName, setcolumnName] = useState("courseId-DESC");
  const [selectedOption, setSelectedOption] = useState(courseName);
  const [country1, setcountry] = useState("Australia");
  const [selectedCountry, setSelectedCountry] = useState(countryvalue);
  const allowedCountries = [
    "Australia",
    "United States",
    "United Kingdom",
    "Canada",
  ];
  const [countrycheck, setcountrycheck] = useState(true);
  const [locationcheck, setlocationcheck] = useState(true);
  const [levelcheck, setlevelcheck] = useState(true);

  const selectCountry = (val) => {
    localStorage.setItem("countryvalue", `${val}`);
    getcity(val);
    setcountry(val);
    localStorage.removeItem("cityvalue");
  };

  const handleCountryChange = (e) => {
    let val = e.target.value;
    localStorage.setItem("countryvalue", `${val}`);
    getcity(val);
    setcountry(val);
    setSelectedCountry(val);
    localStorage.removeItem("cityvalue");
  };

  const getcity = (val) => {
    axios
      .get(`${base_url.api5}/findCityByCountry`, {
        headers: { country: val },
      })
      .then((res) => setcity(res.data.data))
      .catch((err) => setcity([]));
  };

  const [mycityname, setmycityname] = useState(cityvalue);

  const handlechangecity = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementValue = optionElement.getAttribute("value");
    handlecitychange(optionElementValue.trim());
    setmycityname(event.target.value);
  };

  // Handle changes to the select input
  const handleSelectChange = (event) => {
    handlecitychange(event.target.value);
    setmycityname(event.target.value);
  };

  const handlecitychange = (val) => localStorage.setItem("cityvalue", `${val}`);

  const [mylevelname, setmylevelname] = useState(levelname);

  const handlechangelevel = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementValue = optionElement.getAttribute("value");
    let arr = [optionElementValue];
    localStorage.setItem("levelname", JSON.stringify(arr));
    localStorage.setItem("level", optionElementId);
    setmylevelname(event.target.value);
    //console.log("Level changed:", optionElementId);
  };

  const [myareaname, setmyareaname] = useState(areaname);

  const fieldOptions = checkarea.map((item) => ({
    value: item.area_name,
    label: item.area_name,
    id: item.area_id,
  }));

  const handlechangearea = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      setSelectedOption(selectedOption);

      localStorage.setItem("areaname", JSON.stringify([selectedOption.value]));
      localStorage.setItem("area", selectedOption.id);
    } else {
      localStorage.removeItem("areaname");
      localStorage.removeItem("area");
    }
  };

  // const handlechangearea = (event) => {
  //   const index = event.target.selectedIndex;
  //   const optionElement = event.target.childNodes[index];
  //   const optionElementId = optionElement.getAttribute("id");
  //   const optionElementValue = optionElement.getAttribute("value");
  //   let arr = [optionElementValue];
  //   localStorage.setItem("areaname", JSON.stringify(arr));
  //   localStorage.setItem("area", optionElementId);
  //   setmyareaname(event.target.value);
  // };

  const submitcourse = (e) => {
    // localStorage.setItem("PopularState", 0);
    //console.log('entering');

    let countryvalue = e.target[0].value;
    // let cityvalue = e.target[1].value;
    let levelvalue = e.target[2].value;
    let coursevalue = selectedOption;

    if (!countryvalue || !levelvalue || !coursevalue) {
      if (!countryvalue) {
        setcountrycheck(false);
        setlocationcheck(true);
        setlevelcheck(true);
        setareacheck(true);
      } else if (!levelvalue) {
        setcountrycheck(true);
        setlevelcheck(false);
        setlocationcheck(true);
        setareacheck(true);
      } else if (!coursevalue) {
        setcountrycheck(true);
        setareacheck(false);
        setlocationcheck(true);
        setlevelcheck(true);
      }
    } else {
      /* eslint-disable react-hooks/exhaustive-deps */
      window.open(`${attachmenturl}/#/course-searchresult`, "_self");
      window.location.reload();
    }
  };

  const agentLoginCheck = JSON.parse(localStorage.getItem("agentLogin"));

  const [classname, setclassname] = useState();
  const [comparedata, setcomparedata] = useState([]);

  // const Sangamgetcomparelist = () => {
  //   setbackdropOpen(true);
  //   if (agentLoginCheck === 0) {
  //     axios
  //       .get(`${base_url.api5}/getClientCompareListByClientId`, {
  //         headers: {
  //           clientId: clientdetails?.clientId,
  //           columnName: "id",
  //           direction: "DESC",
  //           page: 0,
  //           size: 20,
  //         },
  //       })
  //       .then((res) => {
  //         let data = res.data.data;
  //         // setcomparedatalength(data.length);
  //         // if (res.data.data.length === 1) data.push({});
  //         for (let i = res.data.data.length; i < 4; i++) {
  //           data.push({});
  //         }
  //         let classnumber = 12 / Number(res.data.data.length);
  //         setclassname(`col-md-${classnumber}`);
  //         setcomparedata(data);
  //         setbackdropOpen(false);
  //       })
  //       .catch((err) => {
  //         setbackdropOpen(false);
  //         setcomparedata([]);
  //       });
  //   } else {
  //     axios
  //       .get(`${base_url.api5}/getCompareListByAgentId`, {
  //         headers: {
  //           agentId: clientdetails?.agentId,
  //           columnName: "id",
  //           direction: "DESC",
  //           page: 0,
  //           size: 20,
  //         },
  //       })
  //       .then((res) => {
  //         let data = res.data.data;
  //         // setcomparedatalength(data.length);
  //         // if (res.data.data.length === 1) data.push({});
  //         for (let i = res.data.data.length; i < 4; i++) {
  //           data.push({});
  //         }
  //         let classnumber = 12 / Number(res.data.data.length);
  //         setclassname(`col-md-${classnumber}`);
  //         setcomparedata(data);
  //         setbackdropOpen(false);
  //       })
  //       .catch((err) => {
  //         setbackdropOpen(false);
  //         setcomparedata([]);
  //       });
  //   }
  // };

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    setbackdropOpen(true);
    setcityvalue(localStorage.getItem("cityvalue"));
    getLevel();
    getArea();
    getcity(countryvalue);
    const fromState = localStorage.getItem("fromState");
    var searchValue = localStorage.getItem("searchValue");
    //console.log('287---->', fromState, searchValue);
    if (fromState == 0) {
      submitfreeSearchResult(0);
    } else {
      getheader(0, columnName);
    }
    localStorage.setItem("countryvalue", countryvalue);
    // Sangamgetcomparelist();
    axios
      .get(`${base_url.api5}/findCityByCountry`, {
        headers: { country: countryvalue },
      })
      .then((res) => setcity(res.data.data))
      .catch((err) => setcity([]));
  }, [statevalue, countryvalue, clientdetails?.clientId]);

  // useEffect(() => {
  // 	/* eslint-disable react-hooks/exhaustive-deps */
  // 	axios
  // 		.get(`${base_url.api5}/getByConfigKey`, {
  // 			headers: {
  // 				configKey: 'iswonline_consultant',
  // 			},
  // 		})
  // 		.then((res) => setConfigAdmin(res.data.data.configValue));
  // }, []);

  function calculatePathName(cricosId) {
    // Extract the numeric part of the alphanumeric ID, assuming it's at the start
    const numericPart = cricosId.match(/^\d+/);
    const numericId = numericPart ? parseInt(numericPart[0]) : 0;

    // Calculate the lower and upper bounds for each range
    const lowerBound = Math.floor((numericId - 1) / 500) * 500 + 1;
    const upperBound = Math.min(lowerBound + 499, 10000); // Adjust the upper limit as needed

    // Format the lower and upper bounds as strings with leading zeros
    const lowerBoundStr = String(lowerBound).padStart(5, "0");
    const upperBoundStr = String(upperBound).padStart(5, "0");

    // Construct the path name
    const pathName = `${lowerBoundStr}-${upperBoundStr}`;

    return pathName;
  }

  const [availablecourseData, setAvailablecourseData] = useState([]);

  const getavailablecourses = (headers) => {
    axios
      .get(`${base_url.api5}/searchAvailableInstitutionCourses`, {
        headers: headers,
      })
      .then((res) => {
        //console.log(res);
        setAvailablecourseData(res.data.data.content);
        //console.log('availablecourseData----->', availablecourseData);

        // Create a new array of objects for the 'logo' field with dynamically calculated path names
        const logoArray = res.data.data.content.map((item, index) => ({
          id: index + 1,
          appFees: item.appFees,
          areaId: item.areaId,
          areaName: item.areaName,
          atar: item.atar,
          banner: item.banner,
          branchInstitutionId: item.branchInstitutionId,
          categoryName: item.categoryName,
          country: item.country,
          courseCode: item.courseCode,
          courseId: item.courseId,
          courseName: item.courseName,
          courseObjective: item.courseObjective,
          courseOverview: item.courseOverview,
          courseRating: item.courseRating,
          courseWhatWillYouStudy: item.courseWhatWillYouStudy,
          cricos: item.cricos,
          cricosProviderCode: item.cricosProviderCode,
          duration: item.duration,
          fees: item.fees,
          institutionName: item.institutionName,
          intake: item.intake,
          levelId: item.levelId,
          levelName: item.levelName,
          materialFees: item.materialFees,
          offShoreFees: item.offShoreFees,
          otherFees: item.otherFees,
          provider_name: item.provider_name,
          requirement: item.requirement,
          state: item.state,
          type: item.type,
          uac: item.uac,
          logo: `${attachmenturl}/attachments/providers/australia/${calculatePathName(
            item.cricos
          )}/${item.cricos}/${item.logo}`,
        }));

        //console.log('logoArray', logoArray);

        setcourseDetail(logoArray);
        // setcourseDetail(res.data.data.content);
        let obj = {};
        let availablecourses = res.data.data.content;
        for (let i = 0; i < availablecourses.length; i++) {
          let key = `${availablecourses[i].courseId}${availablecourses[i].branchInstitutionId}`;
          obj[key] = false;
        }
        // let copiedobj = JSON.parse(JSON.stringify(obj));
        if (clientdetails?.token) {
          if (clientdetails?.agentId) {
            getcomparelistbyagentid(obj);
          } else {
            //console.log('coming in else ----------->');
            getcomparelist(obj);
          }
          // getbookmarklist(copiedobj);
        } else if (clientdetails?.clientId) {
          //console.log('coming in else if ----------->');
          getcomparelist(obj);
        }
        setlength(res.data.data.numberOfElements);
        setgetpageno(res.data.data.totalPages);
        settotallength(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setbackdropOpen(false);
      })
      .catch((err) => handleerror());
  };

  const submitfreeSearchResult = (value) => {
    var searchValue = localStorage.getItem("searchValue");
    axios
      .get(`${base_url.api5}/freeSearchCourses`, {
        headers: {
          columnName: "courseId",
          direction: "DESC",
          page: Number(value),
          size: 12,
          searchQuery: searchValue || null,
        },
      })
      .then((res) => {
        //console.log('freeSearchResult----------->', res);
        //console.log(res);
        setAvailablecourseData(res.data.data.content);
        //console.log('availablecourseData----->', availablecourseData);

        // Create a new array of objects for the 'logo' field with dynamically calculated path names
        const logoArray = res.data.data.content.map((item, index) => ({
          id: index + 1,
          appFees: item.appFees,
          areaId: item.areaId,
          areaName: item.areaName,
          atar: item.atar,
          banner: item.banner,
          branchInstitutionId: item.branchInstitutionId,
          categoryName: item.categoryName,
          country: item.country,
          courseCode: item.courseCode,
          courseId: item.courseId,
          courseName: item.courseName,
          courseObjective: item.courseObjective,
          courseOverview: item.courseOverview,
          courseRating: item.courseRating,
          courseWhatWillYouStudy: item.courseWhatWillYouStudy,
          cricos: item.cricos,
          cricosProviderCode: item.cricosProviderCode,
          duration: item.duration,
          fees: item.fees,
          institutionName: item.institutionName,
          intake: item.intake,
          levelId: item.levelId,
          levelName: item.levelName,
          materialFees: item.materialFees,
          offShoreFees: item.offShoreFees,
          otherFees: item.otherFees,
          provider_name: item.provider_name,
          requirement: item.requirement,
          state: item.state,
          type: item.type,
          uac: item.uac,
          logo: `${attachmenturl}/attachments/providers/australia/${calculatePathName(
            item.cricos
          )}/${item.cricos}/${item.logo}`,
        }));

        //console.log('logoArray', logoArray);

        setcourseDetail(logoArray);
        // setcourseDetail(res.data.data.content);
        let obj = {};
        let availablecourses = res.data.data.content;
        for (let i = 0; i < availablecourses.length; i++) {
          let key = `${availablecourses[i].courseId}${availablecourses[i].branchInstitutionId}`;
          obj[key] = false;
        }
        // let copiedobj = JSON.parse(JSON.stringify(obj));
        if (clientdetails?.token) {
          if (clientdetails?.agentId) {
            getcomparelistbyagentid(obj);
          } else {
            //console.log('coming in else ----------->');
            getcomparelist(obj);
          }
          // getbookmarklist(copiedobj);
        } else if (clientdetails?.clientId) {
          //console.log('coming in else if ----------->');
          getcomparelist(obj);
        }
        setlength(res.data.data.numberOfElements);
        setgetpageno(res.data.data.totalPages);
        settotallength(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setbackdropOpen(false);
      });
    // .catch((err) => //console.log(err));
  };

  // const getbookmarklist = (obj2) => {
  //   axios
  //     .get(`${base_url.api5}/fetchClientBookmarkListByClientId`, {
  //       headers: {
  //         clientId: clientdetails?.clientId,
  //       },
  //     })
  //     .then((res) => {
  //       setbackdropOpen(false);
  //       for (let i = 0; i < res.data.data.length; i++) {
  //         let key = `${res.data.data[i].courseId}${res.data.data[i].branchId}`;
  //         if (obj2.hasOwnProperty(key)) obj2[key] = true;
  //       }
  //       setbookmarkcheck(obj2);
  //     })
  //     .catch((err) => setbackdropOpen(false));
  // };

  const getcomparelistbyagentid = (obj) => {
    axios
      .get(`${base_url.api5}/fetchCompareListByAgentId`, {
        headers: {
          agentId: clientdetails?.agentId,
        },
      })
      .then((res) => {
        let comparearr = [];
        for (let i = 0; i < res.data.data.length; i++) {
          let comparemodalobj = {
            courseId: res.data.data[i].courseId,
            branchId: res.data.data[i].branchId,
          };
          let key = `${res.data.data[i].courseId}${res.data.data[i].branchId}`;
          if (obj.hasOwnProperty(key)) obj[key] = true;
          comparearr.push(comparemodalobj);
        }
        setcomparedetailmodal(comparearr);
        setbackdropOpen(false);
        setcomparecheck(obj);
      })
      .catch((err) => {
        setcomparecheck();
        setcomparedetailmodal([]);
        setbackdropOpen(false);
      });
  };

  const getcomparelist = (obj) => {
    if (clientLoginCheck === 1) {
      axios
        .get(`${base_url.api5}/fetchClientCompareListByClientId`, {
          headers: {
            clientId: clientdetails?.clientId,
          },
        })
        .then((res) => {
          //console.log(res);
          let comparearr = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let comparemodalobj = {
              courseId: res.data.data[i].courseId,
              branchId: res.data.data[i].branchId,
            };
            let key = `${res.data.data[i].courseId}${res.data.data[i].branchId}`;
            if (obj.hasOwnProperty(key)) obj[key] = true;
            comparearr.push(comparemodalobj);
          }
          //console.log(comparearr);
          setcomparedetailmodal(comparearr);
          setbackdropOpen(false);
          //console.log(obj);
          setcomparecheck(obj);
        })
        .catch((err) => {
          setcomparecheck();
          setcomparedetailmodal([]);
          setbackdropOpen(false);
        });
    } else {
      axios
        .get(`${base_url.api5}/fetchClientCompareListByClientTempId`, {
          headers: {
            clientTempId: clientdetails?.clientId,
          },
        })
        .then((res) => {
          //console.log(res);
          let comparearr = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let comparemodalobj = {
              courseId: res.data.data[i].courseId,
              branchId: res.data.data[i].branchId,
            };
            let key = `${res.data.data[i].courseId}${res.data.data[i].branchId}`;
            if (obj.hasOwnProperty(key)) obj[key] = true;
            comparearr.push(comparemodalobj);
          }
          //console.log(comparearr);
          setcomparedetailmodal(comparearr);
          setbackdropOpen(false);
          //console.log(obj);
          setcomparecheck(obj);
        })
        .catch((err) => {
          setcomparecheck();
          setcomparedetailmodal([]);
          setbackdropOpen(false);
        });
    }
  };

  const searchcoursewithname = (val) => {
    setbackdropOpen(true);
    if (val === "remove") {
      // setcoursevalue();
      localStorage.removeItem("frontendcoursename");
      localStorage.setItem("frontendcourseid", [].join());
      window.location.reload();
      // setcoursevalueid();
      // getheader(pageno, [].join(), "course");
    } else {
      if (coursevalue) {
        axios
          .get(`${base_url.api5}/fetchAllCoursesByName`, {
            headers: {
              searchQuery: coursevalue,
            },
          })
          .then((res) => {
            const data = res.data.data;
            let courseid = [];
            for (let i = 0; i < data.length; i++) courseid.push(data[i].id);
            if (data.length === 0) courseid.push(0);
            localStorage.setItem("frontendcoursename", coursevalue);
            localStorage.setItem("frontendcourseid", courseid.join());
            setcoursevalueid(courseid.join());
            setpageno(0);
            getheader(0, columnName, courseid.join(), "course");
          })
          .catch((err) => handleerror());
      } else {
        localStorage.setItem("frontendcoursename", coursevalue);
        localStorage.setItem("frontendcourseid", [].join());
        setcoursevalueid();
        getheader(pageno, columnName, [].join(), "course");
      }
    }
  };

  const handleerror = () => {
    setbackdropOpen(false);
    setlength(0);
    setgetpageno(0);
    settotallength(0);
    setcourseDetail([]);
  };

  const handlepage = (event, value) => {
    setbackdropOpen(true);
    setpageno(value - 1);
    getheader(value - 1, columnName);
    props.pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handlelevelChange = (event) => {
    setbackdropOpen(true);
    let arr = [...levelname];
    let arrid = [...levelid];
    if (arrid[0] === null || arrid[0] === "") arrid = [];
    if (event.target.checked) {
      arrid.push(event.target.name);
      arr.push(event.target.id);
      setlevelname((prev) => [...prev, event.target.id]);
      localStorage.setItem("levelname", JSON.stringify(arr));
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === event.target.id) {
          arr.splice(i, 1);
        }
      }
      for (let j = 0; j < arrid.length; j++) {
        if (arrid[j] === event.target.name) {
          arrid.splice(j, 1);
        }
      }
      setlevelname(arr);
      localStorage.setItem("levelname", JSON.stringify(arr));
    }

    setlevelid(arrid);
    setlevelvalue(arrid.join());
    localStorage.setItem("level", arrid.join());
    setpageno(0);
    getheader(0, columnName, arrid.join(), "level");
    setlevelcheckbox({
      ...levelcheckbox,
      [event.target.name]: event.target.checked,
    });
    props.pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleareaChange = (event) => {
    setbackdropOpen(true);
    let arr = [...areaname];
    let arrid = [...areaid];
    if (arrid[0] === null || arrid[0] === "") arrid = [];
    if (event.target.checked) {
      arrid.push(event.target.name);
      arr.push(event.target.id);
      setareaname((prev) => [...prev, event.target.id]);
      localStorage.setItem("areaname", JSON.stringify(arr));
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === event.target.id) {
          arr.splice(i, 1);
        }
      }
      for (let j = 0; j < arrid.length; j++) {
        if (arrid[j] === event.target.name) {
          arrid.splice(j, 1);
        }
      }
      setareaname(arr);
      localStorage.setItem("areaname", JSON.stringify(arr));
    }
    setareaid(arrid);
    setareavalue(arrid.join());
    localStorage.setItem("area", arrid.join());
    setpageno(0);
    getheader(0, columnName, arrid.join(), "area");
    setareacheckbox({
      ...areacheckbox,
      [event.target.name]: event.target.checked,
    });
    props.pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getheader = (value, col, arr, attribute) => {
    //console.log("Attribute", attribute);
    // var popularFilter = localStorage.getItem("PopularState");
    setbackdropOpen(true);
    let headers = {
      columnName: col.split("-")[0],
      direction: col.split("-")[1],
      // popular: popularFilter ? popularFilter : 0,
      // popular: 0,
      page: Number(value),
      size: 12,
    };
    if (countryvalue) {
      headers["country"] = countryvalue;
    }
    if (statevalue) {
      headers["state"] = statevalue;
    }
    if (attribute === "area" && arr) {
      headers["areaId"] = arr;
    } else if (attribute !== "area" && areavalue) {
      headers["areaId"] = areavalue;
    }
    // if (attribute !== "city" && cityvalue) {
    //   headers["city"] = cityvalue;
    // }
    if (attribute !== "state" && cityvalue) {
      headers["state"] = cityvalue;
    }
    if (attribute === "level" && arr) {
      headers["levelId"] = arr;
    } else if (attribute !== "level" && levelvalue) {
      headers["levelId"] = levelvalue;
    }
    if (attribute === "course" && arr) {
      headers["courseId"] = arr;
    } else if (attribute !== "course" && coursevalueid) {
      headers["courseId"] = coursevalueid;
    }
    //console.log("headers---------------->", headers);
    getavailablecourses(headers);
  };

  const data = [
    // {
    //   "courseLevelCount": 21,
    //   "levelId": 20,
    //   "levelName": "Certificate I"
    // },
    {
      courseLevelCount: 166,
      levelId: 14,
      levelName: "Certificate II",
    },
    {
      courseLevelCount: 1758,
      levelId: 13,
      levelName: "Certificate III",
    },
    {
      courseLevelCount: 2106,
      levelId: 12,
      levelName: "Certificate IV",
    },
    {
      courseLevelCount: 3678,
      levelId: 11,
      levelName: "Diploma",
    },
    {
      courseLevelCount: 1617,
      levelId: 10,
      levelName: "Advanced Diploma",
    },
    {
      courseLevelCount: 205,
      levelId: 4,
      levelName: "Associate Degree",
    },
    {
      courseLevelCount: 4172,
      levelId: 1,
      levelName: "Bachelor Degree",
    },
    {
      courseLevelCount: 1518,
      levelId: 8,
      levelName: "Bachelor Honours Degree",
    },
    {
      courseLevelCount: 1005,
      levelId: 5,
      levelName: "Graduate Certificate",
    },
    {
      courseLevelCount: 1350,
      levelId: 9,
      levelName: "Graduate Diploma",
    },
    {
      courseLevelCount: 3383,
      levelId: 7,
      levelName: "Masters Degree (Coursework)",
    },
    {
      courseLevelCount: 867,
      levelId: 2,
      levelName: "Masters Degree (Research)",
    },
    {
      courseLevelCount: 49,
      levelId: 16,
      levelName: "Masters Degree (Extended)",
    },
    {
      courseLevelCount: 1090,
      levelId: 3,
      levelName: "Doctoral Degree",
    },
    {
      courseLevelCount: 389,
      levelId: 15,
      levelName: "Senior Secondary Certificate of Education",
    },
    {
      courseLevelCount: 1745,
      levelId: 6,
      levelName: "Non AQF Award",
    },
  ];

  const getLevel = () => {
    axios.get(`${base_url.api5}/getCourseCountGroupByLevel`).then((res) => {
      setlevel(res.data.data);
      let obj = {};
      for (let i = 0; i < res.data.data.length; i++) {
        let key = res.data.data[i].levelId;
        obj[key] = false;
      }
      let arr = localStorage.getItem("level").split(",");
      for (let i = 0; i < arr.length; i++) {
        let key = arr[i];
        obj[key] = true;
      }
      setlevelcheckbox(obj);
    });
    // .catch((err) => //console.log(err));
  };

  // const getLevel = () => {
  //   setlevel(data);
  //   let obj = {};
  //   for (let i = 0; i < data.length; i++) {
  //     let key = data[i].levelId;
  //     obj[key] = false;
  //   }
  //   let arr = localStorage.getItem("level").split(",");
  //   for (let i = 0; i < arr.length; i++) {
  //     let key = arr[i];
  //     obj[key] = true;
  //   }
  //   setlevelcheckbox(obj);
  // };
  const getArea = () => {
    axios.get(`${base_url.api5}/getCourseCountGroupByArea`).then((res) => {
      setarea(res.data.data);
      let obj = {};
      for (let i = 0; i < res.data.data.length; i++) {
        let key = res.data.data[i].area_id;
        obj[key] = false;
      }
      let arr = localStorage.getItem("area").split(",");
      for (let i = 0; i < arr.length; i++) {
        let key = arr[i];
        obj[key] = true;
      }
      setareacheckbox(obj);
    });
    // .catch((err) => //console.log(err));
  };

  const handleclearall = () => {
    clearall();
    window.location.reload();
    // if (!countryvalue) {
    //   setbackdropOpen(true);
    //   let headers = {
    //     columnName: "courseId",
    //     direction: "DESC",
    //     page: Number(0),
    //     size: 12,
    //   };
    //   if (coursevalueid) headers["courseId"] = coursevalueid;
    //   getavailablecourses(headers);
    //   setpageno(0);
    // }
    // localStorage.removeItem("statevalue");
    // localStorage.removeItem("countryvalue");
    // setstatevalue(null);
    // setcountryvalue(null);
  };

  const clearall = () => {
    localStorage.setItem("levelname", JSON.stringify([]));
    localStorage.setItem("level", [].join());
    localStorage.setItem("areaname", JSON.stringify([]));
    localStorage.setItem("area", [].join());
    localStorage.removeItem("frontendcoursename");
    localStorage.setItem("frontendcourseid", [].join());
    // let obj = {};
    // for (let i = 0; i < Object.keys(levelcheckbox).length; i++) {
    //   obj[Object.keys(levelcheckbox)[i]] = false;
    // }
    // let obj2 = {};
    // for (let i = 0; i < Object.keys(areacheckbox).length; i++) {
    //   obj2[Object.keys(areacheckbox)[i]] = false;
    // }
    // setlevelname([]);
    // setlevelid([]);
    // setlevelvalue([].join());
    // setlevelcheckbox(obj);
    // setareaname([]);
    // setareaid([]);
    // setareavalue([].join());
    // setareacheckbox(obj2);
    localStorage.removeItem("cityvalue");
    localStorage.removeItem("statevalue");
    localStorage.removeItem("countryvalue");
    // setcityvalue(null);
  };

  //console.log(areavalue);
  //console.log(levelvalue);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="coursesrc-title">
        <div className="container homepage_selectioncontainer mt-5 innerpage-selectioncontainer">
          <h1 className="text-center">Search, compare &amp; apply</h1>
          <div className="home-form" style={{ padding: "4px 4px" }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitcourse(e);
              }}
              class="homepage_formcontainer"
            >
              <div class="row mx-0">
                <div class="col">
                  {/* <CountryDropdown
                    defaultOptionLabel="Select Country"
                    className="form-control selecttag countrydropdown2"
                    value={country1}
                    onChange={(val) => selectCountry(val)}
                  /> */}
                  <select
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    className="form-control selecttag countrydropdown2"
                  >
                    {allowedCountries.map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
                {!countrycheck && (
                  <p className="homepageerror">Country is Required</p>
                )}
                <div class="col">
                  {city.length > 0 ? (
                    // <select
                    //   onChange={handlechangecity}
                    //   value={mycityname}
                    //   className="form-control selecttag"
                    // >
                    //   <option value="">Select Region</option>
                    //   {city.map((item, index) => (
                    //     <option key={index} value={item.city}>
                    //       {item.city}
                    //     </option>
                    //   ))}
                    // </select>
                    <select
                      value={mycityname}
                      onChange={handleSelectChange}
                      className="form-control selecttag"
                    >
                      <option value="">Select Region</option>
                      <option value="ACT">ACT (inc. Canberra)</option>
                      <option value="NSW">NSW (inc. Sydney)</option>
                      <option value="NT">NT (inc. Darwin)</option>
                      <option value="QLD">QLD (inc. Brisbane)</option>
                      <option value="SA">SA (inc. Adelaide)</option>
                      <option value="TAS">TAS (inc. Hobart)</option>
                      <option value="VIC">VIC (inc. Melbourne)</option>
                      <option value="WA">WA (inc. Perth)</option>
                    </select>
                  ) : (
                    <input
                      className="form-control"
                      placeholder="Enter Region"
                      value={mycityname}
                      onChange={(e) => handlecitychange(e.target.value)}
                    />
                  )}
                  {!locationcheck && (
                    <p className="homepageerror">Region is Required</p>
                  )}
                </div>
                <div class="col">
                  <select
                    onChange={handlechangelevel}
                    value={mylevelname}
                    className="form-control selecttag"
                  >
                    <option value="">Select Study Level</option>
                    {checklevel.map((item, index) => (
                      <option
                        key={index}
                        value={item.levelName}
                        id={item.levelId}
                      >
                        {item.levelName}
                      </option>
                    ))}
                  </select>
                </div>
                {!levelcheck && (
                  <p className="homepageerror">Study Level is Required</p>
                )}
                <div class="col">
                  <Select
                    value={selectedOption}
                    onChange={handlechangearea}
                    options={fieldOptions}
                    placeholder={courseName || "Select Study Field"}
                    style={customSelectStyles}
                  />
                </div>
                {!areacheck && (
                  <p className="homepageerror">Study Field is Required</p>
                )}
                <div class="col" style={{ padding: "0" }}>
                  <button type="submit" class="btn home-form-btn">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="coursesearchbody_inputcontainer d-none">
        <div className="container">
          <div className="d-flex">
            <input
              className="form-control coursesearchbody_searchinput"
              placeholder="Search for courses,provider,level"
              value={coursevalue}
              onChange={(e) => setcoursevalue(e.target.value)}
            />
            <div style={{ flex: "0.1" }}>
              <i
                onClick={searchcoursewithname}
                className="fa fa-search coursesearchbody_searchicon"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: "0px 0px" }}>
        <div className="row m-0">
          <div className="col-xl-3 pl-0 pr-0 course-searchleftbar">
            <CourseSearchBodyLeft
              checklevel={checklevel}
              levelcheckbox={levelcheckbox}
              checkarea={checkarea}
              areacheckbox={areacheckbox}
              classes={classes}
              handleareaChange={handleareaChange}
              handlelevelChange={handlelevelChange}
              setcityvalue={setcityvalue}
              setstatevalue={setstatevalue}
              setcountryvalue={setcountryvalue}
              countryvalue={countryvalue}
              statevalue={statevalue}
              cityvalue={cityvalue}
              getheader={getheader}
              pageno={pageno}
              levelname={levelname}
              areaname={areaname}
              handleclearall={handleclearall}
              city={city}
              coursevalue={coursevalue}
              searchcoursewithname={searchcoursewithname}
              columnName={columnName}
              setcolumnName={setcolumnName}
            />
          </div>
          <div className="col-xl-9 mt-4 course-searchrightbar">
            <CourseSearchBodyRight
              courseDetail={courseDetail}
              navigate={navigate}
              backdropopen={backdropopen}
              setbackdropOpen={setbackdropOpen}
              comparecheck={comparecheck}
              setcomparecheck={setcomparecheck}
              // bookmarkcheck={bookmarkcheck}
              length={length}
              totallength={totallength}
              toast={toast}
              getheader={getheader}
              pageno={pageno}
              handlepage={handlepage}
              getpageno={getpageno}
              countnumber={countnumber}
              comparedetailmodal={comparedetailmodal}
              configAdmin={configAdmin}
              columnName={columnName}
              getavailablecourses={getavailablecourses}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSearchBody;
const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#6f81aa !important", // Change field color here
    borderColor: state.isFocused ? "#007bff" : "#ced4da", // Change border color here
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6f81aa !important", // Change placeholder color here
  }),
};
