import React, { useState, useEffect } from "react";
import InsuranceDoc from "./AccountBodyDocument/InsuranceDoc";
import MiscellaneousDoc from "./AccountBodyDocument/MiscellaneousDoc";
import VisaDoc from "./AccountBodyDocument/VisaDoc";
import AccountEnquiry from "./AccountEnquiry";
// import AccountEnrollment from "./AccountEnrollment";
import Border from "./../../assets/images/border.png";
import axios from "axios";
import base_url from "../api/bootapi";

const AccountMainBody = (props) => {
  const [checkimage, setcheckimage] = useState("application");
  // const loggedInUser = localStorage.getItem("agentLogin");
  //console.log('Agent Login:',loggedInUser);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getClientDetails();
  }, []);

  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  const [firstName, setfirstName] = useState();
  const [middleName, setmiddleName] = useState();
  const [lastName, setlastName] = useState();

  const getClientDetails = () => {
    axios
      .get(`${base_url.api5}/getClientByUsername`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          username: clientdetails?.username,
        },
      })
      .then((res) => {
        setfirstName(res.data.data.clientDetail.firstName);
        setmiddleName(res.data.data.clientDetail.middleName);
        setlastName(res.data.data.clientDetail.lastName);
        let client = res.data.data.clientDetail;
      });
  };

  return (
    <div className="accountbody">
      <div className="account-username">
        Welcome <br />
        <span>
          {firstName} {middleName} {lastName}
        </span>
      </div>
      <div className="accountbody_tabnav_outer">
        <ul className="nav nav-tabs accountbody_tabnav">
          <li className="nav-item row mx-0 ">
            <a
              className="nav-link active"
              data-toggle="tab"
              href="#enquiry"
              onClick={() => setcheckimage("application")}
            >
              Applications
            </a>
          </li>
          {/* <li className="nav-item">
          <a className="nav-link" data-toggle="tab" href="#document">
            Document
          </a>
        </li> */}
          {/* <li className="nav-item row mx-0">
          <a
            className="nav-link"
            data-toggle="tab"
            href="#enrollment"
            onClick={() => setcheckimage("enrollment")}
          >
            Enrollment
          </a>
          {checkimage === "offer" || checkimage === "enrollment" ? null : (
            <img alt="border" src={Border} />
          )}
        </li> */}
          <li className="nav-item row mx-0">
            <a
              className="nav-link"
              data-toggle="tab"
              href="#visa"
              onClick={() => setcheckimage("visa")}
            >
              Visa Application
            </a>
          </li>
          <li className="nav-item row mx-0">
            <a
              className="nav-link"
              data-toggle="tab"
              href="#insurance"
              onClick={() => setcheckimage("insurance")}
            >
              Insurance
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="tab"
              href="#miscellaneous"
              onClick={() => setcheckimage("miss")}
            >
              Miscellaneous
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content">
        <div id="enquiry" className=" tab-pane active">
          <AccountEnquiry />
          {/* {loggedInUser == 0? <AccountEnquiry /> : <AgentClients />} */}
        </div>
        {/* <div id="document" className=" tab-pane fade">
          <DocumentDoc />
        </div> */}
        {/* <div id="enrollment" className="tab-pane fade">
          <AccountEnrollment />
        </div> */}
        <div id="visa" className=" tab-pane fade">
          <VisaDoc />
        </div>
        <div id="insurance" className=" tab-pane fade">
          <InsuranceDoc />
        </div>
        <div id="miscellaneous" className=" tab-pane fade">
          <MiscellaneousDoc />
        </div>
      </div>
    </div>
  );
};

export default AccountMainBody;
