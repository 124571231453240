import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import LayeredLabel from "./../../assets/images/layeredlabel.gif";
import Remove from "./../../assets/images/btn_remove.webp";
import { Container, Row, Col, Card } from "reactstrap";
import instituteFilterIcon from "./../../assets/images/institute-filter-icon.png";

const CourseSearchBodyLeft = (props) => {
  const [expanded, setExpanded] = useState("panel1");

  const [toggleSidebar, setToggleSidebar] = useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handlecountrychange = (val) => {
    localStorage.removeItem("statevalue");
    localStorage.removeItem("cityvalue");
    props.setcityvalue(null);
    props.setstatevalue(null);
    document.getElementById("myForm").reset();
    if (`'${val}'` === "''") {
      localStorage.removeItem("countryvalue");
      props.setcountryvalue(null);
    } else {
      localStorage.setItem("countryvalue", `${val}`);
      props.setcountryvalue(`'${val}'`);
    }
  };

  const handlestatechange = (val) => {
    props.setcityvalue(null);
    localStorage.removeItem("cityvalue");
    document.getElementById("myForm").reset();
    if (`'${val}'` === "''") {
      localStorage.removeItem("statevalue");
      props.setstatevalue(null);
    } else {
      localStorage.setItem("statevalue", `'${val}'`);
      props.setstatevalue(`'${val}'`);
    }
  };

  const handlechangecity = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementValue = optionElement.getAttribute("value");
    localStorage.setItem("cityvalue", `${optionElementValue}`);
    props.setcityvalue(`${optionElementValue}`);
  };

  const handlechangerating = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementValue = optionElement.getAttribute("value");
    props.setcolumnName(optionElementValue);
    props.getheader(props.pageno, optionElementValue);
  };

  const handlecitychange = (e) => {
    if (`'${e.target.value}'` === "''") {
      localStorage.removeItem("cityvalue");
      props.setcityvalue(null);
    } else {
      localStorage.setItem("cityvalue", `${e.target.value}`);
      props.setcityvalue(`${e.target.value}`);
    }
  };

  const handlecityremove = () => {
    localStorage.removeItem("cityvalue");
    props.setcityvalue(null);
    props.getheader(props.pageno, props.columnName, "", "city");
  };

  /* //console.log(props.cityvalue);
  //console.log(props.statevalue);
  //console.log(props.countryvalue);
  //console.log(props.coursevalue); */
  return (
    <div>
      {/* <p className="coursesearchbody_counttext">Refine Results</p> */}

      <div className="filter-mobile-visible mobile-course-filter">
        <Container>
          <Row>
            <Col className="mobile-course-filter-sort">
              <a onClick={() => setToggleSidebar(!toggleSidebar)}>
                Sort by &nbsp;&nbsp;&nbsp;&nbsp;
                <i
                  className={
                    toggleSidebar ? "fa fa-chevron-down" : "fa fa-chevron-up"
                  }
                ></i>
              </a>
            </Col>
            <Col>
              <a onClick={() => setToggleSidebar(!toggleSidebar)}>
                <span>
                  <img src={instituteFilterIcon} alt="" />
                </span>{" "}
                Filter{" "}
              </a>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={toggleSidebar ? "mobile-course-filter-box" : null}>
        <div className={props.classes.root}>
          {props.levelname?.length > 0 ||
          props.areaname?.length > 0 ||
          props.countryvalue?.split("'")[1] ||
          props.statevalue?.split("'")[1] ||
          props.cityvalue?.split("'")[1] ||
          props.coursevalue ? (
            <Accordion expanded={true}>
              <AccordionSummary>
                <Typography className={props.classes.heading}>
                  Now searching by
                </Typography>
              </AccordionSummary>

              {props.checklevel?.map((p, index) => (
                <div key={index}>
                  {props.levelcheckbox?.[`${p.levelId}`] && (
                    <div className="d-flex justify-content-between px-2 nowsearchingby">
                      <div className="d-flex">
                        <img alt="layeredlabel" src={LayeredLabel} />
                        Level:<span>{p.levelName}</span>
                      </div>
                      <img
                        alt="remove"
                        src={Remove}
                        id={p.levelName}
                        name={p.levelId}
                        onClick={props.handlelevelChange}
                      />
                    </div>
                  )}
                </div>
              ))}
              {props.checkarea?.map((p, index) => (
                <div key={index}>
                  {props.areacheckbox?.[`${p.area_id}`] && (
                    <div className="d-flex justify-content-between px-2 nowsearchingby">
                      <div className="d-flex">
                        <img alt="layeredlabel" src={LayeredLabel} />
                        Area:<span>{p.area_name}</span>
                      </div>
                      <img
                        alt="remove"
                        src={Remove}
                        id={p.area_name}
                        name={p.area_id}
                        onClick={props.handleareaChange}
                      />
                    </div>
                  )}
                </div>
              ))}
              {props.cityvalue?.split("'")[1] && (
                <div className="d-flex justify-content-between px-2 nowsearchingby">
                  <div className="d-flex">
                    <img alt="layeredlabel" src={LayeredLabel} />
                    City:<span>{props.cityvalue?.split("'")[1]}</span>
                  </div>
                  <img alt="remove" src={Remove} onClick={handlecityremove} />
                </div>
              )}
              {props.statevalue?.split("'")[1] && (
                <div className="d-flex justify-content-between px-2 nowsearchingby">
                  <div className="d-flex">
                    <img alt="layeredlabel" src={LayeredLabel} />
                    State:<span>{props.statevalue?.split("'")[1]}</span>
                  </div>
                  <img
                    alt="remove"
                    src={Remove}
                    onClick={() => handlestatechange("")}
                  />
                </div>
              )}
              {props.countryvalue?.split("'")[1] && (
                <div className="d-flex justify-content-between px-2 nowsearchingby">
                  <div className="d-flex">
                    <img alt="layeredlabel" src={LayeredLabel} />
                    Country:<span>{props.countryvalue?.split("'")[1]}</span>
                  </div>
                  <img
                    alt="remove"
                    src={Remove}
                    onClick={() => handlecountrychange("")}
                  />
                </div>
              )}
              {props.coursevalue && (
                <div className="d-flex justify-content-between px-2 nowsearchingby">
                  <div className="d-flex">
                    <img alt="layeredlabel" src={LayeredLabel} />
                    Course:<span>{props.coursevalue}</span>
                  </div>
                  <img
                    alt="remove"
                    src={Remove}
                    onClick={() => props.searchcoursewithname("remove")}
                  />
                </div>
              )}
              <div className="d-flex justify-content-end">
                <p
                  className="courseclearalltext"
                  onClick={props.handleclearall}
                >
                  Clear All
                </p>
              </div>
            </Accordion>
          ) : null}
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={props.classes.heading}>Sort by</Typography>
            </AccordionSummary>
            <div className="coursebodyleft_locationcontainer mx-3">
              <label className="mx-0">Rating</label>
              <select
                value={props.columnName}
                onChange={handlechangerating}
                className="form-control mx-0 mb-4 selecttag"
              >
                <option value="courseId-DESC">Select Rating</option>
                <option value="courseRating-DESC">High-Low</option>
                <option value="courseRating-ASC">Low-High</option>
              </select>
              <label className="mx-0">OffShore Fee</label>

              <select
                value={props.columnName}
                onChange={handlechangerating}
                className="form-control mx-0 mb-4 selecttag"
              >
                <option value="courseId-DESC">Select OffShore Fees</option>
                <option value="offShoreFees-DESC">High-Low</option>
                <option value="offShoreFees-ASC">Low-High</option>
              </select>
              <label className="mx-0">OnShore Fee</label>

              <select
                value={props.columnName}
                onChange={handlechangerating}
                className="form-control mx-0 mb-4 selecttag"
              >
                <option value="courseId-DESC">Select OnShore Fee</option>
                <option value="fees-DESC">High-Low</option>
                <option value="fees-ASC">Low-High</option>
              </select>
            </div>
          </Accordion>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={props.classes.heading}>Levels</Typography>
            </AccordionSummary>
            {props.checklevel.map((p, index) => (
              <div key={index} className="d-flex justify-content-between px-2">
                <div className="d-flex pl-2" style={{ flex: ".9" }}>
                  <div className="course_left">
                    <input
                      type="checkbox"
                      id={p.levelName}
                      name={p.levelId}
                      onChange={props.handlelevelChange}
                      checked={props.levelcheckbox?.[`${p.levelId}`]}
                      style={{ width: "20px", height: "20px", flex: ".06" }}
                    />
                  </div>
                  <p className="mt-2 pl-2" style={{ flex: ".94" }}>
                    {p.levelName}
                  </p>
                </div>
                <p className="coursefiltercount">{p.courseLevelCount}</p>
              </div>
            ))}
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={props.classes.heading}>
                Study Area
              </Typography>
            </AccordionSummary>
            {props.checkarea.map((p, index) => (
              <div key={index} className="d-flex justify-content-between px-2">
                <div className="d-flex pl-2" style={{ flex: ".9" }}>
                  <div className="course_left">
                    <input
                      type="checkbox"
                      checked={props.areacheckbox?.[`${p.area_id}`]}
                      id={p.area_name}
                      name={p.area_id}
                      onChange={props.handleareaChange}
                      style={{ width: "20px", height: "20px", flex: ".06" }}
                    />
                  </div>
                  <p className="mt-2 pl-2" style={{ flex: ".94" }}>
                    {p.area_name}
                  </p>
                </div>
                <p className="coursefiltercount">{p.courseAreaCount}</p>
              </div>
            ))}
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={props.classes.heading}>
                Study Location
              </Typography>
            </AccordionSummary>
            <div className="coursebodyleft_locationcontainer">
              <label>Country</label>
              <CountryDropdown
                defaultOptionLabel="Select Country"
                className="form-control selecttag coursebodyleft_locationfromcontainer"
                value={props.countryvalue?.split("'")[1]}
                onChange={handlecountrychange}
              />
              <label>State</label>
              {props.countryvalue?.split("'")[1] === "Nepal" ? (
                <select
                  className="form-control selecttag coursebodyleft_locationfromcontainer"
                  value={props.statevalue?.split("'")[1]}
                  onChange={(e) => handlestatechange(e.target.value)}
                >
                  <option value="">Select Province</option>
                  <option value="Province No. 1">Province No. 1</option>
                  <option value="Madhesh Province">Madhesh Province</option>
                  <option value="Bagmati Province">Bagmati Province</option>
                  <option value="Gandaki Province">Gandaki Province</option>
                  <option value="Lumbini Province">Lumbini Province</option>
                  <option value="Karnali Province">Karnali Province</option>
                  <option value="Sudurpashchim Province">
                    Sudurpashchim Province
                  </option>
                </select>
              ) : (
                <RegionDropdown
                  defaultOptionLabel="Select State"
                  className="form-control selecttag coursebodyleft_locationfromcontainer"
                  country={props.countryvalue?.split("'")[1]}
                  value={props.statevalue?.split("'")[1]}
                  onChange={handlestatechange}
                />
              )}
              <label>City</label>
              <div className="row m-0 ml-3">
                <form id="myForm" className="px-0 col-md-8">
                  {props.city?.length > 0 ? (
                    <select
                      value={
                        props.cityvalue ? props.cityvalue?.split("'")[1] : null
                      }
                      onChange={handlechangecity}
                      className="form-control mx-0 mb-4"
                    >
                      <option value="">Select City</option>
                      {props.city.map((item, index) => (
                        <option key={index} value={item.city}>
                          {item.city}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      value={
                        props.cityvalue ? props.cityvalue?.split("'")[1] : null
                      }
                      onChange={handlecitychange}
                      className="form-control mx-0 mb-4"
                      placeholder="Enter City Name"
                    />
                  )}
                </form>
                <div className="col-md-4">
                  <button
                    style={{ backgroundColor: "#7de1c3" }}
                    className="btn"
                    onClick={() =>
                      props.getheader(props.pageno, props.columnName)
                    }
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default CourseSearchBodyLeft;
