// *****************************************************
// Define configurations for different environments
const configurations = {
  frontend: {
    api5: "https://api.edupilot.com.au/module5",
  },
  development: {
    api5: "https://testcrm.nepcoms.com/module5",
  },
  branch: {
    api5: "https://branch.nepcoms.com/mongolia-module5",
  },
};

// Set the environment mode
const environment = process.env.REACT_APP_ENVIRONMENT || "frontend";

// Select the configuration based on the environment
const base_url = configurations[environment];

export default base_url;
