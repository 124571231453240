import React, { useEffect, useState } from "react";
import { useStateValue } from "../ContextAPI/StateProvider";
import "./../css/CoursesOverview.css";
import Pagination from "@mui/material/Pagination";
// import moment from "moment";
import base_url from "../api/bootapi";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import OfferLetter from "./AccountBodyDocument/OfferLetter";
import COE from "./AccountBodyDocument/COE";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const AgentMainClients = () => {
  const classes = useStyles();
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [enqyuirydetails, setenqyuirydetails] = useState();
  // eslint-disable-next-line
  const [{ user_tab, enquiryalreadycompared }, dispatch] = useStateValue();
  const [backdrop, setbackdrop] = useState(false);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    setbackdrop(true);
    getEnqyuiry(pageno);
  }, []);
  const handlepage = (event, value) => {
    setbackdrop(true);
    setpageno(value - 1);
    getEnqyuiry(value - 1);
  };
  const getEnqyuiry = (value) => {
    axios
      .get(`${base_url.api5}/getClientBySubAgent`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          subAgent: clientdetails?.username,
          columnName: "addedOn",
          direction: "DESC",
          page: Number(value),
          size: 10,
        },
      })
      .then((res) => {
        setenqyuirydetails(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
        setbackdrop(false);
      })
      .catch((err) => setbackdrop(false));
  };
  return (
    <div className="accountenquiry">
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {user_tab === "enquiry" ? (
        <h2 style={{ color: "#2d5f9f", marginBottom: "20px" }}>Enquiry List</h2>
      ) : null}
      {/* {enquiryalreadycompared && (
        <p
          style={{
            color: "red",
            fontWeight: "bold",
            transition: "all 0.3s ease",
          }}
        >
          Already Applied
        </p>
      )}
      <ul className="nav nav-tabs accountbody_responsivenav">
        <li className="nav-item row mx-0 ">
          <a
            className="nav-link active"
            data-toggle="tab"
            href="#application_admission"
          >
            Admission
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-toggle="tab" href="#application_offer">
            Offer Letter
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-toggle="tab" href="#application_coe">
            COE
          </a>
        </li>
      </ul> */}
      <div className="tab-content">
        <div id="application_admission" className=" tab-pane active">
          <div style={{ marginBottom: "15px", overflowX: "auto" }}>
            <table className="table tbb">
              <thead className="thb">
                <tr>
                  <th style={{ minWidth: "140px" }}>Client Name</th>
                  <th>Email </th>
                  <th>phone </th>
                  <th>Country </th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {enqyuirydetails?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.firstName} {item.middleName} {item.lastName}
                    </td>
                    <td>
                      {item.email}
                      <br />
                      {item.secondaryEmail}
                    </td>
                    <td>{item.phone}</td>
                    <td>{item.country}</td>
                    <td>{item.status == "1" ? "Active" : "InActive"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: 20,
            }}
          >
            <div>
              {enqyuirydetails?.length ? (
                <p>
                  {countnumber * 10 + 1} to{" "}
                  {countnumber * 10 + numberofElements} of {totalUser} records
                </p>
              ) : (
                <p>No Record Found </p>
              )}
            </div>
            <Pagination count={getpageno} onChange={handlepage} />
          </div>
        </div>
        <div id="application_offer" className=" tab-pane">
          <OfferLetter />
        </div>
        <div id="application_coe" className=" tab-pane">
          <COE />
        </div>
      </div>
    </div>
  );
};

export default AgentMainClients;
