import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../api/bootapi";
import NoDataFound from "./../../assets/images/unnamed.jpg";
import NoLogoAvailable from "./../../assets/images/nophoto-transparent.png";
import CompareModal from "../Modal/CompareModal";
// import BookmarkModal from "../Modal/BookmarkModal";
import Pagination from "@mui/material/Pagination";
import CompareDetails from "../Modal/CompareDetails";
import CourseCompareRequirementModal from "../Modal/CourseCompareRequirementModal";
import ApplyNowModal from "../Modal/ApplyNowModal";
import DeleteIcon from "@mui/icons-material/Delete";
import Backdrop from "@mui/material/Backdrop";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { toast } from "react-toastify";
import downlogo from "./../../assets/images/downlogo.png";
import attachmenturl from "../api/attachmenturl";

const CourseSearchBodyRight = (props) => {
  const [toggle, setToggle] = useState(true);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  const agentLoginCheck = JSON.parse(localStorage.getItem("agentLogin"));

  const clientLoginCheck = JSON.parse(localStorage.getItem("clientLogin"));

  const applydetail =
    JSON.parse(localStorage.getItem("isw_frontend_applycourse_detail")) || {};

  const viewCourse = (p) => {
    var courseName = p.courseName.toLocaleLowerCase();
    var courseId = p.courseId;
    let course = {
      courseId: p.courseId,
      branchId: p.branchInstitutionId,
    };
    localStorage.setItem("courseId", JSON.stringify(course));
    // let param = courseId + "/" + courseName.replace(/\s+/g, "");
    let param = courseName.split(" ").join("-") + `-${courseId}`;
    props.navigate("/course-detail/" + param);
  };

  const viewBranch = (p) => {
    const name = p.branchName
      .substring(0, p.branchName.lastIndexOf("-"))
      .toLocaleLowerCase();
    let course = {
      courseId: p.courseId,
      branchId: p.branchInstitutionId,
    };
    localStorage.setItem("courseId", JSON.stringify(course));
    // var providercleaned = p.courseId + "/" + p.branchName.replace(/\s+/g, "");
    let providercleaned = name.split(" ").join("-") + `${p.courseId}`;
    props.navigate("/provider-detail/" + providercleaned);
  };

  useEffect(() => {
    Sangamgetcomparelist();
  }, []);

  const [backdropopen, setbackdropOpen] = useState(false);
  const [classname, setclassname] = useState();
  const [comparedata, setcomparedata] = useState([]);

  const Sangamgetcomparelist = () => {
    setbackdropOpen(true);
    if (clientLoginCheck === 1) {
      axios
        .get(`${base_url.api5}/getClientCompareListByClientId`, {
          headers: {
            clientId: clientdetails?.clientId,
            columnName: "id",
            direction: "DESC",
            page: 0,
            size: 20,
          },
        })
        .then((res) => {
          let data = res.data.data;
          //console.log("Compare Data", res.data.data);
          // setcomparedatalength(data.length);
          // if (res.data.data.length === 1) data.push({});
          for (let i = res.data.data.length; i < 4; i++) {
            data.push({});
          }
          let classnumber = 12 / Number(res.data.data.length);
          setclassname(`col-md-${classnumber}`);
          setcomparedata(data);
          setbackdropOpen(false);
        })
        .catch((err) => {
          setbackdropOpen(false);
          setcomparedata([]);
        });
    } else if (agentLoginCheck === 1) {
      axios
        .get(`${base_url.api5}/getCompareListByAgentId`, {
          headers: {
            agentId: clientdetails?.agentId,
            columnName: "id",
            direction: "DESC",
            page: 0,
            size: 20,
          },
        })
        .then((res) => {
          let data = res.data.data;
          //console.log("Compare Data", res.data.data);
          // setcomparedatalength(data.length);
          // if (res.data.data.length === 1) data.push({});
          for (let i = res.data.data.length; i < 4; i++) {
            data.push({});
          }
          let classnumber = 12 / Number(res.data.data.length);
          setclassname(`col-md-${classnumber}`);
          setcomparedata(data);
          setbackdropOpen(false);
        })
        .catch((err) => {
          setbackdropOpen(false);
          setcomparedata([]);
        });
    } else {
      axios
        .get(`${base_url.api5}/getClientCompareListByClientTempId`, {
          headers: {
            Clienttempid: clientdetails?.clientTempId
              ? clientdetails.clientTempId
              : null,
            columnName: "id",
            direction: "DESC",
            page: 0,
            size: 20,
          },
        })
        .then((res) => {
          let data = res.data.data;
          //console.log("Compare Data", res.data.data);
          // setcomparedatalength(data.length);
          // if (res.data.data.length === 1) data.push({});
          for (let i = res.data.data.length; i < 4; i++) {
            data.push({});
          }
          let classnumber = 12 / Number(res.data.data.length);
          setclassname(`col-md-${classnumber}`);
          setcomparedata(data);
          setbackdropOpen(false);
        })
        .catch((err) => {
          //console.log(err);
          setbackdropOpen(false);
          setcomparedata([]);
        });
    }
  };

  const clearall = () => {
    setbackdropOpen(true);
    let arr = [];
    comparedata.forEach((element) => arr.push(element.id));
    let delete_str = arr.join(",");
    axios
      .delete(`${base_url.api5}/deleteCompareById`, {
        headers: {
          compareId: delete_str,
        },
      })
      .then((res) => {
        toast.success("Compare list cleared");
        if (clientdetails?.token) {
          if (clientdetails?.agentId) {
            // getcomparelistbyagent();
            //console.log("print1--->");
            Sangamgetcomparelist();
          } else {
            //console.log("print2--->");
            Sangamgetcomparelist();
          }
        } else {
          // getcomparelistbylead();
          //console.log("print3--->");
          Sangamgetcomparelist();
        }
        // window.location.reload();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went Wrong!!");
        }
      });
  };

  const removecompare = (id) => {
    if (!id) {
      return;
    }
    setbackdropOpen(true);
    axios
      .delete(`${base_url.api5}/deleteCompareById`, {
        headers: {
          compareId: id,
        },
      })
      .then((res) => {
        toast.success("Compare list deleted");
        if (clientdetails?.token) {
          if (clientdetails?.agentId) {
            // getcomparelistbyagent();
            //console.log("print4--->");
            Sangamgetcomparelist();
          } else {
            //console.log("print5--->");
            Sangamgetcomparelist();
          }
        } else {
          // getcomparelistbylead();
          //console.log("print6--->");
          Sangamgetcomparelist();
        }
      });
  };

  //console.log('comparedata-------->', comparedata);

  function calculatePathName(cricosId) {
    // Extract the numeric part of the alphanumeric ID, assuming it's at the start
    const numericPart = cricosId.match(/^\d+/);
    const numericId = numericPart ? parseInt(numericPart[0]) : 0;

    // Calculate the lower and upper bounds for each range
    const lowerBound = Math.floor((numericId - 1) / 500) * 500 + 1;
    const upperBound = Math.min(lowerBound + 499, 10000); // Adjust the upper limit as needed

    // Format the lower and upper bounds as strings with leading zeros
    const lowerBoundStr = String(lowerBound).padStart(5, "0");
    const upperBoundStr = String(upperBound).padStart(5, "0");

    // Construct the path name
    const pathName = `${lowerBoundStr}-${upperBoundStr}`;

    return pathName;
  }

  return (
    <div>
      {props.length > 0 && (
        <div className="d-none">
          <div className="d-flex justify-content-between">
            <p className="coursesearchbody_counttext">
              {props.countnumber * 12 + 1} to{" "}
              {props.countnumber * 12 + props.length} of {props.totallength}{" "}
              results found
            </p>
            <CompareDetails
              comparedetailmodal={props.comparedetailmodal}
              toast={props.toast}
              getheader={props.getheader}
              columnName={props.columnName}
              pageno={props.pageno}
              setbackdropOpen={props.setbackdropOpen}
            />
          </div>
        </div>
      )}

      {props.courseDetail !== null ? (
        <>
          {props.courseDetail.length > 0
            ? props.courseDetail.map((p, index) => (
                <div
                  key={index}
                  className="coursesearchbody_coursedetailcontainer"
                >
                  <div className="row m-0">
                    <div className="col-md-2 p-0 d-flex align-items-center justify-content-center">
                      <div>
                        {p.logo ? (
                          <img
                            alt="college_logo"
                            style={{ width: "130px" }}
                            src={p.logo}
                          />
                        ) : (
                          <img
                            alt="college_logo"
                            style={{ width: "130px" }}
                            src={NoLogoAvailable}
                          />
                        )}
                        <div
                          className="d-flex mt-1 align-items-center justify-content-center"
                          style={{ color: "gold" }}
                        >
                          {p.courseRating ? (
                            <>
                              {Array(Number(p.courseRating.split(".")[0]))
                                .fill(1)
                                .map((item, index) => (
                                  <i key={index} className="fa fa-star" />
                                ))}
                              {p.courseRating.split(".")[1] && (
                                <i className="fa fa-star-half" />
                              )}
                              {p.courseRating.split(".")[1] ? (
                                <>
                                  {4 - Number(p.courseRating.split(".")[0]) >
                                    0 &&
                                    Array(
                                      4 - Number(p.courseRating.split(".")[0])
                                    )
                                      .fill(1)
                                      .map((item, index) => (
                                        <i
                                          key={index}
                                          className="fa fa-star-o"
                                        />
                                      ))}
                                </>
                              ) : (
                                <>
                                  {5 - Number(p.courseRating.split(".")[0]) ===
                                  0
                                    ? null
                                    : Array(
                                        5 - Number(p.courseRating.split(".")[0])
                                      )
                                        .fill(1)
                                        .map((item, index) => (
                                          <i
                                            key={index}
                                            className="fa fa-star-o"
                                          />
                                        ))}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {Array(5)
                                .fill(1)
                                .map((item, index) => (
                                  <i key={index} className="fa fa-star-o" />
                                ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 p-0 centerofcontainer">
                      <div className="row mx-0 justify-content-between coursesearchbody_bordernotop">
                        <div className="p-2" style={{ maxWidth: "70%" }}>
                          <h5
                            className="navigatetocourse"
                            onClick={() => viewCourse(p)}
                          >
                            {/* {p.branchName
                              ? p.branchName.substring(
                                0,
                                p.branchName.lastIndexOf("-")
                              )
                              : "-"} */}
                            {p.institutionName}
                          </h5>
                          <p className="coursecompare_nameellipsis">
                            {/* <span className="font-weight-bold">Provider: </span> */}
                            <span
                              onClick={() => viewCourse(p)}
                              className="navigatetocourse"
                            >
                              {p.courseName}
                              {/* {p.branchName
                          ? p.branchName.substring(
                              0,
                              p.branchName.lastIndexOf("-")
                            )
                          : "-"}
                        , {p.city} */}
                            </span>
                          </p>
                        </div>
                        <button
                          className="btn coursesearchbody_overviewbutton overview-cta-desktop"
                          onClick={() => viewCourse(p)}
                        >
                          <i className="fa fa-eye" /> Overview
                        </button>
                      </div>
                      <div>
                        <div className="row m-0">
                          <div className="col coursesearchbody_bordernotopbottom centerofcontainer">
                            <p className="mb-0 colorlightblue course-search-card-smlabel">
                              Location
                            </p>
                            <div className="coursesearchbody_bordernotopbottom_input">
                              <p>
                                {p.state},{p.country}
                              </p>
                            </div>
                          </div>
                          <div className="col coursesearchbody_bordernotopbottom centerofcontainer">
                            <p className="mb-0 colorlightblue course-search-card-smlabel">
                              Requirements
                            </p>
                            <div className="coursesearchbody_bordernotopbottom_input">
                              {/* {p.requirement ? (
                          <CourseCompareRequirementModal
                            requirement={p.requirement}
                            fromwhere={"courselist"}
                          />
                        ) : (
                          <p>-</p>
                        )} */}
                              <p
                                className="learnmoretext"
                                onClick={() => viewCourse(p)}
                              >
                                Learn More
                              </p>
                            </div>
                          </div>
                          <div className="col coursesearchbody_bordernotopbottom centerofcontainer">
                            <p className="mb-0 course-search-card-smlabel">
                              Duration
                            </p>
                            <div className="coursesearchbody_bordernotopbottom_input">
                              <p>{p.duration}</p>
                            </div>
                          </div>
                          <div className="col coursesearchbody_bordernotopbottom centerofcontainer">
                            <p className="mb-0 colorlightblue course-search-card-smlabel">
                              OffShore Fee{" "}
                              <span className="tooltip-icon">
                                <i className="fa fa-info-circle" />
                                <div className="tooltip-content">
                                  Price may vary
                                </div>
                              </span>
                            </p>
                            <div className="coursesearchbody_bordernotopbottom_input">
                              {Number(p.offShoreFees) === 0 ? (
                                <p>-</p>
                              ) : (
                                <p>
                                  {p.offShoreFees && "$"}{" "}
                                  {p.offShoreFees || "-"}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col coursesearchbody_bordernotopbottom centerofcontainer">
                            <p className="mb-0 colorlightblue course-search-card-smlabel">
                              OnShore Fee{" "}
                              <span className="tooltip-icon">
                                <i className="fa fa-info-circle" />
                                <div className="tooltip-content">
                                  Price may vary
                                </div>
                              </span>
                            </p>
                            <div className="coursesearchbody_bordernotopbottom_input">
                              {Number(p.fees) === 0 ? (
                                <p>-</p>
                              ) : (
                                <p>
                                  {p.fees && "$"} {p.fees || "-"}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2 p-0 centerofcontainer course-searchrightbarlast">
                      <div class="p-2 overview-cta-mobile">
                        <button
                          className="btn coursesearchbody_overviewbutton "
                          onClick={() => viewCourse(p)}
                        >
                          <i className="fa fa-eye" /> Overview
                        </button>
                      </div>
                      {/* <CompareModal
                    comparecheck={props.comparecheck}
                    p={p}
                    setbackdropOpen={props.setbackdropOpen}
                    toast={props.toast}
                    setcomparecheck={props.setcomparecheck}
                    getheader={props.getheader}
                    pageno={props.pageno}
                    navigate={props.navigate}
                  /> */}
                      <CompareModal
                        comparecheck={props.comparecheck}
                        p={p}
                        setbackdropOpen={props.setbackdropOpen}
                        toast={props.toast}
                        setcomparecheck={props.setcomparecheck}
                        getheader={props.getheader}
                        columnName={props.columnName}
                        pageno={props.pageno}
                        navigate={props.navigate}
                        Sangamgetcomparelist={Sangamgetcomparelist}
                        getavailablecourses={props.getavailablecourses}
                        comparedata={comparedata}
                      />
                      {/* <BookmarkModal
                    bookmarkcheck={props.bookmarkcheck}
                    p={p}
                    setbackdropOpen={props.setbackdropOpen}
                    toast={props.toast}
                    getheader={props.getheader}
                    pageno={props.pageno}
                    navigate={props.navigate}
                  /> */}
                      {applydetail?.[
                        `${p.courseId}${p.branchInstitutionId}`
                      ] ? (
                        <button className="btn coursesearchbody_applynowbutton">
                          Applied
                        </button>
                      ) : (
                        <ApplyNowModal
                          clientdetails={clientdetails}
                          configAdmin={props.configAdmin}
                          coursedetail={p}
                          providerdetail={p}
                          setbackdropOpen={props.setbackdropOpen}
                          toast={props.toast}
                          applydetail={applydetail}
                          fromwhere={"coursesearch"}
                        />
                      )}
                      {/* <button
                      className="btn coursesearchbody_applynowbutton "
                      onClick={() => viewCourse(p)}
                    >
                      Apply Now
                    </button> */}
                    </div>
                  </div>
                </div>
              ))
            : props.backdropopen === false && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ marginTop: "50px" }}
                >
                  <img alt="No data Found" src={NoDataFound} />
                </div>
              )}
        </>
      ) : null}

      {props.length > 0 && (
        <div className="d-flex justify-content-between">
          <p className="coursesearchbody_counttext">
            {props.countnumber * 12 + 1} to{" "}
            {props.countnumber * 12 + props.length} of {props.totallength}{" "}
            results found
          </p>
          <div>
            <Pagination
              color="primary"
              count={props.getpageno}
              onChange={props.handlepage}
            />
          </div>
        </div>
      )}

      {/* Compare Box */}

      <div className="btm-comparebox">
        <div className="text-center">
          <span className="cursor-pointer">
            <i
              onClick={() => setToggle(!toggle)}
              className={toggle ? "fa fa-chevron-down" : "fa fa-chevron-up"}
              aria-hidden="true"
            ></i>
          </span>
        </div>

        {toggle && (
          <div className="container">
            <div className="row mb-3">
              <div className="col">
                <h4>Compare Products</h4>
              </div>
              <div className="col text-right">
                <button
                  class="btn btn-primary w bordered"
                  style={{ backgroundColor: "#fff3e8 !important" }}
                  onClick={clearall}
                >
                  Clear All
                </button>
              </div>
            </div>

            <div className="row align-items-end">
              {comparedata.map((item, index) => (
                <div className="col-3 col-md-2 mb-3 mb-md-0">
                  <div className="comparebox-item">
                    {item.branch_id ? (
                      <>
                        <DeleteIcon
                          className="comparebox-icon"
                          onClick={() => removecompare(item.id)}
                        />
                      </>
                    ) : null}
                    {/* <img alt="college_logo"
                      src={item.logo || NoLogoAvailable}
                      style={{ height: "100px" }}
                    /> */}
                    {item.logo == null ? (
                      <div style={{ fontSize: "x-small" }}>
                        {item.institution_name}
                      </div>
                    ) : (
                      <img
                        alt="college_logo"
                        src={`${attachmenturl}/attachments/providers/australia/${calculatePathName(
                          item.cricos
                        )}/${item.cricos}/${item.logo}`}
                        style={{ height: "100px" }}
                      />
                    )}
                  </div>
                </div>
              ))}
              {/* <div className="col-md-2">
              <div className="comparebox-item">
                <DeleteIcon className="comparebox-icon" />
                <img alt="college_logo" src={NoLogoAvailable} />
              </div>
            </div>
            <div className="col-md-2">
              <div className="comparebox-item">
                <DeleteIcon className="comparebox-icon" />
                <img alt="college_logo" src={NoLogoAvailable} />
              </div>
            </div>
            <div className="col-md-2">
              <div className="comparebox-item">
                <DeleteIcon className="comparebox-icon" />
                <img alt="college_logo" src={NoLogoAvailable} />
              </div>
            </div> */}
              <div className="col-md-4">
                <button
                  class="btn btn-green w-100 "
                  style={{ backgroundColor: "#00CA8E !important;" }}
                  onClick={() => props.navigate("/course-compare")}
                >
                  Compare
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Compare Box */}
    </div>
  );
};

export default CourseSearchBodyRight;
