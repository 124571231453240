import axios from "axios";
import React, { useState, useEffect } from "react";
import base_url from "../api/bootapi";
import Modal from "react-bootstrap/Modal";
import Typography from "@mui/material/Typography";

import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const AddtoCompareModal = (props) => {
  //console.log("coursedetail---------------->", props);
  const navigate = useNavigate();
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [alreadycomparedmodal, setalreadycomparedmodal] = useState(false);
  const location = useLocation();

  const handlealreadycomparedmodal = () =>
    setalreadycomparedmodal(!alreadycomparedmodal);

  useEffect(() => {
    props.getavailablecourses();
  }, []);

  const agentLoginCheck = JSON.parse(localStorage.getItem("agentLogin"));

  const clientLoginCheck = JSON.parse(localStorage.getItem("clientLogin"));

  const handlecomparechange = () => {
    props.setbackdropOpen(true);
    let value =
      props.comparecheck?.[
        `${props.coursedetail?.courseId}${props.coursedetail?.institution_id}`
      ];
    if (clientdetails?.token) {
      if (value) {
        props.setbackdropOpen(false);
        handlealreadycomparedmodal();
      } else {
        if (agentLoginCheck === 1) {
          axios
            .get(`${base_url.api5}/fetchCompareListByAgentId`, {
              headers: { agentId: clientdetails?.agentId },
            })
            .then((res) => {
              if (res.data.data.length <= 3) {
                addtocompare("agent");
                props.getavailablecourses();
                props.setbackdropOpen(false);
                // props.Sangamgetcomparelist();
              } else {
                props.getavailablecourses();
                props.setbackdropOpen(false);
                props.toast.error("Cant Add More than 4 course to compare!");
                // props.Sangamgetcomparelist();
              }
            })
            .catch((err) => addtocompare("agent"));
        } else if (clientLoginCheck === 1) {
          //console.log("coming in else");
          axios
            .get(`${base_url.api5}/fetchClientCompareListByClientId`, {
              headers: { clientId: clientdetails?.clientId },
            })
            .then((res) => {
              if (res.data.data.length <= 3) {
                addtocompare("client");
                props.setbackdropOpen(false);
                props.getavailablecourses();
                // props.Sangamgetcomparelist();
              } else {
                // props.getavailablecourses();
                props.setbackdropOpen(false);
                props.toast.error("Cant Add More than 4 course to compare!");
                // props.Sangamgetcomparelist();
              }
            })
            .catch((err) => addtocompare("client"));
        } else {
          axios
            .get(`${base_url.api5}/fetchClientCompareListByClientTempId`, {
              headers: {
                clientTempId: clientdetails?.clientId,
              },
            })
            .then((res) => {
              if (res.data.data.length <= 3) {
                // props.Sangamgetcomparelist();
                addtocompare("clienttemp");
                props.getavailablecourses();
                props.setbackdropOpen(false);
              } else {
                // props.Sangamgetcomparelist();
                props.setbackdropOpen(false);
                props.toast.error("Cant Add More than 4 course to compare!");
              }
            })
            .catch((err) => addtocompare("clienttemp"));
        }
      }
    } else {
      // props.Sangamgetcomparelist();
      props.getavailablecourses();
      props.setbackdropOpen(false);
      sessionStorage.setItem("prev_path_before_login", location.pathname);
      // navigate("/login");
      navigate("/force-login");
    }
  };

  const addtocompare = (forwhom) => {
    let comparecourse = `${props.coursedetail?.courseId}${props.coursedetail?.institution_id}`;
    props.setcomparecheck({
      ...props.comparecheck,
      [comparecourse]: true,
    });
    let data;
    if (forwhom === "agent") {
      data = [
        {
          agentId: clientdetails?.agentId,
          courseId: props.coursedetail?.courseId,
          branchId: props.coursedetail?.institution_id,
          id: 0,
        },
      ];
    } else if (forwhom === "client") {
      data = [
        {
          clientId: clientdetails?.clientId,
          courseId: props.coursedetail?.courseId,
          branchId: props.coursedetail?.institution_id,
          id: 0,
        },
      ];
    } else {
      data = [
        {
          clientTempId: clientdetails?.clientId,
          courseId: props.coursedetail?.courseId,
          branchId: props.coursedetail?.institution_id,
          id: 0,
        },
      ];
    }
    axios.post(`${base_url.api5}/addToCompare`, data).then((res) => {
      props.toast.success("List Added to Compare");
      props.setbackdropOpen(false);
      // props.getheader(props.pageno, props.columnName);
      // props.Sangamgetcomparelist();
    });
  };

  return (
    <>
      <div className="mt-4">
        <Button
          variant="outlined"
          size="large"
          fullWidth
          onClick={handlecomparechange}
        >
          <AddIcon />
          {props.comparecheck?.[
            `${props.coursedetail?.courseId}${props.coursedetail?.institution_id}`
          ] === true
            ? "Comparing!!"
            : " Add to Compare"}
        </Button>
        <Modal show={alreadycomparedmodal} onHide={handlealreadycomparedmodal}>
          <Modal.Header closeButton>
            <Modal.Title>Compared!!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Typography variant="h5" color="primary">
              Already Added to Compared List
            </Typography>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlealreadycomparedmodal}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => navigate("/course-compare")}
            >
              ComparedList
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default AddtoCompareModal;
