import React, { useState, useEffect } from "react";
import ApplyNowModal from "../Modal/ApplyNowModal";
import { Container, Row, Col, Card } from "reactstrap";
import base_url from "../api/bootapi";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Nologo from "./../../assets/images/nologo.png";
import "./ProviderHeader.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import EnvelopIcon from "@mui/icons-material/Email";

import Button from "@mui/material/Button";
import RoomOutlined from "@mui/icons-material/RoomOutlined";
import SchoolIcon from "@mui/icons-material/SchoolOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBookOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BoxIcon from "@mui/icons-material/WidgetsOutlined";
import StarIcon from "@mui/icons-material/Star";
import Offshoresfees from "../../assets/images/Offshoresfees.png";
import Onshoresfees from "../../assets/images/Onshoresfees.png";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import AddtoCompareModal from "./AddtoCompareModal";

const ProviderHeaderBody = (props) => {
  const navigate = useNavigate();
  const handlecontact = () => navigate("/contactus");

  const applydetail =
    JSON.parse(localStorage.getItem("isw_frontend_applycourse_detail")) || {};

  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  const [backdropopen, setbackdropOpen] = useState(false);
  const [courseDetail, setcourseDetail] = useState([]);
  const [comparedetailmodal, setcomparedetailmodal] = useState([]);

  const [comparecheck, setcomparecheck] = useState();
  const [getpageno, setgetpageno] = useState();
  const [length, setlength] = useState();
  const [totallength, settotallength] = useState();
  const [countnumber, setcountnumber] = useState();

  const handleerror = () => {
    setbackdropOpen(false);
    setlength(0);
    setgetpageno(0);
    settotallength(0);
    setcourseDetail([]);
  };

  useEffect(() => {
    getavailablecourses();
  }, []);

  const getavailablecourses = (headers) => {
    axios
      .get(`${base_url.api5}/getPopularCourses`, {
        headers: headers,
      })
      .then((res) => {
        setcourseDetail(res.data.data.content);
        let obj = {};
        let availablecourses = res.data.data.content;
        for (let i = 0; i < availablecourses.length; i++) {
          let key = `${availablecourses[i].courseId}${availablecourses[i].branchInstitutionId}`;
          obj[key] = false;
        }
        // let copiedobj = JSON.parse(JSON.stringify(obj));
        if (clientdetails?.token) {
          if (clientdetails?.agentId) {
            getcomparelistbyagentid(obj);
          } else {
            getcomparelist(obj);
          }
          // getbookmarklist(copiedobj);
        } else if (clientdetails?.clientId) {
          getcomparelist(obj);
        }
        setlength(res.data.data.numberOfElements);
        setgetpageno(res.data.data.totalPages);
        settotallength(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setbackdropOpen(false);
      })
      .catch((err) => handleerror());
  };

  const getcomparelistbyagentid = (obj) => {
    axios
      .get(`${base_url.api5}/fetchCompareListByAgentId`, {
        headers: {
          agentId: clientdetails?.agentId,
        },
      })
      .then((res) => {
        let comparearr = [];
        for (let i = 0; i < res.data.data.length; i++) {
          let comparemodalobj = {
            courseId: res.data.data[i].courseId,
            branchId: res.data.data[i].branchId,
          };
          let key = `${res.data.data[i].courseId}${res.data.data[i].branchId}`;
          if (obj.hasOwnProperty(key)) obj[key] = true;
          comparearr.push(comparemodalobj);
        }
        setcomparedetailmodal(comparearr);
        setbackdropOpen(false);
        setcomparecheck(obj);
      })
      .catch((err) => {
        setcomparecheck();
        setcomparedetailmodal([]);
        setbackdropOpen(false);
      });
  };

  const getcomparelist = (obj) => {
    if (clientdetails?.token) {
      axios
        .get(`${base_url.api5}/fetchClientCompareListByClientId`, {
          headers: {
            clientId: clientdetails?.clientId,
          },
        })
        .then((res) => {
          //console.log(res);
          let comparearr = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let comparemodalobj = {
              courseId: res.data.data[i].courseId,
              branchId: res.data.data[i].branchId,
            };
            let key = `${res.data.data[i].courseId}${res.data.data[i].branchId}`;
            if (obj.hasOwnProperty(key)) obj[key] = true;
            comparearr.push(comparemodalobj);
          }
          //console.log(comparearr);
          setcomparedetailmodal(comparearr);
          setbackdropOpen(false);
          setcomparecheck(obj);
        })
        .catch((err) => {
          setcomparecheck();
          setcomparedetailmodal([]);
          setbackdropOpen(false);
        });
    } else if (clientdetails?.clientId) {
      axios
        .get(`${base_url.api5}/fetchClientCompareListByClientTempId`, {
          headers: {
            clientTempId: clientdetails?.clientId,
          },
        })
        .then((res) => {
          //console.log(res);
          let comparearr = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let comparemodalobj = {
              courseId: res.data.data[i].courseId,
              branchId: res.data.data[i].branchId,
            };
            let key = `${res.data.data[i].courseId}${res.data.data[i].branchId}`;
            if (obj.hasOwnProperty(key)) obj[key] = true;
            comparearr.push(comparemodalobj);
          }
          //console.log(comparearr);
          setcomparedetailmodal(comparearr);
          setbackdropOpen(false);
          setcomparecheck(obj);
        })
        .catch((err) => {
          setcomparecheck();
          setcomparedetailmodal([]);
          setbackdropOpen(false);
        });
    }
  };

  return (
    <>
      <div className="provide-title">
        <Container fluid>
          <Row className="m-0">
            <Col>
              <div className="provide-breadcumbs">
                <span className="provide-breadcumbs-label">Institution</span>{" "}
                <span>
                  <ChevronRightIcon style={{ color: "#B2B8C6" }} />
                </span>{" "}
                {props.coursedetail?.institutionName
                  ? props.coursedetail?.institutionName.substring(
                      0,
                      props.coursedetail?.institutionName.lastIndexOf("-")
                    )
                  : "-"}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Container fluid>
          <Row className="m-0 mb-5">
            <Col md={8}>
              {/* Logo Title */}

              <Row className="mt-3">
                <Col md={2}>
                  <div className="coursedtl-mainlogo">
                    <img
                      alt="college_logo"
                      src={props.providerdetail?.logo || Nologo}
                    />
                  </div>
                </Col>
                <Col>
                  <h5>
                    {" "}
                    {props.coursedetail?.institutionName
                      ? props.coursedetail?.institutionName.substring(
                          0,
                          props.coursedetail?.institutionName.lastIndexOf("-")
                        )
                      : "-"}
                  </h5>
                  <p>{props.coursedetail?.levelName}</p>
                </Col>
              </Row>

              {/* Logo Title */}

              <Row>
                <Col className="mb-4">
                  <h4>Overview</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="coursedtl-overview-iconbox d-flex">
                    <div className="coursedtl-overview-icon">
                      <RoomOutlined />
                    </div>
                    <div className="coursedtl-overview-text">
                      <h6>Location</h6>
                      {props.coursedetail?.state},{props.coursedetail?.country}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="coursedtl-overview-iconbox d-flex">
                    <div className="coursedtl-overview-icon">
                      <SchoolIcon />
                    </div>
                    <div className="coursedtl-overview-text">
                      <h6>Study Area</h6>
                      {props.coursedetail?.areaName}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="coursedtl-overview-iconbox d-flex">
                    <div className="coursedtl-overview-icon">
                      <SchoolIcon />
                    </div>
                    <div className="coursedtl-overview-text">
                      <h6>Providers</h6>
                      {props.coursedetail?.institutionName
                        ? props.coursedetail?.institutionName.substring(
                            0,
                            props.coursedetail?.institutionName.lastIndexOf("-")
                          )
                        : "-"}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="coursedtl-overview-iconbox d-flex">
                    <div className="coursedtl-overview-icon">
                      <MenuBookIcon />
                    </div>
                    <div className="coursedtl-overview-text">
                      <h6>Study Level</h6>
                      {props.coursedetail?.levelName}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="coursedtl-overview-iconbox d-flex">
                    <div className="coursedtl-overview-icon">
                      <AccessTimeIcon />
                    </div>
                    <div className="coursedtl-overview-text">
                      <h6>Duration</h6>
                      {props.coursedetail?.duration}
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="coursedtl-overview-iconbox d-flex">
                    <div className="coursedtl-overview-icon">
                      <BoxIcon />
                    </div>
                    <div className="coursedtl-overview-text">
                      <h6>Cricos Course Code</h6>
                      {props.coursedetail?.courseCode}
                    </div>
                  </div>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col className="mb-4 mt-4">
                  <h4>Course Details</h4>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <div className="coursedtl-overview-iconbox d-flex">
                    <div className="coursedtl-overview-text m-0">
                      <h6>Rating</h6>
                      {/* <StarIcon style={{ fill: "#FEB018" }} /> 5.0 (324 rating) */}
                      <div style={{ color: "gold" }}>
                        {props.coursedetail?.courseRating ? (
                          <>
                            {Array(
                              Number(
                                props.coursedetail?.courseRating.split(".")[0]
                              )
                            )
                              .fill(1)
                              .map((item, index) => (
                                <i key={index} className="fa fa-star" />
                              ))}
                            {props.coursedetail?.courseRating.split(".")[1] && (
                              <i className="fa fa-star-half" />
                            )}
                            {props.coursedetail?.courseRating.split(".")[1] ? (
                              <>
                                {4 -
                                  Number(
                                    props.coursedetail?.courseRating.split(
                                      "."
                                    )[0]
                                  ) >
                                  0 &&
                                  Array(
                                    4 -
                                      Number(
                                        props.coursedetail?.courseRating.split(
                                          "."
                                        )[0]
                                      )
                                  )
                                    .fill(1)
                                    .map((item, index) => (
                                      <i key={index} className="fa fa-star-o" />
                                    ))}
                              </>
                            ) : (
                              <>
                                {5 -
                                  Number(
                                    props.coursedetail?.courseRating.split(
                                      "."
                                    )[0]
                                  ) ===
                                0
                                  ? null
                                  : Array(
                                      5 -
                                        Number(
                                          props.coursedetail?.courseRating.split(
                                            "."
                                          )[0]
                                        )
                                    )
                                      .fill(1)
                                      .map((item, index) => (
                                        <i
                                          key={index}
                                          className="fa fa-star-o"
                                        />
                                      ))}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {Array(5)
                              .fill(1)
                              .map((item, index) => (
                                <i key={index} className="fa fa-star-o" />
                              ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="coursedtl-overview-iconbox d-flex">
                    <div className="coursedtl-overview-text  m-0">
                      <h6>Cricos</h6>
                      {props.coursedetail?.cricos}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="coursedtl-overview-content">
                  <h6>Course Objective</h6>
                  {props.coursedetail?.courseObjective}
                </Col>
              </Row>

              <hr />

              <Row>
                <Col className="mt-3 coursedtl-overview-content">
                  <h4>Requirement</h4>
                  {props.coursedetail?.requirement}
                </Col>
              </Row>

              <hr />

              <Row>
                <Col className="mb-4 mt-3">
                  <h4>Fee</h4>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <div className="coursedtl-overview-iconbox d-flex">
                    <div className="coursedtl-overview-icon">
                      <img src={Offshoresfees} alt="Client 1" />
                    </div>
                    <div className="coursedtl-overview-text">
                      <h6>Offshores fee</h6>
                      <strong>$ {props.coursedetail?.offShoreFees}</strong>
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="coursedtl-overview-iconbox d-flex">
                    <div className="coursedtl-overview-icon">
                      <img src={Onshoresfees} alt="Client 1" />
                    </div>
                    <div className="coursedtl-overview-text">
                      <h6>Onshores fee</h6>
                      <strong>$ {props.coursedetail?.fees}</strong>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4}>
              <div className="coursedtl-intrestbox">
                <h4 className="mb-4">Interested in this course?</h4>

                <ApplyNowModal
                  clientdetails={props.clientdetails}
                  configAdmin={props.configAdmin}
                  coursedetail={props.coursedetail}
                  providerdetail={props.providerdetail}
                  setbackdropOpen={props.setbackdropOpen}
                  toast={props.toast}
                  applydetail={applydetail}
                  fromwhere={"coursedetail"}
                />

                <AddtoCompareModal
                  comparecheck={comparecheck}
                  setbackdropOpen={props.setbackdropOpen}
                  toast={props.toast}
                  setcomparecheck={setcomparecheck}
                  // getheader={props.getheader}
                  columnName={props.columnName}
                  pageno={props.pageno}
                  navigate={props.navigate}
                  getavailablecourses={getavailablecourses}
                  coursedetail={props.coursedetail}
                />
              </div>

              <div className="coursedtl-financialbox">
                <h4>Financial Assitance</h4>
                <p>Find out information about government assistance </p>
                <div className="coursedtl-contactlink">
                  <a href="#/contactus">
                    <EnvelopIcon /> Contact Us
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div>
        <div className="coursesearchbody_inputcontainer">
          <div className="coursecompare_bodymaincontainer">
            <div className="row mx-0 justify-content-between">
              <h1 className="text-white col-md-10">
                {props.providerdetail?.branchName
                  ? props.providerdetail?.branchName.substring(
                    0,
                    props.providerdetail?.branchName.lastIndexOf("-")
                  )
                  : "-"}
              </h1>
            </div>
          </div>
        </div>
        <div className="coursecompare_bodymaincontainer mt-4 px-0">
          <div className="row m-0">
            <div className="col-md-3 px-0">
              <div className="courseheaderbody_leftcontainer">
                <div>
                  <img
                    alt="college_logo"
                    src={props.providerdetail?.logo || Nologo}
                  />
                </div>
                <p>
                Information Technology is not just a pathway to your future;
                your degree could help you shape your future
              </p>
              </div>
            </div>
            <div className="col-md-9 pr-0">
              <div className="courseheaderbody_rightcontainer">
                <div className="courseheaderbody_shadowrightcontainer">
                  <h3 className="text-center text-white">Overviewsssss</h3>
                  <div className="mt-4">
                    <div className="row m-0">
                      <div className="col-md-6 d-flex">
                        <div className="courseheaderbody_icontainer">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div>
                          <p className="mb-0" style={{ fontSize: "20px" }}>
                            ABN :{" "}
                          </p>
                          <p>{props.providerdetail?.abn}</p>
                        </div>
                      </div>
                      <div className="col-md-6 d-flex">
                        <div className="courseheaderbody_icontainer">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div>
                          <p className="mb-0" style={{ fontSize: "20px" }}>
                            ACN :{" "}
                          </p>
                          <p>{props.providerdetail?.acn}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-md-6 d-flex">
                        <div className="courseheaderbody_icontainer">
                          <i className="fa fa-globe" />
                        </div>
                        <div>
                          <p className="mb-0" style={{ fontSize: "20px" }}>
                            Study Location :{" "}
                          </p>
                          <p>
                            {props.coursedetail?.state},
                            {props.coursedetail?.country}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 d-flex">
                        <div className="courseheaderbody_icontainer">
                          <i className="fa fa-globe" />
                        </div>
                        <div>
                          <p className="mb-0" style={{ fontSize: "20px" }}>
                            Study Area :{" "}
                          </p>
                          <p>{props.coursedetail?.areaName}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-md-6 d-flex">
                        <div className="courseheaderbody_icontainer">
                          <i className="fa fa-university" />
                        </div>
                        <div>
                          <p className="mb-0" style={{ fontSize: "20px" }}>
                            Provider :{" "}
                          </p>
                          <p>
                            {props.providerdetail?.branchName
                              ? props.providerdetail?.branchName.substring(
                                0,
                                props.providerdetail?.branchName.lastIndexOf(
                                  "-"
                                )
                              )
                              : "-"}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 d-flex">
                        <div className="courseheaderbody_icontainer">
                          <i className="fa fa-university" />
                        </div>
                        <div>
                          <p className="mb-0" style={{ fontSize: "20px" }}>
                            Provider Code :{" "}
                          </p>
                          <p>{props.providerdetail?.providerCode}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ProviderHeaderBody;
