import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Footer from "../../Footer/Footer";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PasswordStrengthBar from "react-password-strength-bar";
import axios from "axios";
import base_url from "../../api/bootapi";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import ReactNavbar from "../../Navbar/Navbar";
import ReCAPTCHA from "react-google-recaptcha";
import SelectCurrency from "react-select-currency";
import NavbarNew from "../../Navbar/NavbarNew";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const AgentRegister = () => {
  const classes = useStyles();
  const [backdropopen, setbackdropOpen] = useState(false);
  const [success, setsuccess] = useState(false);
  const [inputtype, setinputtype] = useState("password");
  const [confirminputtype, setconfirminputtype] = useState("password");
  const [countryrequired, setcountryrequired] = useState(false);
  const [captchaerror, setcaptchaerror] = useState(false);
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();
  const [chkPass, setchkPass] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const [chkPassState, setchkPassState] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();
  const pageStartRef = useRef(null);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [captchavalue, setcaptchavalue] = useState("");
  const [baseimage, setbaseimage] = useState("");
  const [currency1, setcurrency] = useState("");
  const selectcurrency = (e) => setcurrency(e.target.value);
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    if (clientdetails?.clientId) {
      navigate("/");
    }
    pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handlePassword = (e) => {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (e.target.value.match(decimal)) {
      setchkPass(false);
      setchkPassState(e.target.value);
      return true;
    } else {
      setchkPass(true);
      return false;
    }
  };

  const selectCountry = (val) => {
    setcountry(val);
    setValue("abn", "");
    setValue("acn", "");
    setValue("can", "");
    setValue("pan", "");
  };
  const selectRegion = (val) => {
    setregion(val);
  };

  const onChangeCaptcha = (value) => setcaptchavalue(value);

  const uploadLogo = async (data) => {
    const file = data.target.files[0];
    const base64 = await convertBase64(file);
    setbaseimage(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleregister = (data2) => {
    if (!country1) {
      setcountryrequired(true);
      return;
    }
    setcountryrequired(false);
    // if (!captchavalue) {
    //   setcaptchaerror(true);
    //   return;
    // }
    setcaptchaerror(false);
    if (data2.confirmPassword !== chkPassState) return;
    setbackdropOpen(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let data = {
      abn: data2.abn,
      acn: data2.acn,
      addedOn: datenow,
      address: data2.street,
      agentId: 0,
      agentUniversityName: data2.university,
      can: data2.can,
      city: data2.city,
      country: country1,
      currency: currency1 || "AUD",
      commissionPercentage: data2.commissionPercentage,
      contactPerson: data2.contactPerson,
      designation: data2.designation,
      email: data2.email.trim(),
      emailVerificationStatus: 0,
      lattitude: data2.latitude,
      photo: baseimage,
      password: chkPass === false ? chkPassState.trim() : null,
      longitude: data2.longitude,
      mobile: data2.mobile,
      name: data2.name,
      overview: data2.overview,
      pan: data2.pan,
      phone: data2.phoneNumber,
      remark: data2.remarks,
      referalPercentage: data2.referalPercentage,
      state: region || null,
      status: 1,
      subAgent: 1,
      superAgent: 0,
      username: data2.email.trim(),
      updatedOn: datenow,
      vatNumber: data2.vat,
      website: data2.website,
      zipCode: data2.zipCode,
    };
    postregister(data);
  };

  const postregister = (data) => {
    axios
      .post(`${base_url.api5}/addNewAgent`, data)
      .then((res) => {
        toast.success("Agent Registered Successfully");
        setbackdropOpen(false);
        setsuccess(true);
        document.getElementById("myForm").reset();
      })
      .catch((err) => {
        setbackdropOpen(false);
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const gotologin = () => navigate("/agent-login");
  return (
    <div ref={pageStartRef}>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavbarNew />
      <div className="coursecompare_bodymaincontainer login my-4 agent-register-main">
        <h2 className="text-center mb-1">Create A Free Agent Account in</h2>
        <h2 className="text-center mb-5">International Student World</h2>
        <form
          id="myForm"
          onSubmit={handleSubmit(handleregister)}
          className="login_container"
        >
          <h4>PERSONAL DETAILS</h4>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("name", { required: true })}
                  className="form-control"
                  placeholder="Name"
                />
                <i className="fa fa-user-o designi" />
              </div>
              {errors.name && (
                <p className="errormessage mb-0">Name is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("email", {
                    required: true,
                    pattern:
                      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/,
                  })}
                  className="form-control"
                  placeholder="e-mail address"
                  autoComplete="new-email"
                />
                <i className="fa fa-envelope-o designi" />
              </div>
              {errors.email?.type === "required" && (
                <p className="errormessage">E-Mail is required</p>
              )}
              {errors.email?.type === "pattern" && (
                <p className="errormessage">Enter valid E-Mail</p>
              )}
            </div>
            <div className="col-md-3">
              <label>
                Password<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={inputtype}
                  {...register("password", { required: true })}
                  className="form-control"
                  autoComplete="new-password"
                  onChange={handlePassword}
                  placeholder="Password"
                />
                <i className="fa fa-lock designi" />
                {inputtype === "password" ? (
                  <i
                    className="fa fa-eye-slash passwordiconpositioning"
                    onClick={() => setinputtype("text")}
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye passwordiconpositioning"
                    onClick={() => setinputtype("password")}
                    aria-hidden="true"
                  ></i>
                )}
                <PasswordStrengthBar password={chkPassState} />
              </div>
              {errors.password && (
                <p className="errormessage mb-0">Password is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>
                Confirm Password<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  type={confirminputtype}
                  {...register("confirmPassword", { required: true })}
                  onChange={(e) => setconfirmpassword(e.target.value)}
                  className="form-control"
                  placeholder="Confirm Password"
                />
                <i className="fa fa-lock designi" />
                {confirminputtype === "password" ? (
                  <i
                    className="fa fa-eye-slash fonticonpositioning"
                    onClick={() => setconfirminputtype("text")}
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye fonticonpositioning"
                    onClick={() => setconfirminputtype("password")}
                    aria-hidden="true"
                  ></i>
                )}
              </div>
              {errors.confirmPassword && (
                <p className="errormessage mb-0">
                  Confirm Password is required
                </p>
              )}
              {confirmpassword && confirmpassword !== chkPassState && (
                <p className="errormessage mb-0">
                  Password not matching or strong password needed
                </p>
              )}
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>Website</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("website")}
                  className="form-control"
                  type="url"
                  placeholder="Website"
                />
                <i className="fa fa-internet-explorer designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Logo</label>
              <div style={{ position: "relative" }}>
                <input
                  className="form-control"
                  type="file"
                  onChange={(data) => {
                    uploadLogo(data);
                  }}
                  accept=".jpg, .jpeg, .bmp, .gif, .png"
                />
                <i className="fa fa-paperclip designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>VAT/GST</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("vat")}
                  className="form-control"
                  placeholder="VAT/GST"
                />
                <i className="fa fa-info-circle designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Currency</label>
              <div style={{ position: "relative" }}>
                <SelectCurrency
                  value={currency1}
                  onChange={selectcurrency}
                  className="form-control"
                />
                <i className="fa fa-usd designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>Contact Person</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("contactPerson")}
                  className="form-control"
                  placeholder="Contact Person"
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Designation</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("designation")}
                  className="form-control"
                  placeholder="Designation"
                />
                <i className="fa fa-clipboard designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Phone</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("phoneNumber")}
                  className="form-control"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Phone Number"
                />
                <i className="fa fa-volume-control-phone designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Mobile</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("mobile")}
                  className="form-control"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Mobile"
                />
                <i className="fa fa-volume-control-phone designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>Commission Percentage</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("commissionPercentage")}
                  className="form-control"
                  type="number"
                  placeholder="Commission Percentage"
                />
                <i className="fa fa-usd designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Referal Percentage</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("referalPercentage")}
                  className="form-control"
                  type="number"
                  placeholder="Referal Percentage"
                />
                <i className="fa fa-usd designi" />
              </div>
            </div>
          </div>
          <h4>CONTACT DETAILS</h4>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>Address</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("street")}
                  className="form-control"
                  placeholder="Address"
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>
                Country<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <CountryDropdown
                  className="form-control selecttag2"
                  value={country1}
                  onChange={(val) => selectCountry(val)}
                />
                <i className="fa fa-globe designi" />
              </div>
              {countryrequired && (
                <p className="errormessage mb-0">Country is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>State</label>
              <div style={{ position: "relative" }}>
                {country1 === "Nepal" ? (
                  <select
                    className="form-control selecttag2"
                    onChange={(e) => selectRegion(e.target.value)}
                  >
                    <option value="">Select Province</option>
                    <option value="Province No. 1">Province No. 1</option>
                    <option value="Madhesh Province">Madhesh Province</option>
                    <option value="Bagmati Province">Bagmati Province</option>
                    <option value="Gandaki Province">Gandaki Province</option>
                    <option value="Lumbini Province">Lumbini Province</option>
                    <option value="Karnali Province">Karnali Province</option>
                    <option value="Sudurpashchim Province">
                      Sudurpashchim Province
                    </option>
                  </select>
                ) : (
                  <RegionDropdown
                    className="form-control selecttag2"
                    defaultOptionLabel="Select State"
                    country={country1}
                    value={region}
                    onChange={(val) => selectRegion(val)}
                  />
                )}
                <i className="fa fa-globe designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>City</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("city")}
                  className="form-control"
                  placeholder="City"
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col">
              <label>Latitude</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("latitude")}
                  className="form-control"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Latitude"
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
            <div className="col">
              <label>Longitude</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("longitude")}
                  className="form-control"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Longitude"
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
            {country1 === "Australia" ? (
              <div className="col-md-6 row mx-0">
                <div className="col pl-0">
                  <label>ABN</label>
                  <div style={{ position: "relative" }}>
                    <input
                      {...register("abn")}
                      className="form-control"
                      placeholder="ABN"
                    />
                    <i className="fa fa-info-circle designi" />
                  </div>
                </div>
                <div className="col pr-0">
                  <label>ACN</label>
                  <div style={{ position: "relative" }}>
                    <input
                      {...register("acn")}
                      className="form-control"
                      placeholder="ACN"
                    />
                    <i className="fa fa-info-circle designi" />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {country1 === "Canada" ? (
                  <div className="col">
                    <label>CAN</label>
                    <div style={{ position: "relative" }}>
                      <input
                        {...register("can")}
                        className="form-control"
                        placeholder="CAN"
                      />
                      <i className="fa fa-info-circle designi" />
                    </div>
                  </div>
                ) : (
                  <>
                    {country1 === "United States" ? (
                      <div className="col">
                        <label>ACN</label>
                        <div style={{ position: "relative" }}>
                          <input
                            {...register("acn")}
                            className="form-control"
                            placeholder="ACN"
                          />
                          <i className="fa fa-info-circle designi" />
                        </div>
                      </div>
                    ) : (
                      <div className="col">
                        <label>PAN</label>
                        <div style={{ position: "relative" }}>
                          <input
                            {...register("pan")}
                            className="form-control"
                            placeholder="PAN"
                          />
                          <i className="fa fa-info-circle designi" />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>PostCode</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("zipCode")}
                  className="form-control"
                  placeholder="PinCode"
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
            <div className="col-md-9">
              <label>Remarks</label>
              <div style={{ position: "relative" }}>
                <textarea
                  {...register("remarks")}
                  className="form-control"
                  placeholder="Remarks"
                />
                <i className="fa fa-comment-o designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>University</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("university")}
                  className="form-control"
                  placeholder="University"
                />
                <i className="fa fa-university designi" />
              </div>
            </div>
            <div className="col-md-9">
              <label>Overview</label>
              <div style={{ position: "relative" }}>
                <textarea
                  {...register("overview")}
                  className="form-control"
                  placeholder="Overview"
                />
                <i className="fa fa-comment-o designi" />
              </div>
            </div>
          </div>

          {/* <div>
            <ReCAPTCHA
              sitekey="6LeowfQcAAAAAFC97vZ7g695Sv5aUbk5ciTDU49m"
              onChange={onChangeCaptcha}
            />
            {captchaerror && (
              <p className="errormessage mb-0">Captcha is required</p>
            )}
          </div> */}
          {success ? (
            <div className="alert alert-success text-center mt-2">
              <strong>Success! </strong>
              Agent Registered Successfully. Verification Link sent to
              registered Mail
            </div>
          ) : (
            <div className="row">
              <div className="col-md-4">{null}</div>
              <div className="col-md-4">
                <button type="submit" className="btn">
                  SIGN UP
                </button>
              </div>
              <div className="col-md-4">{null}</div>
            </div>
          )}
          <p className="text-center mt-4">
            Already have account? <span onClick={gotologin}>Sign In</span>
          </p>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default AgentRegister;
