import React, { useState, useEffect } from "react";
// import CourseCompareLowerBody from "./CourseCompareLowerBody";
import axios from "axios";
import base_url from "../api/bootapi";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoComparison from "../../assets/images/downlogo.png";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import ApplyNowModal from "../Modal/ApplyNowModal";
import Tooltip from "@mui/material/Tooltip";
import NoLogoAvailable from "./../../assets/images/nologo.png";
import CourseCompareRequirementModal from "../Modal/CourseCompareRequirementModal";
import attachmenturl from "../api/attachmenturl";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#000000", // Black color
  },
  tooltip: {
    backgroundColor: "#000000", // Black color
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const CourseCompareNew = () => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [backdropopen, setbackdropOpen] = useState(false);
  const [comparedata, setcomparedata] = useState([]);
  // const [comparedatalength, setcomparedatalength] = useState(0);
  const [configAdmin, setConfigAdmin] = useState(false);
  // const [clienttempdata, setclienttempdata] = useState();
  const classes = useStyles();
  let navigate = useNavigate();
  const applydetail =
    JSON.parse(localStorage.getItem("isw_frontend_applycourse_detail")) || {};
  const [classname, setclassname] = useState();

  const agentLoginCheck = JSON.parse(localStorage.getItem("agentLogin"));

  const clientLoginCheck = JSON.parse(localStorage.getItem("clientLogin"));
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    if (agentLoginCheck === 1) {
      getcomparelistbyagent();
    } else if (clientLoginCheck === 1) {
      getcomparelist();
    } else {
      getcomparelistbylead();
    }
    // getConfigData();
  }, []);

  // const getConfigData = () => {
  //   axios
  //     .get(`${base_url.api5}/getByConfigKey`, {
  //       headers: {
  //         configKey: "iswonline_consultant",
  //       },
  //     })
  //     .then((res) => setConfigAdmin(res.data.data.configValue));
  // };

  // const getclienttemp = (value) => {
  //     axios
  //       .get(`${base_url.api5}/getClientTempById`, {
  //         headers: {
  //           clientTempId: value,
  //         },
  //       })
  //       .then((res) => setclienttempdata(res.data.data));
  // };

  const getcomparelistbyagent = () => {
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getCompareListByAgentId`, {
        headers: {
          agentId: clientdetails?.agentId,
          columnName: "id",
          direction: "DESC",
          page: 0,
          size: 20,
        },
      })
      .then((res) => {
        let data = res.data.data;
        // setcomparedatalength(data.length);
        // if (res.data.data.length === 1) data.push({});
        for (let i = res.data.data.length; i <= 4; i++) {
          data.push({});
        }
        let classnumber = 12 / Number(res.data.data.length);
        setclassname(`col-md-${classnumber}`);
        setcomparedata(data);
        setbackdropOpen(false);
      })
      .catch((err) => {
        setbackdropOpen(false);
        setcomparedata([]);
      });
  };
  const getcomparelist = () => {
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getClientCompareListByClientId`, {
        headers: {
          clientId: clientdetails?.clientId,
          columnName: "id",
          direction: "DESC",
          page: 0,
          size: 20,
        },
      })
      .then((res) => {
        let data = res.data.data;
        // setcomparedatalength(data.length);
        // if (res.data.data.length === 1) data.push({});
        for (let i = res.data.data.length; i < 4; i++) {
          data.push({});
        }
        let classnumber = 12 / Number(res.data.data.length);
        setclassname(`col-md-${classnumber}`);
        setcomparedata(data);
        setbackdropOpen(false);
      })
      .catch((err) => {
        setbackdropOpen(false);
        setcomparedata([]);
      });
  };
  const getcomparelistbylead = () => {
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getClientCompareListByClientTempId`, {
        headers: {
          clientTempId: clientdetails?.clientId,
          columnName: "id",
          direction: "DESC",
          page: 0,
          size: 20,
        },
      })
      .then((res) => {
        let data = res.data.data;
        // setcomparedatalength(data.length);
        // if (res.data.data.length === 1) data.push({});
        for (let i = res.data.data.length; i <= 4; i++) {
          data.push({});
        }
        let classnumber = 12 / Number(res.data.data.length);
        setclassname(`col-md-${classnumber}`);
        setcomparedata(data);
        setbackdropOpen(false);
      })
      .catch((err) => {
        setbackdropOpen(false);
        setcomparedata([]);
      });
  };

  const removecompare = (id) => {
    if (!id) {
      return;
    }
    setbackdropOpen(true);
    axios
      .delete(`${base_url.api5}/deleteCompareById`, {
        headers: {
          compareId: id,
        },
      })
      .then((res) => {
        toast.success("Compare list deleted");
        if (agentLoginCheck === 1) {
          getcomparelistbyagent();
        } else if (clientLoginCheck === 1) {
          getcomparelist();
        } else {
          getcomparelistbylead();
        }
      });
  };

  const clearall = () => {
    setbackdropOpen(true);
    let arr = [];
    comparedata.forEach((element) => arr.push(element.id));
    let delete_str = arr.join(",");
    axios
      .delete(`${base_url.api5}/deleteCompareById`, {
        headers: {
          compareId: delete_str,
        },
      })
      .then((res) => {
        toast.success("Compare list cleared");
        if (agentLoginCheck === 1) {
          getcomparelistbyagent();
        } else if (clientLoginCheck === 1) {
          getcomparelist();
        } else {
          getcomparelistbylead();
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went Wrong!!");
        }
      });
  };

  const viewCourse = (p) => {
    if (!p.name) return;
    var courseName = p.name.toLocaleLowerCase();
    var courseId = p.course_id;
    let course = {
      courseId: p.course_id,
      branchId: p.branch_id,
    };
    localStorage.setItem("courseId", JSON.stringify(course));
    let param = courseName.split(" ").join("-") + `-${courseId}`;
    navigate("/course-detail/" + param);
  };

  //console.log("comparedata.length ------->", comparedata.length);

  const [level, setlevel] = useState([]);
  const [area, setarea] = useState([]);
  const [city, setcity] = useState([]);
  const [country1, setcountry] = useState("Australia");
  const [selectedCountry, setSelectedCountry] = useState("Australia");
  const allowedCountries = [
    "Australia",
    "United States",
    "United Kingdom",
    "Canada",
  ];

  const [levelcheck, setlevelcheck] = useState(true);
  const [locationcheck, setlocationcheck] = useState(true);
  const [countrycheck, setcountrycheck] = useState(true);
  const [areacheck, setareacheck] = useState(true);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    setlevel(data);
    getLevel();
    getArea();
    getcity("Australia");
    localStorage.setItem("countryvalue", "Australia");
  }, []);

  const getcity = (val) => {
    axios
      .get(`${base_url.api5}/findCityByCountry`, {
        headers: { country: val },
      })
      .then((res) => setcity(res.data.data))
      .catch((err) => setcity([]));
  };

  const getLevel = () => {
    axios
      .get(`${base_url.api5}/getCourseCountGroupByLevel`)
      .then((res) => {
        setlevel(res.data.data);
        //console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const data = [
    // {
    //   "courseLevelCount": 21,
    //   "levelId": 20,
    //   "levelName": "Certificate I"
    // },
    {
      courseLevelCount: 166,
      levelId: 14,
      levelName: "Certificate II",
    },
    {
      courseLevelCount: 1758,
      levelId: 13,
      levelName: "Certificate III",
    },
    {
      courseLevelCount: 2106,
      levelId: 12,
      levelName: "Certificate IV",
    },
    {
      courseLevelCount: 3678,
      levelId: 11,
      levelName: "Diploma",
    },
    {
      courseLevelCount: 1617,
      levelId: 10,
      levelName: "Advanced Diploma",
    },
    {
      courseLevelCount: 205,
      levelId: 4,
      levelName: "Associate Degree",
    },
    {
      courseLevelCount: 4172,
      levelId: 1,
      levelName: "Bachelor Degree",
    },
    {
      courseLevelCount: 1518,
      levelId: 8,
      levelName: "Bachelor Honours Degree",
    },
    {
      courseLevelCount: 1005,
      levelId: 5,
      levelName: "Graduate Certificate",
    },
    {
      courseLevelCount: 1350,
      levelId: 9,
      levelName: "Graduate Diploma",
    },
    {
      courseLevelCount: 3383,
      levelId: 7,
      levelName: "Masters Degree (Coursework)",
    },
    {
      courseLevelCount: 867,
      levelId: 2,
      levelName: "Masters Degree (Research)",
    },
    {
      courseLevelCount: 49,
      levelId: 16,
      levelName: "Masters Degree (Extended)",
    },
    {
      courseLevelCount: 1090,
      levelId: 3,
      levelName: "Doctoral Degree",
    },
    {
      courseLevelCount: 389,
      levelId: 15,
      levelName: "Senior Secondary Certificate of Education",
    },
    {
      courseLevelCount: 1745,
      levelId: 6,
      levelName: "Non AQF Award",
    },
  ];

  const getArea = () => {
    axios
      .get(`${base_url.api5}/getCourseCountGroupByArea`)
      .then((res) => setarea(res.data.data))
      .catch((err) => console.log(err));
  };
  const selectCountry = (val) => {
    localStorage.setItem("countryvalue", `${val}`);
    getcity(val);
    setcountry(val);
    localStorage.removeItem("cityvalue");
  };

  const handleCountryChange = (e) => {
    let val = e.target.value;
    localStorage.setItem("countryvalue", `${val}`);
    getcity(val);
    setcountry(val);
    setSelectedCountry(val);
    localStorage.removeItem("cityvalue");
  };

  const handlechangecity = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementValue = optionElement.getAttribute("value");
    handlecitychange(optionElementValue.trim());
  };

  // Handle changes to the select input
  const handleSelectChange = (event) => {
    handlecitychange(event.target.value);
  };

  const handlechangelevel = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const optionElementId = optionElement.getAttribute("id");
    const optionElementValue = optionElement.getAttribute("value");
    let arr = [optionElementValue];
    localStorage.setItem("levelname", JSON.stringify(arr));
    localStorage.setItem("level", optionElementId);
    //console.log("Level Change in course-compare", optionElementId);
  };

  const fieldOptions = area.map((item) => ({
    value: item.area_name,
    label: item.area_name,
    id: item.area_id,
  }));

  const handlechangearea = (selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      setSelectedOption(selectedOption);

      localStorage.setItem("areaname", JSON.stringify([selectedOption.value]));
      localStorage.setItem("area", selectedOption.id);
    } else {
      localStorage.removeItem("areaname");
      localStorage.removeItem("area");
    }
  };

  // const handlechangearea = (event) => {
  //   const index = event.target.selectedIndex;
  //   const optionElement = event.target.childNodes[index];
  //   const optionElementId = optionElement.getAttribute("id");
  //   const optionElementValue = optionElement.getAttribute("value");
  //   let arr = [optionElementValue];
  //   localStorage.setItem("areaname", JSON.stringify(arr));
  //   localStorage.setItem("area", optionElementId);
  // };

  const handlecitychange = (val) => localStorage.setItem("cityvalue", `${val}`);

  const submitcourse = (e) => {
    // localStorage.setItem("PopularState", 0);
    let countryvalue = e.target[0].value;
    // let cityvalue = e.target[1].value;
    let levelvalue = e.target[2].value;
    let coursevalue = selectedOption;
    if (!countryvalue || !levelvalue || !coursevalue) {
      if (!countryvalue) {
        setcountrycheck(false);
        setlocationcheck(true);
        setlevelcheck(true);
        setareacheck(true);
      } else if (!levelvalue) {
        setcountrycheck(true);
        setlevelcheck(false);
        setlocationcheck(true);
        setareacheck(true);
      } else if (!coursevalue) {
        setcountrycheck(true);
        setareacheck(false);
        setlocationcheck(true);
        setlevelcheck(true);
      }
    } else {
      navigate("/course-searchresult");
    }
  };

  const goback = () => {
    navigate("/course-searchresult");
  };

  //console.log("compareData---->", comparedata);
  function calculatePathName(cricosId) {
    // Extract the numeric part of the alphanumeric ID, assuming it's at the start
    const numericPart = cricosId.match(/^\d+/);
    const numericId = numericPart ? parseInt(numericPart[0]) : 0;

    // Calculate the lower and upper bounds for each range
    const lowerBound = Math.floor((numericId - 1) / 500) * 500 + 1;
    const upperBound = Math.min(lowerBound + 499, 10000); // Adjust the upper limit as needed

    // Format the lower and upper bounds as strings with leading zeros
    const lowerBoundStr = String(lowerBound).padStart(5, "0");
    const upperBoundStr = String(upperBound).padStart(5, "0");

    // Construct the path name
    const pathName = `${lowerBoundStr}-${upperBoundStr}`;

    return pathName;
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="coursesrc-title">
        <div className="container homepage_selectioncontainer mt-5 innerpage-selectioncontainer">
          <h1 className="text-center">Search, compare &amp; apply </h1>
          <div className="home-form" style={{ padding: "4px 4px" }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitcourse(e);
              }}
              class="homepage_formcontainer"
            >
              <div class="row mx-0">
                <div class="col">
                  <div style={{ position: "relative" }}>
                    <select
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      className="form-control selecttag countrydropdown2"
                    >
                      {allowedCountries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                  {!countrycheck && (
                    <p className="homepageerror">Country is Required</p>
                  )}
                </div>
                <div class="col">
                  {city.length > 0 ? (
                    // <select
                    //   onChange={handlechangecity}
                    //   className="form-control selecttag"
                    // >
                    //   <option value="">Select City</option>
                    //   {city.map((item, index) => (
                    //     <option key={index} value={item.city}>
                    //       {item.city}
                    //     </option>
                    //   ))}
                    // </select>
                    <select
                      onChange={handleSelectChange}
                      className="form-control selecttag"
                    >
                      <option value="">Select Region</option>
                      <option value="ACT">ACT (inc. Canberra)</option>
                      <option value="NSW">NSW (inc. Sydney)</option>
                      <option value="NT">NT (inc. Darwin)</option>
                      <option value="QLD">QLD (inc. Brisbane)</option>
                      <option value="SA">SA (inc. Adelaide)</option>
                      <option value="TAS">TAS (inc. Hobart)</option>
                      <option value="VIC">VIC (inc. Melbourne)</option>
                      <option value="WA">WA (inc. Perth)</option>
                    </select>
                  ) : (
                    <input
                      className="form-control"
                      placeholder="Enter Region"
                      onChange={(e) => handlecitychange(e.target.value)}
                    />
                  )}
                  {!locationcheck && (
                    <p className="homepageerror">Region is Required</p>
                  )}
                </div>
                <div class="col">
                  <div style={{ position: "relative" }}>
                    <select
                      onChange={handlechangelevel}
                      className="form-control selecttag"
                    >
                      <option value="">Select Study Level</option>
                      {level.map((item, index) => (
                        <option
                          key={index}
                          value={item.levelName}
                          id={item.levelId}
                        >
                          {item.levelName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {!levelcheck && (
                    <p className="homepageerror">Study Level is Required</p>
                  )}
                </div>
                <div class="col">
                  <div style={{ position: "relative" }}>
                    <Select
                      value={selectedOption}
                      onChange={handlechangearea}
                      options={fieldOptions}
                      placeholder="Select Study Field"
                      style={customSelectStyles}
                    />
                  </div>
                  {!areacheck && (
                    <p className="homepageerror">Study Field is Required</p>
                  )}
                </div>
                <div class="col" style={{ padding: "0" }}>
                  <button type="submit" class="btn home-form-btn">
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="coursesearchbody_inputcontainer d-none">
        <h1 className="text-white text-center">COURSE COMPARISON2</h1>
      </div>
      {comparedata.length > 0 && (
        <div className="mt-5 title-n-clear">
          <h2 className="text-center">
            Compare up to 4 courses, choose and apply
          </h2>
          <div className="compare-clearsearch">
            <button
              className="btn coursesearchbody_comparetext"
              onClick={clearall}
            >
              Clear all
            </button>
          </div>
          <div className="clearfix"></div>
          {/* <h5 className="text-center">
            You have {comparedatalength} in your shortlist
          </h5> */}
        </div>
      )}
      {comparedata.length > 0 ? (
        <div
          className={
            classname === "col-md-12"
              ? "container"
              : "coursecompare_bodymaincontainernew"
          }
        >
          {/* <div
            className="d-flex justify-content-end"
            style={{ marginRight: "20px", marginTop: "20px" }}
          >
            <button
              className="btn coursesearchbody_comparetext"
              onClick={clearall}
            >
              Clear all
            </button>
          </div> */}
          {/* <div style={{ display: "flex", margin: "0 20px" }}> */}
          <div className="compare-headlines">
            <h5>Course Information</h5>
          </div>

          <div className="coursecomparenew_tableparentcontainer">
            {comparedata.map((item, index) => (
              <>
                {item.branch_id ? (
                  <div
                    key={index}
                    // className="coursecomparenew_tablecontainer"
                    className="comparenew-row-colm"
                    style={{
                      flex: ".25",
                      maxWidth: "25%",
                      minWidth: "25%",
                      //padding: "10px",
                      // backgroundColor: index % 2 === 0 ? "#f6f8f9" : "#ffffff",
                    }}
                  >
                    <div
                      className={`courseproviderinsidecontainernew`}
                      key={index}
                    >
                      {/* <div className="text-right">
                    <DeleteIcon
                      className="cssfacross"
                      onClick={() => removecompare(item.id)}
                    />
                  </div> */}
                      <div>
                        <div className="row m-0">
                          {/* <h5 className="col-md-6 px-0" style={{ color: "#7de1c3" }}>
                    NAME:
                  </h5> */}
                          <h4 className="col-md-10 px-0 coursecompare_nameellipsis ">
                            {item.institution_name}
                          </h4>
                          <div className="col-md-2 d-flex justify-content-end px-0">
                            <DeleteIcon
                              style={{
                                fill: "#DE575",
                                color: "#DE5753",
                                width: "18px",
                                cursor: "pointer",
                              }}
                              //className="cssfacross"
                              onClick={() => removecompare(item.id)}
                            />
                          </div>
                        </div>
                        <div className="row m-0">
                          {/* <h5 className="col-md-6 px-0" style={{ color: "#7de1c3" }}>
                    LOCATION:
                  </h5> */}
                          <h6 className="col-md-12 px-0 coursecompare_nameellipsis ">
                            {item.city || "-"}
                          </h6>
                        </div>
                        <div className="row m-0">
                          {/* <h5 className="col-md-6 px-0" style={{ color: "#7de1c3" }}>
                    CAMPUS:
                  </h5> */}
                          <h6 className="col-md-12 px-0 coursecompare_nameellipsis ">
                            {item.branch_addres || "-"}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex compare-logobox">
                      <BootstrapTooltip title={item.branch_name?.split("-")[0]}>
                        {/* <img
                          alt="provider_logo"
                          src={`https://api.intstudentworld.com/attachments/providers/australia/${calculatePathName(item.cricos)}/${item.cricos}/${item.logo}` || NoLogoAvailable}
                          style={{ height: "50px", width: "150px" }}
                        // style={{ height: "70px", width: "150px" }}
                        /> */}
                        {item.logo == null ? (
                          <img
                            alt="college_logo"
                            src={NoLogoAvailable}
                            style={{ height: "80px", width: "80px" }}
                          />
                        ) : (
                          <img
                            alt="college_logo"
                            src={`${attachmenturl}/attachments/providers/australia/${calculatePathName(
                              item.cricos
                            )}/${item.cricos}/${item.logo}`}
                            style={{ height: "80px", width: "80px" }}
                          />
                        )}
                      </BootstrapTooltip>
                    </div>
                    <div className="compare-topbox-text">
                      <BootstrapTooltip title={item.cricos}>
                        <p>
                          <b>Provider Code :-</b>
                          {item.cricos}
                        </p>
                      </BootstrapTooltip>
                    </div>

                    <div className="compare-topbox-text">
                      <BootstrapTooltip
                        title={
                          item.name ? `${item.name} - ${item.cricos}` : "-"
                        }
                      >
                        <p
                          className="navigatetocourse"
                          onClick={() => viewCourse(item)}
                        >
                          {item.name}
                        </p>
                      </BootstrapTooltip>
                    </div>

                    <div className="compare-topbox-btm">
                      <div className="row mx-0 mb-2">
                        <div className="col col-sm-6 px-0">
                          <h6>OffShore Fee</h6>
                          {Number(item.off_shore_fees) === 0 ? (
                            <h5>-</h5>
                          ) : (
                            <h5>
                              {item.off_shore_fees && "$"}{" "}
                              {item.off_shore_fees || "-"}
                            </h5>
                          )}
                        </div>
                        <div className="col col-sm-6">
                          <h6>OnShore Fee</h6>
                          {Number(item.fees) === 0 ? (
                            <h5>-</h5>
                          ) : (
                            <h5>
                              {item.fees && "$"} {item.fees || "-"}
                            </h5>
                          )}
                        </div>
                      </div>
                      <div className="row mx-0 mb-2 d-flex justify-content-end">
                        <div className="col col-sm-6 px-0">
                          <h6 className="px-0">Duration</h6>
                          <h5 className="coursecompare_nameellipsis">
                            {item.duration || "-"}
                          </h5>
                        </div>
                        <div className="col col-sm-6">
                          <h6>Cricos Course Code</h6>
                          <h5>{item.course_code || "-"}</h5>
                        </div>
                        <div className="btn-apply">
                          <ApplyNowModal
                            clientdetails={clientdetails}
                            // clienttempdata={clienttempdata}
                            configAdmin={configAdmin}
                            coursedetail={item}
                            providerdetail={item}
                            setbackdropOpen={setbackdropOpen}
                            toast={toast}
                            // getclienttemp={getclienttemp}
                            fromwhere={"compare"}
                            applydetail={applydetail}
                          />
                        </div>
                      </div>
                      <div className="row mx-0 d-none">
                        <div
                          className="col-sm-6 pt-4"
                          style={{
                            borderRight: "1px solid #ccc",
                            borderTop: "1px solid #ccc",
                          }}
                        >
                          <div
                            className="d-flex mt-1 align-items-center justify-content-center"
                            style={{ color: "gold" }}
                          >
                            {item?.course_rating ? (
                              <>
                                {Array(Number(item.course_rating.split(".")[0]))
                                  .fill(1)
                                  .map((item, index) => (
                                    <i key={index} className="fa fa-star" />
                                  ))}
                                {item.course_rating.split(".")[1] && (
                                  <i className="fa fa-star-half" />
                                )}
                                {item.course_rating.split(".")[1] ? (
                                  <>
                                    {4 -
                                      Number(item.course_rating.split(".")[0]) >
                                      0 &&
                                      Array(
                                        4 -
                                          Number(
                                            item.course_rating.split(".")[0]
                                          )
                                      )
                                        .fill(1)
                                        .map((item, index) => (
                                          <i
                                            key={index}
                                            className="fa fa-star-o"
                                          />
                                        ))}
                                  </>
                                ) : (
                                  <>
                                    {5 -
                                      Number(
                                        item.course_rating.split(".")[0]
                                      ) ===
                                    0
                                      ? null
                                      : Array(
                                          5 -
                                            Number(
                                              item.course_rating.split(".")[0]
                                            )
                                        )
                                          .fill(1)
                                          .map((item, index) => (
                                            <i
                                              key={index}
                                              className="fa fa-star-o"
                                            />
                                          ))}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {Array(5)
                                  .fill(1)
                                  .map((item, index) => (
                                    <i key={index} className="fa fa-star-o" />
                                  ))}
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          className="col-sm-6 pt-3 px-1"
                          style={{ borderTop: "1px solid #ccc" }}
                        >
                          <ApplyNowModal
                            clientdetails={clientdetails}
                            // clienttempdata={clienttempdata}
                            configAdmin={configAdmin}
                            coursedetail={item}
                            providerdetail={item}
                            setbackdropOpen={setbackdropOpen}
                            toast={toast}
                            // getclienttemp={getclienttemp}
                            fromwhere={"compare"}
                            applydetail={applydetail}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          </div>

          <div className="coursecomparenew_tableparentcontainer">
            {comparedata.map((item, index) => (
              <>
                {item.branch_id ? (
                  <>
                    <div
                      key={index}
                      className="coursecomparenew_tablecontainer comparenew-row-colm"
                      style={{
                        // backgroundColor: index % 2 === 0 ? "#f6f8f9" : "#ffffff",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flex: ".25",
                        maxWidth: "25%",
                        minWidth: "25%",
                      }}
                    >
                      <div className="compare-headlines">
                        <h5 style={{ marginLeft: "120%" }}>Requirement</h5>
                      </div>

                      {item.name && item.requirement ? (
                        <div className="compare-course-roundedbox">
                          <CourseCompareRequirementModal
                            requirement={item.requirement}
                          />
                        </div>
                      ) : (
                        <div className="compare-course-roundedbox">-</div>
                      )}
                    </div>
                  </>
                ) : null}
              </>
            ))}
          </div>

          <div className="coursecomparenew_tableparentcontainer">
            {comparedata.map((item, index) => (
              <>
                {item.branch_id ? (
                  <>
                    <div
                      key={index}
                      className="coursecomparenew_tablecontainer comparenew-row-colm"
                      style={{
                        // backgroundColor: index % 2 === 0 ? "#f6f8f9" : "#ffffff",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flex: ".25",
                        maxWidth: "25%",
                        minWidth: "25%",
                      }}
                    >
                      <div
                        style={{
                          width: "250px",
                          color: "#07122c",
                          fontWeight: "600",
                          fontSize: "20px",
                          fontFamily: "Sora ,sans-serif !important",
                          display: "inline-block",
                          borderBottom: "2px solid #00ca8e",
                          textAlign: "center",
                          padding: "0px 0px 10px",
                          margin: "0px 0px 20px 0px",
                          marginLeft: "20%",
                        }}
                      >
                        <h5 style={{ fontSize: "1.4rem" }}>Course Overview</h5>
                      </div>
                      {item.name && item.course_overview ? (
                        <div className="compare-course-roundedbox">
                          <CourseCompareRequirementModal
                            overview={item.course_overview}
                            whattoshow={"overview"}
                          />
                        </div>
                      ) : (
                        <div className="compare-course-roundedbox">-</div>
                      )}
                    </div>
                  </>
                ) : null}
              </>
            ))}
          </div>

          <div className="coursecomparenew_tableparentcontainer">
            {comparedata.map((item, index) => (
              <>
                {item.branch_id ? (
                  <>
                    <div
                      key={index}
                      className="coursecomparenew_tablecontainer comparenew-row-colm"
                      style={{
                        // backgroundColor: index % 2 === 0 ? "#f6f8f9" : "#ffffff",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flex: ".25",
                        maxWidth: "25%",
                        minWidth: "25%",
                      }}
                    >
                      <div
                        style={{
                          width: "250px",
                          color: "#07122c",
                          fontWeight: "600",
                          fontSize: "20px",
                          fontFamily: "Sora ,sans-serif !important",
                          display: "inline-block",
                          borderBottom: "2px solid #00ca8e",
                          textAlign: "center",
                          padding: "0px 0px 10px",
                          margin: "0px 0px 20px 0px",
                          marginLeft: "20%",
                        }}
                      >
                        <h5 style={{ fontSize: "1.4rem" }}>Course Objective</h5>
                      </div>
                      {item.name && item.course_objective ? (
                        <div className="compare-course-roundedbox">
                          <CourseCompareRequirementModal
                            objective={item.course_objective}
                            whattoshow={"objective"}
                          />
                        </div>
                      ) : (
                        <div className="compare-course-roundedbox">-</div>
                      )}
                    </div>
                  </>
                ) : null}
              </>
            ))}
          </div>

          {/* <div className="compare-headlines">
            <h5>Qualification Level</h5>
          </div> */}
          <div className="coursecomparenew_tableparentcontainer">
            {comparedata.map((item, index) => (
              <>
                {item.branch_id ? (
                  <>
                    {/* <div
                      key={index}
                      className="coursecomparenew_tablecontainer comparenew-row-colm"
                      style={{
                        // backgroundColor: index % 2 === 0 ? "#f6f8f9" : "#ffffff",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flex: ".25",
                        maxWidth: "25%",
                        minWidth: "25%",
                      }}
                    >
                      <div
                        style={{
                          width: "250px",
                          color: "#07122c",
                          fontWeight: "600",
                          fontSize: "20px",
                          fontFamily: "Sora ,sans-serif !important",
                          display: "inline-block",
                          borderBottom: "2px solid #00ca8e",
                          textAlign: "center",
                          padding: "0px 0px 10px",
                          margin: "0px 0px 20px 0px",
                          marginLeft: "20%",
                        }}
                      >
                        <h5 style={{ fontSize: "1.4rem" }}>
                          Qualification Level
                        </h5>
                      </div>
                      <div className="compare-course-roundedbox">
                        {item.level_name || "-"}
                      </div>
                    </div> */}
                  </>
                ) : null}
              </>
            ))}
          </div>
          {/* <div className="compare-headlines">
            <h5>Study Area</h5>
          </div> */}
          <div className="coursecomparenew_tableparentcontainer">
            {comparedata.map((item, index) => (
              <>
                {item.branch_id ? (
                  <>
                    {/* <div
                      key={index}
                      className="coursecomparenew_tablecontainer comparenew-row-colm"
                      style={{
                        // backgroundColor: index % 2 === 0 ? "#f6f8f9" : "#ffffff",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flex: ".25",
                        maxWidth: "25%",
                        minWidth: "25%",
                      }}
                    >
                      <div
                        style={{
                          width: "200px",
                          color: "#07122c",
                          fontWeight: "600",
                          fontSize: "20px",
                          fontFamily: "Sora ,sans-serif !important",
                          display: "inline-block",
                          borderBottom: "2px solid #00ca8e",
                          textAlign: "center",
                          padding: "0px 0px 10px",
                          margin: "0px 0px 20px 0px",
                          marginLeft: "25%",
                        }}
                      >
                        <h5 style={{ fontSize: "1.4rem" }}>Study Area</h5>
                      </div>
                      <div className="compare-course-roundedbox">
                        {" "}
                        {item.area_name || "-"}
                      </div>
                    </div> */}
                  </>
                ) : null}
              </>
            ))}
          </div>
          {/* <div className="compare-headlines">
            <h5>Intake</h5>

          </div> */}
          <div className="coursecomparenew_tableparentcontainer">
            {comparedata.map((item, index) => (
              <>
                {item.branch_id ? (
                  <>
                    {/* <div
                      key={index}
                      className="coursecomparenew_tablecontainer comparenew-row-colm"
                      style={{
                        // backgroundColor: index % 2 === 0 ? "#f6f8f9" : "#ffffff",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        flex: ".25",
                        maxWidth: "25%",
                        minWidth: "25%",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          color: "#07122c",
                          fontWeight: "600",
                          fontSize: "20px",
                          fontFamily: "Sora ,sans-serif !important",
                          display: "inline-block",
                          borderBottom: "2px solid #00ca8e",
                          textAlign: "center",
                          padding: "0px 0px 10px",
                          margin: "0px 0px 20px 0px",
                          marginLeft: "30%",
                        }}
                      >
                        <h5 style={{ fontSize: "1.4rem" }}>Intake</h5>
                      </div>
                      {item.name && item.requirement ? (
                        <div className="compare-course-roundedbox">
                          <CourseCompareRequirementModal
                            requirement={item.requirement}
                          />
                        </div>
                      ) : (
                        <div className="compare-course-roundedbox">-</div>
                      )}
                      {item.name && (
                        <div style={{ width: "100%" }}> */}
                    {/* <div className="col-sm-6 pt-4">{null}</div> */}
                    {/* <div className="compare-course-apply">
                            <ApplyNowModal
                              clientdetails={clientdetails}
                              // clienttempdata={clienttempdata}
                              configAdmin={configAdmin}
                              coursedetail={item}
                              providerdetail={item}
                              setbackdropOpen={setbackdropOpen}
                              toast={toast}
                              // getclienttemp={getclienttemp}
                              fromwhere={"comparelower"}
                              applydetail={applydetail}
                            />
                          </div>
                        </div>
                      )}
                    </div> */}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
      ) : (
        <div className="container text-center mt-3 ">
          <h2>Please search course and add to your compare list</h2>
          <img
            alt="nothing_to_compare"
            src={NoComparison}
            className="mt-4"
            height={100}
            width={350}
          />
          {/* <div className="alert alert-danger text-center">
            <strong>Sorry! </strong>
            Nothing to Compare
          </div> */}
          <div className="text-center" style={{ marginBottom: "60px" }}>
            <div>
              <button
                className="btn comparemodal_comparebutton"
                onClick={() => navigate("/course-searchresult")}
              >
                <i className="fa fa-plus" /> Search to compare
              </button>
            </div>
          </div>
        </div>
      )}
      <div class="menu" style={{ textAlign: "right" }}>
        <a class="next" onClick={goback} style={{ marginRight: "20px" }}>
          &laquo; Go Back
        </a>
      </div>
      {comparedata.length > 0 ? (
        <div className="mt-4">
          <Footer />
        </div>
      ) : (
        <div className="loginpage_footer">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default CourseCompareNew;

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#6f81aa !important", // Change field color here
    borderColor: state.isFocused ? "#007bff" : "#ced4da", // Change border color here
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6f81aa !important", // Change placeholder color here
  }),
};
