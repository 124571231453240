import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useStateValue } from "../../ContextAPI/StateProvider";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import base_url from "../../api/bootapi";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
// import ReactNavbar from "../../Navbar/Navbar";
import ReCAPTCHA from "react-google-recaptcha";
import NavbarNew from "../../Navbar/NavbarNew";
import Swal from "sweetalert2";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-outline-success",
  },
  buttonsStyling: false,
});

const ForceLogin = () => {
  const [backdropopen, setbackdropOpen] = useState(false);
  const classes = useStyles();
  const [showui, setshowui] = useState("login");
  const [inputtype, setinputtype] = useState("password");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [captchaerror, setcaptchaerror] = useState(false);
  const [apierror, setapierror] = useState("");
  const [captchavalue, setcaptchavalue] = useState("");
  const location_path =
    sessionStorage.getItem("prev_path_before_login") || null;

  // eslint-disable-next-line
  const [{}, dispatch] = useStateValue();
  let navigate = useNavigate();
  const pageStartRef = useRef(null);

  //Forgot UI UseState
  const [forgotemail, setforgotemail] = useState("");
  const [forgotemailerror, setforgotemailerror] = useState(false);
  const [forgotsuccessmessage, setforgotsuccessmessage] = useState(false);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    if (clientdetails?.clientId) {
      navigate("/");
    }
    pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const onChangeCaptcha = (value) => setcaptchavalue(value);

  const handlelogin = (data2) => {
    // if (!captchavalue) {
    //   setcaptchaerror(true);
    //   return;
    // }
    setcaptchaerror(false);
    setbackdropOpen(true);
    const data = {
      username: data2.username.trim(),
      password: data2.password.trim(),
    };
    axios
      .post(`${base_url.api5}/leadAuth`, data)
      .then((response) => {
        localStorage.setItem("agentLogin", 0);
        localStorage.setItem("clientLogin", 0);
        if (response.data.status_code === 202) {
          //console.log("coming in if clientAuthenticate----------->");
          axios
            .post(`${base_url.api5}/clientAuthenticate`, data)
            .then((res) => {
              localStorage.setItem("clientLogin", 1);
              dispatch({
                type: "SET_USER",
                user: res.data.jwtToken,
              });
              let arr = {
                token: res.data.jwtToken,
                clientId: res.data.data.id,
                name: `${res.data.data.firstName} ${res.data.data.lastName}`,
                firstName: res.data.data.firstName,
                lastName: res.data.data.lastName,
                username: res.data.data.email,
                phone: res.data.data.clientDetail.phone,
                officeId: res.data.data.officeId,
                officeName: res.data.data.officeName,
                address: res.data.data.address,
              };
              localStorage.setItem(
                "isw_client_details_frontend",
                JSON.stringify(arr)
              );
              setbackdropOpen(false);
              toast.success("User Logged in Successfully");
              // if (location_path) {
              //   navigate(location_path);
              // } else {
              //   navigate("/myaccount");
              // }
              navigate("/myaccount");
              swalWithBootstrapButtons
                .fire({
                  title: "Do you want to go to the Search Page?",
                  showCancelButton: true,
                  confirmButtonText: "Go to search page",
                  cancelButtonText: "Stay on this page",
                  icon: "question",
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    // Redirect to course page
                    navigate("/course-searchresult");
                  } else {
                    // Stay on the same page
                    //console.log("Action cancelled");
                  }
                });
            })
            .catch((err) => handleerror(err));
        } else if (response.data.status_code === 200) {
          //console.log("coming in else------------>");
          let clientTempId = response.data.data.id;
          if (response.data.data.status === 0) {
            //console.log("coming in innerif clientAuthenticate----------->");
            axios
              .post(`${base_url.api5}/clientAuthenticate`, data)
              .then((res) => {
                localStorage.setItem("clientLogin", 1);
                dispatch({
                  type: "SET_USER",
                  user: res.data.jwtToken,
                });
                let arr = {
                  token: res.data.jwtToken,
                  clientTempId: clientTempId,
                  clientId: res.data.data.id,
                  name: `${res.data.data.firstName} ${res.data.data.lastName}`,
                  firstName: res.data.data.firstName,
                  lastName: res.data.data.lastName,
                  username: res.data.data.email,
                  phone: res.data.data.clientDetail.phone,
                  officeId: res.data.data.officeId,
                  officeName: res.data.data.officeName,
                  address: res.data.data.address,
                };
                localStorage.setItem(
                  "isw_client_details_frontend",
                  JSON.stringify(arr)
                );
                setbackdropOpen(false);
                toast.success("User Logged in Successfully");
                // if (location_path) {
                //   navigate(location_path);
                // } else {
                //   navigate("/myaccount");
                // }
                navigate("/myaccount");
                swalWithBootstrapButtons
                  .fire({
                    title: "Do you want to go to the Search Page?",
                    showCancelButton: true,
                    confirmButtonText: "Go to search page",
                    cancelButtonText: "Stay on this page",
                    icon: "question",
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      // Redirect to course page
                      navigate("/course-searchresult");
                    } else {
                      // Stay on the same page
                      //console.log("Action cancelled");
                    }
                  });
              })
              .catch((err) => handleerror(err));
          } else {
            let clientTempId = response.data.data.id;
            dispatch({
              type: "SET_USER",
              user: response.data.jwtToken,
            });
            let arr = {
              token: response.data.jwtToken,
              clientId: response.data.data.id,
              clientTempId: clientTempId,
              name: `${response.data.data.firstName} ${response.data.data.lastName}`,
              firstName: response.data.data.firstName,
              lastName: response.data.data.lastName,
              username: response.data.data.email,
              phone: response.data.data.phone,
              officeId: response.data.data.officeId,
              officeName: response.data.data.officeName,
              address: response.data.data.address,
            };
            localStorage.setItem(
              "isw_client_details_frontend",
              JSON.stringify(arr)
            );
            setbackdropOpen(false);
            navigate("/myaccount");
            // if (location_path) {
            //   navigate(location_path);
            // } else {
            //   navigate("/myaccount");
            // }
            toast.success("User Logged in Successfully");
            swalWithBootstrapButtons
              .fire({
                title: "Do you want to go to the Search Page?",
                showCancelButton: true,
                confirmButtonText: "Go to search page",
                cancelButtonText: "Stay on this page",
                icon: "question",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  // Redirect to course page
                  navigate("/course-searchresult");
                } else {
                  // Stay on the same page
                  //console.log("Action cancelled");
                }
              });
          }
        } else {
          toast.error("No user Found");
          setapierror("No user Found");
          setbackdropOpen(false);
        }
      })
      .catch((err) => handleerror(err));
  };

  const gotoregister = () => navigate("/register");

  const submitforgotpassword = () => {
    if (!forgotemail) {
      setforgotemailerror(true);
      return;
    }
    setforgotemailerror(false);
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/forgotClientPassword`, {
        headers: { email: forgotemail.trim() },
      })
      .then((res) => {
        setbackdropOpen(false);
        setapierror("");
        setforgotsuccessmessage(true);
      })
      .catch((err) => handleerror(err));
  };

  const handleerror = (err) => {
    setbackdropOpen(false);
    if (err.response) {
      if (err.response.data.message === "Incorrect Password") {
        setapierror("Username or Password is Invalid");
      } else {
        setapierror(err.response.data.message);
      }
    } else {
      setapierror("Something went wrong");
    }
  };

  return (
    <div ref={pageStartRef}>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavbarNew />
      <div className="login">
        {/*         
        <h2 className="text-center mb-0">
          <h1></h1>
          <h1></h1>
          {showui === "login" ? <h1>Create an account.</h1> : <h1></h1>}
          {showui === "login" ? "Sign In To" : "Forgot Password"}
        </h2>
        <h2 className="text-center mb-4">International Student World</h2> */}
        {/* <h2 className="text-center mb-4">International Student World</h2> */}
        <h2 className="text-center mb-0">
          <h1></h1>

          <h1></h1>

          {showui === "login" ? (
            <h2> In order to compare and apply </h2>
          ) : (
            <h1></h1>
          )}
          {showui === "login" ? (
            <h1>Please create an account or </h1>
          ) : (
            <h1></h1>
          )}

          {showui === "login" ? "Log in" : "Forgot Password"}
        </h2>
        {showui === "login" ? (
          <form
            onSubmit={handleSubmit(handlelogin)}
            className="login_container loginwidthsize mt-4"
          >
            <div className="my-4">
              <label>Email</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("username", {
                    required: true,
                    pattern:
                      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/,
                  })}
                  className="form-control"
                  placeholder="e-mail address"
                />
                <i className="fa fa-envelope-o designi" />
              </div>
              {errors.username?.type === "required" && (
                <p className="errormessage">E-Mail is required</p>
              )}
              {errors.username?.type === "pattern" && (
                <p className="errormessage">Enter valid E-Mail</p>
              )}
            </div>
            <div className="mt-4">
              <label>Password</label>
              <div style={{ position: "relative" }}>
                <input
                  type={inputtype}
                  {...register("password", { required: true })}
                  className="form-control"
                  placeholder="Password"
                />
                <i className="fa fa-lock designi" />
                {inputtype === "password" ? (
                  <i
                    className="fa fa-eye-slash fonticonpositioning"
                    onClick={() => setinputtype("text")}
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye fonticonpositioning"
                    onClick={() => setinputtype("password")}
                    aria-hidden="true"
                  ></i>
                )}
              </div>
              {errors.password && (
                <p className="errormessage">Password is required</p>
              )}
            </div>
            <p
              className="text-right"
              onClick={() => {
                setapierror("");
                setshowui("forgotpassword");
              }}
            >
              <i className="fa fa-unlock-alt pr-1" />
              Forgot your password?
            </p>
            {/* <div>
              <ReCAPTCHA
                sitekey="6LeowfQcAAAAAFC97vZ7g695Sv5aUbk5ciTDU49m"
                onChange={onChangeCaptcha}
              />
              {captchaerror && (
                <p className="errormessage mb-0">Captcha is required</p>
              )}
            </div> */}
            {apierror && (
              <div className="alert alert-danger text-center my-2">
                <strong>Error!! </strong>
                {apierror}
              </div>
            )}
            <button type="submit" className="btn mt-2">
              Sign In
            </button>
            {/* <p className="text-center mt-4">
              Are you new? <span onClick={gotoregister}>Sign Up</span>
            </p> */}

            <div className="or-line">
              <hr />
              <span className="or-text">or</span>
              <hr />
            </div>
            <div className="create-btn-container">
              <button id="create-btn" onClick={gotoregister}>
                Create New Account
              </button>
            </div>
          </form>
        ) : (
          <div className="login_container loginwidthsize">
            <div className="my -4">
              <label>Email</label>
              <div style={{ position: "relative" }}>
                <input
                  value={forgotemail}
                  onChange={(e) => setforgotemail(e.target.value)}
                  className="form-control"
                  placeholder="e-mail address"
                />
                <i className="fa fa-envelope-o designi" />
              </div>
              {forgotemailerror && (
                <p className="errormessage mb-0">E-Mail is required</p>
              )}
            </div>
            {/* {!forgotsuccessmessage && (
              <p
                className="text-right"
                onClick={() => {
                  setapierror("");
                  setshowui("login");
                }}
              >
                Cancel Forgot Password..
              </p>
            )} */}
            {apierror && (
              <div className="alert alert-danger text-center my-2">
                <strong>Error!! </strong>
                {apierror}
              </div>
            )}
            {forgotsuccessmessage ? (
              <div className="alert alert-success text-center mt-4">
                <strong>Success!! </strong>
                Reset Link has been sent to your Registered Email Id
              </div>
            ) : (
              <div className="row mx-0 my-2">
                <div className="col-md-6 pl-0">
                  <button
                    onClick={() => {
                      setapierror("");
                      setshowui("login");
                    }}
                    className="btn"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-md-6 px-0">
                  <button onClick={submitforgotpassword} className="btn">
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {/* {showui === "login" ? (
        <Footer />
      ) : ( */}
      <div className="loginpage_footer">
        <Footer />
      </div>
      {/* )} */}
    </div>
  );
};

export default ForceLogin;
