import React, { useEffect, useRef, useState } from "react";
import base_url from "../api/bootapi";
import axios from "axios";
import ReactNavbar from "../Navbar/Navbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import CourseHeaderbody from "./CourseHeaderbody";
import CourseBodyLower from "./CourseBodyLower";
import { toast } from "react-toastify";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import NavbarNew from "../Navbar/NavbarNew";
import { useNavigate } from "react-router-dom";
import attachmenturl from "../api/attachmenturl";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));
const CourseDetail = () => {
  const [backdropopen, setbackdropOpen] = React.useState(false);
  const classes = useStyles();
  // const courseId = JSON.parse(localStorage.getItem("courseId"));
  const [coursedetail, setcoursedetail] = useState();
  const [providerdetail, setproviderdetail] = useState();
  const [totalAddress, setTotalAddress] = useState(null);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [configAdmin, setConfigAdmin] = useState(false);
  const pageStartRef = useRef(null);
  let params = useParams();
  let navigate = useNavigate();

  function calculatePathName(cricosId) {
    // Extract the numeric part of the alphanumeric ID, assuming it's at the start
    const numericPart = cricosId.match(/^\d+/);
    const numericId = numericPart ? parseInt(numericPart[0]) : 0;

    // Calculate the lower and upper bounds for each range
    const lowerBound = Math.floor((numericId - 1) / 500) * 500 + 1;
    const upperBound = Math.min(lowerBound + 499, 10000); // Adjust the upper limit as needed

    // Format the lower and upper bounds as strings with leading zeros
    const lowerBoundStr = String(lowerBound).padStart(5, "0");
    const upperBoundStr = String(upperBound).padStart(5, "0");

    // Construct the path name
    const pathName = `${lowerBoundStr}-${upperBoundStr}`;

    return pathName;
  }

  const [courseObj, setcourseObj] = useState();

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
    const id =
      params?.course_name.split("-")[params?.course_name.split("-").length - 1];
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getCourseById`, {
        headers: {
          // courseId: Number(courseId.courseId),
          courseId: Number(id),
          branchId: Number(0),
        },
      })
      .then((res) => {
        setcourseObj(
          `${attachmenturl}/attachments/providers/australia/${calculatePathName(
            res.data.data.cricos
          )}/${res.data.data.cricos}/${res.data.data.logo}`
        );
        setcoursedetail(res.data.data);
        //console.log("res.data.data123---->", res.data.data);
        // setcoursedetail({
        //   "id": 1,
        //   "appFees": courseObj.appFees,
        //   "areaId": courseObj.areaId,
        //   "areaName":courseObj.areaName,
        //   "atar": courseObj.atar,
        //   "banner":courseObj.banner,
        //   "branchInstitutionId": courseObj.branchInstitutionId,
        //   "categoryName": courseObj.categoryName,
        //   "country": courseObj.country,
        //   "courseCode": courseObj.courseCode,
        //   "courseId": courseObj.courseId,
        //   "courseName":courseObj.courseName,
        //   "courseObjective": courseObj.courseObjective,
        //   "courseOverview": courseObj.courseOverview,
        //   "courseRating":courseObj.courseRating,
        //   "courseWhatWillYouStudy": courseObj.courseWhatWillYouStudy,
        //   "cricos": courseObj.cricos,
        //   "cricosProviderCode":courseObj.cricosProviderCode,
        //   "duration": courseObj.duration,
        //   "fees": courseObj.fees,
        //   "institutionName": courseObj.institutionName,
        //   "intake": courseObj.intake,
        //   "levelId":courseObj.levelId,
        //   "levelName": courseObj.levelName,
        //   "materialFees": courseObj.materialFees,
        //   "offShoreFees":courseObj.offShoreFees,
        //   "otherFees": courseObj.otherFees,
        //   "provider_name": courseObj.provider_name,
        //   "requirement":courseObj.requirement,
        //   "state": courseObj.state,
        //   "type": courseObj.type,
        //   "uac": courseObj.uac,
        //   "logo": `https://api.intstudentworld.com/attachments/providers/australia/${calculatePathName(res.data.data.cricos)}/${res.data.data.cricos}/${res.data.data.logo}`
        // });

        //console.log("logoArray", logoArray);

        // setcoursedetail(logoArray);
        setbackdropOpen(false);
        //console.log("Sangam Response", res.data);
        axios
          .get(`${base_url.api5}/getAllBranchAddressesByCourseId`, {
            headers: {
              courseId: Number(id),
            },
          })
          .then((response) => {
            //console.log("res----------->", response);
            setTotalAddress(response.data.data);
            setbackdropOpen(false);
          })
          .catch((error) => {
            setbackdropOpen(false);
          });
        axios
          .get(`${base_url.api5}/getInstitutionBranchById`, {
            headers: {
              institutionBranchesId: Number(res.data.data.institution_id),
            },
          })
          .then((res) => setproviderdetail(res.data.data));
      })
      .catch((err) => setbackdropOpen(false));
    // getConfigData();
  }, [clientdetails?.clientId]);

  // const getConfigData = () => {
  // 	axios
  // 		.get(`${base_url.api5}/getByConfigKey`, {
  // 			headers: {
  // 				configKey: 'iswonline_consultant',
  // 			},
  // 		})
  // 		.then((res) => setConfigAdmin(res.data.data.configValue));
  // };

  const goback = () => {
    navigate("/course-searchresult");
  };

  return (
    <div ref={pageStartRef}>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <ReactNavbar /> */}
      <NavbarNew />
      <CourseHeaderbody
        clientdetails={clientdetails}
        configAdmin={configAdmin}
        coursedetail={coursedetail}
        providerdetail={providerdetail}
        totalAddress={totalAddress}
        setbackdropOpen={setbackdropOpen}
        toast={toast}
        courseObj={courseObj}
      />
      <CourseBodyLower
        coursedetail={coursedetail}
        clientdetails={clientdetails}
        configAdmin={configAdmin}
        providerdetail={providerdetail}
        setbackdropOpen={setbackdropOpen}
        toast={toast}
        courseObj={courseObj}
      />
      <div class="menu" style={{ textAlign: "center" }}>
        <a class="next" onClick={goback}>
          &laquo; Go Back
        </a>
      </div>
      <div className="mt-4">
        <Footer />
      </div>
    </div>
  );
};

export default CourseDetail;
