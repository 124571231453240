import React, { useEffect, useState } from "react";
import axios from "axios";
import base_url from "../api/bootapi";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataFound from "./../../assets/images/unnamed.jpg";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const AgentFees = () => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [invoicedetails, setinvoicedetails] = useState([]);
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [paymentpageno, setpaymentpageno] = useState(0);
  const [paymentgetpageno, setpaymentgetpageno] = useState();
  const [paymentdetails, setpaymentdetails] = useState([]);
  const [paymenttotalUser, setpaymenttotalUser] = useState();
  const [paymentcountnumber, setpaymentcountnumber] = useState();
  const [paymentnumberofElements, setpaymentnumberofElements] = useState();
  const classes = useStyles();
  const [backdropopen, setbackdropOpen] = useState(false);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getpayableinvoice(pageno);
    getpaidinvoice(paymentpageno);
  }, []);
  const handleinvoicepage = (event, value) => {
    setpageno(value - 1);
    getpayableinvoice(value - 1);
  };
  const handlepaymentpage = (event, value) => {
    setpaymentpageno(value - 1);
    getpaidinvoice(value - 1);
  };

  const getpayableinvoice = (value) => {
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getInvoiceByAgentUsername`, {
        headers: {
          //   Authorization:
          //     "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzaW5naGFsdmlwdWw1M0BnbWFpbC5jb20iLCJleHAiOjE2Mzc3NzAzMzQsImlhdCI6MTYzNzU5NzUzNH0.Hkz9TSEv81kfH_agpchc6gJmzhH6VmYOq8LnIrxWnS9BimTD-nHqI3CHBDFGKUETZorhAaCqrJL9_7wih5Morg",
          Authorization: `Bearer ${clientdetails?.token}`,
          agentUsername: clientdetails?.username,
          columnName: "invoiceCreatedDate",
          direction: "DESC",
          page: Number(value),
          size: 10,
        },
      })
      .then((res) => {
        setinvoicedetails(res.data.data.content || []);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
        setbackdropOpen(false);
      })
      .catch((err) => {
        setbackdropOpen(false);
        setinvoicedetails([]);
      });
  };

  const getpaidinvoice = (value) => {
    axios
      .get(`${base_url.api5}/getPaidInvoiceByAgentUsername`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          agentUsername: clientdetails?.username,
          columnName: "invoiceCreatedDate",
          invoiceStatus: "Paid",
          direction: "DESC",
          page: Number(value),
          size: 10,
        },
      })
      .then((res) => {
        setpaymentdetails(res.data.data.content || []);
        setpaymentgetpageno(res.data.data.totalPages);
        setpaymenttotalUser(res.data.data.totalElements);
        setpaymentcountnumber(res.data.data.number);
        setpaymentnumberofElements(res.data.data.numberOfElements);
      })
      .catch((err) => setinvoicedetails([]));
  };

  return (
    <>
      <h2 className="accountbody-title">Tution Fee & Payment</h2>
      <div className="accountbody accountenquiry">
        <Backdrop className={classes.backdrop} open={backdropopen}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <ul className="nav nav-tabs accountbody_responsivenav accountbody_subtabnav">
          <li className="nav-item row mx-0 ">
            <a
              className="nav-link active"
              data-toggle="tab"
              href="#agent_payable"
            >
              Payable
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#agent_paid">
              Paid
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div id="agent_payable" className=" tab-pane active">
            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              {invoicedetails.length > 0 ? (
                <table className="table tbb">
                  <thead className="thb">
                    <tr>
                      <th>SN</th>
                      <th style={{ minWidth: "170px" }}>Student Number</th>
                      <th style={{ minWidth: "170px" }}>Student</th>
                      <th style={{ minWidth: "170px" }}>Date of Birth</th>
                      <th style={{ minWidth: "250px" }}>Course</th>
                      <th style={{ minWidth: "170px" }}>Income Type</th>
                      <th>Amount</th>
                      <th style={{ minWidth: "250px" }}>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoicedetails.map((p, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{p.externalId}</td>
                        <td>{p.clientUsername}</td>
                        <td>
                          {p.clientDob
                            ? moment(p.clientDob).format("DD MMM YYYY")
                            : "-"}
                        </td>
                        <td>{p.courseName}</td>
                        <td>{p.incomeType}</td>
                        <td>{p.netAmount}</td>
                        <td>
                          Commission for Student Payment due
                          {p.invoiceDueDate
                            ? moment(p.invoiceDueDate).format("DD MMM YYYY")
                            : "-"}
                          <br />
                          {p.invoiceDueDate &&
                            moment(p.invoiceDueDate).format("hh.mm a")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <>
                  {backdropopen ? null : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ marginTop: "50px" }}
                    >
                      <img alt="No data Found" src={NoDataFound} />
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div>
                {invoicedetails?.length ? (
                  <p>
                    {countnumber * 10 + 1} to{" "}
                    {countnumber * 10 + numberofElements} of {totalUser} records
                  </p>
                ) : (
                  <p>No Record Found </p>
                )}
              </div>
              <Pagination count={getpageno} onChange={handleinvoicepage} />
            </div>
          </div>
          <div id="agent_paid" className=" tab-pane">
            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              {paymentdetails.length > 0 ? (
                <table className="table tbb">
                  <thead className="thb">
                    <tr>
                      <th>SN</th>
                      <th>Income Type</th>
                      <th>Invoice Type</th>
                      <th>Enquiry Name</th>
                      <th>Amount</th>
                      <th>Due Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentdetails.map((p, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{p.incomeType}</td>
                        <td>{p.commisionType}</td>
                        <td>{p.enquiryName}</td>
                        <td>{p.netAmount}</td>
                        <td>
                          {p.invoiceDueDate
                            ? moment(p.invoiceDueDate).format("DD MMM YYYY")
                            : "-"}
                          <br />
                          {p.invoiceDueDate
                            ? moment(p.invoiceDueDate).format("hh.mm a")
                            : "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ marginTop: "50px" }}
                >
                  <img alt="No data Found" src={NoDataFound} />
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div>
                {paymentdetails?.length ? (
                  <p>
                    {paymentcountnumber * 10 + 1} to{" "}
                    {paymentcountnumber * 10 + paymentnumberofElements} of{" "}
                    {paymenttotalUser} records
                  </p>
                ) : (
                  <p>No Record Found </p>
                )}
              </div>
              <Pagination
                count={paymentgetpageno}
                onChange={handlepaymentpage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentFees;
