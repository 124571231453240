import React, { useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/downlogo.png";
import { useStateValue } from "../ContextAPI/StateProvider";
// import LockIcon from "@material-ui/icons/Lock";
import { toast } from "react-toastify";
import MenuIcon from "@mui/icons-material/Menu";

import Institution from "../Institution/Institution";

const NavbarNew = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const agentLoginCheck = JSON.parse(localStorage.getItem("agentLogin"));

  const clientLoginCheck = JSON.parse(localStorage.getItem("clientLogin"));

  const leadLoginCheck = JSON.parse(localStorage.getItem("LeadLogin"));

  const [toggle, setToggle] = useState(true);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  // eslint-disable-next-line
  const [{ user }, dispatch] = useStateValue();

  const handlecompare = () => {
    if (!clientdetails?.clientId) {
      sessionStorage.setItem("prev_path_before_login", "/course-compare");
      // navigate("/login");
      navigate("/force-login");
    } else {
      navigate("/course-compare");
    }
  };
  const handlelogin = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/login");
  };
  const handleAgentlogin = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/agent-login");
  };
  const handleregister = () => navigate("/register");
  const handleAgentregister = () => navigate("/agent-register");
  const handleimage = () => navigate("/");
  const handlelogout = () => {
    dispatch({
      type: "SET_USER",
      user: null,
    });
    localStorage.clear();
    navigate("/login");
    toast.success("Logged Out Successfully");
    localStorage.setItem("countryvalue", "Australia");
  };

  // localStorage.setItem("PopularState", 1);
  const handledashboard = () => navigate("/myaccount");
  const handleinstitution = () => navigate("/institution");
  const handlesearch = () => navigate("/popular_course");
  // const handleenquiry = () => navigate("/enquiry");
  const handlecontact = () => navigate("/contactus");
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 71);
    });
  }, []);

  const handleAusEducation = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/education");
  };
  const handleChangeProvider = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/provider");
  };

  return (
    <>
      {/* New Header */}
      <div
        className={
          location.pathname === "/" ||
          location.pathname === "/popular_course" ||
          location.pathname === "/searchbycity"
            ? "main-header main-header-home"
            : "main-header"
        }
      >
        <div className={scroll ? "bg-white" : null}>
          <div className="container-fluid pt-3 pb-3">
            <div className="row">
              <div className="col-6 col-lg-2 col-md-2">
                <img
                  className="cursor-pointer"
                  onClick={handleimage}
                  src={Logo}
                  alt="isw_logo"
                />
              </div>
              <div className="col-6 col-lg-10 col-md-10">
                <nav className="navbar navbar-expand-lg">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon">
                      <i
                        onClick={() => setToggle(!toggle)}
                        className={toggle ? "fa fa-bars" : "fa fa-close"}
                        aria-hidden="true"
                      ></i>
                    </span>
                  </button>

                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mr-auto">
                      {leadLoginCheck === 1 ||
                      clientLoginCheck === 1 ||
                      agentLoginCheck === 1 ? (
                        <li className="nav-item">
                          <span
                            onClick={handledashboard}
                            className={
                              location.pathname === "/myaccount"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            Dashboard
                          </span>
                        </li>
                      ) : null}
                      <li className="nav-item">
                        <span
                          onClick={handleinstitution}
                          className={
                            location.pathname === "/institution"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Institution
                        </span>
                      </li>

                      <li className="nav-item">
                        <span
                          onClick={handlesearch}
                          className={
                            location.pathname === "/popular_course"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Popular Course
                        </span>
                      </li>
                      <li className="nav-item">
                        <span
                          onClick={handlecompare}
                          className={
                            location.pathname === "/course-compare"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Choose &amp; Compare
                        </span>
                      </li>

                      <li className="nav-item">
                        <span
                          onClick={handlecontact}
                          className={
                            location.pathname === "/contactus"
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          Contact Us
                        </span>
                      </li>

                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Resources
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <a
                            className="dropdown-item"
                            onClick={handleAusEducation}
                          >
                            Australian's education System
                          </a>
                          <a
                            className="dropdown-item"
                            onClick={handleChangeProvider}
                          >
                            Changing your course or provider
                          </a>
                        </div>
                      </li>

                      <li className="nav-item">
                        <span
                          className="nav-link nav-item-enquiry"
                          onClick={handlecontact}
                        >
                          <i className="fa fa-star"></i> Enquiry
                        </span>
                      </li>
                    </ul>

                    {clientdetails?.clientId ? (
                      <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Logout
                          </a>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown"
                          >
                            <a
                              className="dropdown-item"
                              onClick={handledashboard}
                            >
                              Dashboard
                            </a>
                            <a className="dropdown-item" onClick={handlelogout}>
                              Logout
                            </a>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <>
                        <ul className="navbar-nav">
                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Log In
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <a
                                className="dropdown-item"
                                onClick={handlelogin}
                              >
                                User Login
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={handleAgentlogin}
                              >
                                Agent Login
                              </a>
                            </div>
                          </li>

                          <li className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle nav-item-signup"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Sign Up
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <a
                                className="dropdown-item"
                                onClick={handleregister}
                              >
                                User SignUp
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={handleAgentregister}
                              >
                                Agent SignUp
                              </a>
                            </div>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* New Header */}

      <div className="old-menu">
        <div className="container-fluid pt-2 pb-2">
          <div className="row justify-content-between">
            {props.fromwhere === "Account" ? (
              <>
                <p className="headertextcss">
                  Welcome {clientdetails?.firstName} {clientdetails?.lastName}
                </p>
              </>
            ) : (
              <div className="col-2">
                <img
                  className="cursor-pointer"
                  onClick={handleimage}
                  src={Logo}
                  alt="isw_logo"
                />
              </div>
            )}

            <div className="col-8">
              {props.fromwhere === "Verify" ? (
                ""
              ) : (
                <div>
                  {leadLoginCheck === 1 ||
                  clientLoginCheck === 1 ||
                  agentLoginCheck === 1 ? (
                    <p
                      className={location.pathname === "/myaccount" && "active"}
                      onClick={handledashboard}
                    >
                      Dashboard
                    </p>
                  ) : null}
                  <p
                    className={location.pathname === "/institution" && "active"}
                    onClick={handleinstitution}
                  >
                    Institution
                  </p>
                  <p
                    className={
                      location.pathname === "/course-searchresult" && "active"
                    }
                    onClick={handlesearch}
                  >
                    Courses
                  </p>
                  <p
                    className={
                      location.pathname === "/course-compare" && "active"
                    }
                    onClick={handlecompare}
                  >
                    Compare
                  </p>

                  <p
                    className={location.pathname === "/contactus" && "active"}
                    onClick={handlecontact}
                  >
                    Contact Us
                  </p>
                </div>
              )}
            </div>

            {props.fromwhere === "Verify" ? (
              ""
            ) : (
              <>
                {clientdetails?.clientId ? (
                  <p
                    onClick={handlelogout}
                    className="headertextcssnew mr-0 mb-0 d-flex align-items-center"
                  >
                    {/* <LockIcon /> */}
                    Logout
                  </p>
                ) : (
                  <div className="row">
                    <p
                      onClick={handlecontact}
                      className="headertextcssnew mb-0 d-flex align-items-center"
                    >
                      Enquiry
                    </p>
                    <NavDropdown
                      className="headertextcssnew"
                      style={{ position: "relative" }}
                      title={"Sign In"}
                    >
                      <NavDropdown.Item onClick={handlelogin}>
                        User Login
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleAgentlogin}>
                        Agent Login
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      className="headersignupcss"
                      style={{ position: "relative" }}
                      title={"Register"}
                    >
                      <NavDropdown.Item onClick={handleregister}>
                        Student SignUp
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={handleAgentregister}>
                        Agent SignUp
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarNew;
