import React from "react";
import ApplyNowModal from "../Modal/ApplyNowModal";
import ProviderContact from "./ProviderContact";
import ProviderCourseList from "./ProviderCourseList";
import ProviderLocation from "./ProviderLocation";
import ProviderProfile from "./ProviderProfile";
import ProviderTransitionArrangement from "./ProviderTransitionArrangement";
import { useNavigate } from "react-router-dom";

const ProviderLowerBody = (props) => {
  const navigate = useNavigate();
  const applydetail =
    JSON.parse(localStorage.getItem("isw_frontend_applycourse_detail")) || {};

  const handlecontact = () => navigate("/contactus");
  return (
    // <div className="coursecompare_bodymaincontainer mt-4 px-0">
    //   <div className="row m-0">
    //     <div className="col-md-3 px-0">
    //       <div className="row m-0 coursebodylower_leftuppercontainer">
    //         <div className="col-md-6 pt-3">
    //           <h5 className="font-weight-bold">Apply Now</h5>
    //           <p>Once you know what you want to study, the next</p>
    //           <ApplyNowModal
    //             fromwhere={"lower"}
    //             clientdetails={props.clientdetails}
    //             configAdmin={props.configAdmin}
    //             coursedetail={props.coursedetail}
    //             providerdetail={props.providerdetail}
    //             setbackdropOpen={props.setbackdropOpen}
    //             toast={props.toast}
    //             applydetail={applydetail}
    //           />
    //         </div>
    //         <div className="col-md-6 px-0">&nbsp;</div>
    //       </div>
    //       <div className="coursebodylower_leftlowercontainer">
    //         <h5 className="font-weight-bold pl-3 pt-3">Financial Assistance</h5>
    //         <div className="row m-0 ">
    //           <div className="col-md-7 pt-3">
    //             <p>Find out information about government assistance for</p>
    //             <button className="btn" onClick={handlecontact}>
    //               Contact Us
    //             </button>
    //           </div>
    //           <div className="col-md-5 px-0">&nbsp;</div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="col-md-9 pr-0">
    //       <div className="courseBody courseBodynav">
    //         <ul className="nav nav-tabs providerdepartmentnav accountbody_responsivenav">
    //           <li className="nav-item coursenavlink">
    //             <a
    //               className="nav-link active"
    //               data-toggle="tab"
    //               href="#profile"
    //               style={{ color: "#fff" }}
    //             >
    //               Profile
    //             </a>
    //           </li>
    //           <li className="nav-item coursenavlink">
    //             <a
    //               className="nav-link "
    //               data-toggle="tab"
    //               href="#courselist"
    //               style={{ color: "#fff" }}
    //             >
    //               Course List
    //             </a>
    //           </li>
    //           <li className="nav-item coursenavlink">
    //             <a
    //               className="nav-link"
    //               data-toggle="tab"
    //               href="#location"
    //               style={{ color: "#fff" }}
    //             >
    //               Location
    //             </a>
    //           </li>
    //           <li className="nav-item coursenavlink">
    //             <a
    //               className="nav-link"
    //               data-toggle="tab"
    //               href="#contact"
    //               style={{ color: "#fff" }}
    //             >
    //               Contact
    //             </a>
    //           </li>
    //           <li className="nav-item coursenavlink">
    //             <a
    //               className="nav-link"
    //               data-toggle="tab"
    //               href="#transitionarrangement"
    //               style={{ color: "#fff", width: "300px" }}
    //             >
    //               Transition Arrangement
    //             </a>
    //           </li>
    //         </ul>
    //         <div className="tab-content coursetabcontent">
    //           <div id="profile" className="container tab-pane active">
    //             <br />
    //             <ProviderProfile providerdetail={props.providerdetail} />
    //           </div>
    //           <div id="courselist" className="container tab-pane fade">
    //             <br />
    //             <ProviderCourseList
    //               courselist={props.courselist}
    //               handlepage={props.handlepage}
    //               getpageno={props.getpageno}
    //             />
    //           </div>
    //           <div id="location" className="container tab-pane fade">
    //             <br />
    //             <ProviderLocation providerdetail={props.providerdetail} />
    //           </div>
    //           <div id="contact" className="container tab-pane fade">
    //             <br />
    //             <ProviderContact providerdetail={props.providerdetail} />
    //           </div>
    //           <div
    //             id="transitionarrangement"
    //             className="container tab-pane fade"
    //           >
    //             <br />
    //             <ProviderTransitionArrangement
    //               providerdetail={props.providerdetail}
    //               setbackdropOpen={props.setbackdropOpen}
    //               transactionalarrangement={props.transactionalarrangement}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <></>
  );
};

export default ProviderLowerBody;
