import React from "react";
import { useNavigate } from "react-router-dom";
import ApplyNowModal from "../Modal/ApplyNowModal";
import CourseOverview from "./CourseOverview";
import CoursePayment from "./CoursePayment";
import CourseRequirement from "./CourseRequirement";

const CourseBodyLower = (props) => {
  const applydetail =
    JSON.parse(localStorage.getItem("isw_frontend_applycourse_detail")) || {};

  const navigate = useNavigate();
  const handlecontact = () => navigate("/contactus");
  return (
    // <div className="coursecompare_bodymaincontainer mt-4 px-0">
    //   <div className="row m-0">
    //     <div className="col-md-3 px-0">
    //       <div className="row m-0 coursebodylower_leftuppercontainer">
    //         <div className="col-md-6 pt-3">
    //           <h5 className="font-weight-bold">Apply Now</h5>
    //           <p>Once you know what you want to study, the next</p>
    //           {Number(props.clientdetails?.published) === 1 ? (
    //             <ApplyNowModal
    //               fromwhere={"lower"}
    //               clientdetails={props.clientdetails}
    //               configAdmin={props.configAdmin}
    //               coursedetail={props.coursedetail}
    //               providerdetail={props.providerdetail}
    //               setbackdropOpen={props.setbackdropOpen}
    //               toast={props.toast}
    //               applydetail={applydetail}
    //             />
    //           ) : null}
    //         </div>
    //         <div className="col-md-6 px-0">&nbsp;</div>
    //       </div>
    //       <div className="coursebodylower_leftlowercontainer">
    //         <h5 className="font-weight-bold pl-3 pt-3">Financial Assistance</h5>
    //         <div className="row m-0 ">
    //           <div className="col-md-7 pt-3">
    //             <p>Find out information about government assistance for</p>
    //             <button className="btn" onClick={handlecontact}>
    //               Contact Us
    //             </button>
    //           </div>
    //           <div className="col-md-5 px-0">&nbsp;</div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="col-md-9 pr-0">
    //       <div className="courseBody courseBodynav">
    //         <ul className="nav nav-tabs departmentnav accountbody_responsivenav">
    //           <li className="nav-item coursenavlink">
    //             <a
    //               className="nav-link active"
    //               data-toggle="tab"
    //               href="#courseoverview"
    //               style={{ color: "#fff" }}
    //             >
    //               Course Overview
    //             </a>
    //           </li>
    //           <li className="nav-item coursenavlink">
    //             <a
    //               className="nav-link"
    //               data-toggle="tab"
    //               href="#requirement"
    //               style={{ color: "#fff" }}
    //             >
    //               Requirement
    //             </a>
    //           </li>
    //           <li className="nav-item coursenavlink">
    //             <a
    //               className="nav-link"
    //               data-toggle="tab"
    //               href="#payment"
    //               style={{ color: "#fff" }}
    //             >
    //               Payment
    //             </a>
    //           </li>
    //           {/* <li className="nav-item coursenavlink">
    //             <a
    //               className="nav-link"
    //               data-toggle="tab"
    //               href="#contact"
    //               style={{ color: "#fff" }}
    //             >
    //               Contact
    //             </a>
    //           </li> */}
    //         </ul>
    //         <div className="tab-content coursetabcontent">
    //           <div id="courseoverview" className="container tab-pane active">
    //             <br />
    //             <CourseOverview
    //               coursedetail={props.coursedetail}
    //               clientdetails={props.clientdetails}
    //               configAdmin={props.configAdmin}
    //               providerdetail={props.providerdetail}
    //               setbackdropOpen={props.setbackdropOpen}
    //               toast={props.toast}
    //               applydetail={applydetail}
    //             />
    //           </div>
    //           <div id="requirement" className="container tab-pane fade">
    //             <br />
    //             <CourseRequirement coursedetail={props.coursedetail} />
    //           </div>
    //           <div id="payment" className="container tab-pane fade">
    //             <br />
    //             <CoursePayment coursedetail={props.coursedetail} />
    //           </div>
    //           {/* <div id="transitionarrangement" className="container tab-pane fade">
    //       <br />
    //       <CourseTransitionArrangement coursedetail={props.coursedetail} />
    //     </div> */}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <></>
  );
};

export default CourseBodyLower;
