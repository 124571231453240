import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../api/bootapi";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";

import SelectCurrency from "react-select-currency";
import Modal from "react-modal";
import ReceiptIcon from "@mui/icons-material/Receipt";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
  cellDefault: {
    backgroundColor: "white", // Default background color
  },
  cellSuccess: {
    backgroundColor: "green", // Green background color
  },
  greenCheckbox: {
    /* Add the custom checkbox styles here */
    content: "",
    display: "inline-block",
    width: "12px",
    height: "12px",
    backgroundColor: "green",
    border: "1px solid green",
    borderRadius: "2px",
    marginRight: "5px",
  },
}));

export const customStylesAttachment = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "1000px",
    maxWidth: "1000px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};

const ReceiptUpload = () => {
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [allIntake, setallIntake] = useState([]);
  const [allVisa, setallVisa] = useState([]);
  const [allHealth, setallHealth] = useState([]);
  const [clientDetail, setclientDetail] = useState();
  const [region, setregion] = useState();
  const [country1, setcountry] = useState();
  const [country1Passport, setcountryPassport] = useState();
  const [countryrequired, setcountryrequired] = useState(false);
  const [staterequired, setstaterequired] = useState(false);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [stateCust, setstateCust] = useState();
  const classes = useStyles();
  const [backdropopen, setbackdropOpen] = useState(false);
  const { register, handleSubmit } = useForm();

  const closeModal = (id) => {
    // setIsOpen(false);
    setIsOpen2(false);
    // setIsOpen3(false);
    // setIsOpen4(false);
  };

  const loggedInUser = localStorage.getItem("agentLogin");
  const [currency1, setCurrency] = useState("");
  const [agentData, setAgentData] = useState();
  const [agentType, setAgentType] = useState();
  const [openTransactionalForm, setOpenTransactionalForm] = useState(false);
  const [transactionalArrangementList, setTransactionalArrangementList] =
    useState();

  const [filesizemore, setfilesizemore] = useState(false);
  const [attachmentdisplay, setattachmentdisplay] = useState(false);
  const [documentpage, setDocPage] = useState(0);
  const chkfilesize = (e) => {
    if (e.target.files.length) {
      let fileSize = 0;
      for (let i = 0; i < e.target.files.length; i++) {
        fileSize += e.target.files[i].size;
      }
      //console.log(fileSize);
      if (fileSize / 1048576 > 200) {
        setfilesizemore(true);
      } else {
        setfilesizemore(false);
      }
    }
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const onSubmitAttachment = (e) => {
    e.preventDefault(); // Prevent default form submission
    //console.log("Selected Files: ", selectedFiles);

    if (selectedFiles.length === 0) {
      setattachmentdisplay(true);
      return;
    }

    let formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("stepfile", selectedFiles[i], selectedFiles[i].name);
    }
    postAttachment(formData, selectedFiles);
  };

  const postAttachment = (formData, fileToUpload) => {
    //console.log("Current here sp--->");
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];

    let logs3 = "Added attachment - By ";
    //  + isw_user_name;
    setbackdropOpen(true);

    if (fileToUpload.length) {
      // if (radioattachmentvalue != undefined) {
      axios
        .post(`${base_url.api5}/uploadAttachment`, formData, {
          headers: {
            Authorization: `Bearer ${clientdetails?.token}`,
            clientId: clientdetails?.clientId,
            createdBy: "Sangam Panda",
            createdOn: datenow,
            log: logs3,
            documentType: "paymentreceipt",
            stepfile: [null],
            // isw_user_name + " (" + isw_user_email + ")",
          },
        })
        .then(
          (res) => {
            //console.log(res.data.data);
            //console.log("Current Page No--->", documentpage);
            getClientDoc(documentpage);
            setIsOpen2(false);
            setbackdropOpen(false);
            toast.success(res.data.message);
          },
          (error) => {
            setbackdropOpen(false);
            //console.log(error);
            // toast.error(JSON.stringify(error.response.data.message));
          }
        );
      // } else {
      //   //console.log("select type");
      //   setdisplayattachmentradio(true);
      // }
    }
  };

  const getClientDoc = (documentpage) => {
    setbackdropOpen(false);
    axios
      .get(`${base_url.api5}/getAttachmentsByClientId`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          clientId: clientdetails?.clientId,
          page: documentpage,
          size: 20,
          documentType: "paymentreceipt",
        },
      })
      .then((res) => {
        //console.log(res.data.data.content);
        //console.log(res);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleUploadSuccess = () => {
    setUploadSuccess(true);
  };

  return (
    <div class="accountbody">
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div class="header-container">
        <h2 className="accountbody-title">Payment Receipt</h2>
        {/* <button className="outline-button" onClick={() => setIsOpen2(true)} >ADD DOCUMENT</button> */}
      </div>

      <div style={{ marginLeft: "30px", marginRight: "35px" }}>
        <div class="header-container">
          <h4 className="s_upload">Upload Payment Receipt</h4>
        </div>
        <form
          class="mt-3"
          onChange={(e) => chkfilesize(e)}
          onSubmit={(e) => {
            e.preventDefault(); // Prevent page refresh
            onSubmitAttachment(e);
            // Simulate a successful upload
            setTimeout(() => {
              handleUploadSuccess();
            }, 1000);
          }}
        >
          <TableContainer style={{ marginTop: "10px" }} component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#00ca8e" }}>
                <TableRow>
                  <TableCell>SN</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Document Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Download/Upload</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {" "}
                    1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {" "}
                    <input type="checkbox" checked={uploadSuccess} />{" "}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Payment Receipt
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Payment Receipt{" "}
                  </TableCell>

                  <TableCell>
                    <input
                      type="file"
                      id="fileUpload"
                      style={{ padding: "10px", marginTop: "15px" }}
                      name="picture"
                      accept=".pdf,.PDF"
                      multiple
                      onChange={(e) => setSelectedFiles(e.target.files)}
                    />
                    <p class="mt-2 mx-3 " className="s_choosefile">
                      <span>Note:</span> All files should be at least 720p and
                      less than 4.0 GB.
                    </p>{" "}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <button
                      className="btn coursesearchbody_applynowbutton "
                      type="submit"
                      style={{ cursor: "pointer" }}
                    >
                      Upload
                    </button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </form>
      </div>
    </div>
  );
};

export default ReceiptUpload;
