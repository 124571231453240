import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";

import Button from "react-bootstrap/Button";
import axios from "axios";
import base_url from "../api/bootapi";
import Tooltip from "@mui/material/Tooltip";
import NoLogoAvailable from "./../../assets/images/nologo.png";
import { makeStyles } from "@mui/styles";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#000000", // Black color
  },
  tooltip: {
    backgroundColor: "#000000", // Black color
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const DeleteModal = (props) => {
  const [deletemodal, setdeletemodal] = useState(false);
  const handledeletemodal = () => setdeletemodal(!deletemodal);
  const handledelete = () => {
    axios
      .delete(`${base_url.api5}/deleteCompareById`, {
        headers: { compareId: props.item.id },
      })
      .then((res) => {
        props.toast.success("Compare List deleted");
        handledeletemodal();
        props.callcompareapi();
        props.getheader(props.pageno, props.columnName);
      });
  };
  return (
    <div className="col-md-1">
      <i className="fa fa-trash" onClick={handledeletemodal} />
      <Modal show={deletemodal} onHide={handledeletemodal}>
        <Modal.Header closeButton>
          <Modal.Title>International School World</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography variant="h5" color="primary">
            Are you sure you want to remove this course from compare?
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handledeletemodal}>
            No
          </Button>
          <Button variant="primary" onClick={handledelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const CompareDetails = (props) => {
  const [comparemodal, setcomparemodal] = useState(false);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  let navigate = useNavigate();
  let location = useLocation();
  const [comparedata, setcomparedata] = useState([]);

  const handlecomparemodal = () => setcomparemodal(!comparemodal);

  const callcompareapi = () => {
    //console.log("entering in callcompareapi");
    if (clientdetails?.clientId) {
      setcomparemodal(true);
      if (clientdetails?.token) {
        if (clientdetails?.agentId) {
          getcomparelistbtagent();
        } else {
          getcomparelist();
        }
      } else if (clientdetails?.clientId) {
        getcomparelistbylead();
      }
    } else {
      sessionStorage.setItem("prev_path_before_login", location.pathname);
      // navigate("/login");
      navigate("/force-login");
    }
  };
  const getcomparelistbtagent = () => {
    axios
      .get(`${base_url.api5}/getCompareListByAgentId`, {
        headers: {
          agentId: clientdetails?.agentId,
          columnName: "id",
          direction: "DESC",
          page: 0,
          size: 20,
        },
      })
      .then((res) => setcomparedata(res.data.data))
      .catch((err) => setcomparedata([]));
  };
  const getcomparelist = () => {
    axios
      .get(`${base_url.api5}/getClientCompareListByClientId`, {
        headers: {
          clientId: clientdetails?.clientId,
          columnName: "id",
          direction: "DESC",
          page: 0,
          size: 20,
        },
      })
      .then((res) => setcomparedata(res.data.data))
      .catch((err) => setcomparedata([]));
  };
  const getcomparelistbylead = () => {
    axios
      .get(`${base_url.api5}/getClientCompareListByClientTempId`, {
        headers: {
          clientTempId: clientdetails?.clientId,
          columnName: "id",
          direction: "DESC",
          page: 0,
          size: 20,
        },
      })
      .then((res) => setcomparedata(res.data.data))
      .catch((err) => setcomparedata([]));
  };

  const handlecompare = () => {
    handlecomparemodal();
    if (clientdetails?.clientId) {
      navigate("/course-compare");
    } else {
      sessionStorage.setItem("prev_path_before_login", "/course-compare");
      // navigate("/login");
      navigate("/force-login");
    }
  };

  const clearall = () => {
    props.setbackdropOpen(true);
    let arr = [];
    comparedata.forEach((element) => arr.push(element.id));
    let delete_str = arr.join(",");
    axios
      .delete(`${base_url.api5}/deleteCompareById`, {
        headers: {
          compareId: delete_str,
        },
      })
      .then((res) => {
        props.setbackdropOpen(false);
        props.toast.success("Compare list cleared");
        props.getheader(props.pageno, props.columnName);
        handlecomparemodal();
      })
      .catch((err) => {
        props.setbackdropOpen(false);
        if (err.response) {
          props.toast.error(err.response.data.message);
        } else {
          props.toast.error("Something went Wrong!!");
        }
      });
  };

  return (
    <div>
      <button
        onClick={callcompareapi}
        className="btn coursesearchbody_comparetext"
      >
        Compare ({props.comparedetailmodal.length})
      </button>

      <Modal
        show={comparemodal}
        onHide={handlecomparemodal}
        size="lg"
        centered
        backdrop="static"
      >
        <div className="row mx-0 py-3 px-1">
          <h3 className="col-md-8 comparemodal_headingtext">
            Finalize and compare your selections
          </h3>
          <div className="col-md-4 text-right">
            <i
              className="fa fa-times comparemodal_cross"
              onClick={handlecomparemodal}
            />
            <br />
            <div className="d-flex justify-content-end">
              {comparedata.length > 0 && (
                <button
                  className="btn comparemodal_comparebutton mr-2"
                  onClick={clearall}
                >
                  Clear All
                </button>
              )}
              <button
                className="btn comparemodal_comparebutton"
                onClick={handlecompare}
              >
                Compare Now
              </button>
            </div>
          </div>
        </div>
        <hr className="my-0" />
        <Modal.Body>
          {comparedata.length > 0 ? (
            <>
              {comparedata.map((item, index) => (
                <div key={index} className="comparedetail_modalbody">
                  <div className="row mx-0">
                    <div className="col-md-1 comparedetail_common">
                      <input
                        type="checkbox"
                        style={{ width: "20px" }}
                        checked
                      />
                    </div>
                    <div className="col-md-2 comparedetail_common">
                      <img
                        alt="provider_image"
                        width={"100%"}
                        height={50}
                        src={item.logo || NoLogoAvailable}
                      />
                    </div>
                    <div
                      className="col-md-8"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <BootstrapTooltip title={item.name}>
                        <h6 className="font-weight-bold pt-2 coursecompare_nameellipsis">
                          {item.name}
                        </h6>
                      </BootstrapTooltip>
                      <BootstrapTooltip title={item.branch_name}>
                        <p className="mb-0 coursecompare_nameellipsis">
                          <span className="font-weight-bold">College: </span>
                          {item.branch_name}
                        </p>
                      </BootstrapTooltip>
                    </div>
                    <DeleteModal
                      item={item}
                      toast={props.toast}
                      getheader={props.getheader}
                      columnName={props.columnName}
                      pageno={props.pageno}
                      callcompareapi={callcompareapi}
                    />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <Typography variant="h5" color="primary">
              No course to compare!!
            </Typography>
          )}
        </Modal.Body>
        <hr className="my-0" />
        <div className="text-center mb-4 mt-2">
          <div>
            <button
              className="btn comparemodal_comparebutton"
              onClick={handlecomparemodal}
            >
              <i className="fa fa-plus" /> Search to add more to compare
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CompareDetails;
