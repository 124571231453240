import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import ISWLogo from "./../../../assets/images/logo.png";
import ISWLogo from "./../../../assets/images/downlogo.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonIcon from "@mui/icons-material/Person";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import MessageIcon from "@mui/icons-material/Message";
import AppsIcon from "@mui/icons-material/Apps";
import GetAppIcon from "@mui/icons-material/GetApp";
import PaymentIcon from "@mui/icons-material/Payment";
import SecurityIcon from "@mui/icons-material/Security";
import PublishIcon from "@mui/icons-material/Receipt";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MailIcon from "@mui/icons-material/Mail";

const LeadSidebar = ({ sidebartab, handlesidebartab }) => {
  let navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const gotohomepage = () => navigate("/");
  return (
    <div
      className="account_sidebar"
      style={{ width: "100%", height: "100vh", boxSizing: "border-box" }}
    >
      {/* <div className="account_sidebarimgcontainer mb-4">
        <img className="cursor-pointer" onClick={gotohomepage} alt="isw_logo" src={ISWLogo} />
      </div> */}
      <div
        className="account_sidebar_inn"
        style={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div class="side-menu" style={{ marginTop: "-9%" }}>
          <div class="row">
            <nav
              class="navbar navbar-expand-lg navbar-light bg-light"
              style={{ width: "100%", backgroundColor: "#fff !important" }}
            >
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#bs-sidebar-navbar-collapse-1"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="side-menu-container">
                <ul class="nav navbar-nav">
                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "edit" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("edit")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <PersonIcon />
                      </span>{" "}
                      Profile
                    </a>
                  </li>

                  <li class="nav-item dropdown">
                    <div
                      onClick={toggleExpansion}
                      class="nav-link"
                      data-toggle="collapse"
                      href="#dropdown-lvl1"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a>
                        <span style={{ padding: "15px 8px" }}>
                          <MailIcon />
                        </span>
                        Mail
                      </a>
                      <a>
                        <div href="#dropdown-lvl1">
                          {isExpanded ? (
                            <span style={{ position: "relative" }}>
                              <span
                                style={{
                                  position: "absolute",
                                  width: "50px",
                                  height: "50px",
                                  zIndex: 3,
                                }}
                                data-toggle="collapse"
                                href="#dropdown-lvl1"
                              ></span>
                              <ExpandLessIcon />
                            </span>
                          ) : (
                            <span style={{ position: "relative" }}>
                              <span
                                data-toggle="collapse"
                                style={{
                                  position: "absolute",
                                  width: "50px",
                                  height: "50px",
                                  zIndex: 3,
                                }}
                                href="#dropdown-lvl1"
                              ></span>
                              <ExpandMoreIcon />
                            </span>
                          )}
                        </div>
                      </a>
                    </div>
                    <div id="dropdown-lvl1" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="nav navbar-nav">
                          <li
                            class="nav-item"
                            className={`account_sidebarelementcontainer ${
                              sidebartab === "message" ? "activeaccount" : ""
                            }`}
                            onClick={() => handlesidebartab("message")}
                          >
                            <a class="nav-link">
                              <span style={{ padding: "inherit" }}>
                                <MoveToInboxIcon />
                              </span>
                              Inbox
                            </a>
                          </li>

                          <li
                            class="nav-item"
                            className={`account_sidebarelementcontainer ${
                              sidebartab === "inbox" ? "activeaccount" : ""
                            }`}
                            onClick={() => handlesidebartab("inbox")}
                          >
                            <a class="nav-link">
                              <span style={{ padding: "inherit" }}>
                                <MessageIcon />
                              </span>
                              Sent
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "comapre" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("comapre")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <CompareArrowsIcon />
                      </span>{" "}
                      Compare List
                    </a>
                  </li>

                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "applied" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("applied")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <ListAltIcon />
                      </span>{" "}
                      Enquired courses list
                    </a>
                  </li>

                  {/* <li class="nav-item" className={`account_sidebarelementcontainer ${sidebartab === "dashboard" ? "activeaccount" : ""
                                        }`}
                                        onClick={() => handlesidebartab("dashboard")}><a class="nav-link" ><span style={{ padding: "inherit" }}><SystemUpdateAltIcon /></span> Applications Status</a></li> */}

                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "fees" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("fees")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <PaymentIcon />
                      </span>{" "}
                      Fees Payment Schedule{" "}
                    </a>
                  </li>

                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "change" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("change")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <SecurityIcon />
                      </span>{" "}
                      Security{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadSidebar;

// import React from "react";
// import { useNavigate } from "react-router-dom";
// // import ISWLogo from "./../../../assets/images/logo.png";
// import ISWLogo from "./../../../assets/images/downlogo.png";

// const LeadSidebar = ({ sidebartab, handlesidebartab }) => {
//     let navigate = useNavigate();

//     const gotohomepage = () => navigate("/");
//     return (
//         <div className="account_sidebar">
//             {/* <div className="account_sidebarimgcontainer mb-4">
//         <img className="cursor-pointer" onClick={gotohomepage} alt="isw_logo" src={ISWLogo} />
//       </div> */}
//             <div className="account_sidebar_inn">
//                 <div
//                     className={`account_sidebarelementcontainer ${sidebartab === "dashboard" ? "activeaccount" : ""
//                         }`}
//                     onClick={() => handlesidebartab("dashboard")}
//                 >
//                     <i className="fa fa-address-book" />
//                     <p>Compare List</p>
//                 </div>
//                 <div
//                     className={`account_sidebarelementcontainer ${sidebartab === "applied" ? "activeaccount" : ""
//                         }`}
//                     onClick={() => handlesidebartab("applied")}
//                 >
//                     <i className="fa fa-credit-card-alt" />
//                     <p>Applied Courses List</p>
//                 </div>
//                 <div
//                     className={`account_sidebarelementcontainer ${sidebartab === "edit" ? "activeaccount" : ""
//                         }`}
//                     onClick={() => handlesidebartab("edit")}
//                 >
//                     <i className="fa fa-credit-card-alt" />
//                     <p>Profile</p>
//                 </div>
//                 <div
//                     className={`account_sidebarelementcontainer ${sidebartab === "inbox" ? "activeaccount" : ""
//                         }`}
//                     onClick={() => handlesidebartab("inbox")}
//                 >
//                     <i className="fa fa-credit-card-alt" />
//                     <p>Inbox</p>
//                 </div>
//                 <div
//                     className={`account_sidebarelementcontainer ${sidebartab === "message" ? "activeaccount" : ""
//                         }`}
//                     onClick={() => handlesidebartab("message")}
//                 >
//                     <i className="fa fa-credit-card-alt" />
//                     <p>Sent Message</p>
//                 </div>
//                 <div
//                     className={`account_sidebarelementcontainer ${sidebartab === "document" ? "activeaccount" : ""
//                         }`}
//                     onClick={() => handlesidebartab("document")}
//                 >
//                     <i className="fa fa-credit-card-alt" />
//                     <p>Document</p>
//                 </div>
//                 <div
//                     className={`account_sidebarelementcontainer ${sidebartab === "fees" ? "activeaccount" : ""
//                         }`}
//                     onClick={() => handlesidebartab("fees")}
//                 >
//                     <i className="fa fa-credit-card-alt" />
//                     <p>Fees & Payment</p>
//                 </div>
//                 <div
//                     className={`account_sidebarelementcontainer ${sidebartab === "change" ? "activeaccount" : ""
//                         }`}
//                     onClick={() => handlesidebartab("change")}
//                 >
//                     <i className="fa fa-lock" />
//                     <p>Security</p>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LeadSidebar;
