import React, { useState } from "react";
import { useForm } from "react-hook-form";
import base_url from "../api/bootapi";
import axios from "axios";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const ChangePassword = () => {
  const [backdropopen, setbackdropOpen] = useState(false);
  const classes = useStyles();
  const [newpassword, setpassword] = useState();
  const [cpassword, setcpassword] = useState();
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [inputtype, setinputtype] = useState("password");
  const [confirminputtype, setconfirminputtype] = useState("password");
  const [oldinputtype, setoldinputtype] = useState("password");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const changePassword = (data2) => {
    if (cpassword === newpassword) {
      setbackdropOpen(true);
      let data = {
        username: clientdetails?.username,
        password: data2.password,
      };
      axios
        .post(`${base_url.api5}/changePasswordByUsername`, data, {
          headers: {
            Authorization: `Bearer ${clientdetails?.token}`,
            newPassword: data2.Newpassword,
          },
        })
        .then((res) => {
          setbackdropOpen(false);
          toast.success("Password Changed Successfully");
        })
        .catch((err) => {
          setbackdropOpen(false);
          if (err.response) {
            toast.error(JSON.stringify(err.response.data.message));
          } else {
            toast.error(JSON.stringify("Something went wrong"));
          }
        });
    }
  };

  return (
    <div class="accountbody">
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <h2 className="accountbody-title">Change Password</h2>
      <form
        onSubmit={handleSubmit(changePassword)}
        class="accountenquiry account-profilebox"
      >
        <div className="row my-4">
          <div className="col-md-12">
            <label>Old Password</label>
            <div style={{ position: "relative" }}>
              <input
                type={oldinputtype}
                {...register("password", { required: true })}
                className="form-control"
                placeholder="Old Password"
              />
              <i className="fa fa-lock designi" />
              {oldinputtype === "password" ? (
                <i
                  className="fa fa-eye-slash passwordiconpositioning"
                  onClick={() => setoldinputtype("text")}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className="fa fa-eye passwordiconpositioning"
                  onClick={() => setoldinputtype("password")}
                  aria-hidden="true"
                ></i>
              )}
            </div>
            {errors.password && (
              <p className="errormessage mb-0">Password is required</p>
            )}
          </div>
        </div>
        <div className="row my-4">
          <div className="col-md-12">
            <label>New Password</label>
            <div style={{ position: "relative" }}>
              <input
                {...register("Newpassword", {
                  required: true,
                  pattern:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
                })}
                type={inputtype}
                className="form-control"
                onChange={(e) => setpassword(e.target.value)}
                placeholder="New Password"
              />
              <i className="fa fa-lock designi" />
              {inputtype === "password" ? (
                <i
                  className="fa fa-eye-slash passwordiconpositioning"
                  onClick={() => setinputtype("text")}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className="fa fa-eye passwordiconpositioning"
                  onClick={() => setinputtype("password")}
                  aria-hidden="true"
                ></i>
              )}
            </div>
            {errors.Newpassword?.type === "pattern" && (
              <p className="errormessage mb-0">
                Password must have at least 8 characters with at least one
                lowercase letter, one uppercase letter, one numeric digit, and
                one special character
              </p>
            )}
            {errors.Newpassword?.type === "required" && (
              <p className="errormessage mb-0">Password is required</p>
            )}
          </div>
        </div>
        <div className="row my-4">
          <div className="col-md-12">
            <label>Confirm Password</label>
            <div style={{ position: "relative" }}>
              <input
                type={confirminputtype}
                {...register("Confirmpassword", { required: true })}
                className="form-control"
                placeholder="Confirm Password"
                onChange={(e) => setcpassword(e.target.value)}
              />
              <i className="fa fa-lock designi" />
              {confirminputtype === "password" ? (
                <i
                  className="fa fa-eye-slash fonticonpositioning"
                  onClick={() => setconfirminputtype("text")}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className="fa fa-eye fonticonpositioning"
                  onClick={() => setconfirminputtype("password")}
                  aria-hidden="true"
                ></i>
              )}
            </div>
            {cpassword && cpassword !== newpassword && (
              <p className="errormessage mb-0">Password Not Matching</p>
            )}
            {errors.Confirmpassword && (
              <p className="errormessage mb-0">Confirm Password is required</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button type="submit" className="btn">
              Change Password
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
