import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import base_url from "../api/bootapi";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import ReactNavbar from "../Navbar/Navbar";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Footer from "../Footer/Footer";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import NavbarNew from "../Navbar/NavbarNew";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const AgentApplyNow = () => {
  const loggedInUser = localStorage.getItem("agentLogin");
  const getCourse = JSON.parse(localStorage.getItem("courseId"));
  const classes = useStyles();
  let navigate = useNavigate();
  const [backdropopen, setbackdropOpen] = useState(false);
  const pageStartRef = useRef(null);
  const courseid = JSON.parse(localStorage.getItem("courseId"));
  const [country1, setcountry] = useState();
  const [region, setregion] = useState();

  const [selectedBranchId, setSelectedBranchId] = useState(""); // State to store the selected branchId
  const [selectedBranchValue, setSelectedBranchValue] = useState(""); // State to store the selected branchId

  const handleSelectChange = (event) => {
    const selectedOption = event.target.selectedOptions[0]; // Get the selected option
    setSelectedBranchId(selectedOption.id); // Update selectedBranchId with the id of the selected option
    setSelectedBranchValue(selectedOption.value); // Update selectedBranchValue with the value of the selected option
    //console.log(selectedOption.id);
    //console.log(selectedOption.value);
  };

  const [totalAddress, setTotalAddress] = useState([]);

  const getAllBranchAddresses = () => {
    axios
      .get(`${base_url.api5}/getAllBranchAddressesByCourseId`, {
        headers: {
          courseId: getCourse.courseId,
        },
      })
      .then((response) => {
        //console.log("res----------->", response);
        setTotalAddress(response.data.data);
        setbackdropOpen(false);
      })
      .catch((error) => {
        setbackdropOpen(false);
      });
  };

  const branchIdToNameMap = {};

  for (const item of totalAddress) {
    branchIdToNameMap[item.branchId] = item.branchName;
  }

  //console.log("branchIdToNameMap------------>", branchIdToNameMap);

  const transformedArray = Object.entries(branchIdToNameMap).map(
    ([branchId, branchName]) => ({
      branchName,
      branchId: Number(branchId), // Assuming you want branchId as a number
    })
  );

  //console.log("transformedArray------------->", transformedArray);

  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const applydetails = JSON.parse(
    localStorage.getItem("isw_apply_details_frontend")
  );
  const [clienttempdata, setclienttempdata] = useState();
  const applydetail =
    JSON.parse(localStorage.getItem("isw_frontend_applycourse_detail")) || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [countryrequired, setcountryrequired] = useState(false);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    if (!clientdetails?.clientId) {
      navigate("/");
    } else if (clientdetails?.agentId) {
      setbackdropOpen(true);
      pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
      getclientdetail();
    } else {
      navigate("/apply");
    }
    getAllBranchAddresses();
  }, []);

  const getclientdetail = () => {
    axios
      .get(`${base_url.api5}/getAgentByUsername`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          username: clientdetails?.username,
        },
      })
      .then((res) => {
        let client = res.data.data;
        setclienttempdata(res.data.data);
        reset(client);
        setcountry(client.country || "");
        setregion(client.state || "");
        setbackdropOpen(false);
      })
      .catch((err) => setbackdropOpen(false));
  };

  const selectCountry = (val) => {
    setcountry(val);
    setValue("abn", "");
    setValue("acn", "");
    setValue("can", "");
    setValue("pan", "");
  };
  const selectRegion = (val) => setregion(val);
  const applyclient = (data2) => {
    if (!country1) {
      setcountryrequired(true);
      return;
    }
    setcountryrequired(false);
    setbackdropOpen(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let mailComments = `Agent Application
        \nCourse Name- ${applydetails.coursename}
        \n Branch Name-${selectedBranchValue}
        \n Cricos Course Code-${applydetails.cricos}
        \n-->Course Applied by agent ${clientdetails?.name}
        on ${moment(datenow).format("DD MMM YYYY hh.mm.a")}`;
    data2.agentId = clienttempdata.agentId;
    data2.country = country1;
    data2.state = region || null;
    data2.updatedOn = datenow;
    axios
      .put(`${base_url.api5}/updateAgentByUsername`, data2, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
        },
      })
      .then((res) => {
        handlesuccess(mailComments);
        // ==================================================================//
        let arraytwo =
          JSON.parse(localStorage.getItem("AppliedCourseList")) || [];
        let bal_array = {
          coursename: applydetails.coursename,
          branchName: selectedBranchValue,
          providername: applydetails.providername,
          cricos: applydetails.cricos,
          courseCode: applydetails.coursecode,
          offShoreFees: applydetails.offShoreFees,
          OnShoreFees: applydetails.OnShoreFees,
          duration: applydetails.duration,
          courseCode: applydetails.courseCode,
        };
        arraytwo.push(bal_array);
        localStorage.setItem("AppliedCourseList", JSON.stringify(arraytwo));
        // ==================================================================//
        navigate("/course-searchresult");
      })
      .catch((err) => {
        //console.log(err);
        // handleerror(err)
      });
  };

  const handlesuccess = (mailComments) => {
    toast.success(
      `Your application has been applied for ${applydetails.coursename} at ${selectedBranchValue}`
    );
    const key = `${courseid.courseId}${courseid.branchId}`;
    applydetail[key] = true;
    localStorage.setItem(
      "isw_frontend_applycourse_detail",
      JSON.stringify(applydetail)
    );
    let isAgent;
    if (Number(loggedInUser) === 1) {
      isAgent = 1;
    } else {
      isAgent = 0;
    }
    let mailData = {
      description: mailComments,
      fromEmailId: clientdetails?.username,
      id: 0,
      mailType: "received",
      status: 0,
      isAgent: isAgent,
      subject:
        "Application Received From " +
        clientdetails?.name +
        " (" +
        clientdetails?.username +
        ")",
      toEmailId: clientdetails?.username,
    };
    axios.post(`${base_url.api5}/createEmail`, mailData);
    setbackdropOpen(false);
    navigate.goBack();
  };
  const handleerror = (err) => {
    setbackdropOpen(false);
    if (err.response) {
      toast.error(err.response.data.message);
    } else {
      toast.error("Something went wrong");
    }
  };
  return (
    <div ref={pageStartRef}>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <ReactNavbar /> */}
      <NavbarNew />
      <div className="coursecompare_bodymaincontainer my-4 login">
        <h2 className="text-center mb-0">Apply Now at</h2>
        <h2 className="text-center mb-4">International Student World</h2>
        <h4 className="text-center my-4 login_h4">
          You are applying for {applydetails?.coursename}
          at {selectedBranchValue}
        </h4>
        <form
          onSubmit={handleSubmit(applyclient)}
          className="login_container mt-4"
        >
          <h4>PERSONAL DETAILS</h4>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("name", { required: true })}
                  className="form-control"
                  placeholder="Name"
                />
                <i className="fa fa-user-o designi" />
              </div>
              {errors.name && (
                <p className="errormessage mb-0">Name is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("email")}
                  className="readonlyformcontrol"
                  readOnly
                />
                <i className="fa fa-envelope-o designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Commission Percentage</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("commissionPercentage")}
                  className="form-control"
                  placeholder="Commission Percentage"
                />
                <i className="fa fa-usd designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Referal Percentage</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("referalPercentage")}
                  className="form-control"
                  placeholder="Referal Percentage"
                />
                <i className="fa fa-usd designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>Website</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("website")}
                  className="form-control"
                  type="url"
                  placeholder="Website"
                />
                <i className="fa fa-internet-explorer designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>VAT/GST</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("vatNumber")}
                  className="form-control"
                  placeholder="VAT/GST"
                />
                <i className="fa fa-info-circle designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Phone</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("phone")}
                  className="form-control"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Phone Number"
                />
                <i className="fa fa-volume-control-phone designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Mobile</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("mobile")}
                  className="form-control"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Mobile"
                />
                <i className="fa fa-volume-control-phone designi" />
              </div>
            </div>
          </div>

          <div className="my-4 row">
            <div className="col-md-3">
              <label>Contact Person</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("contactPerson")}
                  className="form-control"
                  placeholder="Contact Person"
                />
                <i className="fa fa-user-o designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>Designation</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("designation")}
                  className="form-control"
                  placeholder="Designation"
                />
                <i className="fa fa-clipboard designi" />
              </div>
            </div>
          </div>
          <h4>Campus Location</h4>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>
                Select a campus location for the course you want to apply
                <span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <select
                  className="form-control selecttag2"
                  {...register("branchId", { required: true })}
                  onChange={handleSelectChange}
                  value={selectedBranchValue}
                >
                  <option value="">Select Branch</option>
                  {transformedArray?.map((cuntitem, index) => (
                    <option
                      key={index}
                      value={cuntitem.branchName}
                      id={cuntitem.branchId}
                    >
                      {cuntitem.branchName}
                    </option>
                  ))}
                  <option value="AnyLocation">Any location</option>
                </select>
                <i className="fa fa-share-square designi" />
              </div>
              {selectedBranchValue == "" && (
                <p
                  className="errormessage mb-0"
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    cursor: "none",
                  }}
                >
                  Note:&nbsp;
                  <span style={{ fontWeight: "normal" }}>
                    If you are unsure of the location, please choose 'Any
                    location'. We will confirm this later
                  </span>
                </p>
              )}
              {errors.branchId && (
                <p className="errormessage mb-0">Branch name is required</p>
              )}
            </div>
          </div>
          <h4>CONTACT DETAILS</h4>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>Address</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("address")}
                  className="form-control"
                  placeholder="Address"
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>
                Country<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <CountryDropdown
                  className="form-control selecttag2"
                  value={country1}
                  onChange={(val) => selectCountry(val)}
                />
                <i className="fa fa-globe designi" />
              </div>
              {countryrequired && (
                <p className="errormessage mb-0">Country is required</p>
              )}
            </div>
            <div className="col-md-3">
              <label>State</label>
              <div style={{ position: "relative" }}>
                {country1 === "Nepal" ? (
                  <select
                    className="form-control selecttag2"
                    onChange={(e) => selectRegion(e.target.value)}
                  >
                    <option value="">Select Province</option>
                    <option value="Province No. 1">Province No. 1</option>
                    <option value="Madhesh Province">Madhesh Province</option>
                    <option value="Bagmati Province">Bagmati Province</option>
                    <option value="Gandaki Province">Gandaki Province</option>
                    <option value="Lumbini Province">Lumbini Province</option>
                    <option value="Karnali Province">Karnali Province</option>
                    <option value="Sudurpashchim Province">
                      Sudurpashchim Province
                    </option>
                  </select>
                ) : (
                  <RegionDropdown
                    className="form-control selecttag2"
                    defaultOptionLabel="Select State"
                    country={country1}
                    value={region}
                    onChange={(val) => selectRegion(val)}
                  />
                )}
                <i className="fa fa-globe designi" />
              </div>
            </div>
            <div className="col-md-3">
              <label>City</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("city")}
                  className="form-control"
                  placeholder="City"
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col">
              <label>Latitude</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("lattitude")}
                  className="form-control"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Latitude"
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
            <div className="col">
              <label>Longitude</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("longitude")}
                  className="form-control"
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Longitude"
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
            {country1 === "Australia" ? (
              <div className="col-md-6 row mx-0">
                <div className="col pl-0">
                  <label>ABN</label>
                  <div style={{ position: "relative" }}>
                    <input
                      {...register("abn")}
                      className="form-control"
                      placeholder="ABN"
                    />
                    <i className="fa fa-info-circle designi" />
                  </div>
                </div>
                <div className="col pr-0">
                  <label>ACN</label>
                  <div style={{ position: "relative" }}>
                    <input
                      {...register("acn")}
                      className="form-control"
                      placeholder="ACN"
                    />
                    <i className="fa fa-info-circle designi" />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {country1 === "Canada" ? (
                  <div className="col">
                    <label>CAN</label>
                    <div style={{ position: "relative" }}>
                      <input
                        {...register("can")}
                        className="form-control"
                        placeholder="CAN"
                      />
                      <i className="fa fa-info-circle designi" />
                    </div>
                  </div>
                ) : (
                  <>
                    {country1 === "United States" ? (
                      <div className="col">
                        <label>ACN</label>
                        <div style={{ position: "relative" }}>
                          <input
                            {...register("acn")}
                            className="form-control"
                            placeholder="ACN"
                          />
                          <i className="fa fa-info-circle designi" />
                        </div>
                      </div>
                    ) : (
                      <div className="col">
                        <label>PAN</label>
                        <div style={{ position: "relative" }}>
                          <input
                            {...register("pan")}
                            className="form-control"
                            placeholder="PAN"
                          />
                          <i className="fa fa-info-circle designi" />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>PostCode</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("zipCode")}
                  className="form-control"
                  placeholder="PinCode"
                />
                <i className="fa fa-globe designi" />
              </div>
            </div>
            <div className="col-md-9">
              <label>Remarks</label>
              <div style={{ position: "relative" }}>
                <textarea
                  {...register("remark")}
                  className="form-control"
                  placeholder="Remarks"
                />
                <i className="fa fa-comment-o designi" />
              </div>
            </div>
          </div>
          <div className="my-4 row">
            <div className="col-md-3">
              <label>University</label>
              <div style={{ position: "relative" }}>
                <input
                  {...register("agentUniversityName")}
                  className="form-control"
                  placeholder="University"
                />
                <i className="fa fa-university designi" />
              </div>
            </div>
            <div className="col-md-9">
              <label>Overview</label>
              <div style={{ position: "relative" }}>
                <textarea
                  {...register("overview")}
                  className="form-control"
                  placeholder="Overview"
                />
                <i className="fa fa-comment-o designi" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">{null}</div>
            <div className="col-md-4">
              <button type="submit" className="btn">
                Apply
              </button>
            </div>
            <div className="col-md-4">{null}</div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default AgentApplyNow;
