import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Mainhomepage from "./Components/MainHomepage/Mainhomepage";
import CourseDetail from "./Components/Courses/CourseDetail";
import CourseSearchResult from "./Components/CourseSearchResult/CourseSearchResult";
import PopularCourse from "./Components/CourseSearchResult/PopularCourse";
import Institution from "./Components/Institution/Institution";
import ProviderDetail from "./Components/Provider/ProviderDetail";
import { useEffect } from "react";
import { useStateValue } from "./Components/ContextAPI/StateProvider";
import { toast, ToastContainer } from "react-toastify";
import CourseCompare from "./Components/CourseCompare/CourseCompare";
import axios from "axios";
import base_url from "./Components/api/bootapi";
import { useHistory } from "react-router-dom";
import VerifyUser from "./Components/VerifyUser/VerifyUser";
import VerifyAgent from "./Components/VerifyUser/VerifyAgent";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import Account from "./Components/Account/MyAccount/Account";
import Login from "./Components/Signing/Login/Login";
import ForceLogin from "./Components/Signing/Login/ForceLogin";
import AgentLogin from "./Components/Signing/Login/AgentLogin";
import Register from "./Components/Signing/Register/Register";
import AgentRegister from "./Components/Signing/Register/AgentRegister";
import ApplyNow from "./Components/ApplyNow/ApplyNow";
import ContactUs from "./Components/Contact/ContactUs";
import ResetAgentPassword from "./Components/ResetPassword/ResetAgentPassword";
import AgentApplyNow from "./Components/ApplyNow/AgentApplyNow";
import MainhomepageForConsultant from "./Components/MainHomepage/MainhomepageForConsultant";
import Education from "./Components/Resources/Education";
import ChangingProvider from "./Components/Resources/ChangingProvider";

function App() {
  const [{ user }, dispatch] = useStateValue();
  const navigate = useNavigate();
  let clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  useEffect(() => {
    if (clientdetails?.token) {
      checkLogout();
    }
    //console.log("52----->user----->", user);
    //console.log("53----->client---->", clientdetails?.token);
  }, []);

  const checkLogout = () => {
    axios
      .get(`${base_url.api5}/`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
        },
      })
      .then(
        (response) => {
          dispatch({
            type: "SET_USER",
            user: clientdetails?.token,
          });
        },
        (error) => {
          setTimeout(() => {
            localStorage.clear();
            navigate("/login");
            toast.success("Logged Out Successfully");
          }, 1000);
        }
      );
  };

  return (
    <div className="App">
      <ToastContainer />

      <Routes>
        <Route path="/" element={<Mainhomepage />} />
        <Route path="/searchbycity" element={<MainhomepageForConsultant />} />
        <Route path="/login" element={<Login />} />
        <Route path="/force-login" element={<ForceLogin />} />
        <Route path="/agent-login" element={<AgentLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/agent-register" element={<AgentRegister />} />
        <Route path="/verify-user/:email/:token" element={<VerifyUser />} />
        <Route path="/verify-agent/:email/:token" element={<VerifyAgent />} />
        <Route
          path="/reset-password/:email/:token"
          element={<ResetPassword />}
        />
        <Route
          path="/reset-agent-password/:email/:token"
          element={<ResetAgentPassword />}
        />
        <Route path="/course-detail/:course_name" element={<CourseDetail />} />
        <Route
          path="/provider-detail/:provider_name"
          element={<ProviderDetail />}
        />
        <Route path="/course-searchresult" element={<CourseSearchResult />} />
        <Route path="/popular_course" element={<PopularCourse />} />
        <Route path="/institution" element={<Institution />} />
        <Route path="/apply" element={<ApplyNow />} />
        <Route path="/apply-agent" element={<AgentApplyNow />} />
        <Route
          path="/myaccount"
          element={
            user || clientdetails?.token ? (
              <Account />
            ) : (
              <Navigate
                to="/login"
                replace
                state={{
                  prevLocation: "/myaccount",
                  error: "You need to login first!",
                }}
              />
            )
          }
        />

        <Route path="/course-compare" element={<CourseCompare />} />
        <Route path="/education" element={<Education />} />
        <Route path="/provider" element={<ChangingProvider />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
