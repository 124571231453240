import React, { useState } from "react";
import Footer from "../Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import base_url from "../api/bootapi";
import { toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import PasswordStrengthBar from "react-password-strength-bar";
// import ReactNavbar from "../Navbar/Navbar";
import NavbarNew from "../Navbar/NavbarNew";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const ResetAgentPassword = () => {
  const [backdropopen, setbackdropOpen] = useState(false);
  const classes = useStyles();
  const [inputtype, setinputtype] = useState("password");
  const [password, setpassword] = useState();
  const [passworderror, setpassworderror] = useState(false);
  let params = useParams();
  let navigate = useNavigate();
  const [chkPass, setchkPass] = useState();
  const [chkPassState, setchkPassState] = useState();

  const handlePassword = (e) => {
    setpassword(e.target.value);
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (e.target.value.match(decimal)) {
      setchkPass(false);
      setchkPassState(e.target.value);
      return true;
    } else {
      setchkPass(true);
      return false;
    }
  };

  const submitforgotpassword = () => {
    if (!password) {
      setpassworderror(true);
      return;
    }
    setpassworderror(false);
    if (password !== chkPassState) return;
    if (chkPass) return;

    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/setNewAgentPassword`, {
        headers: {
          email: params?.email.trim(),
          verificationToken: params?.token.trim(),
          newPassword: chkPass === false ? chkPassState.trim() : null,
        },
      })
      .then((res) => {
        toast.success(
          "Password Changed Successfully!!\n Redirecting to login page"
        );
        setTimeout(() => {
          setbackdropOpen(false);
          navigate("/agent-login");
        }, 2000);
      })
      .catch((err) => {
        setbackdropOpen(false);
        if (err.response) {
          toast.error(JSON.stringify(err.response.data.message));
        } else {
          toast.error(JSON.stringify("Something went wrong!!"));
        }
      });
  };
  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavbarNew fromwhere={"Verify"} />
      <div className="login resetcontainer">
        <h2 className="text-center mb-0">Reset Password</h2>
        <h2 className="text-center">International Student World</h2>
        <div className="login_container loginwidthsize">
          <div className="my-4">
            <label>Email</label>
            <div style={{ position: "relative" }}>
              <input value={params?.email} className="form-control" readOnly />
              <i className="fa fa-envelope-o designi" />
            </div>
          </div>
          <div className="my-4">
            <label>New Password</label>
            <div style={{ position: "relative" }}>
              <input
                type={inputtype}
                value={password}
                onChange={handlePassword}
                className="form-control"
                placeholder="Password"
              />
              <i className="fa fa-lock designi" />
              {inputtype === "password" ? (
                <i
                  className="fa fa-eye-slash passwordiconpositioning"
                  onClick={() => setinputtype("text")}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className="fa fa-eye passwordiconpositioning"
                  onClick={() => setinputtype("password")}
                  aria-hidden="true"
                ></i>
              )}
              <PasswordStrengthBar password={chkPassState} />
            </div>
            {passworderror && (
              <p className="errormessage mb-0">Password is required</p>
            )}
          </div>
          <button onClick={submitforgotpassword} className="btn">
            Submit
          </button>
        </div>
      </div>
      <div className="loginpage_footer">
        <Footer />
      </div>
    </div>
  );
};

export default ResetAgentPassword;
