import React, { useEffect, useState } from "react";
import "./../css/CoursesOverview.css";
import base_url from "../api/bootapi";
// import attachmenturl from "../api/attachmenturl";
import axios from "axios";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const AccountSendMessage = () => {
  const [selectemail, setselectemail] = useState();

  const [backdropopen, setbackdropOpen] = React.useState(false);
  const classes = useStyles();
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [inboxdetails, setinboxdetails] = useState();
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    setbackdropOpen(true);
    getInbox(pageno);
  }, []);
  const handlepage = (event, value) => {
    //console.log(value - 1);
    setpageno(value - 1);
    getInbox(value - 1);
  };
  const getInbox = (value) => {
    axios
      .get(`${base_url.api5}/getByMailTypeAndToEmailId`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          mailType: "sent",
          columnName: "date",
          direction: "DESC",
          toEmailId: clientdetails?.username,
          page: Number(value),
          size: 10,
        },
      })
      .then((res) => {
        setinboxdetails(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
        setbackdropOpen(false);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
      })
      .catch((err) => {
        setinboxdetails();
        setgetpageno();
        setbackdropOpen(false);
        //console.log(err);
      });
  };

  const downloadAll = (path, toEmailId, fromEmailId) => {
    setbackdropOpen(true);
    let file_name =
      path.split("amazonaws.com/")[path.split("amazonaws.com/").length - 1];
    const header = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${clientdetails?.token}`,
        toEmailId,
        fromEmailId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api5}/downloadEmailAttachment`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        const downloadFileType = localStorage.getItem(
          "enquiry_attachment_file_content_type"
        );
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdropOpen(false);
      })
      .catch((err) => setbackdropOpen(false));
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "enquiry_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };

  return (
    <>
      <div class="show-desktop-only">
        <div class="accountbody">
          <h2 className="accountbody-title">Inbox</h2>
          <div className="accountenquiry">
            <Backdrop className={classes.backdrop} open={backdropopen}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              <table className="table tbb">
                <thead className="thb">
                  <tr>
                    <th>Date</th>
                    <th>Subject</th>
                    <th>Message</th>
                    <th>To</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {inboxdetails?.map((item, index) => (
                    <tr key={index}>
                      <td>{moment(item.date).format("lll")}</td>
                      <td>{item.subject}</td>
                      <td>{item.description}</td>
                      <td>{item.fromEmailId}</td>
                      <td>
                        <Link
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={() => setselectemail(item)}
                          style={{ textDecoration: "none", cursor: "pointer" }}
                          id={item.id}
                        >
                          View
                        </Link>
                        <div className="modal fade" id="myModal" role="dialog">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4 className="modal-title">View Message</h4>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                >
                                  &times;
                                </button>
                              </div>
                              <div className="modal-body">
                                <div>
                                  <h4>Receiver:</h4>
                                  <p>{selectemail?.fromEmailId}</p>
                                </div>
                                <div>
                                  <h4>Subject:</h4>
                                  <p>{selectemail?.subject}</p>
                                </div>
                                <div>
                                  <h4>Description:</h4>
                                  <p>{selectemail?.description}</p>
                                </div>
                                <div>
                                  <h4>Attachment:</h4>
                                  {selectemail?.emailAttachmentPath != null ? (
                                    <span
                                      className="filedownloadtd"
                                      onClick={() =>
                                        downloadAll(
                                          selectemail?.emailAttachmentPath,
                                          selectemail?.toEmailId,
                                          selectemail?.fromEmailId
                                        )
                                      }
                                    >
                                      {selectemail?.attachmentName}
                                    </span>
                                  ) : (
                                    <p>No Attachment</p>
                                  )}
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div>
                {inboxdetails?.length ? (
                  <p>
                    {countnumber * 10 + 1} to{" "}
                    {countnumber * 10 + numberofElements} of {totalUser} records
                  </p>
                ) : (
                  <p>No Record Found </p>
                )}
              </div>
              <Pagination count={getpageno} onChange={handlepage} />
            </div>
          </div>
        </div>
      </div>

      <div class="show-mobile-only">
        <div class="accountbody">
          <h2 className="accountbody-title">Inbox</h2>
          <div className="accountenquiry">
            <Backdrop className={classes.backdrop} open={backdropopen}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              <table className="table tbb">
                <tbody>
                  {inboxdetails?.map((item, index) => (
                    <tr key={index}>
                      <div
                        key={index}
                        className="accunt-rpt-tb-content p-3"
                        style={{ border: 0 }}
                      >
                        <h4>{item.subject}</h4>

                        <h6>Date</h6>
                        <div className="accunt-rpt-tb-text">
                          {moment(item.date).format("lll")}
                        </div>

                        <br />
                        <h6>Message</h6>
                        <div className="accunt-rpt-tb-text">
                          {item.description}
                        </div>

                        <br />
                        <h6>From</h6>
                        <div className="accunt-rpt-tb-text">
                          {item.fromEmailId}
                        </div>

                        <br />
                        <h6>Action</h6>
                        <div className="accunt-rpt-tb-text">
                          <Link
                            data-toggle="modal"
                            data-target="#myModal"
                            onClick={() => setselectemail(item)}
                            style={{
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            id={item.id}
                          >
                            View
                          </Link>
                          <div
                            className="modal fade"
                            id="myModal"
                            role="dialog"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h4 className="modal-title">View Message</h4>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                  >
                                    &times;
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <div>
                                    <h4>Receiver:</h4>
                                    <p>{selectemail?.fromEmailId}</p>
                                  </div>
                                  <div>
                                    <h4>Subject:</h4>
                                    <p>{selectemail?.subject}</p>
                                  </div>
                                  <div>
                                    <h4>Description:</h4>
                                    <p>{selectemail?.description}</p>
                                  </div>
                                  <div>
                                    <h4>Attachment:</h4>
                                    {selectemail?.emailAttachmentPath !=
                                    null ? (
                                      <span
                                        className="filedownloadtd"
                                        onClick={() =>
                                          downloadAll(
                                            selectemail?.emailAttachmentPath,
                                            selectemail?.toEmailId,
                                            selectemail?.fromEmailId
                                          )
                                        }
                                      >
                                        {selectemail?.attachmentName}
                                      </span>
                                    ) : (
                                      <p>No Attachment</p>
                                    )}
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div>
                {inboxdetails?.length ? (
                  <p>
                    {countnumber * 10 + 1} to{" "}
                    {countnumber * 10 + numberofElements} of {totalUser} records
                  </p>
                ) : (
                  <p>No Record Found </p>
                )}
              </div>
              <Pagination count={getpageno} onChange={handlepage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSendMessage;
