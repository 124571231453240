import React, { useEffect, useRef, useState } from "react";
import base_url from "../api/bootapi";
import axios from "axios";
// import ReactNavbar from "../Navbar/Navbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import ProviderHeaderBody from "./ProviderHeaderBody";
import { toast } from "react-toastify";
import ProviderLowerBody from "./ProviderLowerBody";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import NavbarNew from "../Navbar/NavbarNew";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));
const ProviderDetail = () => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [backdropopen, setbackdropOpen] = React.useState(false);
  const classes = useStyles();
  const [getpageno, setgetpageno] = useState();
  const [pageno, setpageno] = useState(0);
  // const courseId = JSON.parse(localStorage.getItem("courseId"));
  const [coursedetail, setcoursedetail] = useState();
  const [providerdetail, setproviderdetail] = useState();
  const [courselist, setcourselist] = useState();
  const [transactionalarrangement, settransactionalarrangement] = useState();
  const [configAdmin, setConfigAdmin] = useState(false);
  const pageStartRef = useRef(null);
  let params = useParams();
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    const id =
      params?.provider_name.split("-")[
        params?.provider_name.split("-").length - 1
      ];
    pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getCourseById`, {
        headers: {
          courseId: Number(id),
          branchId: Number(0),
        },
      })
      .then((res) => {
        setcoursedetail(res.data.data);
        getbranch(res.data.data.institutionId);
      });
    // getConfigData();
  }, [clientdetails?.clientId]);

  // const getConfigData = () => {
  //   axios
  //     .get(`${base_url.api5}/getByConfigKey`, {
  //       headers: {
  //         configKey: "iswonline_consultant",
  //       },
  //     })
  //     .then((res) => setConfigAdmin(res.data.data.configValue));
  // };

  const getbranch = (instituteId) => {
    axios
      .get(`${base_url.api5}/getInstitutionBranchById`, {
        headers: {
          // institutionBranchesId: Number(courseId.branchId),
          institutionBranchesId: Number(instituteId),
        },
      })
      .then((res) => {
        setproviderdetail(res.data.data);
        settransactionalarrangement(res.data.data.branchTransactionalAgreement);
        axios
          .get(`${base_url.api5}/getCourseByInstitutionBranchId`, {
            headers: {
              institutionBranchId: res.data.data.branchId,
              column: "addedOn",
              direction: "DESC",
              page: pageno,
              size: 10,
            },
          })
          .then((res) => {
            setbackdropOpen(false);
            setcourselist(res.data.data.content);
            setgetpageno(res.data.data.totalPages);
          })
          .catch((err) => setbackdropOpen(false));
      })
      .catch((err) => setbackdropOpen(false));
  };
  const handlepage = (event, value) => {
    setpageno(value - 1);
    axios
      .get(`${base_url.api5}/getCourseByInstitutionBranchId`, {
        headers: {
          institutionBranchId: providerdetail.branchId,
          column: "addedOn",
          direction: "DESC",
          page: value - 1,
          size: 10,
        },
      })
      .then((res) => {
        setbackdropOpen(false);
        setcourselist(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
      })
      .catch((err) => setbackdropOpen(false));
  };
  return (
    <div ref={pageStartRef}>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavbarNew />
      <ProviderHeaderBody
        clientdetails={clientdetails}
        configAdmin={configAdmin}
        coursedetail={coursedetail}
        providerdetail={providerdetail}
        setbackdropOpen={setbackdropOpen}
        toast={toast}
      />
      <ProviderLowerBody
        courselist={courselist}
        handlepage={handlepage}
        getpageno={getpageno}
        transactionalarrangement={transactionalarrangement}
        coursedetail={coursedetail}
        clientdetails={clientdetails}
        configAdmin={configAdmin}
        providerdetail={providerdetail}
        setbackdropOpen={setbackdropOpen}
        toast={toast}
      />
      <div className="mt-4">
        <Footer />
      </div>
    </div>
  );
};

export default ProviderDetail;
