import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import ISWLogo from "./../../../assets/images/logo.png";
import ISWLogo from "./../../../assets/images/downlogo.png";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonIcon from "@mui/icons-material/Person";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import MessageIcon from "@mui/icons-material/Message";
import AppsIcon from "@mui/icons-material/Apps";
import GetAppIcon from "@mui/icons-material/GetApp";
import PaymentIcon from "@mui/icons-material/Payment";
import SecurityIcon from "@mui/icons-material/Security";
import AttachmentIcon from "@mui/icons-material/Attachment";
import PublishIcon from "@mui/icons-material/Receipt";
import LetterIcon from "@mui/icons-material/Description";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MailIcon from "@mui/icons-material/Mail";
import UploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/CloudDownload";

const AccountSidebar = ({ sidebartab, handlesidebartab }) => {
  let navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isMailExpanded, setIsMailExpanded] = useState(false);
  const [isUploadExpanded, setIsUploadExpanded] = useState(false);
  const [isDownloadExpanded, setIsDownloadExpanded] = useState(false);

  const toggleExpansion = (type) => {
    if (type == "Mail") {
      setIsMailExpanded(!isMailExpanded);
    }
    if (type == "Upload") {
      setIsUploadExpanded(!isUploadExpanded);
    }
    if (type == "Download") {
      setIsDownloadExpanded(!isDownloadExpanded);
    }
  };

  const gotohomepage = () => navigate("/");
  return (
    <div
      className="account_sidebar"
      style={{ width: "100%", height: "100vh", boxSizing: "border-box" }}
    >
      {/* <div className="account_sidebarimgcontainer mb-4">
        <img className="cursor-pointer" onClick={gotohomepage} alt="isw_logo" src={ISWLogo} />
      </div> */}
      <div
        className="account_sidebar_inn"
        style={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div class="side-menu" style={{ marginTop: "-9%" }}>
          <div class="row">
            <nav
              class="navbar navbar-expand-lg navbar-light bg-light"
              style={{ width: "100%", backgroundColor: "#fff !important" }}
            >
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#bs-sidebar-navbar-collapse-1"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="side-menu-container">
                <ul class="nav navbar-nav">
                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "edit" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("edit")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <PersonIcon />
                      </span>{" "}
                      Profile
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <div
                      onClick={() => toggleExpansion("Mail")}
                      class="nav-link"
                      data-toggle="collapse"
                      href="#dropdown-lvl1"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a>
                        <span style={{ padding: "15px 8px" }}>
                          <MailIcon />
                        </span>
                        Mail
                      </a>
                      <a>
                        <div href="#dropdown-lvl1">
                          {isMailExpanded ? (
                            <span style={{ position: "relative" }}>
                              <span
                                style={{
                                  position: "absolute",
                                  width: "50px",
                                  height: "50px",
                                  zIndex: 3,
                                }}
                                data-toggle="collapse"
                                href="#dropdown-lvl1"
                              ></span>
                              <ExpandLessIcon />
                            </span>
                          ) : (
                            <span style={{ position: "relative" }}>
                              <span
                                data-toggle="collapse"
                                style={{
                                  position: "absolute",
                                  width: "50px",
                                  height: "50px",
                                  zIndex: 3,
                                }}
                                href="#dropdown-lvl1"
                              ></span>
                              <ExpandMoreIcon />
                            </span>
                          )}
                        </div>
                      </a>
                    </div>
                    <div id="dropdown-lvl1" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="nav navbar-nav">
                          <li
                            class="nav-item"
                            className={`account_sidebarelementcontainer ${
                              sidebartab === "message" ? "activeaccount" : ""
                            }`}
                            onClick={() => handlesidebartab("message")}
                          >
                            <a class="nav-link">
                              <span style={{ padding: "inherit" }}>
                                <MoveToInboxIcon />
                              </span>
                              Inbox
                            </a>
                          </li>

                          <li
                            class="nav-item"
                            className={`account_sidebarelementcontainer ${
                              sidebartab === "inbox" ? "activeaccount" : ""
                            }`}
                            onClick={() => handlesidebartab("inbox")}
                          >
                            <a class="nav-link">
                              <span style={{ padding: "inherit" }}>
                                <MessageIcon />
                              </span>
                              Sent
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "comapre" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("comapre")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <CompareArrowsIcon />
                      </span>{" "}
                      Compare List
                    </a>
                  </li>

                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "applied" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("applied")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <ListAltIcon />
                      </span>{" "}
                      {/* Applied Courses list */}
                      Enquired Courses list
                    </a>
                  </li>

                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "dashboard" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("dashboard")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <SystemUpdateAltIcon />
                      </span>{" "}
                      Applications Status
                    </a>
                  </li>
                  {/* 
                  <li class="nav-item dropdown1">
                    <div
                      onClick={() => toggleExpansion("Upload")}
                      class="nav-link"
                      data-toggle="collapse"
                      href="#dropdown1-lvl2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a>
                        <span style={{ padding: "15px 8px" }}>
                          <UploadIcon />
                        </span>
                        Upload
                      </a>
                      <a>
                        {isUploadExpanded ? (
                          <span style={{ position: "relative" }}>
                            <span
                              style={{
                                position: "absolute",
                                width: "50px",
                                height: "50px",
                                zIndex: 3,
                              }}
                              data-toggle="collapse"
                              href="#dropdown1-lvl2"
                            ></span>
                            <ExpandLessIcon />
                          </span>
                        ) : (
                          <span style={{ position: "relative" }}>
                            <span
                              data-toggle="collapse"
                              style={{
                                position: "absolute",
                                width: "50px",
                                height: "50px",
                                zIndex: 3,
                              }}
                              href="#dropdown1-lvl2"
                            ></span>
                            <ExpandMoreIcon />
                          </span>
                        )}
                      </a>
                    </div>
                    <div id="dropdown1-lvl2" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="nav navbar-nav">
                          <li
                            class="nav-item"
                            className={`account_sidebarelementcontainer ${
                              sidebartab === "upload" ? "activeaccount" : ""
                            }`}
                            onClick={() => handlesidebartab("upload")}
                          >
                            <a class="nav-link">
                              <span style={{ padding: "inherit" }}>
                                <AttachmentIcon />
                              </span>
                              Prerequisite Documents
                            </a>
                          </li>

                          <li
                            class="nav-item"
                            className={`account_sidebarelementcontainer ${
                              sidebartab === "receipt" ? "activeaccount" : ""
                            }`}
                            onClick={() => handlesidebartab("receipt")}
                          >
                            <a class="nav-link">
                              <span style={{ padding: "inherit" }}>
                                <PublishIcon />
                              </span>
                              Payment Receipt
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                
                  <li class="nav-item dropdown2">
                    <div
                      onClick={() => toggleExpansion("Download")}
                      class="nav-link"
                      data-toggle="collapse"
                      href="#dropdown2-lvl3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a>
                        <span style={{ padding: "15px 8px" }}>
                          <DownloadIcon />
                        </span>
                        Download
                      </a>
                      <a>
                        <span>
                          {isDownloadExpanded ? (
                            <span style={{ position: "relative" }}>
                              <span
                                style={{
                                  position: "absolute",
                                  width: "50px",
                                  height: "50px",
                                  zIndex: 3,
                                }}
                                data-toggle="collapse"
                                href="#dropdown2-lvl3"
                              ></span>
                              <ExpandLessIcon />
                            </span>
                          ) : (
                            <span style={{ position: "relative" }}>
                              <span
                                style={{
                                  position: "absolute",
                                  width: "50px",
                                  height: "50px",
                                  zIndex: 3,
                                }}
                                data-toggle="collapse"
                                href="#dropdown2-lvl3"
                              ></span>
                              <ExpandMoreIcon />
                            </span>
                          )}
                        </span>
                      </a>
                    </div>
                    <div id="dropdown2-lvl3" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="nav navbar-nav">
                          <li
                            class="nav-item"
                            className={`account_sidebarelementcontainer ${
                              sidebartab === "applicationform"
                                ? "activeaccount"
                                : ""
                            }`}
                            onClick={() => handlesidebartab("applicationform")}
                          >
                            <a class="nav-link">
                              <span style={{ padding: "inherit" }}>
                                <GetAppIcon />
                              </span>
                              Application Form
                            </a>
                          </li>

                          <li
                            class="nav-item"
                            className={`account_sidebarelementcontainer ${
                              sidebartab === "offerletter"
                                ? "activeaccount"
                                : ""
                            }`}
                            onClick={() => handlesidebartab("offerletter")}
                          >
                            <a class="nav-link">
                              <span style={{ padding: "inherit" }}>
                                <LetterIcon />
                              </span>
                              Offer Letter
                            </a>
                          </li>
                          <li
                            class="nav-item"
                            className={`account_sidebarelementcontainer ${
                              sidebartab === "coe" ? "activeaccount" : ""
                            }`}
                            onClick={() => handlesidebartab("coe")}
                          >
                            <a class="nav-link">
                              <span style={{ padding: "inherit" }}>
                                <SystemUpdateAltIcon />
                              </span>
                              COE
                            </a>
                          </li>
                          <li
                            class="nav-item"
                            className={`account_sidebarelementcontainer ${
                              sidebartab === "otherDocument"
                                ? "activeaccount"
                                : ""
                            }`}
                            onClick={() => handlesidebartab("otherDocument")}
                          >
                            <a class="nav-link">
                              <span style={{ padding: "inherit" }}>
                                <SystemUpdateAltIcon />
                              </span>
                              Other Documents
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li> */}

                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "applicationform" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("applicationform")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <GetAppIcon />
                      </span>
                      Documents
                    </a>
                  </li>

                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "fees" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("fees")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <PaymentIcon />
                      </span>{" "}
                      Fees Payment Schedule{" "}
                    </a>
                  </li>

                  <li
                    class="nav-item"
                    className={`account_sidebarelementcontainer ${
                      sidebartab === "change" ? "activeaccount" : ""
                    }`}
                    onClick={() => handlesidebartab("change")}
                  >
                    <a class="nav-link">
                      <span style={{ padding: "inherit" }}>
                        <SecurityIcon />
                      </span>{" "}
                      Security{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSidebar;
