import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ApplyNowModal = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  // const applydetail =
  //   JSON.parse(localStorage.getItem("isw_frontend_applycourse_detail")) || {};
  // const courseid = JSON.parse(localStorage.getItem("courseId"));
  //console.log('entering in handleapply', props.coursedetail);
  const handleapply = () => {
    //console.log(props.coursedetail.course_id);
    // if (props.clientdetails?.token) {
    //   applyclient();
    // } else
    //console.log('entering in handleapply', props);
    if (props.clientdetails?.clientId) {
      // if (!props.clienttempdata.address) {
      let course;
      if (props.fromwhere === "compare" || props.fromwhere === "comparelower") {
        course = {
          courseId: props.coursedetail.course_id,
          branchId: props.coursedetail.branch_id,
        };
      } else {
        course = {
          courseId: props.coursedetail.courseId,
          branchId: props.coursedetail.institution_id,
        };
      }
      localStorage.setItem("courseId", JSON.stringify(course));
      //console.log(props.coursedetail);
      let arr = {
        coursename: props.coursedetail?.name,
        providername:
          props.fromwhere === "compare" || props.fromwhere === "comparelower"
            ? props.providerdetail?.branch_name
            : props.providerdetail?.branchName,
        cricos:
          props.fromwhere === "compare" || props.fromwhere === "comparelower"
            ? props.providerdetail?.cricos_provider_code
            : props.providerdetail?.cricosProviderCode,
        courseCode:
          props.fromwhere === "compare" || props.fromwhere === "comparelower"
            ? props.providerdetail?.course_code
            : props.coursedetail?.courseCode,
        configAdmin: props.configAdmin,
        offShoreFees: props.coursedetail?.off_shore_fees,
        OnShoreFees: props.coursedetail?.fees,
        duration: props.coursedetail?.duration,
        courseId: props.coursedetail?.course_id,
      };
      localStorage.setItem("isw_apply_details_frontend", JSON.stringify(arr));
      // ==================================================================//
      let arraytwo =
        JSON.parse(localStorage.getItem("totalAppliedCourse")) || [];
      let bal_array = {
        coursename: props.coursedetail?.name,
        providername:
          props.fromwhere === "compare" || props.fromwhere === "comparelower"
            ? props.providerdetail?.branch_name
            : props.providerdetail?.branchName,
        cricos:
          props.fromwhere === "compare" || props.fromwhere === "comparelower"
            ? props.providerdetail?.cricos_provider_code
            : props.providerdetail?.cricosProviderCode,
        configAdmin: props.configAdmin,
        offShoreFees: props.coursedetail?.off_shore_fees,
        OnShoreFees: props.coursedetail?.fees,
        duration: props.coursedetail?.duration,
        courseCode: props.coursedetail?.courseCode,
      };
      arraytwo.push(bal_array);
      localStorage.setItem("totalAppliedCourse", JSON.stringify(arraytwo));
      // ==================================================================//
      if (props.clientdetails?.agentId) {
        navigate("/apply-agent");
      } else {
        navigate("/apply");
      }
      // } else {
      //   applyclient(props.clienttempdata);
      // }
    } else {
      sessionStorage.setItem("prev_path_before_login", location.pathname);
      // navigate("/login");
      navigate("/force-login");
    }
  };

  const handleapplyfromsearch = () => {
    // if (props.clientdetails?.token) {
    //   applyclient();
    // } else
    //console.log('entering in handleapplyfromsearch');
    if (props.clientdetails?.clientId) {
      //console.log("coming here");
      let course;
      if (props.fromwhere === "coursedetail") {
        course = {
          courseId: props.coursedetail.courseId,
          branchId: props.coursedetail.branchInstitutionId,
        };
      } else {
        course = {
          courseId: props.coursedetail.courseId,
          branchId: props.providerdetail.branchInstitutionId,
        };
      }
      localStorage.setItem("courseId", JSON.stringify(course));
      let arr = {
        coursename: props.coursedetail?.courseName,
        providername: props.coursedetail?.branchName,
        cricos: props.providerdetail?.cricosProviderCode || null,
        configAdmin: props.configAdmin,
        offShoreFees: props.coursedetail?.offShoreFees,
        OnShoreFees: props.coursedetail?.fees,
        duration: props.coursedetail?.duration,
        courseId: props.coursedetail?.course_id,
        courseCode:
          props.fromwhere === "compare" || props.fromwhere === "comparelower"
            ? props.providerdetail?.course_code
            : props.coursedetail?.courseCode,
      };
      localStorage.setItem("isw_apply_details_frontend", JSON.stringify(arr));
      // ==================================================================//
      let arraytwo =
        JSON.parse(localStorage.getItem("totalAppliedCourse")) || [];
      let bal_array = {
        coursename: props.coursedetail?.courseName,
        providername: props.coursedetail?.branchName,
        cricos: props.providerdetail?.cricosProviderCode || null,
        configAdmin: props.configAdmin,
        offShoreFees: props.coursedetail?.offShoreFees,
        OnShoreFees: props.coursedetail?.fees,
        duration: props.coursedetail?.duration,
        courseCode: props.coursedetail?.courseCode,
      };
      arraytwo.push(bal_array);
      localStorage.setItem("totalAppliedCourse", JSON.stringify(arraytwo));
      // ==================================================================//
      if (props.clientdetails?.agentId) {
        navigate("/apply-agent");
      } else {
        navigate("/apply");
      }
    } else {
      sessionStorage.setItem("prev_path_before_login", location.pathname);
      // navigate("/login");
      navigate("/force-login");
    }
  };

  // const applyclient = () => {
  //   let mailComments;
  //   const isoDateString = new Date().toISOString();
  //   const datenow = isoDateString.split(".")[0];

  //   mailComments = `Course Name- ${
  //     props.fromwhere === "coursesearch"
  //       ? props.coursedetail?.courseName
  //       : props.coursedetail?.name
  //   }
  //       \nProvider Name-${
  //         props.fromwhere === "compare" || props.fromwhere === "comparelower"
  //           ? props.providerdetail?.branch_name
  //           : props.providerdetail?.branchName
  //       }\nCricos- ${
  //     props.fromwhere === "compare" || props.fromwhere === "comparelower"
  //       ? props.providerdetail?.cricos_provider_code
  //       : props.providerdetail?.cricosProviderCode
  //   }
  //       \n-->Course Applied by ${props.clientdetails?.name}
  //       on ${moment(datenow).format("DD MMM YYYY hh.mm.a")}`;
  //   props.setbackdropOpen(true);
  //   mailapplycourse(mailComments);
  // };

  // const mailapplycourse = (mailComments) => {
  //   let mailData = {
  //     description: mailComments,
  //     fromEmailId: props.clientdetails?.username,
  //     id: 0,
  //     mailType: "received",
  //     status: 0,
  //     subject:
  //       "Lead Received From " +
  //       props.clientdetails?.name +
  //       " (" +
  //       props.clientdetails?.username +
  //       ")",
  //     toEmailId: props.configAdmin,
  //   };
  //   axios
  //     .post(`${base_url.api5}/createEmail`, mailData)
  //     .then((res) => {
  //       props.toast.success(
  //         `Your application has been applied for ${
  //           props.fromwhere === "coursesearch"
  //             ? props.coursedetail?.courseName
  //             : props.coursedetail?.name
  //         } at ${
  //           props.fromwhere === "compare" || props.fromwhere === "comparelower"
  //             ? props.providerdetail?.branch_name
  //             : props.providerdetail?.branchName
  //         }`
  //       );
  //       let key;
  //       if (props.fromwhere === "coursedetail" || props.fromwhere === "lower") {
  //         key = `${courseid.courseId}${courseid.branchId}`;
  //       } else if (props.fromwhere === "coursesearch") {
  //         key = `${props.coursedetail.courseId}${props.coursedetail.branchInstitutionId}`;
  //       } else {
  //         key = `${props.coursedetail.course_id}${props.coursedetail.branch_id}`;
  //       }
  //       applydetail[key] = true;
  //       localStorage.setItem(
  //         "isw_frontend_applycourse_detail",
  //         JSON.stringify(applydetail)
  //       );
  //       props.setbackdropOpen(false);
  //     })
  //     .catch((err) => props.setbackdropOpen(false));
  // };

  return (
    <div>
      {props.fromwhere === "compare" ? (
        <>
          {props.applydetail?.[
            `${props.coursedetail?.course_id}${props.coursedetail?.branch_id}`
          ] ? (
            <h5 className="courseComparelower_applybutton">Applied</h5>
          ) : (
            <h5
              className="courseComparelower_applybutton"
              onClick={() => props.coursedetail?.name && handleapply()}
            >
              Apply Now
            </h5>
          )}
        </>
      ) : (
        <>
          {props.fromwhere === "coursedetail" ? (
            <div>
              {props.applydetail?.[
                `${props.coursedetail?.course_id}${props.coursedetail?.branch_id}`
              ] ? (
                <h5 className="courseComparelower_applybutton">Applied</h5>
              ) : (
                <h5
                  className="courseComparelower_applybutton"
                  onClick={handleapplyfromsearch}
                >
                  Apply Now
                </h5>
              )}
            </div>
          ) : (
            <>
              {props.fromwhere === "coursesearch" ? (
                <button
                  className="btn coursesearchbody_applynowbutton "
                  style={{ width: "94%" }}
                  onClick={handleapplyfromsearch}
                >
                  Apply Now
                </button>
              ) : (
                <>
                  {props.applydetail?.[
                    `${props.coursedetail?.courseId}${props.providerdetail?.branchId}`
                  ] ? (
                    <div className="center_div">
                      <button
                        className={
                          props.fromwhere === "lower"
                            ? "btn"
                            : "btn courseheaderbody_overviewbutton"
                        }
                      >
                        Applied
                      </button>
                    </div>
                  ) : (
                    <div className="center_div">
                      <button
                        onClick={handleapply}
                        className={
                          props.fromwhere === "lower"
                            ? "btn"
                            : "btn courseheaderbody_overviewbutton"
                        }
                      >
                        Apply Now
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ApplyNowModal;
