import axios from "axios";
import React, { useEffect, useState } from "react";
import base_url from "../../api/bootapi";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import EnquiryFileDownloadService from "../../../services/EnquiryFileDownloadService";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const MiscellaneousDoc = () => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [missdetails, setmissdetails] = useState([]);
  const classes = useStyles();
  const [backdrop, setbackdrop] = useState(false);

  const [documenttotalUser, setdocumenttotalUser] = useState();
  const [documentcountnumber, setdocumentcountnumber] = useState();
  const [documentnumberofElements, setdocumentnumberofElements] = useState();
  const [documentdetails, setdocumentdetails] = useState([]);
  const [documentgetpageno, setdocumentgetpageno] = useState();
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getclientdocument();
  }, []);

  const handledocumentpage = (event, value) => {
    let data = missdetails.slice((value - 1) * 10, value * 10);
    setdocumentcountnumber(Number(value - 1));
    setdocumentdetails(data);
    setdocumentnumberofElements(data.length);
  };

  const getclientdocument = () => {
    axios
      .get(`${base_url.api5}/getAllAttachmentsByDocumentTypeAndClientId`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          clientId: clientdetails?.clientId,
          documentType: "miscellaneous_doc",
        },
      })
      .then((res) => {
        let data = [];
        res.data.client_attachment.forEach((element) => data.push(element));
        res.data.data.forEach((element) => data.push(element));
        setdocumentcountnumber(Number(0));
        setmissdetails(data);
        const data2 = data.slice(0, 10) || [];
        setdocumentdetails(data.length > 0 ? data.slice(0, 10) : []);
        setdocumentnumberofElements(data2.length);
        setdocumentgetpageno(Math.ceil(Number(data.length) / 10));
        setdocumenttotalUser(data.length);
      });
  };

  const downloaddocument = (path, clientId) => {
    setbackdrop(true);
    let file_name = path.split("amazonaws.com/")[1];
    const header = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${clientdetails?.token}`,
        clientId: clientId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api5}/downloadAttachmentByClientIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        const downloadFileType = localStorage.getItem(
          "client_attachment_file_content_type"
        );
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdrop(false);
      })
      .catch((err) => setbackdrop(false));
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "client_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };

  return (
    <>
      <div class="show-desktop-only">
        <div className="accountenquiry">
          <Backdrop className={classes.backdrop} open={backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className=" tab-pane">
            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              <table className="table tbb">
                <thead className="thb">
                  <tr>
                    <th style={{ width: "50px", textAlign: "center" }}>SN</th>
                    <th>Document</th>
                    <th style={{ width: "200px" }}>Updated On</th>
                  </tr>
                </thead>
                <tbody>
                  {documentdetails?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "50px", textAlign: "center" }}>
                        {index + 1}
                      </td>
                      <td>
                        {item.client_id ? (
                          <EnquiryFileDownloadService
                            name={item.attachment_name}
                            path={item.path}
                            client_id={item.client_id}
                          />
                        ) : (
                          <span
                            className="filedownloadtd"
                            onClick={() =>
                              downloaddocument(item.path, item.clientId)
                            }
                          >
                            {item.attachmentName}
                          </span>
                        )}
                      </td>
                      <td>
                        {moment(item.createdOn || item.created_on).format(
                          "DD MMM YYYY"
                        )}
                        <br />
                        {moment(item.createdOn || item.created_on).format(
                          "hh.mm a"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div>
                {documentdetails?.length ? (
                  <p>
                    {documentcountnumber * 10 + 1} to{" "}
                    {documentcountnumber * 10 + documentnumberofElements} of{" "}
                    {documenttotalUser} records
                  </p>
                ) : (
                  <p>No Record Found </p>
                )}
              </div>
              <Pagination
                count={documentgetpageno}
                onChange={handledocumentpage}
              />
            </div>
          </div>
        </div>
      </div>

      <div class="show-mobile-only">
        <div className="accountenquiry">
          <Backdrop className={classes.backdrop} open={backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className=" tab-pane">
            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              <table className="table tbb">
                <tbody>
                  {documentdetails?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <p>
                          <span className="visa-label-mobile">SN</span>{" "}
                          {index + 1}
                        </p>
                        <p>
                          {item.client_id ? (
                            <EnquiryFileDownloadService
                              name={item.attachment_name}
                              path={item.path}
                              client_id={item.client_id}
                            />
                          ) : (
                            <span
                              className="filedownloadtd"
                              onClick={() =>
                                downloaddocument(item.path, item.clientId)
                              }
                            >
                              <span className="visa-label-mobile">
                                Document
                              </span>{" "}
                              {item.attachmentName}
                            </span>
                          )}
                        </p>
                        <p>
                          <span className="visa-label-mobile">Updated On</span>
                          {moment(item.createdOn || item.created_on).format(
                            "DD MMM YYYY"
                          )}
                          <br />
                          {moment(item.createdOn || item.created_on).format(
                            "hh.mm a"
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div>
                {documentdetails?.length ? (
                  <p>
                    {documentcountnumber * 10 + 1} to{" "}
                    {documentcountnumber * 10 + documentnumberofElements} of{" "}
                    {documenttotalUser} records
                  </p>
                ) : (
                  <p>No Record Found </p>
                )}
              </div>
              <Pagination
                count={documentgetpageno}
                onChange={handledocumentpage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiscellaneousDoc;
