import React, { useEffect, useState } from "react";
import "./../css/CoursesOverview.css";
import base_url from "../api/bootapi";
import axios from "axios";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import Modal from "react-bootstrap/Modal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));
const AccountInbox = () => {
  const [selectemail, setselectemail] = useState();
  const [backdropopen, setbackdropOpen] = React.useState(false);
  const classes = useStyles();
  const [showModal, setshowModal] = useState(false);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [inboxdetails, setinboxdetails] = useState();
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    setbackdropOpen(true);
    getInbox(pageno);
  }, []);
  const handlepage = (event, value) => {
    //console.log(value - 1);
    setpageno(value - 1);
    getInbox(value - 1);
  };
  const closeModal = () => {
    reset("", {
      keepValues: false,
    });
    setshowModal(false);
  };
  const getInbox = (value) => {
    axios
      .get(`${base_url.api5}/getByMailTypeAndToEmailId`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          mailType: "received",
          columnName: "date",
          direction: "DESC",
          toEmailId: clientdetails?.username,
          page: Number(value),
          size: 10,
        },
      })
      .then((res) => {
        setinboxdetails(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
        setbackdropOpen(false);
      })
      .catch((err) => {
        setinboxdetails();
        setgetpageno();
        setbackdropOpen(false);
        //console.log(err);
      });
  };

  const replyMe = (data2) => {
    setbackdropOpen(true);
    let data = {
      description: data2.description,
      fromEmailId: selectemail.toEmailId,
      mailType: "sent",
      subject: data2.subject,
      toEmailId: selectemail.fromEmailId,
    };
    let formData = new FormData();
    let fileToUpload = data2.picture.item(0);
    if (fileToUpload !== null) {
      formData.append("emailAttachmentFile", fileToUpload, fileToUpload.name);
      axios
        .post(`${base_url.api5}/uploadEmailAttachment`, formData, {
          headers: {
            Authorization: `Bearer ${clientdetails?.token}`,
            fromEmailId: selectemail.fromEmailId,
            toEmailId: selectemail.toEmailId,
            mailType: "received",
            subject: data2.subject,
            description: data2.description,
            status: Number(1),
          },
        })
        .then(
          (response) => {
            setbackdropOpen(false);
            toast.success(response.data.message);
            closeModal();
            // dispatch({
            //   type: "ADD_TO_USERTAB",
            //   user_tab: "message",
            // });
          },
          (error) => {
            setbackdropOpen(false);
            toast.error(JSON.stringify(error.response.data.message));
          }
        );
    } else {
      axios.post(`${base_url.api5}/createEmail`, data).then(
        (response) => {
          setbackdropOpen(false);
          toast.success(response.data.message);
          closeModal();
          // setTimeout(() => {
          //   dispatch({
          //     type: "ADD_TO_USERTAB",
          //     user_tab: "message",
          //   });
          // }, 1000);
        },
        (error) => {
          setbackdropOpen(false);
          toast.error(JSON.stringify(error.response.data.message));
        }
      );
    }
  };

  const downloadAll = (path, toEmailId, fromEmailId) => {
    setbackdropOpen(true);
    let file_name =
      path.split("amazonaws.com/")[path.split("amazonaws.com/").length - 1];
    const header = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${clientdetails?.token}`,
        toEmailId,
        fromEmailId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api5}/downloadEmailAttachment`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        const downloadFileType = localStorage.getItem(
          "enquiry_attachment_file_content_type"
        );
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdropOpen(false);
      })
      .catch((err) => setbackdropOpen(false));
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "enquiry_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };

  return (
    <>
      <div class="show-desktop-only">
        <div class="accountbody">
          <h2 className="accountbody-title">Sent Messages to ISW</h2>
          <div className="accountenquiry">
            <Backdrop className={classes.backdrop} open={backdropopen}>
              <CircularProgress color="inherit" />
            </Backdrop>
            {/* <ul class="nav nav-tabs accountbody_subtabnav">
              <li class="nav-item row mx-0 ">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#application_admission"
                >
                  Admission
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#application_offer">
                  Offer Letter
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#application_coe">
                  COE
                </a>
              </li>
            </ul> */}
            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              <table className="table tbb">
                <thead className="thb">
                  <tr>
                    <th>Date</th>
                    <th>Subject</th>
                    <th>Message</th>
                    <th>From</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {inboxdetails?.map((item, index) => (
                    <tr key={index + 1}>
                      <td style={{ width: "140px" }}>
                        {moment(item.date).format("DD MMM YYYY")}
                        <br />
                        {moment(item.date).format("hh.mm a")}
                      </td>
                      <td>{item.subject}</td>
                      <td>{item.description}</td>
                      <td>{item.fromEmailId}</td>
                      <td style={{ width: "180px" }}>
                        <button
                          // data-toggle="modal"
                          // data-target="#myModal2"
                          onClick={() => {
                            setselectemail(item);
                            setshowModal(true);
                            // document.getElementById("myForm").reset();
                          }}
                          // onClick={() => setselectemail(item)}
                          className="btn btn-green"
                          id={item.id}
                        >
                          Reply
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <Link
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={() => setselectemail(item)}
                          style={{ textDecoration: "none", cursor: "pointer" }}
                          id={item.id}
                        >
                          View
                        </Link>
                        &nbsp;&nbsp;&nbsp;
                        <div className="modal fade" id="myModal" role="dialog">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h4 className="modal-title">View Message</h4>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                >
                                  &times;
                                </button>
                              </div>
                              <div className="modal-body">
                                <div>
                                  <h4>Receiver:</h4>
                                  <p>{selectemail?.toEmailId}</p>
                                </div>
                                <div>
                                  <h4>Subject:</h4>
                                  <p>{selectemail?.subject}</p>
                                </div>
                                <div>
                                  <h4>Description:</h4>
                                  <p>{selectemail?.description}</p>
                                </div>
                                <div>
                                  <h4>Attachment:</h4>
                                  {selectemail?.emailAttachmentPath != null ? (
                                    <span
                                      className="filedownloadtd"
                                      onClick={() =>
                                        downloadAll(
                                          selectemail?.emailAttachmentPath,
                                          selectemail?.toEmailId,
                                          selectemail?.fromEmailId
                                        )
                                      }
                                    >
                                      {item.attachmentName}
                                    </span>
                                  ) : (
                                    <p>No Attachment</p>
                                  )}
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Modal show={showModal} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Reply
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form
                    id="myForm"
                    // onSubmit={(e) => {
                    //   e.preventDefault();
                    //   replyMe(e);
                    // }}
                    onSubmit={handleSubmit(replyMe)}
                  >
                    <div className="modal-body">
                      <label>Receiver</label>
                      <br />
                      <input
                        type="text"
                        value={selectemail?.fromEmailId}
                        className="form-control underline-input"
                        readOnly
                      />
                      <label>Subject</label>
                      <br />
                      <input
                        type="text"
                        className="form-control underline-input"
                        {...register("subject", { required: true })}
                      />
                      {errors.subject && (
                        <p className="errormessage">Subject is Required</p>
                      )}
                      <label>Description</label>
                      <br />
                      <textarea
                        rows="4"
                        className="form-control underline-input"
                        {...register("description", { required: true })}
                        style={{
                          height: "100px",
                          backgroundColor: "rgb(245, 245, 249)",
                          overflow: "hidden",
                        }}
                      ></textarea>
                      {errors.description && (
                        <p className="errormessage">Description is Required</p>
                      )}
                      <label>Attachment</label>
                      <br />
                      <input
                        type="file"
                        className="form-control underline-input"
                        {...register("picture")}
                      />
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Reply
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => closeModal()}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div>
                {inboxdetails?.length ? (
                  <p>
                    {countnumber * 10 + 1} to{" "}
                    {countnumber * 10 + numberofElements} of {totalUser} records
                  </p>
                ) : (
                  <p>No Record Found </p>
                )}
              </div>
              <Pagination count={getpageno} onChange={handlepage} />
            </div>
          </div>
        </div>
      </div>

      <div class="show-mobile-only">
        <div class="accountbody">
          <h2 className="accountbody-title">Inbox </h2>
          <div className="accountenquiry">
            <Backdrop className={classes.backdrop} open={backdropopen}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <ul class="nav nav-tabs accountbody_subtabnav">
              <li class="nav-item row mx-0 ">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#application_admission"
                >
                  Admission
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#application_offer">
                  Offer Letter
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#application_coe">
                  COE
                </a>
              </li>
            </ul>
            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              <table className="table tbb">
                <thead className="thb"></thead>
                <tbody>
                  {inboxdetails?.map((item, index) => (
                    <tr key={index + 1}>
                      <td colspan="5">
                        <div
                          key={index}
                          className="accunt-rpt-tb-content"
                          style={{ border: 0 }}
                        >
                          <h4>{item.subject}</h4>
                          <h6>Date</h6>
                          <div className="accunt-rpt-tb-text">
                            {moment(item.date).format("DD MMM YYYY")}
                            <br />
                            {moment(item.date).format("hh.mm a")}
                          </div>

                          <br />
                          <h6>Message</h6>
                          <div className="accunt-rpt-tb-text">
                            {item.description}
                          </div>

                          <br />
                          <h6>From</h6>
                          <div className="accunt-rpt-tb-text">
                            {item.fromEmailId}
                          </div>

                          <br />
                          <h6>Action</h6>
                          <div className="accunt-rpt-tb-text">
                            <button
                              // data-toggle="modal"
                              // data-target="#myModal2"
                              onClick={() => {
                                setselectemail(item);
                                setshowModal(true);
                                // document.getElementById("myForm").reset();
                              }}
                              // onClick={() => setselectemail(item)}
                              className="btn btn-green"
                              id={item.id}
                            >
                              Reply
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <Link
                              data-toggle="modal"
                              data-target="#myModal"
                              onClick={() => setselectemail(item)}
                              style={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              id={item.id}
                            >
                              View
                            </Link>
                            &nbsp;&nbsp;&nbsp;
                            <div
                              className="modal fade"
                              id="myModal"
                              role="dialog"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h4 className="modal-title">
                                      View Message
                                    </h4>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div>
                                      <h4>Receiver:</h4>
                                      <p>{selectemail?.toEmailId}</p>
                                    </div>
                                    <div>
                                      <h4>Subject:</h4>
                                      <p>{selectemail?.subject}</p>
                                    </div>
                                    <div>
                                      <h4>Description:</h4>
                                      <p>{selectemail?.description}</p>
                                    </div>
                                    <div>
                                      <h4>Attachment:</h4>
                                      {selectemail?.emailAttachmentPath !=
                                      null ? (
                                        <span
                                          className="filedownloadtd"
                                          onClick={() =>
                                            downloadAll(
                                              selectemail?.emailAttachmentPath,
                                              selectemail?.toEmailId,
                                              selectemail?.fromEmailId
                                            )
                                          }
                                        >
                                          {item.attachmentName}
                                        </span>
                                      ) : (
                                        <p>No Attachment</p>
                                      )}
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      data-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Modal show={showModal} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Reply
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form
                    id="myForm"
                    // onSubmit={(e) => {
                    //   e.preventDefault();
                    //   replyMe(e);
                    // }}
                    onSubmit={handleSubmit(replyMe)}
                  >
                    <div className="modal-body">
                      <label>Receiver</label>
                      <br />
                      <input
                        type="text"
                        value={selectemail?.fromEmailId}
                        className="form-control underline-input"
                        readOnly
                      />
                      <label>Subject</label>
                      <br />
                      <input
                        type="text"
                        className="form-control underline-input"
                        {...register("subject", { required: true })}
                      />
                      {errors.subject && (
                        <p className="errormessage">Subject is Required</p>
                      )}
                      <label>Description</label>
                      <br />
                      <textarea
                        rows="4"
                        className="form-control underline-input"
                        {...register("description", { required: true })}
                        style={{
                          height: "100px",
                          backgroundColor: "rgb(245, 245, 249)",
                          overflow: "hidden",
                        }}
                      ></textarea>
                      {errors.description && (
                        <p className="errormessage">Description is Required</p>
                      )}
                      <label>Attachment</label>
                      <br />
                      <input
                        type="file"
                        className="form-control underline-input"
                        {...register("picture")}
                      />
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Reply
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => closeModal()}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div>
                {inboxdetails?.length ? (
                  <p>
                    {countnumber * 10 + 1} to{" "}
                    {countnumber * 10 + numberofElements} of {totalUser} records
                  </p>
                ) : (
                  <p>No Record Found </p>
                )}
              </div>
              <Pagination count={getpageno} onChange={handlepage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountInbox;
