import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import base_url from "../api/bootapi";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));
const ProviderTransitionArrangement = (props) => {
  const classes = useStyles();

  // const getPath = (data) => {
  //   data = data.split("/var/www/html")[1];
  //   return data;
  // };

  const downloadAll = (path) => {
    props.setbackdropOpen(true);
    const clientdetails = JSON.parse(
      localStorage.getItem("isw_client_details_frontend")
    );
    let file_name =
      path.split("amazonaws.com/")[path.split("amazonaws.com/").length - 1];
    const header = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${clientdetails?.token}`,
        instituteId: props.providerdetail?.institutionId,
        branchId: props.providerdetail?.branchId,
        fileName: file_name,
      },
    };

    var url = `${base_url.api5}/downloadBranchTransactionalAgreementByBranchIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        const downloadFileType = localStorage.getItem(
          "enquiry_attachment_file_content_type"
        );
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        props.setbackdropOpen(false);
      })
      .catch((err) => props.setbackdropOpen(false));
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "enquiry_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };

  return (
    <div>
      <h4>
        <span className="CourseOverviewheader">Transition Arrangement : </span>
      </h4>
      <TableContainer style={{ marginTop: "10px" }} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead style={{ backgroundColor: "cornflowerblue" }}>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.transactionalarrangement?.map((p, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {p.branchTransactionalAgreementName}
                </TableCell>
                <TableCell align="right">
                  <span
                    className="filedownloadtd"
                    onClick={() =>
                      downloadAll(
                        p.branchTransactionalAgreementPath,
                        props.client_id
                      )
                    }
                  >
                    Download
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProviderTransitionArrangement;
