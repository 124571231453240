import React from "react";
import AccountMainBody from "../AccountMainBody";
// import AccountEnquiry from "../AccountEnquiry";
import AccountInbox from "../AccountInbox";
import AccountSendMessage from "../AccountSendMessage";
import ReactNavbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import EditAccount from "../EditAccount";
import ChangePassword from "../ChangePassword";
import AccountFees from "../AccountFees";
import AgentFees from "../AgentFees";
import LeadMainBody from "../LeadMainBody";
import LeadCompare from "../LeadCompare";
import LeadProfile from "../LeadProfile";
// import LeadDocument from "../PassportDocument";

const LeadDash = (props) => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  return (
    <div>
      <ReactNavbar
        // closeModal={closeModal}
        // openModal={openModal}
        fromwhere={"Account"}
      />
      {props.sidebartab === "comapre" ? (
        <LeadCompare />
      ) : (
        <>
          {props.sidebartab === "applied" ? (
            <LeadMainBody />
          ) : (
            <>
              {props.sidebartab === "inbox" ? (
                <AccountInbox />
              ) : (
                <>
                  {props.sidebartab === "message" ? (
                    <AccountSendMessage />
                  ) : (
                    <>
                      {props.sidebartab === "change" ? (
                        <ChangePassword />
                      ) : (
                        <>
                          {props.sidebartab === "fees" ? (
                            <AccountFees />
                          ) : (
                            <LeadProfile />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {/* {props.sidebartab === "edit" ? (
                <Footer />
            ) : (
                <div className="account_footer">
                    
                </div>
            )} */}
    </div>
  );
};

export default LeadDash;
