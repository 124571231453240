import React, { useEffect, useState } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import Pagination from "@mui/material/Pagination";
import moment from "moment";
import EnquiryFileDownloadService from "../../../services/EnquiryFileDownloadService";

const OfferLetter = () => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [documentdetails, setdocumentdetails] = useState([]);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getdocumentdetails(pageno);
  }, []);
  const handlepage = (event, value) => {
    setpageno(value - 1);
    getdocumentdetails(value - 1);
  };
  const getdocumentdetails = (value) => {
    axios
      .get(`${base_url.api5}/getEnquiryAttachmentsByDocumentTypeAndClientId`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          clientId: clientdetails?.clientId,
          documentType: "offer_letter",
          page: Number(value),
          size: 10,
        },
      })
      .then((res) => {
        setdocumentdetails(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  return (
    <div>
      <div style={{ marginBottom: "15px", overflowX: "auto" }}>
        <table className="table tbb">
          <thead className="thb">
            <tr>
              <th style={{ width: "50px", textAlign: "center" }}>SN</th>
              <th>Document</th>
              <th>Course</th>
              <th>Provider</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {documentdetails?.map((item, index) => (
              <tr key={index}>
                <td style={{ width: "50px", textAlign: "center" }}>
                  {index + 1}
                </td>
                <td>
                  <EnquiryFileDownloadService
                    name={item.attachment_name}
                    path={item.path}
                    client_id={item.client_id}
                  />
                </td>
                <td>{item.course_name}</td>
                <td>{item.institution_name}</td>
                <td style={{ width: "140px" }}>
                  {moment(item.created_on).format("DD MMM YYYY")}
                  <br />
                  {moment(item.created_on).format("hh.mm a")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: 20,
        }}
      >
        <div>
          {documentdetails?.length ? (
            <p>
              {countnumber * 10 + 1} to {countnumber * 10 + numberofElements} of{" "}
              {totalUser} records
            </p>
          ) : (
            <p>No Record Found </p>
          )}
        </div>
        <Pagination count={getpageno} onChange={handlepage} />
      </div>
    </div>
  );
};

export default OfferLetter;
