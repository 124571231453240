import React, { useState } from "react";
import moment from "moment";
import Border from "./../../assets/images/border.png";
import NoDataFound from "./../../assets/images/unnamed.jpg";
import EnquiryFileDownloadService from "../../services/EnquiryFileDownloadService";

const AccordionComponent = ({
  enquirysteps,
  enquiryattachmentsteps,
  index,
  loader,
  fromwhere,
}) => {
  const [checkimage, setcheckimage] = useState("activity");

  return (
    <div className="p-4">
      <ul className="nav nav-tabs accountbody_tabnav">
        <li className="nav-item row mx-0 ">
          <a
            className="nav-link active"
            data-toggle="tab"
            href={
              fromwhere === "visa"
                ? `#activity-${index}`
                : `#insuactivity-${index}`
            }
            onClick={() => setcheckimage("activity")}
          >
            Activities
          </a>
          {checkimage === "activity" || checkimage === "attachment" ? null : (
            <img alt="border" src={Border} />
          )}
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="tab"
            href={
              fromwhere === "visa"
                ? `#attachment-${index}`
                : `#insuattachment-${index}`
            }
            onClick={() => setcheckimage("attachment")}
          >
            Attachments
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div
          id={
            fromwhere === "visa" ? `activity-${index}` : `insuactivity-${index}`
          }
          className=" tab-pane active"
        >
          <div style={{ marginBottom: "15px", overflowX: "auto" }}>
            {enquirysteps.length > 0 ? (
              <table className="table tbb">
                <thead className="thb">
                  <tr>
                    <th>SN</th>
                    <th>StepName</th>
                  </tr>
                </thead>
                <tbody>
                  {enquirysteps.map((p, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{p.workflowStepsName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>
                {loader ? null : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "50px" }}
                  >
                    <img alt="No data Found" src={NoDataFound} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div
          id={
            fromwhere === "visa"
              ? `attachment-${index}`
              : `insuattachment-${index}`
          }
          className=" tab-pane"
        >
          <div style={{ marginBottom: "15px", overflowX: "auto" }}>
            {enquiryattachmentsteps.length > 0 ? (
              <table className="table tbb">
                <thead className="thb">
                  <tr>
                    <th style={{ width: "50px", textAlign: "center" }}>SN</th>
                    <th>Document</th>
                    <th style={{ width: "200px" }}>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {enquiryattachmentsteps.map((p, i) => (
                    <tr key={i}>
                      <td style={{ width: "50px", textAlign: "center" }}>
                        {i + 1}
                      </td>
                      <td>
                        <EnquiryFileDownloadService
                          name={p.attachmentName}
                          path={p.path}
                          client_id={p.clientId}
                        />
                      </td>
                      <td style={{ width: "140px" }}>
                        {moment(p.createdOn).format("DD MMM YYYY")}
                        <br />
                        {moment(p.createdOn).format("hh.mm a")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>
                {loader ? null : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ marginTop: "50px" }}
                  >
                    <img alt="No data Found" src={NoDataFound} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionComponent;
