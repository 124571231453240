import React, { useState, useEffect } from "react";
import InsuranceDoc from "./AccountBodyDocument/InsuranceDoc";
import MiscellaneousDoc from "./AccountBodyDocument/MiscellaneousDoc";
import VisaDoc from "./AccountBodyDocument/VisaDoc";
import AccountEnquiry from "./AccountEnquiry";
// import AccountEnrollment from "./AccountEnrollment";
import Border from "./../../assets/images/border.png";
import axios from "axios";
import base_url from "../api/bootapi";
import Pagination from "@mui/material/Pagination";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const LeadMainBody = (props) => {
  const [checkimage, setcheckimage] = useState("application");
  // const loggedInUser = localStorage.getItem("agentLogin");
  //console.log('Agent Login:',loggedInUser);

  const classes = useStyles();

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getClientDetails();
  }, []);

  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  const clientLoginCheck = JSON.parse(localStorage.getItem("clientLogin"));

  const [firstName, setfirstName] = useState();
  const [middleName, setmiddleName] = useState();
  const [lastName, setlastName] = useState();

  const [countryvalue, setcountryvalue] = useState(
    localStorage.getItem("countryvalue")
  );
  const [statevalue, setstatevalue] = useState(
    localStorage.getItem("statevalue")
  );
  const [cityvalue, setcityvalue] = useState(localStorage.getItem("cityvalue"));
  const [areaname, setareaname] = useState(() => {
    if (localStorage.getItem("areaname")) {
      return JSON.parse(localStorage.getItem("areaname"));
    } else {
      return [];
    }
  });
  const [coursevalue, setcoursevalue] = useState(
    localStorage.getItem("frontendcoursename")
  );
  const [coursevalueid, setcoursevalueid] = useState(
    localStorage.getItem("frontendcourseid")
  );
  const [backdropopen, setbackdropOpen] = useState(false);
  const [courseDetail, setcourseDetail] = useState(null);

  useEffect(() => {
    getheader(0, columnName);
  }, [statevalue, countryvalue, clientdetails?.clientId]);

  const applydetail =
    JSON.parse(localStorage.getItem("isw_frontend_applycourse_detail")) || {};

  const totalAppliedCourse =
    JSON.parse(localStorage.getItem("totalAppliedCourse")) || [];

  const AppliedCourseList =
    JSON.parse(localStorage.getItem("AppliedCourseList")) || [];

  const [levelvalue, setlevelvalue] = useState(localStorage.getItem("level"));
  const [areavalue, setareavalue] = useState(localStorage.getItem("area"));
  const [levelid, setlevelid] = useState([localStorage.getItem("level")]);
  const [areaid, setareaid] = useState([localStorage.getItem("area")]);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [length, setlength] = useState();
  const [totallength, settotallength] = useState();
  const [countnumber, setcountnumber] = useState();
  const [configAdmin, setConfigAdmin] = useState(false);
  const [city, setcity] = useState([]);
  const [columnName, setcolumnName] = useState("courseId-DESC");
  const [comparedetailmodal, setcomparedetailmodal] = useState([]);
  const [comparecheck, setcomparecheck] = useState();

  const getavailablecourses = (headers) => {
    axios
      .get(`${base_url.api5}/getPopularCourses`, {
        headers: headers,
      })
      .then((res) => {
        //console.log(res);
        setcourseDetail(res.data.data.content);
        let obj = {};
        let availablecourses = res.data.data.content;
        for (let i = 0; i < availablecourses.length; i++) {
          let key = `${availablecourses[i].courseId}${availablecourses[i].branchInstitutionId}`;
          obj[key] = false;
        }
        const course_obj = [];
        for (let i = 0; i < availablecourses?.length; i++) {
          const element = availablecourses?.[i];
          //console.log(element);
          course_obj.push(element);
        }
        const course_array = [];
        let course_id = Array.prototype.map
          .call(course_obj, function (item) {
            return item.courseId;
          })
          .join(",");
        course_array.push(course_id);
        //console.log("course_array------->", JSON.parse(course_array));

        // let copiedobj = JSON.parse(JSON.stringify(obj));
        if (clientdetails?.token) {
          if (clientdetails?.agentId) {
            getcomparelistbyagentid(obj);
          } else {
            //console.log("coming in else ----------->");
            getcomparelist(obj);
          }
          // getbookmarklist(copiedobj);
        } else if (clientdetails?.clientId) {
          //console.log("coming in else if ----------->");
          getcomparelist(obj);
        }
        setlength(res.data.data.numberOfElements);
        setgetpageno(res.data.data.totalPages);
        settotallength(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setbackdropOpen(false);
      })
      .catch((err) => handleerror());
  };

  const getheader = (value, col, arr, attribute) => {
    setbackdropOpen(true);
    let headers = {
      columnName: col.split("-")[0],
      direction: col.split("-")[1],
      popular: 1,
      page: Number(value),
      size: 12,
    };
    if (countryvalue) {
      headers["country"] = countryvalue;
    }
    if (statevalue) {
      headers["state"] = statevalue;
    }
    if (attribute === "area" && arr) {
      headers["areaId"] = arr;
    } else if (attribute !== "area" && areavalue) {
      headers["areaId"] = areavalue;
    }
    if (attribute !== "city" && cityvalue) {
      headers["city"] = cityvalue;
    }
    if (attribute === "level" && arr) {
      headers["levelId"] = arr;
    } else if (attribute !== "level" && levelvalue) {
      headers["levelId"] = levelvalue;
    }
    if (attribute === "course" && arr) {
      headers["courseId"] = arr;
    } else if (attribute !== "course" && coursevalueid) {
      headers["courseId"] = coursevalueid;
    }
    //console.log(headers);
    getavailablecourses(headers);
  };

  const getClientDetails = () => {
    axios
      .get(`${base_url.api5}/getClientByUsername`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          username: clientdetails?.username,
        },
      })
      .then((res) => {
        setfirstName(res.data.data.clientDetail.firstName);
        setmiddleName(res.data.data.clientDetail.middleName);
        setlastName(res.data.data.clientDetail.lastName);
        let client = res.data.data.clientDetail;
      });
  };

  const handleerror = () => {
    setbackdropOpen(false);
    setlength(0);
    setgetpageno(0);
    settotallength(0);
    setcourseDetail([]);
  };

  const getcomparelistbyagentid = (obj) => {
    axios
      .get(`${base_url.api5}/fetchCompareListByAgentId`, {
        headers: {
          agentId: clientdetails?.agentId,
        },
      })
      .then((res) => {
        let comparearr = [];
        for (let i = 0; i < res.data.data.length; i++) {
          let comparemodalobj = {
            courseId: res.data.data[i].courseId,
            branchId: res.data.data[i].branchId,
          };
          let key = `${res.data.data[i].courseId}${res.data.data[i].branchId}`;
          if (obj.hasOwnProperty(key)) obj[key] = true;
          comparearr.push(comparemodalobj);
        }
        setcomparedetailmodal(comparearr);
        setbackdropOpen(false);
        setcomparecheck(obj);
      })
      .catch((err) => {
        setcomparecheck();
        setcomparedetailmodal([]);
        setbackdropOpen(false);
      });
  };

  const getcomparelist = (obj) => {
    if (clientLoginCheck === 1) {
      axios
        .get(`${base_url.api5}/fetchClientCompareListByClientId`, {
          headers: {
            clientId: clientdetails?.clientId,
          },
        })
        .then((res) => {
          //console.log(res);
          let comparearr = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let comparemodalobj = {
              courseId: res.data.data[i].courseId,
              branchId: res.data.data[i].branchId,
            };
            let key = `${res.data.data[i].courseId}${res.data.data[i].branchId}`;
            if (obj.hasOwnProperty(key)) obj[key] = true;
            comparearr.push(comparemodalobj);
          }
          //console.log(comparearr);
          setcomparedetailmodal(comparearr);
          setbackdropOpen(false);
          //console.log(obj);
          setcomparecheck(obj);
        })
        .catch((err) => {
          setcomparecheck();
          setcomparedetailmodal([]);
          setbackdropOpen(false);
        });
    } else {
      axios
        .get(`${base_url.api5}/fetchClientCompareListByClientTempId`, {
          headers: {
            clientTempId: clientdetails?.clientId,
          },
        })
        .then((res) => {
          //console.log(res);
          let comparearr = [];
          for (let i = 0; i < res.data.data.length; i++) {
            let comparemodalobj = {
              courseId: res.data.data[i].courseId,
              branchId: res.data.data[i].branchId,
            };
            let key = `${res.data.data[i].courseId}${res.data.data[i].branchId}`;
            if (obj.hasOwnProperty(key)) obj[key] = true;
            comparearr.push(comparemodalobj);
          }
          //console.log(comparearr);
          setcomparedetailmodal(comparearr);
          setbackdropOpen(false);
          //console.log(obj);
          setcomparecheck(obj);
        })
        .catch((err) => {
          setcomparecheck();
          setcomparedetailmodal([]);
          setbackdropOpen(false);
        });
    }
  };

  return (
    <div className="accountbody">
      <div className="account-username">
        Welcome <br />
        <span>
          {firstName} {middleName} {lastName}
        </span>
      </div>
      <div class="show-desktop-only">
        <div class="accountbody">
          <h2 className="accountbody-title">Enquired course list</h2>
          <div className="accountenquiry">
            <Backdrop className={classes.backdrop} open={backdropopen}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              {AppliedCourseList.length > 0 ? (
                <table className="table tbb">
                  <thead className="thb">
                    <tr>
                      <th>CourseName</th>
                      <th>BranchName</th>
                      <th>ProviderName</th>
                      <th>Duration</th>
                      <th>OnShore Fee</th>
                      <th>OffShore Fee</th>
                      <th>Course Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AppliedCourseList?.map((item, i) => (
                      <tr key={i}>
                        <td>{item.coursename || "-"}</td>

                        <td>{item.branchName || "-"}</td>

                        <td>{item.providername || "-"}</td>

                        <td>{item.duration || "-"}</td>

                        <td>
                          {Number(item.OnShoreFees) === 0 ? (
                            <>-</>
                          ) : (
                            <>
                              {item.OnShoreFees && "$"}{" "}
                              {item.OnShoreFees || "-"}
                            </>
                          )}
                        </td>

                        <td>
                          {Number(item.offShoreFees) === 0 ? (
                            <>-</>
                          ) : (
                            <>
                              {item.offShoreFees && "$"}{" "}
                              {item.offShoreFees || "-"}
                            </>
                          )}
                        </td>

                        <td>{item.courseCode || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <>
                  {backdropopen ? null : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ marginTop: "50px" }}
                    >
                      No Record Found
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div></div>
              <Pagination />
            </div>
          </div>
        </div>
      </div>

      <div class="show-mobile-only">
        <div class="accountbody">
          <h2 className="accountbody-title">e List</h2>
          <div className="accountenquiry">
            <Backdrop className={classes.backdrop} open={backdropopen}>
              <CircularProgress color="inherit" />
            </Backdrop>

            <div style={{ marginBottom: "15px", overflowX: "auto" }}>
              <table className="table tbb">
                {/* <tbody>
                                    {courseDetail != null ? (
                                        courseDetail &&
                                        courseDetail?.map((item, index) => (
                                            <>
                                                {applydetail?.[`${item.courseId}${item.branchInstitutionId}`] ?
                                                    <tr key={index}>
                                                        <div key={index} className="accunt-rpt-tb-content p-3" style={{ border: 0 }}>
                                                            <h4>{item.subject}</h4>

                                                            <h6>Date</h6>
                                                            <div className="accunt-rpt-tb-text">{moment(item.date).format("lll")}</div>

                                                            <br /><h6>Message</h6>
                                                            <div className="accunt-rpt-tb-text">{item.description}</div>


                                                            <br /><h6>From</h6>
                                                            <div className="accunt-rpt-tb-text">
                                                                {item.fromEmailId}
                                                            </div>

                                                            <br /><h6>Action</h6>
                                                            <div className="accunt-rpt-tb-text">

                                                                <Link
                                                                    data-toggle="modal"
                                                                    data-target="#myModal"
                                                                    style={{ textDecoration: "none", cursor: "pointer" }}
                                                                >
                                                                    View
                                                                </Link>
                                                                <div className="modal fade" id="myModal" role="dialog">
                                                                    <div className="modal-dialog">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h4 className="modal-title">View Message</h4>
                                                                                <button
                                                                                    type="button"
                                                                                    className="close"
                                                                                    data-dismiss="modal"
                                                                                >
                                                                                    &times;
                                                                                </button>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                <div>
                                                                                    <h4>Receiver:</h4>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div>
                                                                                    <h4>Subject:</h4>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div>
                                                                                    <h4>Description:</h4>
                                                                                    <p></p>
                                                                                </div>
                                                                                <div>
                                                                                    <h4>Attachment:</h4>

                                                                                </div>
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    data-dismiss="modal"
                                                                                >
                                                                                    Close
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>






                                                    </tr>

                                                    :
                                                    <div></div>
                                                }
                                            </>
                                        ))
                                    ) : (
                                        <p>{null}</p>
                                    )}

                                </tbody> */}
                <tbody>
                  {AppliedCourseList?.map((item, i) => (
                    <tr key={i}>
                      <td>{item.coursename || "-"}</td>

                      <td>{item.branchName || "-"}</td>

                      <td>{item.providername || "-"}</td>

                      <td>{item.duration || "-"}</td>

                      <td>
                        {Number(item.OnShoreFees) === 0 ? (
                          <>-</>
                        ) : (
                          <>
                            {item.OnShoreFees && "$"} {item.OnShoreFees || "-"}
                          </>
                        )}
                      </td>

                      <td>
                        {Number(item.offShoreFees) === 0 ? (
                          <>-</>
                        ) : (
                          <>
                            {item.offShoreFees && "$"}{" "}
                            {item.offShoreFees || "-"}
                          </>
                        )}
                      </td>

                      <td>{item.cricos || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: 20,
              }}
            >
              <div></div>
              <Pagination />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadMainBody;
