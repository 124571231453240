import React, { useEffect, useState } from "react";
import axios from "axios";
import base_url from "../../api/bootapi";
import Pagination from "@mui/material/Pagination";

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

import AccordionComponent from "../AccordionComponent";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "15px",
    fontWeight: 400,
    color: "#224289",
  },
}));

const AgentVisaDoc = () => {
  const classes = useStyles();
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [totalUser, settotalUser] = useState();
  const [countnumber, setcountnumber] = useState();
  const [numberofElements, setnumberofElements] = useState();
  const [documentdetails, setdocumentdetails] = useState([]);
  const [enquirysteps, setenquirysteps] = useState([]);
  const [enquiryattachmentsteps, setenquiryattachmentsteps] = useState([]);
  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [expanded, setExpanded] = useState();
  const [loader, setloader] = useState(false);

  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getdocumentdetails(pageno);
  }, []);
  const handlepage = (event, value) => {
    setExpanded();
    setpageno(value - 1);
    getdocumentdetails(value - 1);
  };
  const getdocumentdetails = (value) => {
    axios
      .get(`${base_url.api5}/getEnquiryBySubAgentIdAndWorkflowType`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          subAgentId: clientdetails?.agentId,
          columnName: "created",
          direction: "DESC",
          page: Number(value),
          size: 10,
          workflowType: "Migration",
        },
      })
      .then((res) => {
        setdocumentdetails(res.data.data.content);
        setgetpageno(res.data.data.totalPages);
        settotalUser(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setnumberofElements(res.data.data.numberOfElements);
      });
  };

  const handleChange = (panel, enquiryId) => (event, newExpanded) => {
    setloader(true);
    setenquiryattachmentsteps([]);
    setenquirysteps([]);
    if (newExpanded) {
      axios
        .get(`${base_url.api5}/getEnquiryWorkflowByEnquiryId`, {
          headers: {
            Authorization: `Bearer ${clientdetails?.token}`,
            enquiryId,
          },
        })
        .then((res) => {
          let data = [];
          res.data.data.enquiryWorkflowSteps.forEach((item) =>
            item.status === 1 ? data.push(item) : null
          );
          setenquirysteps(data);
          setloader(false);
        })
        .catch((err) => setloader(false));
      axios
        .get(`${base_url.api5}/getAttachmentsByEnquiryId`, {
          headers: {
            Authorization: `Bearer ${clientdetails?.token}`,
            enquiryId,
          },
        })
        .then((res) => setenquiryattachmentsteps(res.data.data || []));
    }
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="accountenquiry">
      <div>
        <table className="table tbb mb-0">
          <thead className="thb">
            <tr>
              <th style={{ width: "50px", textAlign: "center" }}>SN</th>
              <th style={{ width: "38%" }}>Workflow Name</th>
              <th>Provider</th>
            </tr>
          </thead>
        </table>
      </div>
      {documentdetails.length > 0 &&
        documentdetails.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index, item.enquiryId)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <div className="row w-100">
                <Typography
                  className={`${classes.heading} col-md-1 text-center pl-0`}
                >
                  {index + 1}
                </Typography>
                <Typography className={`${classes.heading} col-md-5 pl-0`}>
                  {item.visaType}
                </Typography>
                <Typography className={`${classes.heading} col-md-5 pl-0`}>
                  {item.institutionName}
                </Typography>
              </div>
            </AccordionSummary>
            <hr className="my-0" />
            <AccordionComponent
              enquirysteps={enquirysteps}
              enquiryattachmentsteps={enquiryattachmentsteps}
              index={index}
              loader={loader}
              fromwhere={"visa"}
            />
          </Accordion>
        ))}
      <div className="d-flex justify-content-between mt-4 ml-3">
        <div>
          {documentdetails?.length ? (
            <p>
              {countnumber * 10 + 1} to {countnumber * 10 + numberofElements} of{" "}
              {totalUser} records
            </p>
          ) : (
            <p>No Record Found </p>
          )}
        </div>
        <Pagination count={getpageno} onChange={handlepage} />
      </div>
    </div>
  );
};

export default AgentVisaDoc;
