export const initialState = {
  user: null,
  user_tab: "dashboard",
  markers: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_USERTAB":
      return {
        ...state,
        user_tab: action.user_tab,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_ENQUIRY_COMPARE":
      return {
        ...state,
        enquiryalreadycompared: action.enquiryalreadycompared,
      };
    case "SET_MARKERS":
      return {
        ...state,
        markers: action.markers,
      };
    default:
      return state;
  }
};

export default reducer;
