import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import base_url from "../api/bootapi";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoDataFound from "./../../assets/images/unnamed.jpg";
import Pagination from "@mui/material/Pagination";
import LogoImages from "./../../../src/assets/images/images.jpeg";
import Footer from "../Footer/Footer";
import NoLogoAvailable from "./../../assets/images/nologo.png";

import NavbarNew from "../Navbar/NavbarNew";
import attachmenturl from "../api/attachmenturl";

const Institution = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      // marginTop: "24px",
      // boxShadow: "0 0 40px #ccc",
      // borderRadius: "10px",
    },
    heading: {
      fontSize: "15px",
      fontWeight: 400,
      color: "#224289",
    },
    backdrop: {
      zIndex: 1000,
      color: "#fff",
    },
  }));

  const pageStartRef = useRef(null);
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const classes = useStyles();
  const [backdropopen, setbackdropOpen] = useState(false);
  const [providerDetail, setproviderDetail] = useState(null);

  const [pageno, setpageno] = useState(0);
  const [getpageno, setgetpageno] = useState();
  const [length, setlength] = useState();
  const [totallength, settotallength] = useState();
  const [countnumber, setcountnumber] = useState();
  const [columnName, setcolumnName] = useState("added_on");
  const [direction, setdirection] = useState("DESC");

  useEffect(() => {
    setbackdropOpen(true);
    getheader(0);
  }, []);

  function calculatePathName(cricosId) {
    // Extract the numeric part of the alphanumeric ID, assuming it's at the start
    const numericPart = cricosId.match(/^\d+/);
    const numericId = numericPart ? parseInt(numericPart[0]) : 0;

    // Calculate the lower and upper bounds for each range
    const lowerBound = Math.floor((numericId - 1) / 500) * 500 + 1;
    const upperBound = Math.min(lowerBound + 499, 10000); // Adjust the upper limit as needed

    // Format the lower and upper bounds as strings with leading zeros
    const lowerBoundStr = String(lowerBound).padStart(5, "0");
    const upperBoundStr = String(upperBound).padStart(5, "0");

    // Construct the path name
    const pathName = `${lowerBoundStr}-${upperBoundStr}`;

    return pathName;
  }

  const getavailableProvider = (headers) => {
    axios
      .get(`${base_url.api5}/getAllProvider`, {
        headers: headers,
      })
      .then((res) => {
        //console.log(res);
        // setproviderDetail(res.data.data.content);
        const logoArray = res.data.data.content.map((item, index) => ({
          id: index + 1,
          banner: item.banner
            ? `${attachmenturl}/attachments/providers/australia/${calculatePathName(
                item.cricos_provider_code
              )}/${item.cricos_provider_code}/${item.banner}`
            : null,
          country: item.country,
          cricos_provider_code: item.cricos_provider_code,
          institutionId: item.institutionId,
          logo: item.logo
            ? `${attachmenturl}/attachments/providers/australia/${calculatePathName(
                item.cricos_provider_code
              )}/${item.cricos_provider_code}/${item.logo}`
            : null,
          name: item.name,
          state: item.state,
        }));

        //console.log("logoArray", logoArray);

        setproviderDetail(logoArray);

        setlength(res.data.data.numberOfElements);
        setgetpageno(res.data.data.totalPages);
        settotallength(res.data.data.totalElements);
        setcountnumber(res.data.data.number);
        setbackdropOpen(false);
      })
      .catch((err) => handleerror());
  };

  const getheader = (value) => {
    setbackdropOpen(true);
    let headers = {
      columnName: columnName,
      direction: direction,
      page: Number(value),
      size: 15,
      status: 1,
    };
    //console.log(headers);
    getavailableProvider(headers);
  };

  const handleerror = () => {
    setbackdropOpen(false);
    setlength(0);
    setgetpageno(0);
    settotallength(0);
    setproviderDetail([]);
  };

  const handlepage = (event, value) => {
    setbackdropOpen(true);
    setpageno(value - 1);
    getheader(value - 1, columnName);
    pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavbarNew />
      {providerDetail !== null ? (
        <>
          <div
            class="container"
            style={{ maxWidth: "1650px", paddingTop: "130px" }}
          >
            <div className="row">
              {providerDetail.length > 0
                ? providerDetail.map((p, index) => (
                    <div
                      class="col-12 col-sm-6 col-md-4 image-grid-item"
                      key={index}
                      style={{ height: "400px" }}
                    >
                      <div style={{ height: "60%", position: "relative" }}>
                        <img
                          src={p.banner}
                          style={{ width: "100%", height: "100%" }}
                        />
                        <div
                          style={{
                            width: "130px",
                            height: "120px",
                            position: "absolute",
                            left: "30px",
                            bottom: "30px",
                            backgroundColor: "#fff",
                            padding: "5px",
                            borderRadius: "8px",
                          }}
                        >
                          <img
                            src={p.logo || NoLogoAvailable}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "18px 20px",
                          backgroundColor: "#fbfbfb",
                          boxShadow: "1px 1px 8px 3px rgba(0,0,0,0.1)",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "22px",
                            fontStyle: "bold",
                            marginBottom: "0px",
                          }}
                        >
                          {p.name.length > 35
                            ? p.name.slice(0, 35) + "..."
                            : p.name}
                        </p>
                        <p style={{ fontSize: "17px", marginBottom: "0px" }}>
                          {p.state}, {p.country}
                        </p>
                      </div>
                    </div>
                  ))
                : backdropopen === false && (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ marginTop: "50px" }}
                    >
                      <img alt="No data Found" src={NoDataFound} />
                    </div>
                  )}
            </div>
          </div>
        </>
      ) : null}

      {length > 0 && (
        <div className="d-flex justify-content-between">
          <p className="coursesearchbody_counttext">
            {countnumber * 15 + 1} to {countnumber * 15 + length} of{" "}
            {totallength} results found
          </p>
          <div>
            <Pagination
              color="primary"
              count={getpageno}
              onChange={handlepage}
            />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Institution;
