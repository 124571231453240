import React, { useState, useEffect } from "react";
import axios from "axios";
import base_url from "../api/bootapi";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useForm } from "react-hook-form";
import moment from "moment";

import { toast } from "react-toastify";

import SelectCurrency from "react-select-currency";
import Modal from "react-modal";

import WorkflowFiles from "./WorkflowFiles";
import { Card, Col, Row, Button } from "reactstrap";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

export const customStylesAttachment = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minWidth: "1000px",
    maxWidth: "1000px",
    maxHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
};

const OtherDocuments = () => {
  const [clientDocuments, setclientDocuments] = useState([]);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [allIntake, setallIntake] = useState([]);
  const [allVisa, setallVisa] = useState([]);
  const [allHealth, setallHealth] = useState([]);
  const [clientDetail, setclientDetail] = useState();
  const [region, setregion] = useState();
  const [country1, setcountry] = useState();
  const [country1Passport, setcountryPassport] = useState();
  const [countryrequired, setcountryrequired] = useState(false);
  const [staterequired, setstaterequired] = useState(false);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [stateCust, setstateCust] = useState();
  const classes = useStyles();
  const [backdropopen, setbackdropOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: clientDetail,
  });

  const closeModal = (id) => {
    // setIsOpen(false);
    setIsOpen2(false);
    // setIsOpen3(false);
    // setIsOpen4(false);
  };

  useEffect(() => {
    getClientDoc();
  }, []);

  const loggedInUser = localStorage.getItem("agentLogin");
  const [currency1, setCurrency] = useState("");
  const [agentData, setAgentData] = useState();
  const [agentType, setAgentType] = useState();
  const [openTransactionalForm, setOpenTransactionalForm] = useState(false);
  const [transactionalArrangementList, setTransactionalArrangementList] =
    useState();

  const [filesizemore, setfilesizemore] = useState(false);
  const [attachmentdisplay, setattachmentdisplay] = useState(false);
  const chkfilesize = (e) => {
    if (e.target.files.length) {
      let fileSize = 0;
      for (let i = 0; i < e.target.files.length; i++) {
        fileSize += e.target.files[i].size;
      }
      //console.log(fileSize);
      if (fileSize / 1048576 > 200) {
        setfilesizemore(true);
      } else {
        setfilesizemore(false);
      }
    }
  };

  const [missdetails, setmissdetails] = useState([]);
  const [documentpage, setDocPage] = useState(0);
  const [documenttotalUser, setdocumenttotalUser] = useState();
  const [documentcountnumber, setdocumentcountnumber] = useState();
  const [documentnumberofElements, setdocumentnumberofElements] = useState();
  const [documentdetails, setdocumentdetails] = useState([]);
  const [documentgetpageno, setdocumentgetpageno] = useState();

  const [getDocPage, setgetDocPage] = useState();
  const [totalDocUser, settotalDocUser] = useState();
  const [countDocnumber, setcountDocnumber] = useState();
  const [numberofDocElements, setnumberofDocElements] = useState();

  const handledocumentpage = (event, value) => {
    let data = missdetails.slice((value - 1) * 10, value * 10);
    setdocumentcountnumber(Number(value - 1));
    setdocumentdetails(data);
    setdocumentnumberofElements(data.length);
  };

  const getClientDoc = () => {
    setbackdropOpen(true);
    axios
      .get(`${base_url.api5}/getAllAttachmentsByDocumentTypeAndClientId`, {
        headers: {
          Authorization: `Bearer ${clientdetails?.token}`,
          clientId: clientdetails?.clientId,
          page: documentpage,
          size: 10,
          documentType: "miscellaneous_doc",
        },
      })
      .then((res) => {
        let data = [];
        res.data.client_attachment.forEach((element) => data.push(element));
        // res.data.data.forEach((element) => data.push(element));
        setdocumentcountnumber(Number(0));
        setmissdetails(data);
        const data2 = data.slice(0, 10) || [];
        setdocumentdetails(data.length > 0 ? data.slice(0, 10) : []);
        setdocumentnumberofElements(data2.length);
        setdocumentgetpageno(Math.ceil(Number(data.length) / 10));
        setdocumenttotalUser(data.length);
        setbackdropOpen(false);
      })
      .catch((err) => {
        setclientDocuments(null);
        setgetDocPage(null);
        settotalDocUser(null);
        setcountDocnumber(null);
        setnumberofDocElements(null);
        setbackdropOpen(false);
        //console.log(err);
      });
  };

  const downloadAll = (path, clientId) => {
    //console.log("Path--------->", path);
    setbackdropOpen(true);
    let file_name;
    if (path.split("amazonaws.com//")[1]) {
      file_name = path.split("amazonaws.com//")[1];
    } else {
      file_name = path.split("amazonaws.com/")[1];
    }

    const header = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${clientdetails?.token}`,
        clientId: clientdetails?.clientId,
        fileName: clientdetails?.clientId + `/` + file_name,
      },
    };

    var url = `${base_url.api5}/downloadAttachmentByClientIdAndFileName`;
    fetch(url, header)
      .then((response) => readAllChunks(response))
      .then(async function (response) {
        const downloadFileType = localStorage.getItem(
          "client_attachment_file_content_type"
        );
        var blob = new Blob(response, { type: downloadFileType });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file_name;
        link.click();
        setbackdropOpen(false);
      })
      .catch((error) => {
        setbackdropOpen(false);
        //console.log(error);
      });
  };

  const readAllChunks = (readableStream) => {
    localStorage.setItem(
      "client_attachment_file_content_type",
      readableStream.headers.get("content-type")
    );
    const reader = readableStream.body.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return chunks;
        }
        chunks.push(value);
        return pump();
      });
    }
    return pump();
  };

  const [finalFile, setFinalFile] = useState();
  const [finalFileType, setFinalFileType] = useState();

  const handleDocument = (event, value) => {
    setbackdropOpen(true);
    setDocPage(value - 1);
    getClientDoc(value - 1);
  };

  return (
    <div class="accountbody">
      <div class="header-container">
        <h2 className="accountbody-title">Miscellaneous Document</h2>
        {/* <button className="outline-button" onClick={() => setIsOpen2(true)} >ADD DOCUMENT</button> */}
      </div>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ marginBottom: "15px", overflowX: "auto" }}>
        <table className="table tbb">
          <thead className="thb">
            <tr>
              <th>SN</th>
              <th>Title</th>
              <th>Added On</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {documentdetails?.map((item, index) => (
              <tr key={index + 1}>
                <td>{index + 1}</td>
                <td>
                  {item.clientId ? (
                    <span className="filedownloadtd">
                      {item.attachmentName}
                    </span>
                  ) : (
                    <span className="filedownloadtd">
                      {item.attachmentName}
                    </span>
                  )}
                </td>
                <td>
                  {moment(item.createdOn || item.created_on).format(
                    "DD MMM YYYY"
                  )}
                  <br />
                  {moment(item.createdOn || item.created_on).format("hh.mm a")}
                </td>

                <td>
                  <div
                    style={{
                      position: "relative",
                      textAlign: "left",
                    }}
                  >
                    <a
                      className="outline-button"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        downloadAll(item.path, item.clientId);
                      }}
                    >
                      Download
                    </a>

                    <WorkflowFiles
                      clientId={item.clientId}
                      // fileName={item.path.split("amazonaws.com/")[1]}
                      fileName={
                        item.path.includes("amazonaws.com//")
                          ? item.path.split("amazonaws.com//")[1]
                          : item.path.split("amazonaws.com/")[1]
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Row>
          <Col md={6}>
            {documentdetails?.length ? (
              <p style={{ marginTop: "20px" }}>
                {documentcountnumber * 3 + 1} to{" "}
                {documentcountnumber * 3 + documentnumberofElements} of{" "}
                {documenttotalUser} records
              </p>
            ) : (
              <p className="formfooterrecordstyle">No Record Found</p>
            )}
          </Col>
          <Col md={4}></Col>
          <Col md={2}>
            <div className={classes.root}>
              <Pagination
                count={documentgetpageno}
                onChange={handledocumentpage}
                shape="rounded"
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OtherDocuments;
