import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import CourseSearchBody from "./CourseSearchBody";
// import ReactNavbar from "../Navbar/Navbar";
import NavbarNew from "../Navbar/NavbarNew";

const CourseSearchResult = () => {
  const location = useLocation();

  const pageStartRef = useRef(null);
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div ref={pageStartRef}>
      <NavbarNew />
      {/* <ReactNavbar /> */}
      <CourseSearchBody pageStartRef={pageStartRef} />
      <Footer />
    </div>
  );
};

export default CourseSearchResult;
