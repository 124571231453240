import React from "react";
import AgentMainBody from "../AgentMainBody";
// import AccountEnquiry from "../AccountEnquiry";
import AccountInbox from "../AccountInbox";
import AccountSendMessage from "../AccountSendMessage";
import ReactNavbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import EditAccount from "../EditAccount";
import ChangePassword from "../ChangePassword";
import AccountFees from "../AccountFees";
import AgentFees from "../AgentFees";
import LeadCompare from "../LeadCompare";
import LeadProfile from "../LeadProfile";
// import LeadDocument from "../PassportDocument";
import LeadMainBody from "../LeadMainBody";

const AgentClients = (props) => {
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );

  return (
    <div>
      <ReactNavbar
        // closeModal={closeModal}
        // openModal={openModal}
        fromwhere={"Account"}
      />
      {props.sidebartab === "dashboard" ? (
        <AgentMainBody />
      ) : (
        <>
          {props.sidebartab === "fees" ? (
            <div className="accountbody">
              {clientdetails?.agentId ? <AgentFees /> : <AccountFees />}
            </div>
          ) : (
            <>
              {props.sidebartab === "inbox" ? (
                <AccountInbox />
              ) : (
                <>
                  {props.sidebartab === "message" ? (
                    <AccountSendMessage />
                  ) : (
                    <>
                      {props.sidebartab === "edit" ? (
                        <EditAccount />
                      ) : (
                        <>
                          {props.sidebartab === "comapre" ? (
                            <LeadCompare />
                          ) : (
                            <>
                              {props.sidebartab === "applied" ? (
                                <LeadMainBody />
                              ) : (
                                <ChangePassword />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {props.sidebartab === "edit" ? (
        <Footer />
      ) : (
        <div className="account_footer">
          {/* <Footer /> */}
        </div>
      )}
    </div>
  );
};

export default AgentClients;