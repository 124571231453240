import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Footer = () => {
  const currentyear = new Date().getFullYear();
  let location = useLocation();
  return (
    <div
      className={
        location.pathname === "/" || location.pathname === "/searchbycity"
          ? "footer_container main-footer-home"
          : "footer_container"
      }
    >
      <p className="text-center m-0">
        &copy; Copyright {currentyear}, Internaltional Student World
      </p>
    </div>
  );
};

export default Footer;
