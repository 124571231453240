import React, { useEffect, useRef } from "react";
import HomePageBody from "../HomePage/HomePageBody";
// import ReactNavbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import NavbarNew from "../Navbar/NavbarNew";
import NavbarForSearch from "../Navbar/NavbarForSearch";

const Mainhomepage = () => {
  const pageStartRef = useRef(null);
  // const [scroll, setScroll] = useState(false);
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
    // window.addEventListener("scroll", () => {
    //   setScroll(window.scrollY > 620);
    // });
  }, []);
  return (
    <div ref={pageStartRef}>
      {/* <ReactNavbar /> */}
      {/* <NavbarNew /> */}
      <NavbarForSearch />
      <HomePageBody />
      <div className="mt-4">
        <Footer />
      </div>
    </div>
  );
};

export default Mainhomepage;
