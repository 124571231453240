import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../Footer/Footer";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PasswordStrengthBar from "react-password-strength-bar";
import axios from "axios";
import base_url from "../../api/bootapi";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
// import ReactNavbar from "../../Navbar/Navbar";
import ReCAPTCHA from "react-google-recaptcha";
import NavbarNew from "../../Navbar/NavbarNew";
import { Collapse, CardBody, Card } from "reactstrap";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import CheckIcon from "@mui/icons-material/Done";
import EyeIcon from "@mui/icons-material/Visibility";
import EyeCloseIcon from "@mui/icons-material/VisibilityOff";

import Swal from "sweetalert2";
import attachmenturl from "../../api/attachmenturl";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1000,
    color: "#fff",
  },
}));

const Register = () => {
  const classes = useStyles();
  let location = useLocation();
  const [backdropopen, setbackdropOpen] = useState(false);
  const [success, setsuccess] = useState(false);
  const [inputtype, setinputtype] = useState("password");
  const [confirminputtype, setconfirminputtype] = useState("password");
  const [countryrequired, setcountryrequired] = useState(false);
  const [captchaerror, setcaptchaerror] = useState(false);
  const [staterequired, setstaterequired] = useState(false);
  const [chkPass, setchkPass] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const [chkPassState, setchkPassState] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();
  const pageStartRef = useRef(null);
  const clientdetails = JSON.parse(
    localStorage.getItem("isw_client_details_frontend")
  );
  const [captchavalue, setcaptchavalue] = useState("");
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    if (clientdetails?.clientId) {
      navigate("/");
    }
    pageStartRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handlePassword = (e) => {
    var decimal =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (e.target.value.match(decimal)) {
      setchkPass(false);
      setchkPassState(e.target.value);
      return true;
    } else {
      setchkPass(true);
      return false;
    }
  };

  const selectCountry = (val) => {
    setcountry(val);
  };
  const selectRegion = (val) => {
    setregion(val);
  };

  const handlelogin = () => {
    sessionStorage.setItem("prev_path_before_login", location.pathname);
    navigate("/login");
  };

  const onChangeCaptcha = (value) => setcaptchavalue(value);

  const handleregister = (data2) => {
    //console.log("hii");
    //console.log(data2);
    if (!country1) {
      setcountryrequired(true);
      setstaterequired(false);
      return;
    } else if (!region) {
      setcountryrequired(false);
      setstaterequired(true);
      return;
    }
    setcountryrequired(false);
    setstaterequired(false);
    // if (!captchavalue) {
    //   setcaptchaerror(true);
    //   return;
    // }
    setcaptchaerror(false);
    if (data2.confirmPassword !== chkPassState) return;
    setbackdropOpen(true);
    const isoDateString = new Date().toISOString();
    const datenow = isoDateString.split(".")[0];
    let data = {
      addedOn: datenow,
      country: country1,
      state: region,
      email: data2.email.trim(),
      emailVerificationStatus: 0,
      firstName: data2.firstName,
      gender: data2.gender,
      id: 0,
      leadType: "HotLead",
      lastName: data2.lastName,
      middleName: data2.middleName,
      officeId: 2,
      officeName: "ISW Online",
      officeCode: "ISWCC",
      // password: chkPass === false ? chkPassState.trim() : null,
      //password: "sangam@123",
      password: chkPassState == null ? null : chkPassState.trim(),
      phone: Number(data2.phoneNumber),
      status: 1,
      updatedOn: datenow,
      username: data2.email.trim(),
    };
    postregister(data);
  };

  const postregister = (data) => {
    axios
      .post(`${base_url.api5}/addNewClientTemp`, data)
      .then((res) => {
        toast.success("User Registered Successfully");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Confirmation Email Sent",
          html: "User successfully registered. The verification email was sent into your inbox for confirmmation. <br> <h6>Please Verify the link</h6>",
          footer: `<a href="${attachmenturl}/#/login" >Go To Login Page</a>`,
        });
        setbackdropOpen(false);
        setsuccess(true);
        document.getElementById("myForm").reset();
      })
      .catch((err) => {
        //console.log(err.response.data.message);
        setbackdropOpen(false);
        if ((err.response.data.message = "Data Already Exists")) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Email Already Exists!",
          });
        } else if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const [isPersonalOpen, setIsPersonalOpen] = useState(true);

  const personalToggle = () => setIsPersonalOpen(!isPersonalOpen);

  const [isAddressOpen, setIsAddressOpen] = useState(false);

  const addressToggle = () => setIsAddressOpen(!isAddressOpen);

  const [country1, setcountry] = useState("United States");
  const [countryValid, setCountryValid] = useState(false);

  const [region, setregion] = useState("New York");
  const [regionValid, setRegionValid] = useState(false);

  const prevBtns = document.querySelectorAll(".btn-prev");
  const nextBtns = document.querySelectorAll(".btn-next");
  const progress = document.getElementById("progress");
  const formSteps = document.querySelectorAll(".form-step");
  const progressSteps = document.querySelectorAll(".progress-step");

  let formStepsNum = 0;

  nextBtns.forEach((btn) => {
    btn.addEventListener("click", () => {
      formStepsNum++;
      updateFormSteps();
      updateProgressbar();
    });
  });

  prevBtns.forEach((btn) => {
    btn.addEventListener("click", () => {
      formStepsNum--;
      updateFormSteps();
      updateProgressbar();
    });
  });

  function updateFormSteps() {
    formSteps.forEach((formStep) => {
      formStep.classList.contains("form-step-active") &&
        formStep.classList.remove("form-step-active");
    });

    formSteps[formStepsNum].classList.add("form-step-active");
  }

  function updateProgressbar() {
    progressSteps.forEach((progressStep, idx) => {
      if (idx < formStepsNum + 1) {
        progressStep.classList.add("progress-step-active");
      } else {
        progressStep.classList.remove("progress-step-active");
      }
    });

    const progressActive = document.querySelectorAll(".progress-step-active");

    progress.style.width =
      ((progressActive.length - 1) / (progressSteps.length - 1)) * 100 + "%";
  }

  const gotologin = () => navigate("/login");
  return (
    <div ref={pageStartRef}>
      <Backdrop className={classes.backdrop} open={backdropopen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavbarNew />
      <div className="coursecompare_bodymaincontainer login login-bg">
        {/* <h2 className="text-center mb-0">Create A Free Account in</h2>
        <h2 className="text-center">International Student World</h2> */}

        <div className="loginbox">
          <Container fluid className="p-0">
            <Row className="m-0 loginbox-mb">
              <Col className="login-leftbox pb-5">
                <h6>Sign Up</h6>
                <h2>Create A Free Account in International Student World</h2>
              </Col>
              <Col className="login-rightform">
                <div className="login-rightform-inn">
                  <div
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    <form
                      id="myForm"
                      onSubmit={handleSubmit(handleregister)}
                      style={{ position: "relative" }}
                    >
                      <div>
                        <h2>Personal Details</h2>

                        <FormGroup row>
                          <Col
                            xs="12"
                            md="6"
                            className="already-register-first-nm"
                          >
                            <Label>First name</Label>
                            <input
                              type="text"
                              id="tfirst"
                              className="form-control underline-input"
                              {...register("firstName", { required: true })}
                            />
                            {errors.first_name && (
                              <p className="errormessage">
                                First Name is Required
                              </p>
                            )}
                          </Col>
                          <Col xs="12" md="6">
                            <Label>Middle name</Label>
                            <input
                              type="text"
                              id="tmiddle"
                              className="form-control underline-input"
                              {...register("middleName")}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col xs="12" md="6">
                            <Label>Last name</Label>
                            <input
                              type="text"
                              id="tlast"
                              className="form-control underline-input"
                              {...register("lastName", { required: true })}
                            />
                            {errors.last_name && (
                              <p className="errormessage">
                                Last Name is Required
                              </p>
                            )}
                          </Col>

                          <Col xs="12" md="6">
                            <Label>Gender</Label>
                            <select
                              className="form-control"
                              id="tgender"
                              {...register("gender", { required: true })}
                            >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                            {errors.gender && (
                              <p className="errormessage">Gender is Required</p>
                            )}
                          </Col>
                        </FormGroup>

                        <p style={{ fontSize: "24px", fontWeight: "700" }}>
                          Contact Details
                        </p>

                        <FormGroup row>
                          <Col
                            xs="12"
                            md="6"
                            className="already-register-first-nm"
                          >
                            <Label>Password</Label>
                            <div style={{ position: "relative" }}>
                              <input
                                type={inputtype}
                                autoComplete="new-password"
                                {...register("password", { required: true })}
                                className="form-control underline-input"
                                value={chkPassState}
                                onChange={(e) =>
                                  setchkPassState(e.target.value)
                                }
                              />
                              {inputtype === "password" ? (
                                <i
                                  className="fa fa-eye-slash fonticonpositioning"
                                  onClick={() => setinputtype("text")}
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye fonticonpositioning"
                                  onClick={() => setinputtype("password")}
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                            <PasswordStrengthBar password={chkPassState} />
                          </Col>
                          <Col xs="12" md="6">
                            <Label>Confirm Password</Label>
                            <div style={{ position: "relative" }}>
                              <input
                                type={confirminputtype}
                                {...register("confirmPassword", {
                                  required: true,
                                })}
                                onChange={(e) =>
                                  setconfirmpassword(e.target.value)
                                }
                                className="form-control"
                                placeholder="Confirm Password"
                              />
                              <i className="fa fa-lock designi" />
                              {confirminputtype === "password" ? (
                                <i
                                  className="fa fa-eye-slash fonticonpositioning"
                                  onClick={() => setconfirminputtype("text")}
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye fonticonpositioning"
                                  onClick={() =>
                                    setconfirminputtype("password")
                                  }
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                            {errors.confirmPassword && (
                              <p className="errormessage mb-0">
                                Confirm Password is required
                              </p>
                            )}
                            {confirmpassword &&
                              confirmpassword !== chkPassState && (
                                <p className="errormessage mb-0">
                                  Password not matching or strong password
                                  needed
                                </p>
                              )}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col xs="12" md="6">
                            <Label>Email</Label>
                            <input
                              type="email"
                              autoComplete="new-email"
                              id="temail"
                              className="form-control underline-input"
                              {...register("email", {
                                required: true,
                                pattern:
                                  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/,
                              })}
                            />
                            {errors.email?.type === "required" && (
                              <p className="errormessage">E-Mail is required</p>
                            )}
                            {errors.email?.type === "pattern" && (
                              <p className="errormessage">Enter valid E-Mail</p>
                            )}
                          </Col>
                          <Col xs="12" md="6">
                            <Label>Phone number</Label>
                            <input
                              type="text"
                              id="tcontact"
                              className="form-control underline-input"
                              {...register("phoneNumber")}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Col xs="12" md="6">
                            <Label>Country</Label>
                            <CountryDropdown
                              className="form-control selecttag2"
                              value={country1}
                              onChange={(val) => selectCountry(val)}
                            />
                            <i className="fa fa-globe designi" />
                            {countryrequired && (
                              <p className="errormessage mb-0">
                                Country is required
                              </p>
                            )}
                          </Col>
                          <Col xs="12" md="6">
                            <Label>State</Label>
                            {country1 === "Nepal" ? (
                              <select
                                className="form-control selecttag2"
                                onChange={(e) => selectRegion(e.target.value)}
                              >
                                <option value="">Select Province</option>
                                <option value="Province No. 1">
                                  Province No. 1
                                </option>
                                <option value="Madhesh Province">
                                  Madhesh Province
                                </option>
                                <option value="Bagmati Province">
                                  Bagmati Province
                                </option>
                                <option value="Gandaki Province">
                                  Gandaki Province
                                </option>
                                <option value="Lumbini Province">
                                  Lumbini Province
                                </option>
                                <option value="Karnali Province">
                                  Karnali Province
                                </option>
                                <option value="Sudurpashchim Province">
                                  Sudurpashchim Province
                                </option>
                              </select>
                            ) : (
                              <RegionDropdown
                                className="form-control selecttag2"
                                defaultOptionLabel="Select State"
                                country={country1}
                                value={region}
                                onChange={(val) => selectRegion(val)}
                              />
                            )}
                            <i className="fa fa-globe designi" />
                            {staterequired && (
                              <p className="errormessage mb-0">
                                State is required
                              </p>
                            )}
                          </Col>
                        </FormGroup>

                        <Row className="mt-5 align-items-center">
                          <Col xs="12" md="6" className="mb-3">
                            <button
                              class="btn btn-next btn-green width-50 btn-next-register"
                              type="submit"
                              value="Submit"
                            >
                              Submit
                            </button>
                          </Col>

                          <Col
                            xs="12"
                            md="6"
                            className="text-right text-already-register"
                          >
                            Already have account?{" "}
                            <a onClick={handlelogin}>
                              <strong>Sign In</strong>
                            </a>
                          </Col>
                        </Row>
                      </div>
                    </form>{" "}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Register;
